<script setup>
import { useRoute, useRouter } from "vue-router";
import { onKeyStroke } from "@vueuse/core";
import { Chat } from "../Chat";

const route = useRoute();
const router = useRouter();

const onBack = () => {
	router.push({ name: "Chats" });
};

onKeyStroke("Escape", (e) => {
	e.preventDefault();
	onBack();
});
</script>

<template>
	<Chat @back="onBack" :chat-id="route.params.chat_id" />
</template>
