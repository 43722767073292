<script setup>
import { toRefs } from "vue";
import { Skeleton, useSuspense } from "@shared/ui";
import { TYPOGRAPHY_TYPES } from "./Typography.const";

const props = defineProps({
	suspense: { type: Boolean, default: false },
	type: { type: String, default: TYPOGRAPHY_TYPES.CAPTION_2 },
	noSuspense: { type: Boolean, default: false },
	tag: {
		type: String,
		default: "p",
	},
});

const { suspense } = toRefs(props);

const { isSuspense } = useSuspense(suspense);
</script>

<template>
	<component :is="tag" class="typography" :class="type">
		<slot v-if="!isSuspense || noSuspense"></slot>
		<Skeleton class="typography-skeleton" v-else />
	</component>
</template>

<style lang="scss" scoped>
@import "./Typography.scss";
</style>
