<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { useModal, SvgTemplate, TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { useTranslation } from "i18next-vue";
import { useScreenSize } from "@shared/lib";
import { ChangeLocaleModal } from "../ChangeLocaleModal";

const { t } = useTranslation();
const { isDesktop } = useScreenSize();
const store = useStore();

const modal = ref();

const { open } = useModal(modal);

const getAnnouncementLanguage = computed(() => {
	const language = store.getters["user/getAnnouncementTranslate"];
	return language === "is_origin" ? t("translateAnnouncementOriginal") : language.toUpperCase();
});
</script>

<template>
	<button class="change-locale-button" type="button" @click="open">
		<SvgTemplate class="change-locale-button__svg" name="hyeroglyph" />
		<Typography
			v-if="isDesktop"
			class="change-locale-button__name"
			:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
		>
			{{ t("translateAbbreviation") }}
		</Typography>
		<Typography
			v-if="isDesktop"
			class="change-locale-button__name"
			:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
		>
			{{ getAnnouncementLanguage }}
		</Typography>
	</button>
	<ChangeLocaleModal ref="modal" />
</template>

<style lang="scss" scoped>
@import "./ChangeLocaleBtn.scss";
</style>
