import { nonDigitRegExp } from "@shared/const";

/**
 * @param {string} value
 * @returns {string}
 */
export const toLocalePhone = (value = "") => {
	if (typeof value !== "string") {
		throw new Error(`Ожидал string, получил ${typeof value}`);
	}

	const formatedValue = value.replaceAll(nonDigitRegExp, "");

	if (formatedValue.length > 0) {
		return `+${formatedValue}`;
	}

	return "";
};

export const toRawPhone = (value) => {
	const formatedValue = value.replaceAll(nonDigitRegExp, "");

	if (formatedValue.length > 0) {
		return `+${formatedValue}`;
	}

	return "";
};
