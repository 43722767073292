/**
 * @typedef {{
 * 	ad_name: string;
 * 	ad_status: string;
 * 	category_id: number;
 * 	category_name: string;
 * 	creation_date: string;
 * 	currency_label: string;
 * 	description: string;
 * 	item_city: string;
 * 	item_created: string;
 * 	item_id: number;
 * 	price: number;
 * 	price_rub: number;
 * 	rub_label: string;
 * 	status_name: string;
 * 	title_photo: string;
 * }} AnnouncementCard
 */

export const ANNOUNCEMENT_PREVIEW_ORIENTATION = {
	HORIZONTAL: "horizontal",
	VERTICAL: "vertical",
};
