import { startsWith } from "lodash-es";
import { Capacitor } from "@capacitor/core";
import { UAParser } from "ua-parser-js";

export const IS_DEV = startsWith(import.meta.env.MODE, "development");

// Отладка PWA
export const IS_DEV_PWA = import.meta.env.MODE === "development-pwa";

// Препрод сборка
export const IS_DEV_BUILD = import.meta.env.MODE === "development-build";

export const IS_PROD_WEB = import.meta.env.MODE === "production-web";

export const JWT_TOKEN = import.meta.env.VITE_JWT_TOKEN;

export const BUCKET_URL = import.meta.env.VITE_BUCKET_URL;

export const BUCKET_PREFIX_MEDIUM = import.meta.env.VITE_BUCKET_PREFIX_MEDIUM;

export const GOOGLE_CLIENT = import.meta.env.VITE_GOOGLE_CLIENT;

export const APPLE_CLIENT = import.meta.env.VITE_APPLE_CLIENT;

export const TELEGRAM_BOT = import.meta.env.VITE_TELEGRAM_BOT;

export const SUPPORT_TELEGRAM = import.meta.env.VITE_SUPPORT_TELEGRAM;

export const SUPPORT_WHATSAPP = import.meta.env.VITE_SUPPORT_WHATSAPP;

export const IS_IOS = Capacitor.getPlatform() === "ios";

// Андроид приложение
export const IS_TWA_ANDROID = document.referrer.startsWith("android-app://");

// Добавлено на главный экран
export const IS_STANDALONE = (() => {
	const os = new UAParser().getOS();

	const isIos = os.name === "iOS";

	const isAndroid = os.name === "Android";

	if (isIos) {
		return window.matchMedia("(display-mode: standalone)").matches && !IS_IOS;
	}

	if (isAndroid) {
		return window.matchMedia("(display-mode: fullscreen)").matches && !IS_TWA_ANDROID;
	}

	return false;
})();

// Не используется
export const IS_ANDROID = Capacitor.getPlatform() === "android";

export const IS_NATIVE_APP = IS_IOS || IS_ANDROID;

export const IS_WEB_APP = !IS_NATIVE_APP;

export const GOOGLE_CLIENT_IOS = import.meta.env.VITE_GOOGLE_CLIENT_IOS;

export const DOMAIN_URL = import.meta.env.VITE_API_URL;

export const FB_CONFIG = JSON.parse(import.meta.env.VITE_FB_CFG || "{}");
