export default {
	ru: {
		favoritesAnnouncementListTitle: "Избранные объявления",
		favoritesAnnouncementListEmptyTitle: "Пока ничего нет",
		favoritesAnnouncementListEmpty1: "Чтобы не потерять понравившееся объявление, нажмите",
		favoritesAnnouncementListEmpty2: "и оно отобразится на этой странице",
	},
	en: {
		favoritesAnnouncementListTitle: "Favorites",
		favoritesAnnouncementListEmptyTitle: "Nothing here yet",
		favoritesAnnouncementListEmpty1: "To save a ad you like, click",
		favoritesAnnouncementListEmpty2: "and it will appear on this page",
	},
	ka: {
		favoritesAnnouncementListTitle: "რჩეული განცხადებები",
		favoritesAnnouncementListEmptyTitle: "ჯერ არაფერია...",
		favoritesAnnouncementListEmpty1: "რომ არ დაკარგოთ შერჩეული განცხადებები დააჭირეთ",
		favoritesAnnouncementListEmpty2: "და ის გამოისახება ამ გვერძე",
	},
	tr: {
		favoritesAnnouncementListTitle: "Favorilerim",
		favoritesAnnouncementListEmptyTitle: "Henüz bir şey yok",
		favoritesAnnouncementListEmpty1: "Beğendiğiniz bir ilanı kaybetmemek için",
		favoritesAnnouncementListEmpty2: "e tıklayın, bu sayfada görünecektir",
	},
};
