/** Вариации типов стилей для компонентов */
export const THEME_VARIANTS = /** @type {const} */ ({
	PRIMARY: "primary",
	SECONDARY: "secondary",
	SUCCESS: "success",
	DANGER: "danger",
	WARNING: "warning",
	INFO: "info",
	LIGHT: "light",
	DARK: "dark",
});

export const THEMES = /** @type {const} */ ({
	CONTAINED: "contained",
	TEXT: "text",
	OUTLINED: "outlined",
});
