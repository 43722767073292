import { CHAT_ACTIONS, REQUST_STATUSES } from "@shared/const";

export const SET_SOCKET = (state, { socket, status }) => {
	state.status.socket = status;
	state.socket = socket;
};

export const RESET_CHATS = (state) => {
	state.chats = [];
	state.actions = [];
};

export const HANDLE_ACTION = (state, data) => {
	if (data.action === CHAT_ACTIONS.LOAD_CHATS) {
		// загрузка чатов
		state.chats = data.chats;
		state.status.chats = REQUST_STATUSES.SUCCESS;
	} else if (data.action === CHAT_ACTIONS.LOAD_CHAT) {
		// загрузка сообщений чата
		const chat = state.chats.find((chat) => +chat.chat_id === +data.chat_id);
		chat.messages = data.chat_messages;
	} else if (data.action === CHAT_ACTIONS.USER_STOP_TYPING) {
		// прекращение события - печатает
		state.actions = state.actions.filter(
			(x) => +x.chat_id !== +data.chat_id || x.action !== CHAT_ACTIONS.USER_TYPING
		);
	} else if (data.action === CHAT_ACTIONS.DELIVERED) {
		// сообщенеи приянто на отправку, обработано и сохранено в БД
		const chat = state.chats.find((chat) => +chat.chat_id === +data.chat_id);
		const message = chat.messages.find((x) => x.extKey === data.extKey);
		message.delivered = true;
		message.timestamp = data.timestamp;
		message.message_id = data.message_id;
	} else if (data.action === CHAT_ACTIONS.NOT_DELIVERED) {
		// Ошибка при обработки сообщения на стороне сервера
		const chat = state.chats.find((chat) => +chat.chat_id === +data.chat_id);
		const message = chat.messages.find((x) => x.extKey === data.extKey);
		message.delivered = false;
		message.error = true;
		message.timestamp = data.timestamp;
		message.message_id = data.message_id;
	} else if (data.action === CHAT_ACTIONS.MESSAGE_SEEN) {
		// Сообщение просмотрено получателем
		const chat = state.chats.find((chat) => +chat.chat_id === +data.chat_id);
		const message = chat.messages.find((x) => x.message_id === data.message_id);
		message.viewed = true;
	} else if (data.action === CHAT_ACTIONS.USER_ONLINE) {
		// Собеседник онлайн
		const chat = state.chats.find((chat) => +chat.user_id === +data.sender);
		if (chat) chat.user_online = true;
	} else if (data.action === CHAT_ACTIONS.USER_OFFLINE) {
		// Собеседник оффлайн
		const chat = state.chats.find((chat) => +chat.user_id === +data.sender);
		if (chat) chat.user_online = false;
	} else if (data.action === CHAT_ACTIONS.INIT_CHAT) {
		// Инициализация чата
		state.chats.push(data.chat);
	} else {
		// остальные события отправляются в шину
		state.actions.push(data);
	}
};

export const HANDLE_MESSAGE = (state, { data, userId }) => {
	const chat = state.chats.find((chat) => +chat.chat_id === +data.chat_id);
	if (chat.messages) chat.messages.push(data);
	chat.text = data.text;
	chat.translated = data.translated;
	chat.viewed = false;
	chat.last_msg_timestamp = data.timestamp;
	if (+userId !== +data.sender) chat.unseen_counter += 1;
};

export const SET_MESSAGE = (state, { message, chatId }) => {
	const chat = state.chats.find((chat) => +chat.chat_id === +chatId);
	chat.messages.push(message);
	chat.text = message.text;
	chat.translated = null;
	chat.viewed = true;
};

export const SET_MESSAGE_SEEN = (state, { chatId, messageId }) => {
	const chat = state.chats.find((chat) => +chat.chat_id === +chatId);
	chat.viewed = true;
	const message = chat.messages.find((message) => +message.message_id === +messageId);
	message.viewed = true;
	chat.unseen_counter -= 1;
};

export const SET_CHATS_STATUS = (state, status) => {
	state.status.chats = status;
};
