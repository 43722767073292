// TODO сделать композицией
export const saveData = async (itemId) => {
	let newData = [];
	if (typeof localStorage !== "undefined") {
		try {
			const favoriteDataArray = localStorage.getItem("favoriteData")
				? JSON.parse(localStorage.getItem("favoriteData"))
				: [];

			const objectIndex = favoriteDataArray.findIndex((obj) => obj?.item_id === itemId);

			if (objectIndex === -1) {
				newData = { item_id: itemId, is_favor: true };
				favoriteDataArray.push(newData);
			} else {
				favoriteDataArray.splice(objectIndex, 1);
			}

			localStorage.setItem("favoriteData", JSON.stringify(favoriteDataArray));
			return true;
		} catch (error) {
			console.error("Error saving to Local Storage:", error);
		}
	}
	// else if (typeof caches !== "undefined") {
	// 	try {
	// 		const cache = await caches.open("favoriteCache");
	// 		const cachedData = await cache.match("favoriteCache");
	// 		let cachedDataArray = [];

	// 		if (cachedData) {
	// 			cachedDataArray = JSON.parse(await cachedData.text());
	// 			const objectIndex = cachedDataArray.findIndex((obj) => obj.item_id === itemId);

	// 			if (objectIndex === -1) {
	// 				newData = { item_id: itemId, is_favor: true };
	// 				cachedDataArray.push(newData);
	// 			} else {
	// 				cachedDataArray.splice(objectIndex, 1);
	// 			}
	// 		} else {
	// 			newData = { item_id: itemId, is_favor: true };
	// 			cachedDataArray.push(newData);
	// 		}

	// 		await cache.put("favoriteCache", new Response(JSON.stringify(cachedDataArray)));
	// 		return true;
	// 	} catch (error) {
	// 		console.error("Error saving to Cache Storage:", error);
	// 	}
	// }

	return false;
};

export const getUnregisterItemId = async () => {
	if (typeof localStorage !== "undefined") {
		const favoriteData = JSON.parse(localStorage.getItem("favoriteData"));
		return favoriteData || [];
	}
	// if(typeof caches !== "undefined") {
	// 	const cache = await caches.open("favoriteCache");
	// 	const cachedData = await cache.match("favoriteCache");

	// 	let favoriteData = [];
	// 	if (cachedData) {
	// 		const cachedDataArray = await cachedData.json();
	// 		favoriteData = cachedDataArray;
	// 	}
	// 	return favoriteData || [];
	// }

	return [];
};

export const fetchIsFavorite = async (idComparison) => {
	const data = await getUnregisterItemId();
	return data ? data.find((obj) => obj.item_id === idComparison)?.is_favor : false;
};
