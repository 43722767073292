import { inject, isRef, unref, watch } from "vue";
import { isArray } from "lodash-es";
import { REQUST_STATUSES } from "@shared/const";
import { PRELOADER_INJECTION_KEY } from "./Preloader.const";

export const usePreloader = () => {
	const { open, close, isOpen } = inject(PRELOADER_INJECTION_KEY);

	const watchByStatuses = (statuses) => {
		if (isArray(statuses)) {
			watch(
				statuses,
				(newVal) => {
					if (newVal.some((status) => unref(status) === REQUST_STATUSES.PENDING)) {
						open();
					} else {
						close();
					}
				},
				{ immediate: true }
			);
		}
		if (isRef(statuses)) {
			watch(
				statuses,
				(newVal) => {
					if (newVal === REQUST_STATUSES.PENDING) {
						open();
					} else {
						close();
					}
				},
				{ immediate: true }
			);
		}
	};

	return { open, close, isOpen, watchByStatuses };
};
