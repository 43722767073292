<script setup>
import { ref } from "vue";
import { Modal, TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { AnnouncementGroups } from "@widgets/AnnouncementGroups";

const modalRef = ref(null);
defineProps({
	disableClose: { type: Boolean, default: false },
});

const emit = defineEmits(["selectGroup", "selectTagCategory"]);

const selectGroup = (id) => {
	emit("selectGroup", id);
	modalRef.value.close();
};

const selectTagCategory = (id) => {
	emit("selectTagCategory", id);
	modalRef.value.close();
};

const open = () => {
	modalRef.value?.open();
};

defineExpose({ open });
</script>

<template>
	<Modal :disable-close="disableClose" ref="modalRef" v-bind="modalProps">
		<div class="catalog__container">
			<Typography class="catalog__title" :type="TYPOGRAPHY_TYPES.TEXT">
				<slot name="title"></slot>
			</Typography>
			<AnnouncementGroups @update:group="selectGroup" @update:tagCategory="selectTagCategory" />
		</div>
	</Modal>
</template>

<style lang="scss" scoped>
@import "./CatalogModal.scss";
</style>
