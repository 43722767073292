<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { Tippy } from "vue-tippy";
import { REQUST_STATUSES, STORAGE_KEY } from "@shared/const";
import { SvgTemplate, TYPOGRAPHY_TYPES, Typography, Image } from "@shared/ui";
import { useScreenSize, getCountryFlag, isAutoShowModal } from "@shared/lib";
import { useTranslation } from "i18next-vue";
import { ChangeRegionModal } from "../ChangeRegionModal";
import { SupposedLocationModal } from "../SupposedLocationModal";
import { SupposedLocation } from "../SupposedLocation";

defineProps({
	headerMobile: {
		type: Boolean,
		default: false,
	},
});

const store = useStore();
const route = useRoute();
const { t } = useTranslation();

const tippySupposedRegion = ref();
const country = ref(null);
const city = ref(null);

const openChangeRegionModal = () => {
	changeRegionModal.value.open();
};

const { isDesktop } = useScreenSize();

const currentLocation = computed(() => {
	return store.getters["user/getHandleLocation"];
});

const selectedRegion = computed(() => {
	const cityData = store.getters["announcement/cityById"](currentLocation.value?.regionId);
	if (cityData?.id === 0) cityData.name = t("placeholderChangeRegionBtnEmpty");
	return cityData;
});

const currentUserStatus = computed(() => {
	return store.getters["user/currentUserStatus"];
});

const currentCountryFlag = computed(() => {
	return getCountryFlag({
		countryId: currentLocation.value?.countryId,
		countryIsoCode: currentLocation.value?.isoCode,
	});
});

const changeRegionModal = ref(null);
const supposedLocationModal = ref(null);

const isSuspense = computed(() => {
	return (
		[REQUST_STATUSES.PENDING, REQUST_STATUSES.OUTDATED].includes(currentUserStatus.value) ||
		!selectedRegion.value?.name
	);
});

const discardSupposedLocation = () => {
	openChangeRegionModal();
};

const supposedUserLocation = computed(() => store.getters["user/getSupposedUserLocation"]);

const supposedUserLocationStatus = computed(
	() => store.getters["user/getSupposedUserLocationStatus"]
);

watch(
	() => supposedUserLocationStatus.value,
	() => {
		if (supposedUserLocationStatus.value) {
			if (supposedUserLocation.value) {
				country.value = store.getters["announcement/countryById"](
					supposedUserLocation.value.country_id
				);
				city.value = store.getters["announcement/cityById"](supposedUserLocation.value.city_id);
				if (country.value) {
					autoOpenSupposedLocation();
				}
			}
		}
	}
);

const isShowModalHomePage = computed(() => {
	return route.name === "Home" && currentLocation.value.countryId === 0;
});

const autoOpenSupposedLocation = () => {
	const autoVisibleModal = isAutoShowModal(isShowModalHomePage.value, STORAGE_KEY.KEY_TIMER, 1);
	if (isShowModalHomePage.value && autoVisibleModal) {
		if (isDesktop.value) {
			tippySupposedRegion.value.show();
		} else {
			supposedLocationModal.value.open();
		}
	}
};

const setLocation = ({ withRegion = false } = {}) => {
	const data = {
		countryId: country.value.id,
		isoCode: country.value.isoCode,
		regionId: withRegion ? city.value.id : "0",
	};
	store.commit("user/SET_HANDLE_LOCATION", data);
};

const onConfirmSupposedLocation = () => {
	setLocation({ withRegion: true });
	store.commit("announcement/SET_CURRENCT_HANDLE_LOCATION", true);
	tippySupposedRegion.value.hide();
};

const onBackSupposedLocation = () => {
	setLocation();
	tippySupposedRegion.value.hide();
	changeRegionModal.value.open();
};
</script>

<template>
	<Tippy
		:offset="[0, 8]"
		:hideOnClick="true"
		:interactive="true"
		:max-width="400"
		ref="tippySupposedRegion"
		theme="white"
		placement="bottom-start"
		zIndex="7000"
		trigger="manual"
	>
		<div class="location" @click.stop="openChangeRegionModal">
			<Image
				class="location-icon"
				:src="currentCountryFlag"
				:key="currentCountryFlag"
				:suspense="isSuspense"
			/>
			<template v-if="!headerMobile">
				<Typography class="location-name" :type="TYPOGRAPHY_TYPES.BUTTON_1" :suspense="isSuspense">
					{{ selectedRegion?.name }}
				</Typography>
				<SvgTemplate v-if="isDesktop" class="location-chevron-icon" name="polygon" />
			</template>
			<ChangeRegionModal ref="changeRegionModal" />
			<SupposedLocationModal
				:country="country"
				:city="city"
				@discard-supposed-location="discardSupposedLocation"
				@onConfirm="onConfirmSupposedLocation"
				@onBack="onBackSupposedLocation"
				ref="supposedLocationModal"
			/>
		</div>
		<template #content>
			<div class="location-tippy">
				<SupposedLocation
					:country="country"
					:city="city"
					with-buttons
					@onConfirm="onConfirmSupposedLocation"
					@onBack="onBackSupposedLocation"
				/>
			</div>
		</template>
	</Tippy>
</template>

<style lang="scss" scoped>
@import "./ChangeRegionButton.scss";
</style>
