<script setup>
import { useScreenSize } from "@shared/lib";
import { Skeleton } from "@shared/ui";

const { isDesktop } = useScreenSize();
</script>

<template>
	<div v-if="!isDesktop" class="saller__line" />
	<div class="seller__penging">
		<Skeleton class="skeleton__contact" />
		<div class="seller__info">
			<Skeleton class="skeleton__image" />
			<div class="seller__info-name">
				<Skeleton class="skeleton__name" />
				<Skeleton class="skeleton__time" />
				<Skeleton class="skeleton__location" />
			</div>
		</div>
		<Skeleton class="skeleton__other" />
		<div class="saller__line"></div>
		<Skeleton class="skeleton__tel" />
		<Skeleton class="skeleton__tel" />
		<Skeleton class="skeleton__tel" />
	</div>
</template>

<style lang="scss" scoped>
@import "./SalerCardPending.scss";
@import "./SalerCard.scss";
</style>
