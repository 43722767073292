<!-- eslint-disable no-unused-vars -->
<script setup>
import { computed, ref, nextTick, onMounted } from "vue";
import { Typography, TYPOGRAPHY_TYPES } from "@shared/ui";
import { useElementBounding } from "@vueuse/core";
import { withPx } from "@shared/lib";

const props = defineProps({
	description: {
		type: String,
		default: "",
	},
	isToggle: {
		type: Boolean,
		default: false,
	},
	hideHeight: {
		type: Number,
		default: 100,
	},
});

const contentRef = ref(null);

const { height } = useElementBounding(contentRef);

const contentHeight = computed(() => {
	const hiddenHeight = !isCanHide.value ? height.value : props.hideHeight;
	return withPx(!props.isToggle ? hiddenHeight : height.value);
});

const isCanHide = computed(() => {
	return height.value > props.hideHeight;
});

const skipTransition = ref(true);

onMounted(() => {
	setTimeout(() => {
		skipTransition.value = false;
	}, 10);
});
</script>

<template>
	<slot name="toggleUp" :is-can-hide="isCanHide"></slot>
	<div
		class="text-collapse__descr"
		:class="{
			'text-collapse__descr_hidden': !isToggle && isCanHide,
			'text-collapse__descr_skip': skipTransition,
		}"
		:style="{ height: contentHeight }"
	>
		<Typography ref="contentRef" class="text-collapse__text" :type="TYPOGRAPHY_TYPES.TEXT_1">
			{{ description }}
		</Typography>
	</div>
	<slot name="toggleDown" :is-can-hide="isCanHide"></slot>
</template>

<style scoped lang="scss">
@import "./DescriptionTextCollapse.scss";
</style>
