<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { TYPOGRAPHY_TYPES, Typography, SvgTemplate } from "@shared/ui";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	languageId: {
		type: String,
		default: "",
	},
});

const store = useStore();
const { t } = useTranslation();

const langTitle = computed(() => {
	return t("profileSettingsLanguage");
});

const data = [
	{
		id: "lang",
		icon: "hyeroglyph",
	},
	// {
	// 	id: "coin",
	// 	title: "Валюта просмотра объявлений",
	// 	icon: "coin",
	// },
];

const languages = computed(() => {
	return store.getters["user/languages"].find((item) => item.language_code === props.languageId);
});
</script>

<template>
	<div class="settings">
		<slot name="title" />
		<div class="settings__wrapper">
			<div class="settings__info" v-for="(item, index) in data" :key="item.id + index">
				<SvgTemplate class="settings__svg" :name="item.icon" />
				<div class="settings__description">
					<Typography class="settings__title" :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
						{{ langTitle }}
					</Typography>
					<Typography class="settings__variable" :type="TYPOGRAPHY_TYPES.CONTACT">
						{{ item.id === "lang" ? languages?.display_name_ru : t("profileSettingsRub") }}
					</Typography>
				</div>
			</div>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "./ProfileSettings.scss";
</style>
