<script setup>
import { computed } from "vue";

const props = defineProps({
	count: { type: Number, default: 0 },
});

const unseenCounter = computed(() => {
	const num = +props.count;
	const res = num >= 100 ? "+99" : num.toString();
	return res;
});
</script>

<template>
	<div
		v-if="count"
		class="unseen-counter"
		:class="{
			'unseen-counter_one': unseenCounter.length === 1,
			'unseen-counter_two': unseenCounter.length === 2,
			'unseen-counter_three': unseenCounter.length === 3,
		}"
	>
		{{ unseenCounter }}
	</div>
</template>

<style lang="scss" scoped>
@import "./ChatUnseenCounter.scss";
</style>
