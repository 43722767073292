export default {
	ru: {
		changeRegionModalTitle: "Местоположение",
		changeRegionModalSubtitle: "Выберите местоположение, где хотите найти объявление",
		changeRegionModalBtnBack: "Сбросить",
		changeRegionModalBtnConfirm: "Применить",
	},
	en: {
		changeRegionModalTitle: "Location",
		changeRegionModalSubtitle: "Select the location where you want to find the ad",
		changeRegionModalBtnBack: "Reset",
		changeRegionModalBtnConfirm: "Apply",
	},
	ka: {
		changeRegionModalTitle: "ადგილმდებარეობა",
		changeRegionModalSubtitle: "აირჩიეთ ადგილმდებარეობა, სად გინდათ იპოვოთ განცხადება",
		changeRegionModalBtnBack: "ახლიდან",
		changeRegionModalBtnConfirm: "დადასტურება",
	},
	tr: {
		changeRegionModalTitle: "Konum",
		changeRegionModalSubtitle: "İlan görmek istediğiniz konumu seçiniz",
		changeRegionModalBtnBack: "Sıfırla",
		changeRegionModalBtnConfirm: "Uygula",
	},
};
