<script setup>
import { computed } from "vue";
import {
	AnnouncementListSection,
	ON_PAGE_COUNT,
	useAnnouncementStore,
	useCategories,
} from "@entities/announcement";
import { useFilters } from "@features/Filters";
import { useInitData } from "@shared/lib";
import { DefaultHeader } from "@widgets/DefaultHeader";
import { useTranslation } from "i18next-vue";
import FiltersEmptyList from "./FiltersEmptyList.vue";

const initData = useInitData();

const { filtersParams } = useFilters();
const { categoriesParams } = useCategories();
const { t } = useTranslation();

const params = computed(() => ({
	...filtersParams.value,
	...categoriesParams.value,
	orders: [],
	with_ads: true,
	on_page: ON_PAGE_COUNT.GENERAL_PAGE_COUNT,
}));

const {
	getAnnouncement,
	updateAnnouncements,
	announcementsList,
	announcementsListStatus,
	isEmptyList,
} = useAnnouncementStore(params);

initData(async () => {
	await getAnnouncement();
});
</script>
<template>
	<AnnouncementListSection
		:items="announcementsList"
		:status="announcementsListStatus"
		with-keep-alive
		:is-empty="isEmptyList"
		@update-list="updateAnnouncements"
	>
		<template #title>
			<DefaultHeader :title="t('searchingResults')" class="filters-list__title" />
		</template>
		<template #empty>
			<FiltersEmptyList />
		</template>
	</AnnouncementListSection>
</template>

<style lang="scss" scoped>
@import "./FiltersAnnouncementList.scss";
</style>
