import { APPLE_CLIENT, DOMAIN_URL, IS_NATIVE_APP } from "@shared/const";
import { nanoid } from "nanoid";
import { useStore } from "vuex";
import { SignInWithApple } from "@capacitor-community/apple-sign-in";
import { App } from "@capacitor/app";

export const useAppleIdAuth = () => {
	const store = useStore();

	const signIn = async () => {
		if (IS_NATIVE_APP) {
			await signInNative();
		} else {
			await signInWeb();
		}
	};

	const signInNative = async () => {
		const { id } = await App.getInfo();
		const state = nanoid(64);

		const options = {
			clientId: id,
			redirectURI: DOMAIN_URL,
			scope: "email name",
			state,
		};

		const { response } = await SignInWithApple.authorize(options);

		const { familyName, givenName, email } = response;
		const authParams = {
			credential: {
				code: response.authorizationCode,
				id_token: response.identityToken,
				state,
			},
		};

		if (familyName && givenName && email) {
			authParams.user = {
				email: response.email,
				name: {
					firstName: response.givenName,
					lastName: response.familyName,
				},
			};
		}

		await store.dispatch("user/authAppleUser", authParams);
		await store.dispatch("user/getCurrentUser");
	};

	const signInWeb = async () => {
		AppleID.auth.init({
			clientId: APPLE_CLIENT,
			scope: "email name",
			redirectURI: DOMAIN_URL,
			state: nanoid(64),
			usePopup: true,
		});
		const data = await AppleID.auth.signIn();
		await store.dispatch("user/authAppleUser", {
			credential: data.authorization,
			user: data.user,
		});
		await store.dispatch("user/getCurrentUser");
	};

	return { signIn };
};
