export default {
	ru: {
		changeLanguage: "Выберите язык",
		changeLanguageInterface: "Язык интерфейса",
		changeLanguageAnnouncement: "Язык объявлений",
		changeLanguageOriginal: "Язык объявлений",
	},
	en: {
		changeLanguage: "Select the language",
		changeLanguageInterface: "Interface language",
		changeLanguageAnnouncement: "Announcement language",
		changeLanguageOriginal: "Original Language",
	},
	ka: {
		changeLanguage: "აირჩიეთ სასურველი ენა",
		changeLanguageInterface: "ინტერფეისის ენა",
		changeLanguageAnnouncement: "განცხადების ენა",
		changeLanguageOriginal: "Original Language",
	},
	tr: {
		changeLanguage: "Dili değiştir",
		changeLanguageInterface: "Arayüz dili",
		changeLanguageAnnouncement: "Duyuru dili",
		changeLanguageOriginal: "Original Language",
	},
};
