export const SET_STATUS = (state, { status, entityId }) => {
	state.filesStatus[entityId] = status;
};

export const SET_FILES = (state, { data, entityId }) => {
	state.files[entityId] = data;
};

export const DELETE_FILES = (state, { filename, entityId }) => {
	delete state.files[entityId]?.[filename];
};

export const UPDATE_FILES = (state, { data, entityId }) => {
	state.files[entityId] = { ...state.filename[entityId], ...data };
};

export const SET_DOCUMENTS = (state, data) => {
	state.documents = data;
};

export const SET_DOCUMENT_STATUS = (state, status) => {
	state.status.document = status;
};
