<script setup>
import { withPx } from "@shared/lib";
import {
	useElementBounding,
	useElementVisibility,
	useIntervalFn,
	useWindowScroll,
	debouncedWatch,
} from "@vueuse/core";
import { ref, watch, watchEffect } from "vue";

const props = defineProps({
	isVisible: { type: Boolean, required: true },
	offset: { type: Array, default: () => [0, 0] },
	checkIntervalBounding: { type: Boolean, default: false },
});

const emit = defineEmits(["hide"]);

const target = ref(null);

const { left, top, height, width, update } = useElementBounding(target);
const windowScroll = useWindowScroll();

const isTargetVisible = useElementVisibility(target);
const topPos = ref(0);

const leftPos = ref(0);

debouncedWatch(
	[top, height, () => props.offset, windowScroll],
	() => {
		const res = top.value + height.value + props.offset[1] + windowScroll.y.value;
		topPos.value = withPx(res);
	},
	{ debounce: 5, immediate: true }
);

debouncedWatch(
	[left, () => props.offset, windowScroll],
	() => {
		const res = left.value + props.offset[0] + windowScroll.x.value;
		leftPos.value = withPx(res);
	},
	{ debounce: 5, immediate: true }
);

const onClickOutside = () => {
	emit("hide");
};

watch(isTargetVisible, () => {
	if (!isTargetVisible.value) {
		emit("hide");
	}
});

// Засталяем проверять изменился ли bounding у целевого элемента
if (props.checkIntervalBounding) {
	const { pause, resume } = useIntervalFn(update, 100);
	pause();
	watchEffect(() => {
		if (props.isVisible) {
			resume();
		} else {
			pause();
		}
	});
}
</script>

<template>
	<div class="tooltip-target" ref="target" v-click-outside="onClickOutside">
		<slot />
	</div>
	<Teleport to="#app">
		<Transition name="fade-tooltip">
			<KeepAlive>
				<div
					class="tooltip"
					:style="{
						left: leftPos,
						top: topPos,
						width: withPx(width),
					}"
					v-if="isVisible"
				>
					<slot name="content" />
				</div>
			</KeepAlive>
		</Transition>
	</Teleport>
</template>

<style lang="scss" scoped>
@import "./Tooltip.scss";
</style>
