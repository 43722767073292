import { HTTP_METHODS, getApiUrl } from "@shared/const";
import { httpRequest } from "@shared/lib";

export const apiGetPhones = () => {
	const endpoint = "dimensions/phones";

	const url = getApiUrl(endpoint);

	return httpRequest({ url, method: HTTP_METHODS.GET });
};
