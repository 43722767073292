<script setup>
import { TYPOGRAPHY_TYPES, Typography, SvgTemplate } from "@shared/ui";
import { useTranslation } from "i18next-vue";

// const onBack = () => {
// 	router.back();
// };

const { t } = useTranslation();
</script>

<template>
	<div class="navigation-block">
		<!-- <button type="button" class="back-button" @click="onBack">
			<SvgTemplate class="back-button__icon" name="left-arrow" />
		</button> -->
		<div class="navigation-block__menu">
			<RouterLink to="profile" class="navigation-block__menu-button">
				<SvgTemplate class="back-button__icon" name="user-header-icon" />
				<Typography :type="TYPOGRAPHY_TYPES.BUTTON_1">
					{{ t("navigationProfileMyProgile") }}
				</Typography>
			</RouterLink>
			<RouterLink to="/my-announcements" class="navigation-block__menu-button">
				<SvgTemplate class="back-button__icon" name="menucard" />
				<Typography :type="TYPOGRAPHY_TYPES.BUTTON_1">{{ t("navigationProfileMyAds") }}</Typography>
			</RouterLink>
			<RouterLink
				to="/my-reviews"
				class="navigation-block__menu-button navigation-block__menu-button_star"
			>
				<SvgTemplate class="back-button__icon" name="star" />
				<Typography :type="TYPOGRAPHY_TYPES.BUTTON_1">{{ t("reviewsTitle") }}</Typography>
			</RouterLink>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./NavigationProfile.scss";
</style>
