import { helpers } from "@vuelidate/validators";

const isTrue = (value) => () => {
	return value.value;
};

const isTrueCheck = (value, message = "Не валидное значени") => {
	return helpers.withMessage(message, isTrue(value));
};

export default isTrueCheck;
