import { isObject } from "lodash-es";
import { IS_DEV } from "@shared/const";
import { ON_PAGE_COUNT } from "../const";

export const SET_STATUS = (state, status) => {
	state.status.announcements = status;
};

export const SET_ANNOUNCEMENTS = (state, payload) => {
	if (payload.items.length < ON_PAGE_COUNT.GENERAL_PAGE_COUNT) {
		state.announcements.isFullfield = true;
	}
	state.announcements.items = payload.items;
	state.announcements.pagination = payload.pagination;
	state.announcements.ads.push(...payload.ads);
};

export const SET_ANNOUNCEMENTS_SLIDER = (state, data) => {
	state.announcementsSliderList = data.items;
};

export const SET_CATEGORIES = (state, payload) => {
	state.categories = payload;
};

export const SET_REGIONS = (state, payload) => {
	state.regions = payload;
};

export const SET_REGIONS_STATUS = (state, status) => {
	state.status.regions = status;
};

export const SET_AD_ID = (state, payload) => {
	state.currentADId = payload;
};

export const SET_FAVORITES_STATUS = (state, status) => {
	state.status.favoritesAnnouncements = status;
};

export const UPDATE_ANNOUNCEMENTS = (state, payload) => {
	if (payload.items.length < ON_PAGE_COUNT.GENERAL_PAGE_COUNT) {
		state.announcements.isFullfield = true;
	}
	state.announcements.items = [...state.announcements.items, ...payload.items];
	state.announcements.pagination = payload.pagination;
	state.announcements.ads.push(...payload.ads);
};

export const CLEAR_ANNOUNCEMENT = (state) => {
	const { announcementsCity, announcementsCountry, announcementsOther } =
		state.announcementsSplitItemsMain;

	state.announcements = { items: [], pagination: {}, isFullfield: false, ads: [] };

	announcementsCity.data = [];
	announcementsCountry.data = [];
	announcementsOther.data = [];
	announcementsCity.isFullfield = false;
	announcementsCountry.isFullfield = false;
};

export const SET_CURRENCT_HANDLE_LOCATION = (state, payload) => {
	state.currentSearchLocation = payload;
};

export const SET_AD = (state, payload) => {
	state.currentAD = payload;
};

export const UPDATE_AD = (state, query) => {
	const { item_id: itemId, ...data } = query;
	if (state.currentAD.item_id === itemId) state.currentAD = { ...state.currentAD, ...data };
};

export const SET_CATEGORIES_STATUS = (state, status) => {
	state.status.categories = status;
};

export const SET_CONTROLLER = (state, payload) => {
	const { id, controller } = payload;
	state.announcementsControllers[id] = controller;
};

export const DELETE_CONTROLLER = (state, id) => {
	delete state.announcementsControllers[id];
};

export const UPDATE_AD_LIST = (state, query) => {
	const { itemId, isFavor } = query;
	const curItem = state.announcements.items.find((x) => x.item_id === itemId);
	if (curItem) {
		curItem.is_favor = isFavor;
	}
};

export const SET_AD_STATUS = (state, status) => {
	state.status.currentAD = status;
};

export const DELETE_AD_BY_ID = (state, itemId) => {
	state.announcements.items = state.announcements.items.filter((x) => x.item_id !== itemId.itemId);
};

export const TOGGLE_ACTIVE_EDITOR = (state, bool) => {
	state.activeAdEditor = bool;
};

export const SET_ANNOUNCEMENTS_BY_ID = (state, { data, id, onPage }) => {
	state.announcementsById[id] = {
		isFullfield: false,
	};
	if (data.items.length < onPage) {
		state.announcementsById[id].isFullfield = true;
	}
	state.announcementsById[id].items = data.items;
	state.announcementsById[id].pagination = data.pagination;
};

export const UPDATE_ANNOUNCEMENTS_BY_ID = (state, { data, id, onPage }) => {
	if (!isObject(state.announcementsById[id])) {
		if (IS_DEV) {
			console.log("Не найден id списка");
		}
		return;
	}
	if (data.items.length < onPage) {
		state.announcementsById[id].isFullfield = true;
	}
	state.announcementsById[id].items.push(...data.items);
	state.announcementsById[id].pagination = data.pagination;
};

export const SET_CONTROLLER_BY_ID = (state, { controllerId, controller, id }) => {
	if (!isObject(state.announcementsControllersById[id])) {
		state.announcementsControllersById[id] = {};
	}
	state.announcementsControllersById[id][controllerId] = controller;
};

export const SET_ANNOUNCEMENTS_STATUS_BY_ID = (state, { status, id }) => {
	state.status.announcementsById[id] = status;
};

export const CLEAR_ANNOUNCEMENT_BY_ID = (state, id) => {
	delete state.announcementsById[id];
	delete state.announcementsControllersById[id];
	delete state.status.announcementsById[id];
};

export const RESET_ANNOUNCEMENT_BY_ID = (state, id) => {
	delete state.announcementsById[id];
};

export const DELETE_CONTROLLER_BY_ID = (state, { id, controllerId }) => {
	delete state.announcementsControllersById?.[id]?.[controllerId];
};

export const DELETE_ALL_CONTROLLERS_BY_HASH = (state, id) => {
	delete state.announcementsControllersById[id];
};

export const SET_CURRENCY_STATUS = (state, status) => {
	state.status.currency = status;
};

export const SET_CURRENCY = (state, payload) => {
	state.currency = payload;
};

export const SET_TAGS_STATUS = (state, status) => {
	state.status.tags = status;
};

export const SET_TAGS = (state, payload) => {
	state.tags = payload;
};

export const SET_UNREG_FAVORITES = (state, payload) => {
	if (payload.items.length < ON_PAGE_COUNT.GENERAL_PAGE_COUNT) {
		state.unregistereFavoritsList.isFullfield = true;
	}

	state.unregistereFavoritsList.items = payload.items;
	state.unregistereFavoritsList.page = payload.pagination.page;
};

export const UPDATE_UNREG_FAVORITS = (state, payload) => {
	if (payload.items.length < ON_PAGE_COUNT.GENERAL_PAGE_COUNT) {
		state.announcements.isFullfield = true;
	}
	state.unregistereFavoritsList.items = [...state.unregistereFavoritsList.items, ...payload.items];
	state.unregistereFavoritsList.page = payload.pagination.page;
};

export const CLEAR_UNREG_FAVORITS = (state) => {
	state.unregistereFavoritsList = {
		items: [],
		page: 0,
		isFullfield: false,
		ads: [],
	};
};

export const SET_UNREG_FAVOR_STATUS = (state, status) => {
	state.status.unregisterFavoritsStatus = status;
};
