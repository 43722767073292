import { REQUST_STATUSES } from "@shared/const";
import { ANNOUNCEMENT_PREVIEW_ORIENTATION } from "@entities/announcement";

export const announcementListProps = {
	status: {
		type: String,
		default: REQUST_STATUSES.OUTDATED,
	},
	items: {
		type: Array,
		default: () => [],
	},
	withFavorite: {
		type: Boolean,
		default: true,
	},
	isFullfield: {
		type: Boolean,
		default: false,
	},
	orientation: {
		type: String,
		default: ANNOUNCEMENT_PREVIEW_ORIENTATION.VERTICAL,
	},
	updateByItem: {
		type: Boolean,
		default: false,
	},
	withKeepAlive: {
		type: Boolean,
		default: false,
	},
};
