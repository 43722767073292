import { apiGetCurrency } from "../api";

export const getCurrency = ({ commit }) => {
	try {
		const { data } = apiGetCurrency();
		commit("SET_CURRENCY", data);
	} catch (error) {
		// TODO  буфер ошибок
		console.error(error);
	}
};
