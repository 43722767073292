<script setup>
import { computed, ref, watch, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import { isNil } from "lodash-es";
import {
	TYPOGRAPHY_TYPES,
	Typography,
	Modal,
	Button,
	SvgTemplate,
	InputField,
	INPUT_THEMES,
	INPUT_MODE,
} from "@shared/ui";
import { SIZES, THEME_VARIANTS } from "@shared/const";
import { SILENT_ERROR } from "@entities/errors";
import { useVuelidate } from "@vuelidate/core";
import { helpers } from "@vuelidate/validators";
import { useEventBus } from "@vueuse/core";
import { requiredIf, email, useScreenSize, useBackOrHome } from "@shared/lib";
import { useAppleIdAuth, useGoogleAuth, useTelegramAuth } from "@entities/user";
import { useTranslation } from "i18next-vue";
import { useToast } from "vue-toast-notification";

const props = defineProps({
	preventSelfOpen: {
		type: Boolean,
		default: false,
	},
	preventBack: {
		type: Boolean,
		default: false,
	},
});

const store = useStore();
const toast = useToast();
const { t } = useTranslation();

const { isMobile } = useScreenSize();

/** @type {import("vue").Ref<InstanceOf<typeof Modal>>} */
const modalRef = ref(null);

const emit = defineEmits("closed");
const closeCreateModal = useEventBus("closeCreateModal");

const STEPS = {
	INIT: "init",
	REG: "registration",
	REC: "recover",
	EMAIL_CONFIRM: "email_confirm",
	PASSWORD_CONFIRM: "password_confirm",
};

const data = reactive({
	login: null,
	password: null,
	confirmPassword: null,
	code: null,
	nonce: null,
	forgotFlag: false,
	step: STEPS.INIT,
	pending: false,
});

const needToBeEqual = (value) => {
	return !helpers.req(value) || value === data.password;
};

const $externalResults = ref({});

const validators = {
	login: {
		requiredIf: requiredIf(() => [STEPS.INIT, STEPS.REG, STEPS.REC].includes(data.step)),
		email,
	},
	password: {
		requiredIf: requiredIf(() => [STEPS.INIT, STEPS.PASSWORD_CONFIRM].includes(data.step)),
	},
	confirmPassword: {
		requiredIf: requiredIf(() => data.step === STEPS.PASSWORD_CONFIRM),
		needToBeEqual: helpers.withMessage("Пароли должны совпадать", needToBeEqual),
	},
	code: { requiredIf: requiredIf(() => data.step === STEPS.EMAIL_CONFIRM) },
};

const validate = useVuelidate(validators, data, { $externalResults });

const openModal = () => {
	modalRef.value.open();
};

const onConfirm = () => {
	modalRef.value.close();
};

const isAuthSuccess = ref(false);

const { back: routerBack } = useBackOrHome();

const closeModal = () => {
	if (props.preventBack) {
		closeCreateModal.emit();
		return;
	}
	if (!props.preventSelfOpen) {
		if (!isAuthSuccess.value) {
			routerBack();
		}
	} else {
		emit("closed");
	}
};

defineExpose({ open: openModal });

const isUserLoggedIn = computed(() => {
	return store.getters["user/isUserLoggedIn"];
});

/** @type {import("vue").Ref<InstanceOf<typeof InputField>>} */
const loginInputRef = ref(null);

onMounted(() => {
	watch(
		() => isUserLoggedIn.value,
		(value) => {
			if (!isNil(value) && !value && !props.preventSelfOpen) {
				openModal();
			}
		},
		{ immediate: true }
	);
});

const succesAuth = () => {
	toast.success(t("toastAuthSuccessful"), { position: "top-right" });
	isAuthSuccess.value = true;
	modalRef.value.close();
};

const failedAuth = (error) => {
	if (SILENT_ERROR !== error) {
		toast.error(t("toastAuthError"), { position: "top-right" });
	}
};

const { signIn: telegramSignIn } = useTelegramAuth();

const tgBtn = async () => {
	try {
		await telegramSignIn();
		succesAuth();
	} catch (error) {
		failedAuth(error);
	}
};

const { signIn: googleSignIn } = useGoogleAuth();

const googleBtn = async () => {
	try {
		await googleSignIn();
		succesAuth();
	} catch {
		failedAuth();
	}
};

const { signIn: appleIdSignIn } = useAppleIdAuth();

const appleIdBtn = async () => {
	try {
		await appleIdSignIn();
		succesAuth();
	} catch (error) {
		failedAuth();
	}
};

const enter = async () => {
	try {
		validate.value.$clearExternalResults();
		validate.value.$touch();
		if (validate.value.$invalid) return;
		data.pending = true;
		await store.dispatch("user/initEmailAuth", { email: data.login, password: data.password });
		await store.dispatch("user/getCurrentUser");
		succesAuth();
	} catch (err) {
		if (err.response.status === 400) {
			if ("email" in err.response.data.errors.json) {
				const msg = err.response.data.errors.json.email;
				$externalResults.value = { login: msg };
			}
			if ("password" in err.response.data.errors.json) {
				const msg = err.response.data.errors.json.password;
				$externalResults.value = { password: msg };
			}
		} else {
			failedAuth();
		}
	} finally {
		data.pending = false;
	}
};

const recover = () => {
	validate.value.$reset();
	data.forgotFlag = true;
	data.step = STEPS.REC;
};

const registrate = () => {
	validate.value.$reset();
	data.step = STEPS.REG;
};

const back = () => {
	validate.value.$reset();
	data.forgotFlag = false;
	data.step = STEPS.INIT;
};

const backToCode = () => {
	validate.value.$reset();
	if (data.forgotFlag) {
		data.step = STEPS.REC;
		return;
	}
	data.step = STEPS.REG;
};

const sendCode = () => {
	validate.value.$clearExternalResults();
	validate.value.$touch();
	if (validate.value.$invalid) return;
	data.pending = true;
	store
		.dispatch("user/initEmailAuth", { email: data.login, forgot_flag: data.forgotFlag })
		.then(() => {
			validate.value.$reset();
			data.step = STEPS.EMAIL_CONFIRM;
		})
		.catch((err) => {
			if (err.response.status === 400) {
				$externalResults.value = { login: err.response.data.errors.json.email };
			}
		})
		.finally(() => {
			data.pending = false;
		});
};

const confirmCode = () => {
	validate.value.$clearExternalResults();
	validate.value.$touch();
	if (validate.value.$invalid) return;
	data.pending = true;
	store
		.dispatch("user/confirmEmailAuth", { email: data.login, code: data.code })
		.then((nonce) => {
			validate.value.$reset();
			data.nonce = nonce;
			data.password = null;
			data.confirmPassword = null;
			data.step = STEPS.PASSWORD_CONFIRM;
		})
		.catch((err) => {
			if (err.response.status === 400) {
				$externalResults.value = { code: err.response.data.errors.query.code };
			}
		})
		.finally(() => {
			data.pending = false;
		});
};

const finish = async () => {
	try {
		validate.value.$clearExternalResults();
		validate.value.$touch();
		if (validate.value.$invalid) return;
		data.pending = true;
		await store.dispatch("user/passwordEmailAuth", {
			email: data.login,
			password: data.password,
			nonce: data.nonce,
		});
		validate.value.$reset();
		data.login = null;
		data.password = null;
		data.confirmPassword = null;
		data.code = null;
		data.nonce = null;
		data.forgotFlag = false;
		data.step = STEPS.INIT;
		await store.dispatch("user/getCurrentUser");
		succesAuth();
	} catch (err) {
		if (err.response.status === 400) {
			$externalResults.value = { password: err.response.data.errors.json.nonce };
		} else {
			failedAuth();
		}
	} finally {
		data.pending = false;
	}
};
</script>
<template>
	<Modal
		ref="modalRef"
		@confirm="onConfirm"
		@close="closeModal"
		:button-default="false"
		:full-expand-mobile="true"
		:priority="100"
		no-padding-content
	>
		<div class="modal-auth">
			<div
				class="modal-auth__main"
				:class="{ 'modal-auth__main-padding': data.step != STEPS.INIT }"
			>
				<div class="modal-auth__header">
					<img
						class="modal-auth__header-logo"
						:class="{ 'modal-auth_unvisible': data.step != STEPS.INIT }"
						src="/img/Logo2.svg"
						alt="Logotip rexpat"
					/>
					<Typography
						class="modal-auth_blue1 modal-auth__header-main"
						:type="TYPOGRAPHY_TYPES.TITLE_2"
					>
						<span v-if="data.step == STEPS.INIT">{{ t("titleAuthLogin") }}</span>
						<span v-if="data.step == STEPS.REC">{{ t("titleAuthPasswordRecovery") }}</span>
						<span v-if="data.step == STEPS.REG">{{ t("titleAuthRegistration") }}</span>
						<span v-if="data.step == STEPS.PASSWORD_CONFIRM">{{ t("titleAuthPassword") }}</span>
						<span v-if="data.step == STEPS.EMAIL_CONFIRM">{{ t("titleAuthConfirmation") }}</span>
					</Typography>
					<Typography
						class="modal-auth_blue2 modal-auth__header-secondary"
						:type="TYPOGRAPHY_TYPES.TEXT"
					>
						<span v-if="data.step == STEPS.INIT">{{ t("subtitleAuthLogin") }}</span>
						<span v-if="data.step == STEPS.REC">{{ t("subtitleAuthAuthPasswordRecovery") }}</span>
						<span v-if="data.step == STEPS.REG">{{ t("subtitleAuthRegistration") }}</span>
						<span v-if="data.step == STEPS.PASSWORD_CONFIRM">{{ t("subtitleAuthPassword") }}</span>
						<span v-if="data.step == STEPS.EMAIL_CONFIRM">{{ t("subtitleAuthConfirmation") }}</span>
					</Typography>
				</div>

				<div v-if="data.step == STEPS.INIT" class="modal-auth__alternative">
					<Typography class="modal-auth_blue2" :type="TYPOGRAPHY_TYPES.TEXT">
						{{ t("alternativeAuthTitle") }}
					</Typography>
					<div class="modal-auth__alternative-buttons">
						<button
							type="button"
							class="modal-auth__alternative-btn modal-auth__alternative-btn_tg"
							@click="tgBtn"
						>
							<SvgTemplate name="tg" />
							<Typography v-if="!isMobile" :type="TYPOGRAPHY_TYPES.BUTTON_1">Telegram</Typography>
						</button>
						<button
							type="button"
							class="modal-auth__alternative-btn modal-auth__alternative-btn_apple"
							@click="appleIdBtn"
						>
							<SvgTemplate name="apple-logo" />
							<Typography v-if="!isMobile" :type="TYPOGRAPHY_TYPES.BUTTON_1">Apple</Typography>
						</button>
						<button
							type="button"
							class="modal-auth__alternative-btn modal-auth__alternative-btn_google"
							@click="googleBtn"
						>
							<SvgTemplate name="google" />
							<Typography v-if="!isMobile" :type="TYPOGRAPHY_TYPES.BUTTON_1">Google</Typography>
						</button>
					</div>
					<Typography class="modal-auth_blue2" :type="TYPOGRAPHY_TYPES.TEXT">
						{{ t("alternativeAuthPretext") }}
					</Typography>
				</div>

				<form
					class="modal-auth__form"
					:class="{ 'modal-auth__form_init': data.step === STEPS.INIT }"
				>
					<div class="modal-auth__inputs">
						<div
							class="modal-auth__input-container"
							v-if="[STEPS.INIT, STEPS.REG, STEPS.REC].includes(data.step)"
						>
							<SvgTemplate class="modal-auth_blue2 modal-auth__input-icon" name="person" />
							<InputField
								class="modal-auth_grow1"
								ref="loginInputRef"
								:placeholder="t('alternativeAuthMailInput')"
								v-model="data.login"
								:size="SIZES.BIG"
								:mode="INPUT_MODE.ONE_WORD"
								:theme="INPUT_THEMES.LIGHT"
								autocomplete="login"
								@focus="validate.login.$reset"
								:errors="validate.login.$errors"
							/>
						</div>
						<div class="modal-auth__pw-continer">
							<SvgTemplate
								v-if="data.step == STEPS.PASSWORD_CONFIRM"
								class="modal-auth_blue2 modal-auth__two-input-icon"
								name="lock_vector"
							/>
							<div class="modal-auth__pw-inputs-continer">
								<div
									class="modal-auth__input-container"
									v-if="[STEPS.INIT, STEPS.PASSWORD_CONFIRM].includes(data.step)"
								>
									<SvgTemplate
										v-if="data.step == STEPS.INIT"
										class="modal-auth_blue2 modal-auth__input-icon"
										name="lock"
									/>
									<InputField
										class="modal-auth_grow1"
										:placeholder="
											data.step == STEPS.INIT
												? t('alternativeAuthPasswordInput')
												: t('alternativeAuthCreatePasswordInput')
										"
										v-model="data.password"
										:size="SIZES.BIG"
										:mode="INPUT_MODE.ONE_WORD"
										:theme="INPUT_THEMES.LIGHT"
										type="password"
										:autocomplete="data.step == STEPS.INIT ? 'current-password' : 'new-password'"
										@focus="validate.password.$reset"
										:errors="validate.password.$errors"
									/>
								</div>
								<div class="modal-auth__input-container" v-if="data.step == STEPS.PASSWORD_CONFIRM">
									<InputField
										class="modal-auth_grow1"
										:placeholder="t('alternativeAuthRepeatPasswordInput')"
										v-model="data.confirmPassword"
										:size="SIZES.BIG"
										:mode="INPUT_MODE.ONE_WORD"
										:theme="INPUT_THEMES.LIGHT"
										autocomplete="new-password"
										type="password"
										@focus="validate.confirmPassword.$reset"
										:errors="validate.confirmPassword.$errors"
									/>
								</div>
							</div>
						</div>
						<Typography
							v-if="data.step == STEPS.INIT"
							@click="recover"
							class="modal-auth_blue7 modal-auth__forgot-btn"
							:type="TYPOGRAPHY_TYPES.PRO_TEXT"
						>
							{{ t("alternativeAuthForgotPassword") }}
						</Typography>
						<div class="modal-auth__input-container" v-if="data.step == STEPS.EMAIL_CONFIRM">
							<SvgTemplate class="modal-auth_blue2 modal-auth__input-icon" name="envelope" />
							<InputField
								class="modal-auth_grow1"
								:placeholder="t('alternativeAuthCodeInput')"
								v-model="data.code"
								:size="SIZES.BIG"
								:mode="INPUT_MODE.ONE_WORD"
								:theme="INPUT_THEMES.LIGHT"
								autocomplete="one-time-code"
								@focus="validate.code.$reset"
								:errors="validate.code.$errors"
							/>
						</div>
					</div>
					<div class="modal-auth__btns">
						<template v-if="data.step == STEPS.INIT">
							<Button
								:variant="THEME_VARIANTS.PRIMARY"
								class="modal-auth__main-btn"
								@click="enter"
								:size="SIZES.NORMAL"
								:pill="false"
								width-full
								:pending="data.pending"
							>
								<Typography class="modal-auth_white" :type="TYPOGRAPHY_TYPES.TITLE_3">
									{{ t("titleAuthLogin") }}
								</Typography>
							</Button>
							<div class="modal-auth__registration-btn-container" v-if="data.step == STEPS.INIT">
								<Typography class="modal-auth_blue2" :type="TYPOGRAPHY_TYPES.TEXT">
									{{ t("alternativeAuthDontAccount") }}
								</Typography>
								<button type="button" @click="registrate">
									<Typography class="modal-auth_blue7" :type="TYPOGRAPHY_TYPES.PRO_TEXT">
										{{ t("alternativeAuthCreateAccountBtn") }}
									</Typography>
								</button>
							</div>
						</template>
						<template v-if="[STEPS.REG, STEPS.REC].includes(data.step)">
							<Button
								:variant="THEME_VARIANTS.PRIMARY"
								class="modal-auth__main-btn"
								@click="sendCode"
								:size="SIZES.NORMAL"
								:pill="false"
								width-full
								:pending="data.pending"
								:disabled="data.pending"
							>
								<Typography class="modal-auth_white" :type="TYPOGRAPHY_TYPES.BUTTON_2">
									{{ t("alternativeAuthToGetCodeBtn") }}
								</Typography>
							</Button>
							<button type="button" @click="back">
								<Typography class="modal-auth_blue7" :type="TYPOGRAPHY_TYPES.BUTTON_2">
									{{ t("buttonTextCancel") }}
								</Typography>
							</button>
						</template>
						<template v-if="data.step == STEPS.EMAIL_CONFIRM">
							<Button
								:variant="THEME_VARIANTS.PRIMARY"
								class="modal-auth__main-btn"
								@click="confirmCode"
								:size="SIZES.NORMAL"
								:pill="false"
								width-full
								:pending="data.pending"
							>
								<Typography class="modal-auth__text_white" :type="TYPOGRAPHY_TYPES.BUTTON_2">
									{{ t("alternativeAuthConfirmBtn") }}
								</Typography>
							</Button>
							<button type="button" @click="backToCode">
								<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">
									{{ t("alternativeAuthReceiveCodeBtn") }}
								</Typography>
							</button>
						</template>
						<template v-if="data.step == STEPS.PASSWORD_CONFIRM">
							<Button
								:variant="THEME_VARIANTS.PRIMARY"
								class="modal-auth__main-btn"
								@click="finish"
								:size="SIZES.NORMAL"
								:pill="false"
								width-full
								:pending="data.pending"
							>
								<Typography class="modal-auth_white" :type="TYPOGRAPHY_TYPES.BUTTON_2">
									{{ t("buttonTextSave") }}
								</Typography>
							</Button>
						</template>
					</div>
				</form>
			</div>
		</div>
	</Modal>
</template>
<style lang="scss" scoped>
@import "./Authorization.scss";
</style>
