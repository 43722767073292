<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { ModalConfirm } from "@shared/ui";
import { ModalPolicyContent } from "@widgets/ModalPolicyContent";
import { REQUST_STATUSES } from "@shared/const";
import { useScreenSize } from "@shared/lib";
import { isNil } from "lodash-es";

const store = useStore();
const router = useRouter();
const confirmPending = ref(false);

const { isDesktop } = useScreenSize();

const documentStatus = computed(() => {
	return store.getters["files/documentStatus"];
});

const documents = ref([]);
const documentsData = ref([]);
const confirmDocuments = ref(false);

const activeIndex = ref(0);

const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});

watch([user, documentStatus], ([newUser, newDocumentStatus]) => {
	if (newDocumentStatus === REQUST_STATUSES.SUCCESS && !newUser?.is_confirmed) {
		documents.value = store.getters["files/getDocuments"];

		baseFilterDocuments();

		if (!isNil(newUser?.user_id) && !newUser?.is_confirmed) modalRef.value.open();
	}
});

const modalRef = ref(null);

const onConfirm = () => {
	if (!confirmDocuments.value) return;

	confirmPending.value = true;
	store
		.dispatch("user/updateCurrentUser", { user_id: user.value.user_id, is_confirmed: true })
		.then(() => modalRef.value.close())
		.finally(() => {
			confirmPending.value = false;
		});
};

const onBack = () => {
	if (!isNil(Telegram?.WebApp?.initData) && Telegram?.WebApp?.initData !== "") {
		Telegram.WebApp.close();
		return;
	}
	modalRef.value.close();
	store.dispatch("user/logout").then(() => router.push({ name: "Home" }));
};

const sendConfirmDocument = (evnt) => {
	activeIndex.value = evnt;
};

const updateDocument = (evnt) => {
	documentsData.value = documents.value.filter((item) => item.document_code === evnt);
	activeIndex.value = 0;
};

const baseFilterDocuments = () => {
	documentsData.value = documents.value.filter(
		(item) => item.document_code === "user_agreement" || item.document_code === "privacy_policy"
	);
};
</script>

<template>
	<ModalConfirm
		ref="modalRef"
		@confirm="onConfirm"
		@back="onBack"
		:confirmPending="confirmPending"
		:hide-cross="true"
		:class-name="'policy__modal'"
		:full-expand-mobile="true"
		:disable-btn="!confirmDocuments"
		:hide-top-bar="!isDesktop"
		:back-text="'Отказаться'"
		:confirmText="'Принять'"
	>
		<div class="policy">
			<div class="policy__content-wrapper">
				<ModalPolicyContent
					:document-data="documentsData[activeIndex]"
					:confirm-data="documentsData"
					@updateDocument="updateDocument"
					@toggleCheckbox="confirmDocuments = !confirmDocuments"
					@sendConfirmDocument="sendConfirmDocument"
					@backBtn="baseFilterDocuments"
					:show-back-btn="documentsData.length && documentsData.length === 1"
				/>
			</div>
		</div>
	</ModalConfirm>
</template>

<style lang="scss" scoped>
@import "./PrivacyPolicy.scss";
</style>
