import { createRouter, createWebHistory } from "vue-router";
import i18next from "i18next";
import { LAYOUTS_TYPES } from "@widgets/Layout";
import { FALLBACK_LANG, LANG_WITH_PREFIX, useKeepAliveRouterSharedState } from "@shared/lib";
import { isEmpty, omit, isEqual } from "lodash";
import { FavoritesPage } from "./Favorites";
import { ChatsPage, ChatPage, ChatNotSelectedPage } from "./Chats";
import { AnnouncementCreateMain } from "./AnnouncementCreatePage";
import { AnounceCardPage } from "./AnnounceCard";
import { MainPage } from "./MainPage";
import { ProfilePage } from "./Profile";
import { EditProfilePage } from "./EditProfile";
import { AnnouncementCatalogPage } from "./AnnouncementsCatalog";
import { UserAnouncementsPage } from "./UserAnouncements";
import { MyAnnouncementPage } from "./MyAnnouncement";
import { MyReviewsPage } from "./MyReviews";
import { SearchAnnouncementPage } from "./SearchAnnouncement";
import { NotFoundPage } from "./NotFound";
import { SuccessAuth } from "./SuccessAuth";
import { DeleteProfilePage } from "./DeleteProfile";

const ANNOUNCEMENT_CARD_PAGE_NAME = "AnounceCardPage";

export const router = createRouter({
	history: createWebHistory(import.meta.env.BASE_URL),
	scrollBehavior(to, from, savedPosition) {
		const { checkIsCachedRoute } = useKeepAliveRouterSharedState();
		if (savedPosition && checkIsCachedRoute(to.name)) {
			return savedPosition;
		}

		if (!isEqual(to, from)) {
			return { top: 0 };
		}
		return {};
	},
	routes: [
		{
			path: "/:lang(en|tr|ka)?",
			children: [
				{
					path: "",
					name: "Home",
					component: MainPage,
					meta: {
						layout: LAYOUTS_TYPES.DEFAULT,
						bgColor: {
							desktop: "white",
							mobile: "white",
							tablet: "white",
						},
					},
				},
				{
					path: "profile",
					name: "Profile",
					component: ProfilePage,
					meta: {
						layout: LAYOUTS_TYPES.DEFAULT,
						headerDisable: true,
					},
				},
				{
					path: "profile-edit",
					name: "ProfileEdit",
					component: EditProfilePage,
					meta: {
						layout: LAYOUTS_TYPES.CLEAN,
					},
				},
				{
					path: "profile-delete",
					name: "ProfileDelete",
					component: DeleteProfilePage,
					meta: {
						layout: LAYOUTS_TYPES.CLEAN,
						bgColor: {
							desktop: "white",
							mobile: "white",
							tablet: "white",
						},
					},
				},
				{
					path: "my-announcements",
					name: "myAnnouncements",
					component: MyAnnouncementPage,
					meta: {
						layout: LAYOUTS_TYPES.DEFAULT,
						headerDisable: true,
					},
				},
				{
					path: "my-reviews",
					name: "MyReviewsPage",
					component: MyReviewsPage,
					meta: {
						layout: LAYOUTS_TYPES.DEFAULT,
						headerDisable: true,
					},
				},
				{
					path: "favorites",
					name: "Favorites",
					component: FavoritesPage,
					meta: {
						layout: LAYOUTS_TYPES.DEFAULT,
						bgColor: {
							desktop: "white",
							mobile: "white",
							tablet: "white",
						},
					},
				},
				{
					path: "chats",
					component: ChatsPage,
					children: [
						{
							path: "",
							name: "Chats",
							component: ChatNotSelectedPage,
							meta: {
								layout: {
									desktop: LAYOUTS_TYPES.DEFAULT,
									mobile: LAYOUTS_TYPES.DEFAULT,
									tablet: LAYOUTS_TYPES.DEFAULT,
								},
								bgColor: {
									mobile: "white",
									tablet: "white",
								},
							},
						},
						{
							path: ":chat_id",
							name: "Chat",
							component: ChatPage,
							meta: {
								layout: {
									desktop: LAYOUTS_TYPES.DEFAULT,
									mobile: LAYOUTS_TYPES.CLEAN,
									tablet: LAYOUTS_TYPES.CLEAN,
								},
								bgColor: {
									mobile: "white",
									tablet: "white",
								},
							},
						},
					],
				},
				// {
				// 	path: "/chat/:chat_id",
				// 	name: "Chat",
				// 	component: ChatPage,
				// 	meta: {
				// 		layout: LAYOUTS_TYPES.CLEAN,
				// 		subHeaderDisable: true,
				// 		footerDisable: true,
				// 	},
				// },
				{
					path: "create_page",
					name: "AnnouncementCreate",
					component: AnnouncementCreateMain,
					meta: {
						layout: LAYOUTS_TYPES.DEFAULT,
						subHeaderDisable: true,
						headerDisable: true,
						footerDisable: true,
					},
				},
				{
					path: "announce/:ad_id",
					name: ANNOUNCEMENT_CARD_PAGE_NAME,
					component: AnounceCardPage,
					meta: {
						layout: LAYOUTS_TYPES.DEFAULT,
					},
				},
				{
					path: "announcements",
					name: "Announcements",
					component: AnnouncementCatalogPage,
					meta: {
						layout: LAYOUTS_TYPES.DEFAULT,
						bgColor: {
							desktop: "white",
							mobile: "white",
							tablet: "white",
						},
					},
				},
				{
					path: "user_announcements/:user_id",
					name: "UserAnouncements",
					component: UserAnouncementsPage,
					meta: {
						layout: LAYOUTS_TYPES.DEFAULT,
						bgColor: {
							desktop: "white",
							mobile: "white",
							tablet: "white",
						},
					},
				},
				{
					path: "search_announcements/:term",
					name: "SearchAnnouncementPage",
					component: SearchAnnouncementPage,
					meta: {
						layout: LAYOUTS_TYPES.DEFAULT,
						bgColor: {
							desktop: "white",
							mobile: "white",
							tablet: "white",
						},
					},
				},
				{
					path: "success_auth",
					name: "SuccessAuth",
					component: SuccessAuth,
					meta: {
						layout: LAYOUTS_TYPES.CLEAN,
					},
				},
				{
					path: ":pathMatch(.*)*",
					name: "404_page",
					component: NotFoundPage,
					meta: {
						layout: LAYOUTS_TYPES.DEFAULT,
					},
				},
			],
		},
	],
});

// фильтруем utm метки
const utmFilter = (route) =>
	Object.fromEntries(Object.entries(route.query).filter((item) => item[0].startsWith("utm")));

router.beforeEach((to, from, next) => {
	const utmTagsTo = utmFilter(to);
	const utmTagsFrom = utmFilter(from);

	if (
		from.name === "AnounceCardPage" &&
		to.name === "AnnouncementCreate" &&
		history.state.current === "/create_page"
	) {
		next("/");
	}
	if (!isEmpty(utmTagsFrom) && isEmpty(utmTagsTo)) {
		// если в запросе есть utm метки их нужно сохранить при переходе дальше
		next({ ...to, query: { ...to.query, ...utmTagsFrom } });
	} else {
		next();
	}
});

// Добавляет префикс в зависимости от языка (ru - без префикса, остальные от iso кода)
router.beforeEach((to, _, next) => {
	const { language } = i18next;
	if (language === FALLBACK_LANG && to.params?.lang) {
		next({ ...to, params: omit(to.params, "lang") });
	} else if (LANG_WITH_PREFIX.includes(language) && to.params?.lang !== language) {
		next({ ...to, params: { ...to.params, lang: language } });
	} else {
		next();
	}
});

// При смене языка меняем префикс и редактируем html(язык и манифест)
i18next.on("languageChanged", async () => {
	await router.isReady();
	const to = router.currentRoute.value;

	const { language } = i18next;
	if (language === FALLBACK_LANG && to.params?.lang) {
		router.replace({ ...to, params: omit(to.params, "lang") });
	} else if (LANG_WITH_PREFIX.includes(language) && to.params?.lang !== language) {
		router.replace({ ...to, params: { ...to.params, lang: language } });
	}

	const manifest = document.querySelector('[rel="manifest"]');

	const manifestUrl =
		language === FALLBACK_LANG ? "manifest.webmanifest" : ` manifest-${language}.webmanifest`;
	const html = document.querySelector("html[lang]");

	html.setAttribute("lang", language);
	manifest.setAttribute("href", manifestUrl);
});
