<script setup>
import { ref, computed, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import { useScreenSize } from "@shared/lib";
import { REQUST_STATUSES } from "@shared/const";
import {
	TabsBar,
	TABS_BAR_TYPES,
	NavigationProfile,
	SvgTemplate,
	Typography,
	TYPOGRAPHY_TYPES,
	DeactivateAccount,
} from "@shared/ui";
import { ReviewList } from "@entities/announcement";
import { useTranslation } from "i18next-vue";
import { values } from "lodash";

const route = useRoute();
const router = useRouter();
const store = useStore();
const { t } = useTranslation();

const isUpload = ref(false);

const annpuncementTitle = computed(() => {
	return {
		ACTIVE: t("myReviewsReviewsMe"),
		ARCHIVE: t("myReviewsMy"),
	};
});

const reviews = computed(() => {
	const data = { ...store.getters["reviews/getReviews"] };

	if (data.items && data.items.length > 0) {
		const lastItemIndex = data.items.length - 1;

		data.items[lastItemIndex] = {
			...data.items[lastItemIndex],
			updateOnVisible: true,
		};
	}

	return data;
});
const reviewStatus = computed(
	() => store.getters["reviews/getReviewStatus"] === REQUST_STATUSES.SUCCESS
);
const user = computed(() => store.getters["user/getCurrentUser"]);
const getUserStatus = computed(() => store.getters["user/currentUserStatus"]);
const tabs = computed(() => values(annpuncementTitle.value));
const selectedItemType = ref(route.query.tab || tabs.value[0]);
const reviewForMe = computed(() => selectedItemType.value === t("myReviewsMy"));
const myReview = computed(
	() => selectedItemType.value === annpuncementTitle.value.ACTIVE || !reviewForMe.value
);

const uploadReviews = async () => {
	if (myReview.value) {
		await store.dispatch("reviews/getReviewsAnnouncement", {
			seller_id: user.value.user_id,
			on_page: 10,
			page: 1,
		});
	}
	if (reviewForMe.value) {
		await store.dispatch("reviews/getReviewsAnnouncement", {
			created_by: user.value.user_id,
		});
	}
	isUpload.value = false;
};

const isReviewsForMe = computed(() => {
	return selectedItemType.value === annpuncementTitle.value.ACTIVE || !reviewForMe.value;
});

watch(
	[selectedItemType, getUserStatus],
	([selectedItemTypeVal, getUserStatusVal]) => {
		if (getUserStatusVal === "success") {
			router.replace({ query: { tab: selectedItemTypeVal } });
			isUpload.value = true;
			uploadReviews();
		}
	},
	{ immediate: true }
);

watch(annpuncementTitle, () => {
	selectedItemType.value = t("myReviewsReviewsMe");
});

const { isDesktop } = useScreenSize();

const onBack = () => {
	router.back();
};

const onClick = (itemId) => {
	router.push({ name: "AnounceCardPage", params: { ad_id: itemId } });
};

const updateList = async () => {
	if (reviews.value.isFullfield && reviewStatus.value) {
		if (myReview.value) {
			await store.dispatch("reviews/updateReviews", {
				seller_id: user.value.user_id,
			});
		}
		if (reviewForMe.value) {
			await store.dispatch("reviews/updateReviews", {
				created_by: user.value.user_id,
			});
		}
	}
};
</script>

<template>
	<div v-if="isDesktop" class="my-review__container">
		<NavigationProfile />
		<DeactivateAccount />
	</div>
	<div class="my-review__content">
		<nav class="my-review__nav">
			<button v-if="!isDesktop" type="button" class="my-review__nav-back" @click="onBack">
				<SvgTemplate class="back-button__icon" name="left-arrow" />
			</button>
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">{{ t("reviewsTitle") }}</Typography>
		</nav>
		<TabsBar
			:type="TABS_BAR_TYPES.MAIN"
			:tabs="tabs"
			v-model="selectedItemType"
			class="my-review__tabs-bar"
		></TabsBar>
		<template v-if="reviews.items.length">
			<ReviewList
				v-for="review in reviews.items"
				:class="['my-review', { 'my-review__deprecated': !review.ad_active }]"
				:key="review.fb_id"
				:data="review"
				:showInfo="isReviewsForMe"
				:emit-on-visible="review.updateOnVisible"
				@visible="updateList"
			>
				<template #reviewTitleBtn>
					<div
						:class="[
							'my-review__title-wrapper',
							{ 'my-review__title-deprecated': !review.ad_active },
						]"
						@click="onClick(review.ad_id)"
					>
						<div class="my-review__unpublished" v-if="!review.ad_active">
							<Typography
								class="my-review__announcement-title my-review__announcement-title_black"
								:type="TYPOGRAPHY_TYPES.LABEL_CATEGORY"
							>
								{{ t("previewWithdrawn") }}
							</Typography>
						</div>
						<Typography class="my-review__announcement-title" :type="TYPOGRAPHY_TYPES.PRO_TEXT">
							{{ review.ad_name }}
						</Typography>
						<SvgTemplate
							v-if="review.ad_active"
							class="my-review__announcement-title-polygon"
							name="polygon"
						/>
					</div>
				</template>
			</ReviewList>
		</template>
		<template v-if="reviewStatus && !reviews.items.length">
			<div class="my-review__empty-list">
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">{{ t("myReviewsRemoveEmpty") }}</Typography>
			</div>
		</template>
	</div>
</template>

<style lang="scss" scoped>
@import "./MyReviews.scss";
</style>
