<script setup>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import {
	AnnouncemenSplitedList,
	ON_PAGE_COUNT,
	useAnnouncementStore,
	useSplitList,
	getAnnouncementOrderParams,
	FIELD,
} from "@entities/announcement";
import { useInitData } from "@shared/lib";
import { TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { useTranslation } from "i18next-vue";
import SearchEmptyList from "./SearchEmptyList.vue";

const props = defineProps({ term: { type: String, default: undefined } });

const initData = useInitData();

const store = useStore();
const { t } = useTranslation();

const currentLocation = computed(() => {
	return store.getters["user/getHandleLocation"];
});

const params = computed(() => {
	const { countryId, regionId } = currentLocation.value;

	const orders = [];

	if (+countryId !== 0) {
		orders.push(getAnnouncementOrderParams(FIELD.COUNTRY, countryId, "2"));
	}

	if (+regionId !== 0) {
		orders.push(getAnnouncementOrderParams(FIELD.REGION, regionId, "1"));
	}

	const res = {
		orders,
		with_ads: true,
		term: props.term,
		on_page: ON_PAGE_COUNT.GENERAL_PAGE_COUNT,
	};

	console.log(res);

	return res;
});

const {
	getAnnouncement,
	updateAnnouncements,
	announcementsList,
	announcementsListStatus,
	isFullfield,
} = useAnnouncementStore(params);

const { splitedList, setSplitReducer, removeSplitReducer, splitedListDict } = useSplitList(
	announcementsList,
	isFullfield,
	announcementsListStatus
);

const SPLIT_TYPES = {
	REGION: "region",
	COUNTRY: "country",
	OTHER_COUNTRIES: "other-countries",
};

watch(
	currentLocation,
	() => {
		const { countryId, regionId } = currentLocation.value;

		if (+regionId !== 0) {
			setSplitReducer(SPLIT_TYPES.REGION, (item) => item.region_id === regionId, 1);
		} else {
			removeSplitReducer(SPLIT_TYPES.REGION);
		}
		if (+countryId !== 0) {
			setSplitReducer(SPLIT_TYPES.COUNTRY, (item) => item.country_id === countryId, 2);
		} else {
			removeSplitReducer(SPLIT_TYPES.COUNTRY);
		}
	},
	{ immediate: true, deep: true }
);

setSplitReducer(SPLIT_TYPES.OTHER_COUNTRIES, () => true, 3);

initData(async () => {
	await getAnnouncement();
});

const isRegionAndCountryEmpty = computed(() => {
	const { countryId, regionId } = currentLocation.value;
	const res = +countryId === 0 && +regionId === 0;
	return res;
});

const isCountrySplitHidden = computed(() => {
	const res =
		!!splitedListDict.value[SPLIT_TYPES.REGION] &&
		!!splitedListDict.value[SPLIT_TYPES.REGION]?.isEmpty &&
		!!splitedListDict.value[SPLIT_TYPES.COUNTRY]?.isEmpty;
	return res;
});
</script>
<template>
	<AnnouncemenSplitedList
		:splited-list="splitedList"
		with-keep-alive
		class="search-splited-list"
		@update-list="updateAnnouncements"
	>
		<template #[`title.${SPLIT_TYPES.REGION}`]="{ isEmpty, isSuspense }">
			<Typography
				v-if="isEmpty"
				:type="TYPOGRAPHY_TYPES.TITLE_2"
				:suspense="isSuspense"
				class="search-splited-list__title"
			>
				{{ t("couldNotFindAdsCity") }}
			</Typography>
			<Typography
				v-else
				:type="TYPOGRAPHY_TYPES.TITLE_2"
				:suspense="isSuspense"
				class="search-splited-list__title"
			>
				{{ t("foundInYourCity") }}
			</Typography>
		</template>
		<template #[`sub-title.${SPLIT_TYPES.REGION}`]="{ isEmpty, isFullfield }">
			<template v-if="isFullfield">
				<Typography
					v-if="isEmpty"
					:type="TYPOGRAPHY_TYPES.TEXT"
					class="search-splited-list__sub-title"
				>
					{{ t("emptyCity") }}
				</Typography>
				<Typography v-else :type="TYPOGRAPHY_TYPES.TEXT" class="search-splited-list__sub-title">
					{{ t("adverrtisementsCityOver") }}
				</Typography>
			</template>
		</template>
		<template
			#[`title.${SPLIT_TYPES.COUNTRY}`]="{ isEmpty, isSuspense }"
			v-if="!isCountrySplitHidden"
		>
			<Typography
				v-if="isEmpty"
				:type="TYPOGRAPHY_TYPES.TITLE_2"
				class="search-splited-list__title"
				:suspense="isSuspense"
			>
				{{ t("couldNotFindAdsCountry") }}
			</Typography>
			<Typography
				v-else
				:type="TYPOGRAPHY_TYPES.TITLE_2"
				class="search-splited-list__title"
				:suspense="isSuspense"
			>
				{{ t("foundInYourCountry") }}
			</Typography>
		</template>

		<template
			#[`sub-title.${SPLIT_TYPES.COUNTRY}`]="{ isEmpty, isFullfield }"
			v-if="!isCountrySplitHidden"
		>
			<template v-if="isFullfield">
				<Typography
					v-if="isEmpty"
					:type="TYPOGRAPHY_TYPES.TEXT"
					class="search-splited-list__sub-title"
				>
					{{ t("emptyCountryOffer") }}
				</Typography>
				<Typography v-else :type="TYPOGRAPHY_TYPES.TEXT" class="search-splited-list__sub-title">
					{{ t("adverrtisementsCountryOver") }}
				</Typography>
			</template>
		</template>
		<template #[`title.${SPLIT_TYPES.OTHER_COUNTRIES}`]="{ isSuspense, isEmpty }">
			<Typography
				v-if="!isEmpty"
				:type="TYPOGRAPHY_TYPES.TITLE_2"
				:suspense="isSuspense"
				class="search-splited-list__title"
			>
				<template v-if="isRegionAndCountryEmpty">
					{{ t("searchingResults") }}
				</template>
				<template v-else>
					{{ t("foundInOtherCountries") }}
				</template>
			</Typography>
		</template>
		<template #empty-list><SearchEmptyList /></template>
	</AnnouncemenSplitedList>
</template>

<style lang="scss" scoped>
@import "./SearchAnnouncementList.scss";
</style>
