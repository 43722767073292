import { Haptics, ImpactStyle } from "@capacitor/haptics";
import { IS_NATIVE_APP } from "@shared/const";

export const useHaptics = () => {
	const impact = (style) => {
		if (IS_NATIVE_APP) {
			Haptics.impact(style);
		}
	};

	const impactLight = () => {
		impact(ImpactStyle.Light);
	};
	const impactMedium = () => {
		impact(ImpactStyle.Medium);
	};
	const impactHeavy = () => {
		impact(ImpactStyle.Heavy);
	};

	return { impactLight, impactHeavy, impactMedium };
};
