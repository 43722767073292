<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import { Authorization } from "@widgets/Authorization";
import { isNil } from "lodash-es";
import { Profile } from "../Profile";

const store = useStore();

const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});
</script>
<template>
	<section class="profile-page">
		<Profile v-if="!isNil(user?.user_id)" />
		<Authorization />
	</section>
</template>
<style lang="scss" scoped>
@import "./ProfilePage.scss";
</style>
