<script setup>
import { computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { THEME_VARIANTS, REQUST_STATUSES } from "@shared/const";
import { TYPOGRAPHY_TYPES, Typography, SvgTemplate, ModalConfirm, Autocomplete } from "@shared/ui";
import { useScreenSize, required } from "@shared/lib";
import useVuelidate from "@vuelidate/core";
import { isEmpty } from "lodash";
import { useToast } from "vue-toast-notification";
import { useTranslation } from "i18next-vue";

const router = useRouter();
const store = useStore();
const toast = useToast();
const { t } = useTranslation();

const { isDesktop } = useScreenSize();

const items = ref([
	t("deleteProfileAccountHacked"),
	t("deleteProfileNoCountry"),
	t("deleteProfileAnotherAccount"),
	t("deleteProfileWorried"),
	t("deleteProfileDontUseService"),
	t("deleteProfileOther"),
]);
const modalRefRemove = ref(null);
const deleteModalRef = ref(null);
const formFields = reactive({
	reason: null,
});
const validators = {
	reason: { required },
};

const validate = useVuelidate(validators, formFields, { $scope: false });

const logout = async () => {
	router.push({ name: "Home" });
	await store.dispatch("user/logout");
	store.commit("user/CLEAR_CURRENT_USER");
	store.commit("user/SET_CURRENT_USER_STATUS", REQUST_STATUSES.ERROR);
};

const remove = () => {
	modalRefRemove.value.open();
};

const isTelegram = computed(() => {
	return !isEmpty(Telegram?.WebApp?.initDataUnsafe);
});

const currentUser = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const onDelete = () => {
	validate.value.$touch();
	if (validate.value.$invalid) {
		return;
	}
	deleteModalRef.value.open();
};

const isButtonPending = ref(false);

const onAction = (action) => {
	if ((isDesktop.value && action === "confirm") || (!isDesktop.value && action === "back")) {
		deleteModalRef.value.close();
	}
	if ((isDesktop.value && action === "back") || (!isDesktop.value && action === "confirm")) {
		sendRemove();
	}
};

const sendRemove = async () => {
	isButtonPending.value = true;
	try {
		await store.dispatch("user/deleteProfile", formFields.reason);
		toast.success(t("deactivateAccountToastSuccess"), { position: "top-right" });
		deleteModalRef.value.close();
		modalRefRemove.value.close();
	} catch (error) {
		console.error(error);
		toast.error(t("toastAuthError"), { position: "top-right" });
	}
	isButtonPending.value = false;
};
</script>

<template>
	<div class="deactivate-block">
		<div class="deactivate-block__menu">
			<button
				v-if="!isTelegram"
				type="button"
				class="deactivate-block__menu-button"
				@click="logout()"
			>
				<SvgTemplate class="back-button__icon" name="portrait-arrow-right" />
				<Typography :type="isDesktop ? TYPOGRAPHY_TYPES.BUTTON_1 : TYPOGRAPHY_TYPES.PRO_TEXT">
					{{ t("deactivateAccountSignOut") }}
				</Typography>
			</button>
			<button
				type="button"
				class="deactivate-block__menu-button deactivate-block__menu-button_down"
				@click="remove"
			>
				<SvgTemplate class="back-button__icon" name="delete-profile" />
				<Typography
					class="back-button__text"
					:type="isDesktop ? TYPOGRAPHY_TYPES.BUTTON_1 : TYPOGRAPHY_TYPES.PRO_TEXT"
				>
					{{ t("deactivateAccountDeleteAccount") }}
				</Typography>
			</button>
		</div>
	</div>

	<ModalConfirm
		ref="modalRefRemove"
		@confirm="onDelete"
		@back="modalRefRemove.close()"
		back-text="buttonTextCancel"
		confirm-text="deactivateAccountDeleteAccount"
		:disable-btn="validate.$invalid"
		:hide-cross="true"
	>
		<div v-if="!currentUser.delete_request" class="remove-modal__title-box">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
				{{ t("deactivateAccountDeleteTitle") }}
			</Typography>
			<Typography
				class="remove-modal__subtitle"
				:type="isDesktop ? TYPOGRAPHY_TYPES.PRICE_TEXT_2 : TYPOGRAPHY_TYPES.CARD_PRICE_2"
			>
				{{ t("deactivateAccountReasonDelete") }}
			</Typography>
		</div>
		<template v-if="!currentUser.delete_request">
			<Autocomplete
				v-model="formFields.reason"
				class="remove-modal__selector"
				:items="items"
				disable-input
				:placeholder="t('deleteTitleSelectReason')"
				:errors="validate.reason.$errors"
				@focus="validate.reason.$reset"
				@blur="validate.reason.$touch"
			/>
		</template>
		<template v-else>
			<div class="remove-modal__pending">
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
					{{ t("deactivateAccountDeleteBeing") }}
				</Typography>
			</div>
		</template>
	</ModalConfirm>

	<ModalConfirm
		ref="deleteModalRef"
		@confirm="onAction('confirm')"
		@back="onAction('back')"
		:confirm-text="isDesktop ? 'buttonTextCancel' : 'buttonTextBlock'"
		:back-text="isDesktop ? 'buttonTextBlock' : 'buttonTextCancel'"
		:hide-cross="true"
		:theme-confirm-btn="isDesktop ? THEME_VARIANTS.LIGHT : THEME_VARIANTS.PRIMARY"
		:theme-back-btn="isDesktop ? THEME_VARIANTS.PRIMARY : THEME_VARIANTS.LIGHT"
	>
		<section class="modal-delete-caution">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
				{{ t("deactivateAccountDeleteAccount") }}
			</Typography>
			<Typography :type="TYPOGRAPHY_TYPES.TEXT_1">
				{{ t("deactivateAccountDeleteWarning") }}
			</Typography>
			<Typography :type="TYPOGRAPHY_TYPES.TEXT_1" class="modal-delete-caution_decsription">
				{{ t("deactivateAccountDeleteProcessingTime") }}
			</Typography>
		</section>
	</ModalConfirm>
</template>

<style lang="scss" scoped>
@import "./DeactivateAccount.scss";
</style>
