export default {
	ru: {
		emptyAnnouncementListTitle: "Мы не смогли найти ничего по запросу",
		emptyAnnouncementListTitleText1:
			"Пока ничего нет. Чтобы не потерять понравившееся объявление, нажмите",
		emptyAnnouncementListTitleText2: ", и оно отобразиться здесь",
		emptyAnnouncementListTextEmpty: "Здесь пока пусто",
	},
	en: {
		emptyAnnouncementListTitle: "We couldn't find anything for your query.",
		emptyAnnouncementListTitleText1:
			"There's nothing here yet. To save a ad you like, click and it will appear here.",
		emptyAnnouncementListTitleText2: "It's empty here yet.",
		emptyAnnouncementListTextEmpty: "It's empty here yet.",
	},
	ka: {
		emptyAnnouncementListTitle: "ჩვენ ვერ შევძელით თქვენი მოთხოვნის პოვნა",
		emptyAnnouncementListTitleText1:
			"ჯერ ჯერობით არაფერი ახალი. რომ არ დაკარგოთ მოწონებული განცხადება დააჭირეთ , ",
		emptyAnnouncementListTitleText2: "აქ ჯერ ცარიელია",
		emptyAnnouncementListTextEmpty: "აქ ჯერ ცარიელია",
	},
	tr: {
		emptyAnnouncementListTitle: "Sorgunuz için bir şey bulamadık",
		emptyAnnouncementListTitleText1:
		"Burada henüz bir şey yok. Beğendiğiniz bir ilanı kaydetmek için tıklayın ve ",
		emptyAnnouncementListTitleText2: "ilan burada görünecektir",
		emptyAnnouncementListTextEmpty: "Burası şimdilik boş",
	},
};
