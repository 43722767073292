import { InAppBrowser } from "@capgo/inappbrowser";
import { IS_NATIVE_APP, DOMAIN_URL, TELEGRAM_BOT, IS_WEB_APP } from "@shared/const";
import { nanoid } from "nanoid";
import qs from "qs";
import { useStore } from "vuex";

const TG_OAUTH_DOMAIN = "https://oauth.telegram.org";

export const useTelegramAuth = () => {
	const store = useStore();

	const signIn = async () => {
		if (IS_NATIVE_APP) {
			await signInNative();
		}
		if (IS_WEB_APP) {
			await signInWeb();
		}
	};

	const signInNative = () => {
		const query = {
			bot_id: TELEGRAM_BOT,
			origin: DOMAIN_URL,
			return_to: `${DOMAIN_URL}/success_auth`,
			nonce: nanoid(),
		};

		const TELEGRAM_AUTH_URL = `${TG_OAUTH_DOMAIN}/auth?${qs.stringify(query)}`;

		return new Promise((res, rej) => {
			InAppBrowser.addListener("urlChangeEvent", async (state) => {
				const { url } = state;
				if (url.indexOf("tgAuthResult") !== -1) {
					try {
						const token = url.split("tgAuthResult=")[1];
						await store.dispatch("user/authUser", { token });
						await store.dispatch("user/getCurrentUser");

						res();
						InAppBrowser.close();
					} catch {
						rej();
					}
				}
			});

			setTimeout(() => {
				InAppBrowser.openWebView({
					title: "Авторизация Telegram",
					url: TELEGRAM_AUTH_URL,
					backgroundColor: "white",
				});
			});
		});
	};

	const signInWeb = () => {
		return new Promise((res, rej) => {
			Telegram.Login?.auth(
				{ bot_id: TELEGRAM_BOT },
				async (data) => {
					try {
						await store.dispatch("user/authUser", data);
						await store.dispatch("user/getCurrentUser");
						res();
					} catch {
						rej();
					}
				},
				(error) => rej(error)
			);
		});
	};

	return {
		signIn,
	};
};

export const clearTgOauthCache = async () => {
	await InAppBrowser.clearCookies({ url: TG_OAUTH_DOMAIN });
};
