import { createSharedComposable } from "@vueuse/core";
import { ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

const backOrHome = () => {
	const route = useRoute();
	const router = useRouter();
	const length = ref(0);

	watch(
		() => route.path,
		() => {
			length.value += 1;
		}
	);

	const back = () => {
		if (length.value > 1) {
			router.back();
		} else {
			router.replace({ path: "/" });
		}
	};
	return { back };
};

export const useBackOrHome = createSharedComposable(backOrHome);
