import { STORAGE_KEY } from "@shared/const";
import { isNil } from "lodash-es";

/** @param {typeof import("./state").default} state */
export const SET_CURRENT_USER_STATUS = (state, status) => {
	state.status.currentUser = status;
};
/** @param {typeof import("./state").default} state */
export const SET_CURRENT_USER = (state, payload) => {
	state.currentUser = payload;
	if (payload?.jwt) {
		localStorage.setItem("jwtToken", payload.jwt);
	}
};
/**
 * @param {typeof import("./state").default} state
 * @param {import("../api").UpdateUserDto} userData
 */
export const UPDATE_CURRENT_USER = (state, userData) => {
	const { user_id: userId, ...data } = userData;
	state.currentUser = { ...state.currentUser, ...data };
	if ("region_id" in state.currentUser && typeof state.currentUser?.region_id === "string") {
		state.currentUser.region_id = +state.currentUser.region_id;
	}
};

export const CLEAR_CURRENT_USER = (state) => {
	state.currentUser = {};
};

/** @param {typeof import("./state").default} state */
export const SET_USER_STATUS = (state, { status, userId }) => {
	state.status.users[userId] = status;
};
/** @param {typeof import("./state").default} state */
export const SET_USER = (state, { data, userId }) => {
	state.users[userId] = data;
};

/**
 * @param {typeof import("./state").default} state
 * @param {import("../api").UpdateUserDto} userData
 */
export const UPDATE_USER = (state, userData) => {
	const { user_id: userId } = userData;
	state.users[userId] = { ...state.users[userId], userData };
};

export const SET_AUTH_STATUS = (state, status) => {
	state.status.auth = status;
};

// Была ли показана модалка с выбором региона на главной странице(Нужно найти более элегантное решение(MainPage/model))
export const CHANGE_REGION_SHOWN = (state) => {
	state.isChangeRegionShown = true;
};

export const SET_HANDLE_LOCATION = (state, data) => {
	let existingData = localStorage.getItem("myData");
	existingData = existingData ? JSON.parse(existingData) : {};
	Object.assign(existingData, data);
	const updatedDataString = JSON.stringify(existingData);

	localStorage.setItem(STORAGE_KEY.HANDLE_LOCATION, updatedDataString);
	state.userHandleRegion = data;
};

export const SET_DEFAULT_UNAUTH_REGION = (state) => {
	const region = localStorage.getItem(STORAGE_KEY.REGION_LC_KEY);
	if (isNil(region)) {
		state.unauthRegionId = 0;
		localStorage.setItem(STORAGE_KEY.REGION_LC_KEY, 0);
	} else {
		state.unauthRegionId = +region;
		localStorage.setItem(STORAGE_KEY.REGION_LC_KEY, region);
	}
};

export const SET_UNAUTH_REGION = (state, region) => {
	if (!isNil(region)) {
		state.unauthRegionId = +region;
		localStorage.setItem(STORAGE_KEY.REGION_LC_KEY, region);
	}
};

export const SET_LANGUAGE_STSTUS = (state, status) => {
	state.status.languages = status;
};

export const SET_LANGUAGES = (state, data) => {
	state.languages = data.data;
};

export const SET_SUPPOSED_LOCATION_STATUS = (state, status) => {
	state.status.supposedLocation = status;
};

export const SET_SUPPOSED_LOCATION = (state, data) => {
	state.supposedLocation = data;
};

export const SET_FB_TOKEN = (state, token) => {
	state.fbToken = token;
};

export const SET_USER_TRANSLATE = (state, data) => {
	const { announcementLanguage } = data;

	localStorage.setItem(STORAGE_KEY.USER_LANGUAGE, JSON.stringify(data));
	state.announcementTranslate = announcementLanguage;
};
