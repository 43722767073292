<script setup>
import { computed, ref, nextTick, watch } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
	Typography,
	TYPOGRAPHY_TYPES,
	Image,
	useSuspense,
	SvgTemplate,
	ModalConfirm,
	Button,
	Modal,
	Skeleton,
} from "@shared/ui";
import {
	BUCKET_URL,
	BUCKET_PREFIX_MEDIUM,
	REQUST_STATUSES,
	NOT_IMAGES,
	SIZES,
	THEMES,
} from "@shared/const";
import { DescriptionTextCollapse, Contacts } from "@widgets/Profile";
import { useScreenSize, getRegistDate, plural } from "@shared/lib";
import { ReviewList } from "@entities/announcement";
import { useTranslation } from "i18next-vue";
import SalerCardPenging from "./SalerCardPending.vue";

const props = defineProps({
	currentAd: {
		type: Object,
		default: () => {},
	},
	descriptionData: {
		type: Object,
		default: () => {},
	},
	status: {
		type: String,
		default: "",
	},
	isMy: {
		type: Boolean,
		default: false,
	},
	isPending: {
		type: Boolean,
		default: false,
	},
	isAdmin: {
		type: Boolean,
		default: false,
	},
});

const store = useStore();
const router = useRouter();
const { isDesktop, screenSize, SCREEN_SIZES_LIST } = useScreenSize();
const { t } = useTranslation();

const { isSuspense } = useSuspense(true);

const modalReviews = ref(null);

const avatarUrl = computed(() => {
	if (!props.currentAd.photo_user) {
		return NOT_IMAGES.ANONYM;
	}
	return `${BUCKET_URL}${BUCKET_PREFIX_MEDIUM}/user/${props.currentAd.user_id}/${props.currentAd.photo_user}`;
});

const userData = computed(() => {
	return props.currentAd;
});

const sallerReviews = computed(() => {
	const data = { ...store.getters["reviews/getUserReviews"] };

	if (data.items && data.items.length > 0) {
		const lastItemIndex = data.items.length - 1;

		data.items[lastItemIndex] = {
			...data.items[lastItemIndex],
			updateOnVisible: true,
		};
	}

	return data;
});

const sallerReviewsStatus = computed(() => {
	return store.getters["reviews/getUserReviewStatus"] === REQUST_STATUSES.SUCCESS;
});

const userAnnouncements = () => {
	router.push({ name: "UserAnouncements", params: { user_id: props.currentAd.user_id } });
};

const toggleDescr = ref(false);

const modalUserBlocked = ref(null);

const openModalRemoveConfirm = () => {
	modalUserBlocked.value.open();
};

const onBackRemove = () => {
	modalUserBlocked.value.close();
};

const onConfirmRemove = () => {
	modalUserBlocked.value.close();
	deleteUser();
};

const deleteUser = async () => {
	await store.dispatch("user/updateUser", { user_id: props.currentAd.user_id, banned: true });
	router.back();
};

const otherAnnouncementTitle = computed(() => {
	return screenSize.value !== SCREEN_SIZES_LIST.LG
		? t("salerCardMoreAdsUser")
		: t("salerCardMoreAds");
});
const blockedAnnouncementTitle = computed(() => {
	return screenSize.value !== SCREEN_SIZES_LIST.LG ? t("salerCardBlockUser") : t("buttonTextBlock");
});

const chatId = computed(() => store.getters["chat/getChatBySubject"](props.currentAd.item_id));

const chatIsPending = ref(false);

const openChat = () => {
	if (chatId.value) {
		router.push({ name: "Chat", params: { chat_id: chatId.value } });
	} else {
		chatIsPending.value = true;
		store.dispatch("chat/initChat", {
			to: props.currentAd.user_id,
			entityId: props.currentAd.item_id,
		});
		const unwatch = watch(chatId, (value) => {
			if (value) {
				chatIsPending.value = false;

				router.push({ name: "Chat", params: { chat_id: value } });

				nextTick(() => {
					unwatch();
				});
			}
		});
	}
};

const onClickReview = (data) => {
	router.push({ name: "AnounceCardPage", params: { ad_id: data.ad_id } });
	modalReviews.value.close();
};

const updateList = async () => {
	if (sallerReviews.value.isNeedUpdate && sallerReviewsStatus.value) {
		await store.dispatch("reviews/updateUserReviews", {
			seller_id: props.currentAd.user_id,
		});
	}
};
</script>

<template>
	<template v-if="!isPending">
		<div class="saler__info">
			<div v-if="status === REQUST_STATUSES.SUCCESS" class="saler__avatar">
				<div class="saler__circle_block">
					<Image :src="avatarUrl" :alt="аватарка" :suspense="isSuspense" />
				</div>
			</div>
			<div>
				<Typography class="saler__name" :type="TYPOGRAPHY_TYPES.CARD_TITLE">
					{{ currentAd?.first_name }}&nbsp; {{ currentAd.second_name }}
				</Typography>
				<Typography class="saler__data" :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
					{{ t("salerCardData") }} {{ getRegistDate(currentAd.registration_date) }}
				</Typography>
			</div>
		</div>

		<button
			v-if="sallerReviews.reviewsCount !== 0 && sallerReviewsStatus"
			type="button"
			class="saler__reviews"
			@click="modalReviews.open()"
		>
			<Typography class="saler__reviews-count-text" :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
				{{ t("salerCardGradeUser") }}
			</Typography>
			<div class="saler__reviews-box">
				<SvgTemplate name="star" />
				<div class="saler__reviews-text">
					<Typography class="saler__reviews-count" :type="TYPOGRAPHY_TYPES.CARD_TITLE">
						{{ sallerReviews.grade }}
					</Typography>
					<Typography
						class="saler__reviews-count-text"
						:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
					>
						{{ t("salerCardLook") }}
						{{
							sallerReviews.reviewsCount +
							" " +
							plural(
								[t("reviewsCountText1"), t("reviewsCountText2"), t("reviewsCountText3")],
								sallerReviews.reviewsCount
							)
						}}
					</Typography>
				</div>
			</div>
		</button>
		<Skeleton v-if="!sallerReviewsStatus" class="saler-reviews__suspense" />
		<Button
			class="saller__chat-btn"
			:theme="THEMES.CONTAINED"
			:size="SIZES.BIG"
			:pending="chatIsPending"
			@click.stop="openChat"
			v-if="isAdmin"
		>
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">{{ t("buttonTextCahtWrite") }}</Typography>
		</Button>

		<Contacts
			v-if="isAdmin || isMy"
			class="saller__contacts"
			:tg="{ value: userData.username, visible: userData.is_tg }"
			:phone="{ value: userData.phone, visible: userData.is_phone }"
			:wa="{ value: userData.whats_app_phone, visible: userData.is_wa }"
			:email="{ value: userData.email, visible: userData.is_email }"
		/>

		<button v-if="!isMy" type="button" class="user-info__btn" @click="userAnnouncements()">
			<div class="user-info__btn-title">
				<SvgTemplate
					class="user-info__contact__icon-small user-info__contact__icon-small_rotated"
					name="menucard"
				/>
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">{{ otherAnnouncementTitle }}</Typography>
			</div>
			<SvgTemplate
				class="user-info__contact__icon-small user-info__contact__icon-small_rotated"
				name="polygon"
			/>
		</button>

		<div v-if="isDesktop && isAdmin && !isMy" class="saller__line" />

		<div v-if="isAdmin && !isMy">
			<button type="button" class="saler__blocked" @click="openModalRemoveConfirm">
				<SvgTemplate name="flag" />
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_3" class="announcement-card__red">
					{{ blockedAnnouncementTitle }}
				</Typography>
			</button>
		</div>
		<div v-if="!isDesktop && descriptionData.description" class="saller__line" />
		<div v-if="!isDesktop && descriptionData.description">
			<DescriptionTextCollapse
				:description="descriptionData.description"
				:is-toggle="!toggleDescr"
				:hide-height="60"
			>
				<template #toggleUp="{ isCanHide }">
					<div
						class="text-collapse__toggle text-collapse__toggle_descr"
						@click="toggleDescr = !toggleDescr"
					>
						<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">{{ t("cardInfoBtnCollapse") }}</Typography>
						<SvgTemplate
							v-if="isCanHide"
							class="text-collapse__toggle-icon"
							:class="{ 'text-collapse__toggle-icon_full': !toggleDescr }"
							name="polygon"
						/>
					</div>
				</template>
			</DescriptionTextCollapse>
		</div>
	</template>
	<SalerCardPenging v-else />
	<ModalConfirm
		ref="modalUserBlocked"
		@confirm="onConfirmRemove"
		@back="onBackRemove"
		back-text="buttonTextCancel"
		confirm-text="buttonTextBlock"
		:hide-cross="true"
	>
		<div class="confirm-edit">
			<Typography class="confirm-edit__text" :type="TYPOGRAPHY_TYPES.TITLE_2">
				{{ t("salerCardDataMoBlockText") }}
			</Typography>
		</div>
	</ModalConfirm>

	<Modal ref="modalReviews" :hide-cross="true" :full-expand-mobile="true">
		<template #mobile-title>
			<Typography :type="TYPOGRAPHY_TYPES.CARD_TITLE">{{ t("reviewsTitle") }}</Typography>
		</template>
		<div class="user-reviews__modal-wrapper">
			<div class="user-reviews__title-wrapper">
				<Typography :type="TYPOGRAPHY_TYPES.CARD_TITLE" v-if="isDesktop">
					{{ t("reviewsTitle") }}
				</Typography>
			</div>
			<ReviewList
				v-for="review in sallerReviews.items"
				:class="['user-review__wrapper', { 'user-review__deprecated': !review.ad_active }]"
				:key="review.fb_id"
				:data="review"
				:emit-on-visible="review.updateOnVisible"
				@visible="updateList"
			>
				<template #reviewTitleBtn>
					<div
						:class="[
							'user-review__title-wrapper',
							{ 'user-review__review__title-deprecated': !review.ad_active },
						]"
						@click="onClickReview(review)"
					>
						<div class="user-review__unpublished" v-if="!review.ad_active">
							<Typography
								class="user-review__announcement-title user-review__announcement-title_black"
								:type="TYPOGRAPHY_TYPES.LABEL_CATEGORY"
							>
								{{ t("previewWithdrawn") }}
							</Typography>
						</div>
						<button type="button">
							<Typography class="user-review__announcement-title" :type="TYPOGRAPHY_TYPES.PRO_TEXT">
								{{ review.ad_name }}
							</Typography>
						</button>
						<SvgTemplate
							v-if="review.ad_active"
							class="user-review__announcement-title-polygon"
							name="polygon"
						/>
					</div>
				</template>
			</ReviewList>
		</div>
	</Modal>
</template>

<style lang="scss" scoped>
@import "./SalerCard.scss";
</style>
