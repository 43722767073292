import { ref } from "vue";

export const useTooltip = (target) => {
	const isVisible = ref(false);

	const show = () => {
		isVisible.value = true;
	};

	const hide = () => {
		isVisible.value = false;
	};

	return { show, hide, isVisible, target };
};
