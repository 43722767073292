export default {
	ru: {
		myActiveActive: "Активные",
		myActiveArchive: "Архив",
	},
	en: {
		myActiveActive: "Active",
		myActiveArchive: "Archive",
	},
	ka: {
		myActiveActive: "აქტიურები",
		myActiveArchive: "არქივი",
	},
	tr: {
		myActiveActive: "Aktif",
		myActiveArchive: "Arşiv",
	},
};
