<script setup>
import { provide, ref, watch, onErrorCaptured } from "vue";
import { REQUST_STATUSES } from "@shared/const";

const props = defineProps({
	status: {
		type: [String, Array],
		default: REQUST_STATUSES.OUTDATED,
		validator: (status) => {
			return Object.values(REQUST_STATUSES).entries(status);
		},
	},
	noFallback: {
		type: Boolean,
		default: false,
	},
});

const isSuspense = ref(false);

const isError = ref(false);

provide("isSuspense", isSuspense);

watch(
	() => props.status,
	() => {
		if (typeof props.status === "string") {
			if (props.status === REQUST_STATUSES.PENDING) {
				isSuspense.value = true;
			} else if (props.status === REQUST_STATUSES.ERROR) {
				isError.value = true;
			} else if (props.status === REQUST_STATUSES.SUCCESS) {
				isSuspense.value = false;
			}
		} else if (Array.isArray(props.status)) {
			if (props.status.includes(REQUST_STATUSES.PENDING)) {
				isSuspense.value = true;
			} else if (props.status.includes(REQUST_STATUSES.ERROR)) {
				isError.value = true;
			} else if (props.status.includes(REQUST_STATUSES.SUCCESS)) {
				isSuspense.value = false;
			}
		}
	},
	{ immediate: true }
);

onErrorCaptured(() => {
	isError.value = true;
});
</script>

<template>
	<slot v-if="!isSuspense || noFallback" :is-suspense="isSuspense" />
	<slot v-if="isSuspense && !noFallback" name="fallback" :is-suspense="isSuspense" />
</template>
