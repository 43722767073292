import { last, sortBy } from "lodash-es";

export const chats = (state) => {
	return sortBy(state.chats, (chat) => {
		const timestampToSort =
			last(chat?.messages || [])?.timestamp || chat?.last_msg_timestamp || chat.created_at;

		let res = 0;
		if (timestampToSort) {
			res = +new Date(timestampToSort);
		}
		return -res;
	});
};

export const chatsStatus = (state) => {
	return state.status.chats;
};

export const chatMessages = (state) => (chatId) => {
	return state.chats.find((x) => +x.chat_id === +chatId)?.messages;
};

export const getChat = (state) => (chatId) => {
	return state.chats.find((x) => +x.chat_id === +chatId);
};

export const isUserTyping = (state) => (chatId) => {
	return state.actions.find((x) => +x.chat_id === +chatId && x.action === "typing");
};

export const socketState = (state) => state.status.socket;

export const getChatBySubject = (state) => (subjectId) => {
	return state.chats.find((x) => +x.subject === +subjectId)?.chat_id;
};

export const getTotalUnseenCounter = (state) => {
	return state.chats.reduce((acc, chat) => acc + chat.unseen_counter, 0);
};

export const getChatUnseenCounter = (state) => (chatId) => {
	return state.chats.find((x) => +x.chat_id === +chatId)?.unseen_counter || 0;
};
