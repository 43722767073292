<script setup>
import { TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();
</script>

<template>
	<div class="archive-empty-list">
		<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">{{ t("emptyNotAds") }}</Typography>
	</div>
</template>

<style lang="scss" scoped>
@import "./ArchiveEmptyList.scss";
</style>
