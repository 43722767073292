import { REQUST_STATUSES } from "@shared/const";
import { apiPostFiles, apiDeleteFiles, apiGetADPhoto, apiGetDocuments } from "../api";

export const getFiles = async ({ commit }, entityId) => {
	commit("SET_STATUS", { status: REQUST_STATUSES.PENDING, entityId });
	try {
		const { data } = await apiGetADPhoto({ entity_id: entityId });
		commit("SET_STATUS", { status: REQUST_STATUSES.SUCCESS, entityId });
		commit("SET_FILES", { data, entityId });
	} catch (e) {
		commit("SET_STATUS", { status: REQUST_STATUSES.ERROR, entityId });
	}
};

export const postFiles = async ({ commit }, { entityId, files }) => {
	commit("SET_STATUS", { status: REQUST_STATUSES.PENDING, entityId });
	try {
		const { data } = await apiPostFiles(entityId, files);
		commit("SET_STATUS", { status: REQUST_STATUSES.SUCCESS, entityId });
		commit("UPDATE_FILES", { data, entityId });
	} catch (e) {
		commit("SET_STATUS", { status: REQUST_STATUSES.ERROR, entityId });
	}
};

export const deleteFiles = ({ commit }, { entityId, filename }) => {
	commit("SET_STATUS", { status: REQUST_STATUSES.PENDING, entityId });
	try {
		apiDeleteFiles({ entityId, filename });
		commit("SET_STATUS", { status: REQUST_STATUSES.SUCCESS, entityId });
		commit("DELETE_FILES", { filename, entityId });
	} catch (e) {
		commit("SET_STATUS", { status: REQUST_STATUSES.ERROR, entityId });
	}
};

export const getDocuments = async ({ commit }, lang) => {
	try {
		commit("SET_DOCUMENT_STATUS", REQUST_STATUSES.PENDING);
		const { data } = await apiGetDocuments(lang);
		commit("SET_DOCUMENTS", data);
		commit("SET_DOCUMENT_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (error) {
		// TODO Создать буфер ошибок в store
		console.error(error);
		commit("SET_DOCUMENT_STATUS", REQUST_STATUSES.ERROR);
	}
};
