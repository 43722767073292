<script setup>
import { ref, nextTick } from "vue";
import { TYPOGRAPHY_TYPES, Typography, Checkbox, SvgTemplate } from "@shared/ui";

const props = defineProps({
	documentData: {
		type: Object,
		default: () => {},
	},
	confirmData: {
		type: Array,
		default: () => [],
	},
	showBackBtn: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(["updateDocument", "toggleCheckbox", "sendConfirmDocument", "backBtn"]);

const documentContent = ref(null);
const activeIdx = ref(0);

const historyScroll = ref(0);

const sanitizeHTML = (html) => {
	const element = document.createElement("div");
	element.innerHTML = html;

	const scripts = element.querySelectorAll("script");
	scripts.forEach((script) => {
		script.parentNode.removeChild(script);
	});

	const nodes = element.querySelectorAll("*");
	nodes.forEach((node) => {
		const attributes = node.getAttributeNames();
		attributes.forEach((attribute) => {
			if (attribute.startsWith("on")) {
				node.removeAttribute(attribute);
			}
		});
	});

	nextTick(() => documentContent.value.scrollTo(0, props.showBackBtn ? 0 : historyScroll.value));

	return element.innerHTML;
};

const handleClick = (event) => {
	if (event.target.id) {
		if (!props.showBackBtn) historyScroll.value = documentContent.value.scrollTop;
		emit("updateDocument", event.target.id);
	}
};

const documentConfirmClick = (idx) => {
	emit("sendConfirmDocument", idx);
	activeIdx.value = idx;
};
</script>
<template>
	<div class="policy">
		<div class="policy__title-box">
			<div v-if="showBackBtn" class="policy__confirm-back" @click="emit('backBtn')">
				<SvgTemplate name="left-arrow" />
			</div>
			<Typography class="policy__title" :type="TYPOGRAPHY_TYPES.TITLE_2">
				{{ documentData.document_name }}
			</Typography>
		</div>

		<div
			class="policy__content"
			v-html="sanitizeHTML(documentData.document)"
			ref="documentContent"
			@click="handleClick"
		/>

		<div v-if="confirmData.length && confirmData.length === 2" class="policy__confirm">
			<div v-for="(doc, index) in confirmData" :key="doc.document_name">
				<button type="button" @click="documentConfirmClick(index)">
					<Typography
						:class="[
							'policy__confirm-text policy__confirm-text_wheight',
							{ 'policy__text-active': activeIdx === index },
						]"
						:type="TYPOGRAPHY_TYPES.BUTTON_4"
					>
						{{ doc.document_name }}
					</Typography>
				</button>
			</div>
			<div class="policy__confirm-checbox">
				<Checkbox :checkBoxParamName="'confirm'" @toggleCheckbox="emit('toggleCheckbox')" />
				<Typography class="policy__confirm-text" :type="TYPOGRAPHY_TYPES.BUTTON_4">
					Ознакомлен и принимаю
				</Typography>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./ModalPolicyContent.scss";
</style>
