export default {
	ru: {
		footerMain: "Профиль",
	},
	en: {
		footerMain: "Profile",
	},
	ka: {
		footerMain: "პროფილი",
	},
	tr: {
		footerMain: "Profil",
	},
};
