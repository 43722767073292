import { REQUST_STATUSES, STORAGE_KEY, IS_IOS } from "@shared/const";
import i18next from "i18next";
import { isNil } from "lodash-es";
import { FALLBACK_LANG } from "@shared/lib";
import {
	apiGetTGUser,
	apiGetUser,
	apiAuthTGUser,
	apiAuthTGoogleUser,
	apiAuthAppleUser,
	apiPostUser,
	apiPutUser,
	apiLogout,
	initEmail,
	confirmEmail,
	createPassword,
	deleteAccount,
	apiGetLanguages,
	apiGetCurrentLocation,
	apiPostFBToken,
	apiDeleteFBToken,
} from "../api";
import { clearTgOauthCache } from "../lib";

/**
 * @param {import("vuex").ActionContext} param0
 * @param {number} userId
 */
export const getUser = async ({ commit }, userId) => {
	commit("SET_USER_STATUS", REQUST_STATUSES.PENDING);
	try {
		const { data } = await apiGetUser(userId);
		commit("SET_USER", { data, userId: data.user_id });
		commit("SET_USER_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (e) {
		commit("SET_USER_STATUS", REQUST_STATUSES.ERROR);
	}
};

/**
 * @param {import("vuex").ActionContext} param0
 * @param {import("../api").UpdateUserDto} userData
 */
export const updateUser = async ({ commit }, userData) => {
	commit("SET_USER_STATUS", REQUST_STATUSES.PENDING);
	try {
		const data = await apiPutUser(userData);
		commit("SET_USER", data);
		commit("SET_USER_STATUS", REQUST_STATUSES.SUCCESS);
		return Promise.resolve(data);
	} catch (e) {
		commit("SET_USER_STATUS", REQUST_STATUSES.ERROR);
		return Promise.reject(e);
	}
};

/**
 * @param {import("vuex").ActionContext} param0
 * @param {import("../api").CreateUserDto} userData
 */
export const createUser = async ({ commit }, userData) => {
	commit("SET_USER_STATUS", REQUST_STATUSES.PENDING);
	try {
		const { data } = await apiPostUser(userData);
		commit("SET_USER", data);
		commit("SET_USER_STATUS", REQUST_STATUSES.SUCCESS);
		return Promise.resolve(data);
	} catch (e) {
		commit("SET_USER_STATUS", REQUST_STATUSES.ERROR);
		return Promise.reject(e);
	}
};

export const getCurrentUser = async ({ commit, dispatch, getters }) => {
	commit("SET_CURRENT_USER_STATUS", REQUST_STATUSES.PENDING);
	try {
		const data = await apiGetTGUser();
		commit("SET_CURRENT_USER", data.data);
		commit("SET_UNAUTH_REGION", data.data.region_id);
		commit("SET_CURRENT_USER_STATUS", REQUST_STATUSES.SUCCESS);
		dispatch(
			"chat/initUserSocket",
			{ jwt: data.data.jwt, userId: data.data.user_id },
			{ root: true }
		);

		if (getters.fbToken) {
			await apiPostFBToken(getters.fbToken);
		}

		i18next.changeLanguage(data.data?.interface_language);
		commit("SET_USER_TRANSLATE", {
			announcementLanguage: data.data.ad_language || FALLBACK_LANG,
		});

		const favoriteData = localStorage.getItem("favoriteData");
		if (favoriteData) {
			const favoriteDataId = JSON.parse(favoriteData).map((item) => item.item_id);
			const dict = {
				items_id: favoriteDataId,
				user_id: data.data.user_id,
				is_active: true,
			};
			await dispatch("announcement/addPrefer", dict, { root: true });
			localStorage.removeItem("favoriteData");
		}
		return Promise.resolve();
	} catch (error) {
		commit("SET_CURRENT_USER_STATUS", REQUST_STATUSES.ERROR);
		commit("SET_DEFAULT_UNAUTH_REGION");
		const userLanguage = localStorage.getItem(STORAGE_KEY.USER_LANGUAGE);
		if (!isNil(userLanguage)) {
			const parsedUserLanguage = JSON.parse(userLanguage);
			commit("SET_USER_TRANSLATE", {
				interfaceLanguage: parsedUserLanguage.interfaceLanguage,
				announcementLanguage: parsedUserLanguage.announcementLanguage,
			});
			i18next.changeLanguage(parsedUserLanguage.interfaceLanguage);
		}
		return Promise.reject();
	}
};

export const updateCurrentUser = async ({ commit, getters }, userData) => {
	commit("SET_USER_STATUS", REQUST_STATUSES.PENDING);
	try {
		const currentUserId = getters.getCurrentUser?.user_id;
		const data = await apiPutUser({ user_id: currentUserId, ...userData });
		commit("UPDATE_CURRENT_USER", data.data);
		commit("SET_USER_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (e) {
		commit("SET_USER_STATUS", REQUST_STATUSES.ERROR);
	}
};

export const authUser = async ({ commit }, query) => {
	commit("SET_AUTH_STATUS", REQUST_STATUSES.PENDING);
	try {
		const res = await apiAuthTGUser(query);
		localStorage.setItem("jwtToken", res.data.jwt);
		commit("SET_AUTH_STATUS", REQUST_STATUSES.SUCCESS);
		return Promise.resolve();
	} catch (e) {
		commit("SET_AUTH_STATUS", REQUST_STATUSES.ERROR);
		return Promise.reject();
	}
};

export const authGoogleUser = async ({ commit }, query) => {
	commit("SET_AUTH_STATUS", REQUST_STATUSES.PENDING);
	try {
		const res = await apiAuthTGoogleUser(query);

		localStorage.setItem("jwtToken", res.data.jwt);
		commit("SET_AUTH_STATUS", REQUST_STATUSES.SUCCESS);
		return Promise.resolve();
	} catch (e) {
		console.log(e);
		commit("SET_AUTH_STATUS", REQUST_STATUSES.ERROR);
		return Promise.reject();
	}
};

export const authAppleUser = async ({ commit }, query) => {
	commit("SET_AUTH_STATUS", REQUST_STATUSES.PENDING);
	try {
		const { data } = await apiAuthAppleUser(query);
		localStorage.setItem("jwtToken", data.jwt);
		commit("SET_AUTH_STATUS", REQUST_STATUSES.SUCCESS);
		return Promise.resolve(data);
	} catch (e) {
		commit("SET_AUTH_STATUS", REQUST_STATUSES.ERROR);
		return Promise.reject(e);
	}
};

export const logout = async ({ commit, getters }, query) => {
	commit("SET_AUTH_STATUS", REQUST_STATUSES.PENDING);
	try {
		commit("CLEAR_CURRENT_USER");
		await apiLogout(query);
		localStorage.removeItem("jwtToken");
		if (getters.fbToken) {
			await apiDeleteFBToken(getters.fbToken);
		}
		if (IS_IOS) {
			await clearTgOauthCache();
		}

		commit("SET_AUTH_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (e) {
		commit("SET_AUTH_STATUS", REQUST_STATUSES.ERROR);
	}
};

export const initEmailAuth = async ({ commit }, query) => {
	commit("SET_AUTH_STATUS", REQUST_STATUSES.PENDING);
	try {
		const { data } = await initEmail(query);
		localStorage.setItem("jwtToken", data.jwt);
		commit("SET_AUTH_STATUS", REQUST_STATUSES.SUCCESS);
		return Promise.resolve();
	} catch (err) {
		commit("SET_AUTH_STATUS", REQUST_STATUSES.ERROR);
		return Promise.reject(err);
	}
};

export const confirmEmailAuth = async ({ commit }, query) => {
	try {
		const response = await confirmEmail(query);
		commit("SET_AUTH_STATUS", REQUST_STATUSES.SUCCESS);
		return Promise.resolve(response.data.nonce);
	} catch (err) {
		commit("SET_AUTH_STATUS", REQUST_STATUSES.ERROR);
		return Promise.reject(err);
	}
};

export const passwordEmailAuth = async ({ commit }, query) => {
	try {
		const { data } = await createPassword(query);
		localStorage.setItem("jwtToken", data.jwt);
		commit("SET_AUTH_STATUS", REQUST_STATUSES.SUCCESS);
		return Promise.resolve();
	} catch (err) {
		commit("SET_AUTH_STATUS", REQUST_STATUSES.ERROR);
		return Promise.reject(err);
	}
};

export const deleteProfile = async ({ dispatch }, message) => {
	try {
		await deleteAccount(message);
		await dispatch("getCurrentUser");
		return Promise.resolve();
	} catch (err) {
		return Promise.reject(err);
	}
};

export const getLanguages = async ({ commit }) => {
	try {
		const data = await apiGetLanguages();
		commit("SET_LANGUAGES", data);
		commit("SET_LANGUAGE_STSTUS", REQUST_STATUSES.SUCCESS);
		return Promise.resolve();
	} catch (err) {
		commit("SET_LANGUAGE_STSTUS", REQUST_STATUSES.ERROR);
		return Promise.reject(err);
	}
};

export const getCurrentLocation = async ({ commit }) => {
	try {
		const { data } = await apiGetCurrentLocation();
		commit("SET_SUPPOSED_LOCATION", data);
		commit("SET_SUPPOSED_LOCATION_STATUS", REQUST_STATUSES.SUCCESS);
		return Promise.resolve();
	} catch (err) {
		commit("SET_SUPPOSED_LOCATION_STATUS", REQUST_STATUSES.ERROR);
		return Promise.reject(err);
	}
};

export const setFBToken = async ({ commit, getters }, token) => {
	commit("SET_FB_TOKEN", token);
	if (getters.isUserLoggedIn) {
		try {
			await apiPostFBToken(token);
		} catch (e) {
			console.log(e);
		}
	}
};
