export default {
	ru: {
		cardInfoNotPrice: "Цена не указана",
		cardInfoBtnCall: "Позвонить/написать",
		cardInfoBtnCollapse: "Описание",
		cardInfoMoPublish: "Хотите проверить и отредактировать объявление перед публикацией?",
		cardInfoMoRemoveArchive:
			"Мы сохраним объявление в Архиве. Вы сможете опубликовать его в любой момент",
		cardInfoMoDelete:
			"При удалении ваше объявление пропадет навсегда, мы не сможем его восстановить",
		cardInfoMoContactCahtText1:
			"Ура, мы запустили чаты! Общаться будет быстрее и безопаснее внутри сервиса",
		cardInfoMoContactCahtText2: "чаты доступны только для авторизованных пользователей",
		cardInfoMoContactCahtText3: "пожалуйста, авторизуйтесь",
		cardInfoMoContactCahtBtnLogin: "Авторизоваться",
		cardInfoMoContactMessengers: "Связь через привычные мессенджеры остается доступной",
	},
	en: {
		cardInfoNotPrice: "Price not specified",
		cardInfoBtnCall: "Call/write",
		cardInfoBtnCollapse: "Description",
		cardInfoMoPublish: "Do you want to check and edit your ad before publishing?",
		cardInfoMoRemoveArchive: "We will save the ad in the Archive. You can publish it at any time",
		cardInfoMoDelete:
			"When you delete your ad, it will be gone forever, we will not be able to restore it",
		cardInfoMoContactCahtText1:
			"Hooray, we have launched chats! It will be faster and safer to communicate within the service",
		cardInfoMoContactCahtText2: "Chats are available only for authorized users",
		cardInfoMoContactCahtText3: "Please log in",
		cardInfoMoContactCahtBtnLogin: "Login",
		cardInfoMoContactMessengers: "Communication through familiar messengers remains available",
	},
	ka: {
		cardInfoNotPrice: "ფასი მითითებული არ არის",
		cardInfoBtnCall: "დარეკვა/მიწერა",
		cardInfoBtnCollapse: "აღწერა",
		cardInfoMoPublish: "გსურთ შეამოწმოთ ან და დაარედაქტიროთ განცხადება გამოქვეყნებამდე?",
		cardInfoMoRemoveArchive:
			"ჩვენ შევინახავთ განცხადებას არქივში და თქვენ შეძლებთ ნებისმიერ დროს გამოაქვეყნოთ იგი",
		cardInfoMoDelete:
			"წაშლის შემთხვევაში თქვენი განცხადებები გაქრება,ჩვენ ვერ დაგეხმარებით აღდგენაში",
		cardInfoMoContactCahtText1:
			"ვაშა!ჩვენ გავაკეთეთ ჩათები!კომუნიკაცია იქნება უფრო სწრაფი და უსაფრთხო უშუალოდ აპლიკაციაში",
		cardInfoMoContactCahtText2:
			"ჩათში მიწერა შესაძლებელია მხოლოდ ავტორიზირებული მომხმარებლებისთვის",
		cardInfoMoContactCahtText3: "გთხოვთ გაიაროთ ავტორიზაცია",
		cardInfoMoContactCahtBtnLogin: "ავტორიზაცია",
		cardInfoMoContactMessengers: "სხვა მესენჯერებით დაკონტაქტება რჩება შესაძლებელი",
	},
	tr: {
		cardInfoNotPrice: "Fiyat belirtilmemiş",
		cardInfoBtnCall: "Ara/yaz",
		cardInfoBtnCollapse: "Açıklama",
		cardInfoMoPublish: "Yayınlamadan önce ilanınızı kontrol etmek ve düzenlemek ister misiniz?",
		cardInfoMoRemoveArchive: "İlanı Arşiv'e kaydedeceğiz. İstediğiniz zaman yayınlayabilirsiniz",
		cardInfoMoDelete: "İlanınızı sildiğinizde, tamamen yok olacak, geri yükleyemeyeceğiz",
		cardInfoMoContactCahtText1: "hizmet",
		cardInfoMoContactCahtText2: "Sohbetler yalnızca kayıtlı kullanıcılar tarafından kullanılabilir",
		cardInfoMoContactCahtText3: "Lütfen giriş yapınız",
		cardInfoMoContactCahtBtnLogin: "Giriş yap",
		cardInfoMoContactMessengers:
			"Bilindik sohbet platformlarından iletişim mevcut olmayı sürdürüyor",
	},
};
