import { io } from "socket.io-client";
import { SOCKET_STATUSES, CHAT_EVENTS, CHAT_PROTOCOL, REQUST_STATUSES } from "@shared/const";
import { nanoid } from "nanoid";

const API_URL = import.meta.env.VITE_API_URL;
const JWT_TOKEN = import.meta.env.VITE_JWT_TOKEN;

export const initUserSocket = async ({ dispatch, commit }, { jwt, userId }) => {
	let clientUuid = sessionStorage.getItem("client_uuid");
	if (!clientUuid) {
		clientUuid = nanoid();
		sessionStorage.setItem("client_uuid", clientUuid);
	}
	const header = JWT_TOKEN ? `Bearer ${JWT_TOKEN}` : `Bearer ${jwt}`;
	const socket = io(`${API_URL}`, {
		reconnectionDelayMax: 10000,
		extraHeaders: {
			Authorization: header,
			Client: clientUuid,
		},
		transports: ["polling", "websocket"],
	});

	socket.on("connect", async () => {
		commit("SET_SOCKET", { socket, status: SOCKET_STATUSES.CONNECTED });
		commit("RESET_CHATS");
		const res = await dispatch("loadChats");
		console.log(res);
	});

	socket.on("connect_error", (error) => {
		commit("SET_SOCKET", { socket: null, status: SOCKET_STATUSES.ERROR_CONNECTION });
		commit("RESET_CHATS");
		console.log(error);
	});

	socket.on("disconnect", () => {
		commit("SET_SOCKET", { socket: null, status: SOCKET_STATUSES.DISCONNECTED });
		console.log("disconnected");
	});

	socket.on("action", (data) => {
		commit("HANDLE_ACTION", data);
	});

	socket.on("message", (data) => {
		commit("HANDLE_MESSAGE", { data, userId });
	});
};

export const emitEvent = async ({ state }, { event, content }) => {
	state.socket.emit(event, content);
};

export const disconnectSocket = ({ state, commit }) => {
	state.socket?.disconnect();
	commit("SET_SOCKET", { socket: null, status: SOCKET_STATUSES.DISCONNECTED });
};

export const loadChats = async ({ commit, dispatch }) => {
	try {
		commit("SET_CHATS_STATUS", REQUST_STATUSES.PENDING);
		await dispatch("emitEvent", {
			event: CHAT_EVENTS.ACTION,
			content: CHAT_PROTOCOL.ACTIONS.LOAD_CHATS,
		});
		return Promise.resolve("success");
	} catch (error) {
		dispatch("disconnectSocket");
		console.log("error loading chats, socket disconnected - reload page");
		return Promise.reject(error);
	}
};

export const loadChatMessages = async ({ dispatch }, { chatId }) => {
	try {
		await dispatch("emitEvent", {
			event: CHAT_EVENTS.ACTION,
			content: CHAT_PROTOCOL.ACTIONS.LOAD_CHAT(chatId),
		});
		return Promise.resolve();
	} catch (error) {
		dispatch("disconnectSocket");
		console.log("error loading chat, socket disconnected - reload page");
		return Promise.reject(error);
	}
};

export const userOnline = async ({ dispatch }) => {
	try {
		await dispatch("emitEvent", {
			event: CHAT_EVENTS.ACTION,
			content: CHAT_PROTOCOL.ACTIONS.USER_ONLINE,
		});
		return Promise.resolve();
	} catch (error) {
		dispatch("disconnectSocket");
		console.log("error sending online activity, socket disconnected - reload page");
		return Promise.reject(error);
	}
};

export const userOffline = async ({ dispatch }) => {
	try {
		await dispatch("emitEvent", {
			event: CHAT_EVENTS.ACTION,
			content: CHAT_PROTOCOL.ACTIONS.USER_OFFLINE,
		});
		return Promise.resolve();
	} catch (error) {
		dispatch("disconnectSocket");
		console.log("error sending offline activity, socket disconnected - reload page");
		return Promise.reject(error);
	}
};

export const initChat = async ({ dispatch }, { to, entityId }) => {
	try {
		await dispatch("emitEvent", {
			event: CHAT_EVENTS.ACTION,
			content: CHAT_PROTOCOL.ACTIONS.INIT_CHAT(to, entityId),
		});
		return Promise.resolve();
	} catch (error) {
		dispatch("disconnectSocket");
		console.log("error init chat, socket disconnected - reload page");
		return Promise.reject(error);
	}
};
