<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { getBucketUrlSmall } from "@shared/const";
import { useScreenSize } from "@shared/lib";
import { Image, TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { ChatUnseenCounter } from "../ChatUnseenCounter";

const emit = defineEmits(["click"]);
const store = useStore();

const props = defineProps({
	chat: {
		type: Object,
		required: true,
	},
	selected: {
		type: Boolean,
		default: false,
	},
});

const adsAvatar = computed(() => {
	return getBucketUrlSmall(props.chat.ad_photo, props.chat.subject);
});

const isUserTyping = computed(() => {
	return store.getters["chat/isUserTyping"](props.chat.chat_id);
});

const userId = computed(() => {
	return store.getters["user/getCurrentUser"]?.user_id;
});

const isNewMessage = computed(() => !props.chat.viewed && props.chat.receiver === userId.value);

const click = () => emit("click");

const { isDesktop } = useScreenSize();

const messageText = computed(() => {
	if (props.chat.receiver !== userId.value) return props.chat.text;
	return props.chat.translated || props.chat.text;
});

const showChat = computed(() => {
	return !!props.chat.text || props.chat.ad_user !== userId.value;
});
</script>

<template>
	<div
		v-show="showChat"
		@click="click"
		class="chat-card"
		:class="{ 'chat-card_selected': selected }"
	>
		<div class="chat-card__title-photo-container">
			<Image :src="adsAvatar" class="chat-card__title-photo" />
			<div v-if="chat.user_online" class="chat-card__title-photo_online" />
		</div>
		<div class="chat-card__details">
			<Typography class="chat-card__text" :type="TYPOGRAPHY_TYPES.TITLE_1">
				{{ chat.second_name }} {{ chat.first_name }}
			</Typography>
			<Typography class="chat-card__text" :type="TYPOGRAPHY_TYPES.TITLE_1">
				{{ chat.ad_name }}
			</Typography>
			<div class="chat-card__last-row">
				<Typography v-if="isUserTyping" class="chat-card__text_sub" :type="TYPOGRAPHY_TYPES.TEXT">
					{{ t("chatHeaderTypes") }}
				</Typography>
				<Typography
					v-else
					class="chat-card__text_sub"
					:type="isNewMessage ? TYPOGRAPHY_TYPES.TITLE_3 : TYPOGRAPHY_TYPES.TEXT"
				>
					{{ messageText }}
				</Typography>
				<ChatUnseenCounter :count="chat.unseen_counter" />
			</div>
		</div>
	</div>
	<div v-if="!isDesktop && showChat" class="chat-card__mobile-endline" />
</template>

<style lang="scss" scoped>
@import "./ChatCard.scss";
</style>
