import { computed, watch } from "vue";
import { useStore } from "vuex";
import { REQUST_STATUSES } from "@shared/const";
import { useRoute } from "vue-router";
import { filterObjectEmptyValues, useKeepAliveRoute } from "@shared/lib";
import { computedEager } from "@vueuse/core";
import { isEmpty, pick, values, omit, isNil } from "lodash-es";
import { router } from "@pages";
import { ANNOUNCEMENT_FILTER_KEYS, INTEGER_FILTERS } from "../const";

export const useFilters = () => {
	const route = useRoute();
	const keepAliveRoute = useKeepAliveRoute();

	const store = useStore();

	const currencyStatus = computed(() => {
		return store.getters["announcement/currenciesStatus"];
	});

	const regionsStatus = computed(() => {
		return store.getters["announcement/regionsStatus"];
	});

	const queryOmitFiltersKeys = computedEager(() => {
		return omit(keepAliveRoute.value.query, values(ANNOUNCEMENT_FILTER_KEYS));
	});

	const queryPickFiltersKeys = computedEager(() => {
		return pick(keepAliveRoute.value.query, values(ANNOUNCEMENT_FILTER_KEYS));
	});

	/**
	 * @type {import("vue").ComputedRef<
	 * 	Record<(typeof ANNOUNCEMENT_FILTER_KEYS)[keyof typeof ANNOUNCEMENT_FILTER_KEYS], unknown>
	 * >}
	 */
	const filtersParams = computedEager(() => {
		const queryFilters = { ...queryPickFiltersKeys.value };
		Object.keys(queryFilters).forEach((key) => {
			if (INTEGER_FILTERS.includes(key)) {
				queryFilters[key] = +queryFilters[key];
			}
		});
		return queryFilters;
	});

	const setFilters = (filtersParams = {}) => {
		const pickFilters = filterObjectEmptyValues(
			pick(filtersParams, values(ANNOUNCEMENT_FILTER_KEYS))
		);
		router.replace({
			...route,
			query: { ...queryOmitFiltersKeys.value, ...pickFilters },
		});
	};

	const isFiltersEmpty = computedEager(() => {
		return isEmpty(filterObjectEmptyValues(filtersParams.value));
	});

	const isFiltersLocationEmpty = computedEager(() => {
		return isEmpty(
			filterObjectEmptyValues(
				pick(filtersParams.value, [
					ANNOUNCEMENT_FILTER_KEYS.COUNTRY_ID,
					ANNOUNCEMENT_FILTER_KEYS.REGION_ID,
				])
			)
		);
	});

	const setFiltersByRouteQuery = () => {
		const query = {};
		const {
			country_id: countryId,
			region_id: cityId,
			currency_id: currencyId,
			currency_down: currencyDown,
			currency_up: currencyUp,
		} = queryPickFiltersKeys.value;

		// Проверка страны
		if (!isNil(countryId)) {
			const isCountryByIdExist = store.getters["announcement/countryById"](countryId);
			if (isCountryByIdExist) {
				query.country_id = countryId;
			}
		}

		// Проверка города
		if (!isNil(cityId)) {
			const isCityByIdExist = store.getters["announcement/cityById"](cityId);
			if (!isNil(isCityByIdExist)) {
				const countryByCityId = store.getters["announcement/countryByCityId"](cityId);
				if (!isNil(countryId)) {
					// Если город существует и id страны по городу совпадают

					if (+countryId === +countryByCityId.id) {
						query.region_id = cityId;
					}
				} else {
					// Если город существует и id страны не существует, возьмем id Страны по городу
					query.country_id = countryByCityId;
					query.region_id = cityId;
				}
			}
		}

		// Проверка валюты
		if (!isNil(currencyId)) {
			const isCurrencyExist = store.getters["announcement/currencyById"](currencyId);

			if (isCurrencyExist) {
				query.currency_id = currencyId;
			}

			if (!isNaN(+currencyDown) && !isNaN(+currencyUp) && +currencyDown <= +currencyUp) {
				query.currency_down = currencyDown;
				query.currency_up = currencyUp;
			} else if (!isNaN(+currencyDown)) {
				query.currency_down = currencyDown;
			} else if (!isNaN(+currencyUp)) {
				query.currency_up = currencyUp;
			}
		}

		setFilters(query);
	};

	watch(
		[currencyStatus, regionsStatus],
		() => {
			if (
				currencyStatus.value === REQUST_STATUSES.SUCCESS &&
				regionsStatus.value === REQUST_STATUSES.SUCCESS
			) {
				setFiltersByRouteQuery();
			}
		},
		{ immediate: true }
	);

	return {
		filtersParams,
		isFiltersEmpty,
		isFiltersLocationEmpty,
		setFilters,
	};
};
