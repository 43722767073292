export default {
	ru: {
		translateName: "Русский",
		translateAbbreviation: "RU",
		translateAnnouncementOriginal: "Оригинал",
	},
	en: {
		translateName: "English",
		translateAbbreviation: "EN",
		translateAnnouncementOriginal: "Original",
	},
	ka: {
		translateName: "ქართული",
		translateAbbreviation: "KA",
		translateAnnouncementOriginal: "Ორიგინალური",
	},
	tr: {
		translateName: "Туркçe",
		translateAbbreviation: "TR",
		translateAnnouncementOriginal: "Orijinal",
	},
};
