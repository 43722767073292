export default {
	ru: {
		popularCatTitle: "Популярные категории",
		popularCatSubTitle: "Собрали самые кликабельные категории сервиса в одном месте",
		goods: "Товары",
		services: "Услуги",
		jobs: "Работа",
		events: "Мероприятия",
		realEstate: "Недвижимость",
		transport: "Транспорт",
		tourism: "Туризм",
	},
	en: {
		popularCatTitle: "Popular categories",
		popularCatSubTitle:
			"We have collected the most clickable categories of the service in one place.",
		goods: "Goods",
		services: "Services",
		jobs: "Job",
		events: "Events",
		realEstate: "Real estate",
		transport: "Transport",
		tourism: "Tourism",
	},
	ka: {
		popularCatTitle: "პოპულარული კატეგორიები",
		popularCatSubTitle: "ყველაზე დაკლიკებადი კატეგორიების თავშეყრა ერთ სივრცეში",
		goods: "პროდუქცია",
		services: "მომსახურება",
		jobs: "დასაქმება",
		events: "ღონისძიებები",
		realEstate: "უძრავი ქონება",
		transport: "ტრანსპორტი",
		tourism: "ტურიზმი",
	},
	tr: {
		popularCatTitle: "Popüler kategoriler",
		popularCatSubTitle: "Hizmetin en çok tıklanan kategorilerini tek bir yerde topladık",
		goods: "Ürünler",
		services: "Hizmetler",
		jobs: "İş",
		events: "Etkinlikler",
		realEstate: "Gayrimenkül",
		transport: "Ulaşım",
		tourism: "Turizm",
	},
};
