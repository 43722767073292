import { nonDigitRegExp, whiteSpacesRegExp, emojiRegExp } from "@shared/const";

/**
 * @param {string | number} value
 * @returns
 */
export const toLocaleNumber = (value) => {
	if (!value) {
		return "";
	}

	if (typeof value !== "string" && typeof value !== "number") {
		throw new Error(`Ожидал string или number, получил ${typeof value}`);
	}

	const formatedValue = value.toString().replaceAll(nonDigitRegExp, "").slice(0, 16);

	if (!formatedValue) {
		return value;
	}

	const numberValue = parseFloat(formatedValue);

	const options = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
	if (numberValue % 1 !== 0) {
		return numberValue.toLocaleString("ru-RU", options).replace(",", ".");
	}

	return numberValue.toLocaleString("ru-RU").replace(",", ".");
};

const getDecimalFromString = (string) => {
	if (string.includes(",")) {
		return ",";
	}
	if (string.includes(".")) {
		return ".";
	}

	return null;
};

export const toFloatNumber = (value) => {
	let result = value
		.replaceAll(/[^\d,.]+/g, "")
		.split("")
		.reduce((string, char) => {
			if ((char === "." || char === ",") && (string.includes(",") || string.includes("."))) {
				return string;
			}
			return string + char;
		}, "");

	const decimalChar = getDecimalFromString(result);

	if (decimalChar) {
		const decimalValue = result.split(decimalChar)[1].slice(0, 2);
		result = `${result.split(decimalChar)[0]}.${decimalValue}`;
	}
	return result;
};

export const toLocaleFloatNumber = (value = "") => {
	const result = toFloatNumber(value.replaceAll(whiteSpacesRegExp, ""));

	const decimalChar = getDecimalFromString(result);

	if (decimalChar) {
		const [leftPart, rightPart] = result.split(decimalChar);
		return toLocaleNumber(leftPart) + decimalChar + rightPart;
	}
	return toLocaleNumber(result);
};

export const deleteEmojiFromString = (string) => {
	// Удаляем все emoji
	const result = string.replaceAll(emojiRegExp, "");
	return result;
};
