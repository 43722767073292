<script setup>
import { ref, nextTick, onMounted, onUnmounted, provide, readonly } from "vue";
import { THEME_VARIANTS } from "@shared/const";
import { SvgTemplate } from "@shared/ui";
import { COLLAPSE_THEMES } from "./Collapse.const";

const props = defineProps({
	disabled: {
		type: Boolean,
		default: false,
	},
	theme: {
		type: String,
		default: COLLAPSE_THEMES.ANNOUNCEMENT_CREATE,
	},
	variant: {
		type: String,
		default: THEME_VARIANTS.SECONDARY,
	},
	opened: {
		type: Boolean,
		default: false,
	},
	isShowChevron: {
		type: Boolean,
		default: true,
	},
});

const emit = defineEmits(["open", "close"]);

const isOpen = ref(false);

provide("swiper-state", readonly(isOpen));

/** @type {import("vue").Ref<Nullable<HTMLElement>>} */
const contentRef = ref(null);

const openCollapse = () => {
	emit("open");
	isOpen.value = true;
	nextTick(() => {
		contentRef.value.style.height = `${contentRef.value.scrollHeight}px`;
		contentRef.value.style.opacity = 1;
	});
};

const closeCollapse = () => {
	emit("close");
	isOpen.value = false;
	nextTick(() => {
		contentRef.value.style.height = 0;
		contentRef.value.style.opacity = 0;
	});
};

defineExpose({ closeCollapse, openCollapse });

const toggleCollapse = () => {
	if (props.disabled) {
		return;
	}
	if (!isOpen.value) {
		openCollapse();
	} else {
		closeCollapse();
	}
};

const onMutate = () => {
	if (isOpen.value) {
		openCollapse();
	}
};

const mutationObserver = new MutationObserver(onMutate);

onMounted(() => {
	if (props.opened) {
		openCollapse();
	}

	mutationObserver.observe(contentRef.value, {
		childList: true,
		subtree: true,
	});
});

onUnmounted(() => {
	mutationObserver.disconnect();
});
</script>

<template>
	<div
		class="v-collapse"
		:class="{
			'v-collapse_opened': isOpen,
			[`v-collapse_${theme}`]: theme,
			[`v-collapse_${variant}`]: variant,
			['v-collapse_disabled']: disabled,
		}"
	>
		<div class="v-collapse__title" @click="toggleCollapse">
			<slot name="title" />
			<SvgTemplate v-if="isShowChevron" :name="'chevron'" class="v-collapse__icon" />
		</div>
		<div class="v-collapse__content" ref="contentRef">
			<slot name="content" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./Collapse.scss";
</style>
