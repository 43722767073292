import { yaMetrikaEvent } from "@shared/lib";

export const YA_METRIKA_EVENTS_IDS = /** @type {const} */ ({
	ANNOUNCEMENT_CREATE: "announcement-create",
	ANNOUNCEMENT_CREATE_BY_ADMIN: "admin-announcement-create",
});

export const yaMetrikaAnnounceCreateByUser = () => {
	yaMetrikaEvent(YA_METRIKA_EVENTS_IDS.ANNOUNCEMENT_CREATE);
};

export const yaMetrikaAnnounceCreateByAdmin = () => {
	yaMetrikaEvent(YA_METRIKA_EVENTS_IDS.ANNOUNCEMENT_CREATE_BY_ADMIN);
};
