<script setup>
import { computed, ref, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { Modal } from "@shared/ui";
import { ModalPolicyContent } from "@widgets/ModalPolicyContent";
import { REQUST_STATUSES } from "@shared/const";
import { isNil } from "lodash-es";

const route = useRoute();
const router = useRouter();
const store = useStore();

const modalRef = ref(null);

const documents = ref([]);
const documentCode = ref("");

const documentStatus = computed(() => {
	return store.getters["files/documentStatus"];
});

watch(() => {
	if (
		route.query.mode === "agreements" &&
		!isNil(route.query.type) &&
		documentStatus.value === REQUST_STATUSES.SUCCESS
	) {
		documents.value = store.getters["files/getDocuments"];
		nextTick(() => {
			modalRef.value.open();
		});
	}
});

const matchingAgreement = (code) => {
	return documents.value.find((agreement) => agreement.document_code === code);
};

const queryData = computed(() => {
	if (documentCode.value !== "") {
		return matchingAgreement(documentCode.value);
	}

	return matchingAgreement(route.query.type);
});

const closeModal = () => {
	router.push({ path: route.path, query: {} });
};

const updateDocument = (evt) => {
	documentCode.value = evt;
};

const onBack = () => {
	matchingAgreement(route.query.type);
	documentCode.value = "";
};
</script>

<template>
	<Modal
		ref="modalRef"
		@close="closeModal"
		back-text="buttonTextCancel"
		confirm-text="buttonTextBlock"
		hide-cross
	>
		<ModalPolicyContent
			:document-data="queryData"
			@updateDocument="updateDocument"
			:show-back-btn="documentCode !== ''"
			@backBtn="onBack"
		/>
	</Modal>
</template>
