export default {
	ru: {
		selectCategoriesSearchCategory: "Поиск в категории",
		selectCategoriesSearchCategoryShort: "Поиск по:",
	},
	en: {
		selectCategoriesSearchCategory: "Search by category",
		selectCategoriesSearchCategoryShort: "Search by:",
	},
	ka: {
		selectCategoriesSearchCategory: "კატეგორიის მიხედვით ძებნა",
		selectCategoriesSearchCategoryShort: "ეძებთ?",
	},
	tr: {
		selectCategoriesSearchCategory: "Kategoriye göre arama",
		selectCategoriesSearchCategoryShort: "Arama:",
	},
};
