<script setup>
import { TYPOGRAPHY_TYPES, Typography, Button } from "@shared/ui";
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();
</script>

<template>
	<div class="active-empty-list">
		<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">{{ t("emptyNotAds") }}</Typography>
		<Typography :type="TYPOGRAPHY_TYPES.LABEL_CATEGORY" class="active-empty-list__ad-subtitle">
			{{ t("myActiveEmptyListPleasePress") }}
		</Typography>
		<RouterLink :to="{ name: 'AnnouncementCreate' }">
			<Button>
				<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">{{ t("createAnnoucementBtn") }}</Typography>
			</Button>
		</RouterLink>
	</div>
</template>

<style lang="scss" scoped>
@import "./MyActiveEmptyList.scss";
</style>
