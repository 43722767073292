<script setup>
import { ref, watch, computed } from "vue";
import { AnnouncementGroups } from "@widgets/AnnouncementGroups";
import { TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { isNil } from "lodash";
import { useTranslation } from "i18next-vue";
import Categories from "./Categories.vue";

defineProps({
	category: {
		type: Number,
		default: null,
	},
	subcategory: {
		type: Number,
		default: null,
	},
	isCreate: {
		type: Boolean,
		default: false,
	},
});

const { t } = useTranslation();

const emit = defineEmits(["update:category", "update:subcategory", "confirm"]);

const selecteGroup = ref(null);
const selecteCategory = ref(null);
const selecteSubCategory = ref(null);
const prevSelecteCategory = ref(null);
const prevSelecteSubCategory = ref(null);

watch(
	() => selecteCategory.value,
	(newVal, oldVal) => {
		emit("update:category", selecteCategory.value);
		if (isNil(newVal) && !isNil(oldVal)) {
			prevSelecteCategory.value = oldVal;
		}
	}
);

watch(
	() => selecteSubCategory.value,
	(newVal, oldVal) => {
		emit("update:subcategory", selecteSubCategory.value);
		if (isNil(newVal) && !isNil(oldVal)) {
			prevSelecteSubCategory.value = oldVal;
		}
	}
);

const isShowCat = computed(() => {
	return !!selecteGroup.value;
});

const confirm = () => {
	emit("confirm");
};
</script>
<template>
	<AnnouncementGroups v-if="!selecteGroup" v-model:group="selecteGroup" :with-tags="false">
		<template #title>
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="groups_header">
				{{ t("categorySelectorTitle") }}
			</Typography>
			<Typography :type="TYPOGRAPHY_TYPES.TEXT" class="groups__subheader">
				{{ t("categorySelectorSubTitle") }}
			</Typography>
		</template>
	</AnnouncementGroups>
	<Categories
		v-else-if="isShowCat"
		v-model:group="selecteGroup"
		v-model:category="selecteCategory"
		v-model:subcategory="selecteSubCategory"
		:prev-selecte-category="prevSelecteCategory"
		:prev-selecte-sub-category="prevSelecteSubCategory"
		@select="confirm"
	/>
</template>
<style lang="scss" scoped>
@import "./CategorySelector.scss";
</style>
