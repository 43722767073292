export default {
	ru: {
		userInfoInfoYourself: "Добавьте информацию о себе",
		userInfoInfoDescrCollaps: "Посмотреть полностью",
		contactText: "Контакты",
		profileSettingsText: "Настройки профиля",
	},
	en: {
		userInfoInfoYourself: "Add information about yourself",
		userInfoInfoDescrCollaps: "View full",
		contactText: "Contacts",
		profileSettingsText: "Profile settings",
	},
	ka: {
		userInfoInfoYourself: "დაამატეთ ინფორმაცია თქვენს შესახებ",
		userInfoInfoDescrCollaps: "სრულიად ნახვა",
		contactText: "კონტაქტები",
		profileSettingsText: "პროფილის რედაქტირება",
	},
	tr: {
		userInfoInfoYourself: "Kendi hakkınızda bilgi ekleyin",
		userInfoInfoDescrCollaps: "Tümünü gör",
		contactText: "Kişiler",
		profileSettingsText: "Profil ayarları",
	},
};
