export default {
	ru: {
		editProfileDeletePhoto: "Удалить фото",
		editProfileMoSave:
			"Внесенные изменения будут потеряны. Подтвердите выход или сохраните изменения",
		editProfileMoSaveSelectLanguageText:
			"Выберите язык для общения в чатах с пользователями сервиса",
		editProfileWhatsAppMatches: "WhatsApp совпадает с телефоном",
		editProfileValidateContact: "Укажите хотя бы один способ связи",
		editProfileValidateInvalidFormat: "Неверный формат телефона",
	},
	en: {
		editProfileDeletePhoto: "Delete photo",
		editProfileMoSave: "Unsaved changes will be lost. Confirm exit or save changes",
		editProfileMoSaveSelectLanguageText:
			"Select the language for communication in chats with users of the service",
		editProfileWhatsAppMatches: "WhatsApp matches the phone number",
		editProfileValidateContact: "Specify at least one way of communication",
		editProfileValidateInvalidFormat: "Incorrect phone format",
	},
	ka: {
		editProfileDeletePhoto: "ფოტოს წაშლა",
		editProfileMoSave:
			"შეტანილი ცვლილებები იქნება დაკარგული. დაადასტურეთ ან შეინახეთ შეტანილი ცვლილებები",
		editProfileMoSaveSelectLanguageText:
			"აირჩიეთ სასურველი ენა ჩათში სხვა მომხმარებლებთან სალაპარაკოდ",
		editProfileWhatsAppMatches: "Whatsupp-ის ნომერი ემთხვევა თქვენს ნომერს",
		editProfileValidateContact: "მიუთითეთ დასაკავშირებლად ერთი საშუალება მაინც",
		editProfileValidateInvalidFormat: "ტელეფონის ნომრის არასწორი ფორმატი",
	},
	tr: {
		editProfileDeletePhoto: "Fotoğrafı silin",
		editProfileMoSave: "Kaydedilmemiş değişiklikler kaybolacaktır. Çıkışı onaylayın veya değişiklikleri kaydedin",
		editProfileMoSaveSelectLanguageText: "Hizmetin kullanıcıları ile sohbetlerde iletişim için dil seçin",
		editProfileWhatsAppMatches: "WhatsApp, telefon numarası ile eşleşmektedir",
		editProfileValidateContact: "En az bir iletişim yolu belirtin",
		editProfileValidateInvalidFormat: "Yanlış telefon formatı",
	},
};
