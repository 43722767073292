<script setup>
import { ref, watch, computed } from "vue";
import { AnnouncementPreview } from "@entities/announcement";
import { REQUST_STATUSES } from "@shared/const";
import { useScreenSize } from "@shared/lib";
import { announcementListProps } from "./AnnouncementList.const";

const props = defineProps({
	...announcementListProps,
});

const { isDesktop, isTablet } = useScreenSize();

const suspensePreviewCount = computed(() => {
	if (isDesktop.value) {
		return 12;
	}
	if (isTablet.value) {
		return 6;
	}

	return 4;
});

const emit = defineEmits(["updateList"]);

const isNeedToUpdate = ref(true);

watch(
	() => props.status,
	() => {
		if (props.status === REQUST_STATUSES.SUCCESS) {
			isNeedToUpdate.value = true;
		}
	}
);

const updateList = () => {
	if (isNeedToUpdate.value && props.status !== REQUST_STATUSES.PENDING) {
		emit("updateList");
		isNeedToUpdate.value = false;
	}
};

const isShowAnnouncement = computed(() => {
	return (
		(props.status === REQUST_STATUSES.PENDING || props.status === REQUST_STATUSES.OUTDATED) &&
		!props.isFullfield
	);
});

const suspenseCount = computed(() => {
	return new Array(suspensePreviewCount.value).fill(null);
});
</script>

<template>
	<div class="announcement-list">
		<AnnouncementPreview
			v-for="(item, index) in items"
			:key="item.item_id"
			:announcementCard="item"
			:orientation="orientation"
			:with-favorite="withFavorite"
			:with-keep-alive="withKeepAlive"
			:emit-on-visible="updateByItem ? item.updateOnVisible : index === items.length - 1"
			@visible="updateList"
		/>
		<template v-if="isShowAnnouncement">
			<AnnouncementPreview
				v-for="(item, index) in suspenseCount"
				:key="index"
				:suspense="true"
				:orientation="orientation"
			/>
		</template>
	</div>
</template>

<style lang="scss" scoped>
@import "./AnnouncementList.scss";
</style>
