<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { Image } from "@shared/ui";
import { AnnouncementRecommended } from "@widgets/AnnouncementRecommended";
import { useScreenSize } from "@shared/lib";
import { ADS_PLACE_CODE } from "@shared/const";
import { Stories } from "@widgets/Stories";
import { Ads } from "@features/Ads";
import { ActualAnnouncementList } from "@widgets/AnnouncementLists";
import { Feedback } from "@widgets/Feedback";

import { Rubricator } from "../Rubricator";

const { screenSize, SCREEN_SIZES_LIST, isDesktop } = useScreenSize();
const store = useStore();

const currentUserExists = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const feedback = false;

const BANNER_MOBILE = "img/banner-mobile.svg";

const BANNER_MD_SRC = "img/banner-md.svg";

const BANNER_LG_SRC = "img/banner-lg.svg";

const bannerSrc = computed(() => {
	let res = BANNER_MOBILE;

	if (screenSize.value === SCREEN_SIZES_LIST.LG) {
		res = BANNER_MD_SRC;
	} else if (
		screenSize.value === SCREEN_SIZES_LIST.XL ||
		screenSize.value === SCREEN_SIZES_LIST.XXL
	) {
		res = BANNER_LG_SRC;
	}

	return res;
});
</script>

<template>
	<section class="main-page">
		<Ads :place-code="ADS_PLACE_CODE.TOP" />
		<section
			:class="[{ 'main-page__strories': !feedback, 'main-page__strories-feedback': feedback }]"
		>
			<Stories />
			<div class="main-page__banner" v-if="!feedback && isDesktop">
				<Image :src="bannerSrc" />
			</div>
			<div v-if="feedback" class="main-page__feedback">
				<Feedback />
			</div>
		</section>
		<Rubricator />
		<AnnouncementRecommended v-if="currentUserExists?.user_id" />
		<ActualAnnouncementList />
	</section>
</template>
<style lang="scss" scoped>
@import "./MainPage.scss";

.token {
	word-break: break-all;
}
</style>
