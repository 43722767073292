import { HTTP_METHODS, getApiUrl } from "@shared/const";
import { httpRequest } from "@shared/lib";

const ENDPOINT = "users";
const FILES_ENDPOINT = "files";
const ENDPOINT_TG_AUTH = "auth/tg";
const ENDPOINT_GOOGLE_AUTH = "auth/google";
const ENDPOINT_APPLE_AUTH = "auth/apple";
const ENDPOINT_EMAIL_AUTH_INIT = "auth/email/init";
const ENDPOINT_EMAIL_AUTH_CONFIRM = "auth/email/confirm_by_email";
const ENDPOINT_EMAIL_AUTH_PASSWORD = "auth/email/password";
const ENDPOINT_CU_AUTH = "auth/current_user";
const ENDPOINT_DELETE_PROFILE = "auth/delete_profile";
const LANGUAGES_ENDPOINT = "dimensions/languages";
const CURRENT_LOCATION_ENDPOINT = "location/current";

const url = getApiUrl(ENDPOINT);

const urlTgAuth = getApiUrl(ENDPOINT_TG_AUTH);

const urlGoogleAuth = getApiUrl(ENDPOINT_GOOGLE_AUTH);

const urlAppleAuth = getApiUrl(ENDPOINT_APPLE_AUTH);

const urlEmailAuthInit = getApiUrl(ENDPOINT_EMAIL_AUTH_INIT);

const urlEmailAuthConfirm = getApiUrl(ENDPOINT_EMAIL_AUTH_CONFIRM);

const urlEmailAuthPassword = getApiUrl(ENDPOINT_EMAIL_AUTH_PASSWORD);

const urlCuAuth = getApiUrl(ENDPOINT_CU_AUTH);

const urlFiles = getApiUrl(FILES_ENDPOINT);

const urlDeleteProfile = getApiUrl(ENDPOINT_DELETE_PROFILE);
const urlGetLanguages = getApiUrl(LANGUAGES_ENDPOINT);
const urlGetCurrentLocation = getApiUrl(CURRENT_LOCATION_ENDPOINT);

/** @param {number} userId */
export const apiGetUser = (userId) => {
	const method = HTTP_METHODS.GET;

	const query = { user_id: userId };

	return httpRequest({ url, method }, { query });
};

/** @param {CreateUserDto} userData */
export const apiPostUser = (userData) => {
	const method = HTTP_METHODS.POST;

	return httpRequest({ url, method }, { body: userData });
};

/** @param {UpdateUserDto} userData */
export const apiPutUser = (userData) => {
	const method = HTTP_METHODS.PUT;

	return httpRequest({ url, method }, { body: userData });
};

/**
 * @typedef {{
 * 	first_name: string;
 * 	last_name: string;
 * 	register_type: string;
 * 	telegram_id: number;
 * 	username: string;
 * }} CreateUserDto
 */

/**
 * @typedef {{
 * 	description: string;
 * 	email: string;
 * 	first_name: string;
 * 	is_phone_hide: true;
 * 	is_tg_hide: true;
 * 	phone: string;
 * 	region_id: number;
 * 	second_name: string;
 * 	user_id: number;
 * }} UserDto
 */

/**
 * @typedef {{
 * 	description?: string;
 * 	email?: string;
 * 	first_name?: string;
 * 	is_phone_hide?: true;
 * 	is_tg_hide?: true;
 * 	phone?: string;
 * 	region_id?: number;
 * 	second_name?: string;
 * 	user_id?: number;
 * }} UpdateUserDto
 */

export const apiGetTGUser = () => {
	const method = HTTP_METHODS.GET;

	return httpRequest({ url: urlCuAuth, method }, {});
};

export const apiAuthTGUser = (userData) => {
	const method = HTTP_METHODS.GET;

	return httpRequest({ url: urlTgAuth, method }, { query: userData });
};

export const apiAuthTGoogleUser = (userData) => {
	const method = HTTP_METHODS.POST;

	return httpRequest({ url: urlGoogleAuth, method }, { body: userData });
};

export const apiAuthAppleUser = (userData) => {
	const method = HTTP_METHODS.POST;

	return httpRequest({ url: urlAppleAuth, method }, { body: userData });
};

export const apiLogout = () => {
	const method = HTTP_METHODS.DELETE;

	return httpRequest({ url: urlTgAuth, method }, {});
};

export const apiPostUserPhoto = (params) => {
	return httpRequest(
		{ method: HTTP_METHODS.POST, url: urlFiles },
		{ query: params.query, formDataName: "files", formData: params.file }
	);
};

export const apiGetUserPhoto = (params) => {
	return httpRequest({ method: HTTP_METHODS.GET, url: urlFiles }, { query: params });
};

export const apiDeleteUserPhoto = (params) => {
	return httpRequest({ method: HTTP_METHODS.DELETE, url: urlFiles }, { query: params });
};

export const initEmail = async (data) => {
	const method = HTTP_METHODS.POST;

	return httpRequest({ url: urlEmailAuthInit, method }, { body: data });
};

export const confirmEmail = async (data) => {
	const method = HTTP_METHODS.POST;

	return httpRequest({ url: urlEmailAuthConfirm, method }, { query: data });
};

export const createPassword = (data) => {
	const method = HTTP_METHODS.POST;

	return httpRequest({ url: urlEmailAuthPassword, method }, { body: data });
};

export const deleteAccount = (comment) => {
	const method = HTTP_METHODS.POST;

	return httpRequest({ url: urlDeleteProfile, method }, { body: { comment } });
};

export const apiGetLanguages = () => {
	const method = HTTP_METHODS.GET;
	return httpRequest({ url: urlGetLanguages, method });
};

export const apiGetCurrentLocation = () => {
	const method = HTTP_METHODS.GET;
	return httpRequest({ url: urlGetCurrentLocation, method });
};

export const apiPostFBToken = (token) => {
	const url = getApiUrl("push/reg_token/user");
	return httpRequest(
		{ url, method: HTTP_METHODS.POST },
		{ body: { token } },
		{ silentError: true }
	);
};

export const apiDeleteFBToken = (token) => {
	const url = getApiUrl("push/reg_token/user");
	return httpRequest(
		{ url, method: HTTP_METHODS.DELETE },
		{ body: { token } },
		{ silentError: true }
	);
};
