<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { Autocomplete, Button, Modal, TYPOGRAPHY_TYPES, useModal, Typography } from "@shared/ui";
import i18next from "i18next";
import { useTranslation } from "i18next-vue";

const modal = ref();

const store = useStore();
const modalState = useModal(modal);
const { t } = useTranslation();

const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const isUserLoggedIn = computed(() => {
	return store.getters["user/isUserLoggedIn"];
});

const locales = [
	{ flag: "RU", label: "Русский", locale: "ru" },
	{ flag: "RU", label: "English", locale: "en" },
	{ flag: "RU", label: "Georgian", locale: "ka" },
	{ flag: "RU", label: "Turkish", locale: "tr" },
];

const announcementLocales = [
	{ label: t("changeLanguageOriginal"), locale: "is_origin" },
	...locales,
];

const getAnnouncementLanguage = computed(() => {
	return store.getters["user/getAnnouncementTranslate"];
});

const getCurrentLocal = () => {
	return locales.find((locale) => locale.locale === i18next.language);
};

const getAnnouncementLocal = () => {
	return announcementLocales.find((locale) => locale.locale === getAnnouncementLanguage.value);
};

const currentLocaleInterface = ref();
const currentAnnouncementLocale = ref();

const localeReducer = (locale) => locale.label;

defineExpose({ ...modalState });

const onOpen = () => {
	currentLocaleInterface.value = getCurrentLocal();
	currentAnnouncementLocale.value = getAnnouncementLocal();
};

const onConfirm = () => {
	i18next.changeLanguage(currentLocaleInterface.value.locale);
	if (isUserLoggedIn.value) {
		store.dispatch("user/updateCurrentUser", {
			user_id: user.value.user_id,
			interface_language: i18next.language,
			ad_language: currentAnnouncementLocale.value?.locale || "is_origin",
		});
	}
	store.commit("user/SET_USER_TRANSLATE", {
		announcementLanguage: currentAnnouncementLocale.value.locale,
	});
	modalState.close();
};
</script>

<template>
	<Modal ref="modal" @open="onOpen">
		<Typography class="change-locale__title" :type="TYPOGRAPHY_TYPES.TITLE_2">
			{{ t("changeLanguage") }}
		</Typography>
		<Typography class="change-locale__list-title" :type="TYPOGRAPHY_TYPES.CARD_NAME">
			{{ t("changeLanguageInterface") }}
		</Typography>
		<Autocomplete
			:items="locales"
			:reducer="localeReducer"
			v-model="currentLocaleInterface"
			:disable-input="true"
			:placeholder="t('placeholderChangeLocaleModal')"
		/>
		<Typography class="change-locale__list-title" :type="TYPOGRAPHY_TYPES.CARD_NAME">
			{{ t("changeLanguageAnnouncement") }}
		</Typography>
		<Autocomplete
			:items="announcementLocales"
			:reducer="localeReducer"
			v-model="currentAnnouncementLocale"
			:disable-input="true"
			:placeholder="t('placeholderChangeLocaleModal')"
		/>
		<Button
			class="change-locale__confirm"
			width-full
			:disabled="!currentLocaleInterface"
			@click="onConfirm"
		>
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">
				{{ t("changeRegionModalBtnConfirm") }}
			</Typography>
		</Button>
	</Modal>
</template>

<style lang="scss" scoped>
@import "./ChangeLocaleModal.scss";
</style>
