export const GROUPS = /** @type {const} */ ({
	1: {
		name: "groupsGoods",
		icon: "cart",
		description: "groupsGoodsText",
	},
	2: {
		name: "groupsServices",
		icon: "wrench-screwdriver",
		description: "groupsServicesText",
	},
	3: {
		name: "groupsJob",
		icon: "briefcase",
		description: "groupsJobText",
	},
	4: {
		name: "groupsEvents",
		icon: "theatermasks",
		description: "groupsEventsText",
	},
	5: {
		name: "groupsRealEstate",
		icon: "house",
		description: "groupsRealEstateText",
	},
	6: {
		name: "groupsTransport",
		icon: "car",
		description: "groupsTransportText",
	},
	7: {
		name: "groupsTourism",
		icon: "figure-walk",
		description: "groupsTourismText",
	},
});
