import { useStore } from "vuex";
import { computed } from "vue";
import { countBy } from "lodash";
import { formatByMask } from "@shared/lib";

export const usePhone = () => {
	const store = useStore();

	const phonesByCountry = computed(() => store.getters["phone/phonesByCountry"]);

	/** @param {string} phoneStr */
	const findPhoneCountry = (phoneStr) => {
		const result = phonesByCountry.value.find((phone) => {
			const code = phone.code.toString();
			const mask = phone.mask.toString();

			const numberLength = countBy(mask).x;
			const modelLengthWithoutCode = phoneStr.length - code.length;

			return phoneStr.startsWith(code) && numberLength === modelLengthWithoutCode;
		});
		return result;
	};

	/**
	 * @param {string} phoneStr
	 * @returns {string}
	 */
	const toFormatPhone = (phoneStr) => {
		let result = "";
		if (phoneStr) {
			const currentCountry = findPhoneCountry(phoneStr);
			result = phoneStr;

			if (currentCountry) {
				result = `+${currentCountry.code} ${formatByMask(
					phoneStr.slice(currentCountry.code.toString().length),
					currentCountry.mask
				)}`;
			}
		}
		return result;
	};

	return {
		findPhoneCountry,
		toFormatPhone,
	};
};
