<script setup>
import { ref, computed, watch, nextTick } from "vue";
import { CATEGORIES_STEPS, useCategories } from "@entities/announcement";
import {
	Autocomplete,
	Skeleton,
	SuspenseProvider,
	SvgTemplate,
	Typography,
	TYPOGRAPHY_TYPES,
} from "@shared/ui";
import { useScreenSize } from "@shared/lib";
import { FiltersModal, useFilters } from "@features/Filters";
import { CatalogModal } from "@features/CatalogModal";
import { useTranslation } from "i18next-vue";

const {
	breadCrumbs,
	currentStep,
	categoriesByCurrentId,
	subCategoriesByCurrentId,
	tagsByTagCategoryId,
	subCategoryModel,
	categoryModel,
	categoriesStatus,
	subCategoryId,
	categoryId,
	selectGroup,
	isValid,
	tagCategoryId,
	tagId,
	tagModel,
	selectTagCategory,
} = useCategories();

const { isDesktop } = useScreenSize();
const { isFiltersEmpty } = useFilters();
const { t } = useTranslation();

const reducerCat = (category) => {
	return category.category_name;
};

const reducerSubCat = (subcategory) => {
	return subcategory.name;
};

const filtersModalRef = ref(null);

const openFiltersModal = () => {
	filtersModalRef.value.open();
};

const isShowSubCategory = computed(() => {
	return (
		subCategoriesByCurrentId.value.length > 0 &&
		(currentStep.value === CATEGORIES_STEPS.CATEGORY ||
			currentStep.value === CATEGORIES_STEPS.SUB_CATEGORY)
	);
});

const catalogModal = ref(null);

watch(
	[currentStep, isValid],
	() => {
		if (currentStep.value === CATEGORIES_STEPS.NULL && isValid.value && !tagCategoryId.value) {
			nextTick(() => catalogModal.value?.open());
		}
	},
	{ immediate: true }
);

watch(
	categoriesByCurrentId,
	() => {
		if (categoriesByCurrentId.value.length === 1) {
			const firstItem = categoriesByCurrentId.value[0];
			nextTick(() => {
				categoryModel.value = firstItem;
			});
		}
	},
	{ immediate: true }
);
watch(
	subCategoriesByCurrentId,
	() => {
		if (subCategoriesByCurrentId.value.length === 1) {
			const firstItem = subCategoriesByCurrentId.value[0];
			nextTick(() => {
				subCategoryModel.value = firstItem;
			});
		}
	},
	{ immediate: true }
);

const isShowCategory = computed(() => !tagCategoryId.value && !tagId.value);

const tagReducer = (tag) => {
	return tag.tag_name;
};

const isShowTagSelector = computed(() => tagCategoryId.value);
</script>
<template>
	<section class="select-categories">
		<SuspenseProvider :status="categoriesStatus" v-if="currentStep !== CATEGORIES_STEPS.NULL">
			<template #fallback>
				<Skeleton class="select-categories__breadcrumbs" :height="22" :b-radius="8" />
			</template>
			<div class="select-categories__search-title">
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
					{{
						isDesktop
							? t("selectCategoriesSearchCategory")
							: t("selectCategoriesSearchCategoryShort")
					}}
				</Typography>
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
					{{ t(breadCrumbs[0]) }}
				</Typography>
			</div>
		</SuspenseProvider>
		<div class="select-categories__form">
			<SuspenseProvider :status="categoriesStatus">
				<template #fallback>
					<Skeleton :height="40" :b-radius="8" />
					<Skeleton :height="40" :b-radius="8" v-if="subCategoryId || categoryId" />
				</template>
				<Autocomplete
					v-if="isShowTagSelector"
					:placeholder="t('placeholderSelectCategory')"
					v-model="tagModel"
					:items="tagsByTagCategoryId"
					disable-input
					:reducer="tagReducer"
					check-interval-bounding
					reset-disabled-options
				/>
				<Autocomplete
					v-if="isShowCategory"
					v-model="categoryModel"
					class="select-categories__category"
					:class="{ 'select-categories__category-sub': isShowSubCategory }"
					disable-input
					:placeholder="t('placeholderSelectCategory')"
					:items="categoriesByCurrentId"
					:reset-disabled="categoriesByCurrentId.length < 2"
					:reducer="reducerCat"
					check-interval-bounding
					reset-disabled-options
				/>
				<Autocomplete
					v-if="isShowSubCategory"
					v-model="subCategoryModel"
					class="select-categories__sub-category"
					disable-input
					:placeholder="t('placeholderSelectCategory')"
					:reset-disabled="subCategoriesByCurrentId.length < 2"
					:items="subCategoriesByCurrentId"
					:reducer="reducerSubCat"
					reset-disabled-options
				/>
			</SuspenseProvider>
			<div
				class="list-by-categories__filters"
				:class="{
					'list-by-categories__active-subcategory': isShowSubCategory,
					'list-by-categories__active-filters': !isFiltersEmpty,
				}"
				@click="openFiltersModal"
			>
				<button type="button" class="list-by-categories__btn">
					<SvgTemplate class="announcement-card__svg" name="filters-nav" />
				</button>
			</div>
		</div>
	</section>
	<FiltersModal ref="filtersModalRef" />
	<CatalogModal
		disable-close
		ref="catalogModal"
		@select-group="selectGroup"
		@select-tag-category="selectTagCategory"
	>
		<template #title>{{ t("placeholderSelectCategory") }}:</template>
	</CatalogModal>
</template>

<style lang="scss" scoped>
@import "./SelectCategories.scss";
</style>
