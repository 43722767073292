<script setup>
import { AnnouncementList } from "@entities/announcement";
import { useSlots, computed } from "vue";
import { ErrorFallback } from "@shared/ui";
import { REQUST_STATUSES } from "@shared/const";
import { useTranslation } from "i18next-vue";
import { EmptyList } from "../EmptyList";
import { announcementListProps } from "../AnnouncementList";

const props = defineProps({
	...announcementListProps,
	isEmpty: {
		type: Boolean,
		default: false,
	},
});

const { t } = useTranslation();

const emit = defineEmits(["updateList"]);

const slots = useSlots();

const isSuspense = computed(() => {
	return (
		(props.status === REQUST_STATUSES.PENDING || props.status === REQUST_STATUSES.OUTDATED) &&
		props.items.length === 0
	);
});
</script>

<template>
	<section class="announcement-list-section">
		<slot name="title" :is-suspense="isSuspense"></slot>

		<template v-if="isEmpty">
			<slot name="empty" v-if="slots.empty" />
			<EmptyList v-else />
		</template>
		<template v-else-if="status === REQUST_STATUSES.ERROR">
			<slot name="error" v-if="slots.error" />
			<ErrorFallback v-else :message='t("announcementListSectionError")' />
		</template>
		<AnnouncementList
			v-else
			:with-favorite="withFavorite"
			:items="items"
			:status="status"
			:orientation="orientation"
			:isFullfield="isFullfield"
			:update-by-item="updateByItem"
			:with-keep-alive="withKeepAlive"
			@updateList="emit('updateList')"
		/>
		<slot name="sub-title" :is-suspense="isSuspense"></slot>
	</section>
</template>

<style lang="scss" scoped>
@import "./AnnouncementListSection.scss";
</style>
