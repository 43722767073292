import { nanoid } from "nanoid";

// Шаблоны сообщений и событий для чата
export const CHAT_EVENTS = {
	ACTION: "action",
	MESSAGE: "message",
};

export const CHAT_ACTIONS = {
	LOAD_CHATS: "load_chats",
	LOAD_CHAT: "load_chat_msg",
	INIT_CHAT: "init_chat",
	USER_TYPING: "typing",
	USER_STOP_TYPING: "stop_typing",
	USER_ONLINE: "online",
	USER_OFFLINE: "offline",
	MESSAGE_SEEN: "viewed",
	NOT_DELIVERED: "not_delivered",
	DELIVERED: "delivered",
};

export const CHAT_PROTOCOL = {
	ACTIONS: {
		LOAD_CHATS: {
			type: CHAT_EVENTS.ACTION,
			action: CHAT_ACTIONS.LOAD_CHATS,
		},
		INIT_CHAT: (to, entityId) => ({
			type: CHAT_EVENTS.ACTION,
			to,
			subject: entityId,
			action: CHAT_ACTIONS.INIT_CHAT,
		}),
		LOAD_CHAT: (chatId) => ({
			type: CHAT_EVENTS.ACTION,
			chat_id: chatId,
			action: CHAT_ACTIONS.LOAD_CHAT,
		}),
		USER_TYPING: (chatId) => ({
			type: CHAT_EVENTS.ACTION,
			chat_id: chatId,
			action: CHAT_ACTIONS.USER_TYPING,
		}),
		USER_STOP_TYPING: (chatId) => ({
			type: CHAT_EVENTS.ACTION,
			chat_id: chatId,
			action: CHAT_ACTIONS.USER_STOP_TYPING,
		}),
		USER_ONLINE: {
			type: CHAT_EVENTS.ACTION,
			action: CHAT_ACTIONS.USER_ONLINE,
		},
		USER_OFFLINE: {
			type: CHAT_EVENTS.ACTION,
			action: CHAT_ACTIONS.USER_OFFLINE,
		},
		MESSAGE_SEEN: (chatId, messageId) => ({
			type: CHAT_EVENTS.ACTION,
			chat_id: chatId,
			message_id: messageId,
			action: CHAT_ACTIONS.MESSAGE_SEEN,
		}),
	},
	MESSAGES: {
		SEND_MESSAGE: (text, entityId, chatId) => {
			const msg = {
				type: CHAT_EVENTS.MESSAGE,
				text,
				extKey: nanoid(),
				subject: entityId,
			};
			if (chatId) msg.chat_id = chatId;
			return msg;
		},
	},
};
