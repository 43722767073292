export default {
	ru: {
		sliderTitleSimilarProducts: "Похожие объявления",
		sliderTitleRecenttlyWatched: "Вы недавно смотрели",
	},
	en: {
		sliderTitleSimilarProducts: "Similar ads",
		sliderTitleRecenttlyWatched: "You recently viewed",
	},
	ka: {
		sliderTitleSimilarProducts: "მსგავსი განცხადებები",
		sliderTitleRecenttlyWatched: "ნანახი",
	},
	tr: {
		sliderTitleSimilarProducts: "Benzer ilanlar",
		sliderTitleRecenttlyWatched: "Yakın zamanda görüntülediniz",
	},
};
