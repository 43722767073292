<script setup>
import { computed } from "vue";
import { TAG_CATEGORIES } from "@entities/announcement";
import { SvgTemplate, Typography, TYPOGRAPHY_TYPES } from "@shared/ui";
import { useScreenSize } from "@shared/lib";

const props = defineProps({
	tagCategoryId: {
		type: Object,
		required: true,
	},
});

const { screenSize } = useScreenSize();

const THEMES = {
	[TAG_CATEGORIES.NEW_YEAR]: "new-year",
};

const tagTheme = computed(() => THEMES[props.tagCategoryId]);
</script>

<template>
	<div :class="['tag-label', tagTheme]">
		<template v-if="tagCategoryId === TAG_CATEGORIES.NEW_YEAR">
			<Typography :type="TYPOGRAPHY_TYPES.TAG">Новогоднее</Typography>
			<SvgTemplate v-if="screenSize !== 'xs'" class="christmas-tree-svg" name="christmas-tree" />
		</template>
	</div>
</template>

<style lang="scss" scoped>
@import "./AnnouncementTag.scss";
</style>
