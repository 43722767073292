import { BUCKET_URL, FLAG_MAP } from "@shared/const";

export const getCountryFlag = ({ countryId, countryIsoCode }) => {
	let url;
	if (countryIsoCode) {
		url = getCountryFlagByIsoCode(countryIsoCode);
	} else {
		const flag = FLAG_MAP[countryId];
		url = flag ? `/flags/${flag}.svg` : `/flags/${FLAG_MAP[0]}.svg`;
	}
	return url;
};

export const getCountryFlagByIsoCode = (countryIsoCode) => {
	if (countryIsoCode) {
		return `${BUCKET_URL}country-flag/${countryIsoCode}.svg`;
	}
	return `${BUCKET_URL}country-flag/GLOBAL.svg`;
};
