<script setup>
import { ref, watch, reactive, computed } from "vue";
import { useStore } from "vuex";
import { isEmpty } from "lodash";
import {
	Autocomplete,
	INPUT_THEMES,
	TYPOGRAPHY_TYPES,
	Typography,
	InputField,
	INPUT_MODE,
	SvgTemplate,
} from "@shared/ui";
import { SIZES } from "@shared/const";
import { apiFindUser } from "@entities/announcement";
import { Phone } from "@features/Phone";
import { isTrue, requiredContact, required } from "@shared/lib";
import { useVuelidate } from "@vuelidate/core";
import { Contacts } from "@widgets/Profile";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	userADId: {
		type: Number,
		default: 0,
	},
});

const store = useStore();
const { t } = useTranslation();

const emit = defineEmits(["updateUser"]);

const selectUserAD = reactive({
	first_name: "",
	user_id: null,
	second_name: "",
	phone: "",
	whats_app_phone: "",
	username: "",
	email: "",
	is_phone_hide: false,
	is_tg_hide: false,
	is_wa_hide: false,
	is_email_hide: false,
	is_confirmed: false,
});

const selectUser = ref();

watch(
	() => props.userADId,
	async (value) => {
		if (value && value !== 0) {
			await store.dispatch("user/getUser", { user_id: value });
		}
	}
);

const user = computed(() => {
	return store.getters["user/getUser"](props.userADId);
});

const userData = computed(() => {
	return store.getters["user/getCurrentUser"];
});

watch(
	() => isEmpty(user),
	(value) => {
		if (!value) {
			selectUserAD.first_name = user.value.first_name;
			selectUserAD.second_name = user.value.second_name;
			selectUserAD.phone = user.value.phone;
			selectUserAD.phone_ccc = userData.value.phone_ccc;
			selectUserAD.whats_app_phone = user.value.whats_app_phone;
			selectUserAD.username = user.value.username;
			selectUserAD.email = user.value.email;
			selectUserAD.is_confirmed = user.value.is_confirmed;
			selectUserAD.user_id = user.value.user_id;
			selectUserAD.is_tg_hide = user.value.is_tg_hide;
			selectUserAD.is_wa_hide = user.value.is_wa_hide;
			selectUserAD.is_phone_hide = user.value.is_phone_hide;
		}
	}
);

watch(
	() => selectUser.value,
	(value) => {
		if (value) {
			selectUserAD.first_name = selectUser.value.first_name;
			selectUserAD.second_name = selectUser.value.second_name;
			selectUserAD.phone = selectUser.value.phone;
			selectUserAD.whats_app_phone = selectUser.value.whats_app_phone;
			selectUserAD.username = selectUser.value.username;
			selectUserAD.email = selectUser.value.email;
			selectUserAD.is_confirmed = selectUser.value.is_confirmed;
			selectUserAD.user_id = selectUser.value.user_id;
			selectUserAD.is_tg_hide = selectUser.value.is_tg_hide;
			selectUserAD.is_wa_hide = selectUser.value.is_wa_hide;
			selectUserAD.is_phone_hide = selectUser.value.is_phone_hide;
			selectUserAD.is_email_hide = selectUser.value.is_email_hide;
		}
	}
);

const isPhoneNoExist = ref(true);

const isWaNoExist = ref(true);

const isTGNoExist = ref(true);

const isEmailNoExist = ref(true);

const unWatchUser = ref(true);

const formFields = computed(() => {
	return {
		firstName: selectUserAD.first_name,
		phone: selectUserAD.phone,
		email: selectUserAD.email,
		wa: selectUserAD.whats_app_phone,
		username: selectUserAD.username,
	};
});

const isEmptyContact = computed(() => {
	return !(
		selectUserAD.username ||
		selectUserAD.phone ||
		selectUserAD.whats_app_phone ||
		selectUserAD.email
	);
});

const validateRules = {
	firstName: { required },
	phone: {
		isTrue: isTrue(isPhoneNoExist, "Пользователь с такими контактными данными уже существует"),
		requiredIf: requiredContact(isEmptyContact),
	},
	email: {
		isTrue: isTrue(isEmailNoExist, "Пользователь с такими контактными данными уже существует"),
		requiredIf: requiredContact(isEmptyContact),
	},
	wa: {
		isTrue: isTrue(isWaNoExist, "Пользователь с такими контактными данными уже существует"),
		requiredIf: requiredContact(isEmptyContact),
	},
	username: {
		isTrue: isTrue(isTGNoExist, "Пользователь с такими контактными данными уже существует"),
		requiredIf: requiredContact(isEmptyContact),
	},
};

const validate = useVuelidate(validateRules, formFields);

const saveData = async () => {
	const isValid = !validate.value.$invalid;
	validate.value.$touch();
	if (isValid) {
		const query = Object.fromEntries(
			Object.entries(selectUserAD).filter(([s, v]) => v != null && v !== "" && s)
		);
		delete query.is_confirmed;
		let endPoint = "user/createUser";
		if (selectUserAD.user_id) {
			endPoint = "user/updateUser";
		}
		try {
			const res = await store.dispatch(endPoint, query);
			return Promise.resolve(res);
		} catch (e) {
			const noValidFields = e?.response?.data?.errors?.json;
			if (!isEmpty(noValidFields)) {
				isTGNoExist.value = !noValidFields.userFlag;
				isPhoneNoExist.value = !noValidFields.phoneFlag;
				isWaNoExist.value = !noValidFields.waFlag;
				validate.value.$touch();
			}
			return Promise.reject(e);
		}
	} else {
		return Promise.reject();
	}
};

const reducerUsers = (item) => {
	const firstName = item?.first_name ? item.first_name : "";
	const secondName = item?.second_name ? item.second_name : "";
	const phone = item?.phone ? item.phone : "";
	const username = item?.username ? item.username : "";
	return `${firstName} ${secondName} ${phone} ${username}`;
};

const focusPhone = () => {
	validate.value.phone.$reset();
	isPhoneNoExist.value = true;
};

const focusWa = () => {
	validate.value.wa.$reset();
	isWaNoExist.value = true;
};

const focusTG = () => {
	validate.value.username.$reset();
	isTGNoExist.value = true;
};

const updateUser = () => {
	unWatchUser.value = false;
	validate.value.username.$reset();
	validate.value.phone.$reset();
	isPhoneNoExist.value = true;
	isEmailNoExist.value = true;
	isWaNoExist.value = true;
	isTGNoExist.value = true;
	validate.value.firstName.$reset();
	if (selectUser.value?.user_id) {
		emit("updateUser", { user_id: selectUser.value.user_id });
	}
};

const createNew = () => {
	selectUser.value = null;
	selectUserAD.first_name = "";
	selectUserAD.user_id = null;
	selectUserAD.second_name = "";
	selectUserAD.phone = "";
	selectUserAD.username = "";
	selectUserAD.email = "";
	selectUserAD.is_confirmed = false;
	unWatchUser.value = false;
	emit("updateUser", { user_id: 0 });
};

defineExpose({ saveData });
</script>

<template>
	<div>
		<Typography :type="TYPOGRAPHY_TYPES.TEXT" class="user-block__subtitle">
			{{ t("announcementCreateUserBlockSearch1") }}
			<br />
			{{ t("announcementCreateUserBlockSearch2") }}
		</Typography>
		<div class="user-block__search-create">
			<Autocomplete
				:size="SIZES.NORMAL"
				:placeholder="t('announcementCreateUserBlockPlaceholder')"
				v-model="selectUser"
				:api="apiFindUser"
				:reducer="reducerUsers"
				:mode="'api'"
				:theme="INPUT_THEMES.LIGHT"
				@update:modelValue="updateUser"
				class="user-block__search"
			/>
			<button type="button" @click="createNew" class="user-block__create">
				<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">
					{{ t("announcementCreateUserBlockCreateBtn") }}
				</Typography>
			</button>
		</div>
	</div>

	<div class="user-block__autocmplete" v-if="!unWatchUser">
		<Typography :type="TYPOGRAPHY_TYPES.TITLE_1">
			{{ t("announcementCreateUserBlockContactText") }}
		</Typography>
		<div class="user-block__name">
			<InputField
				:placeholder="t('placeholderName')"
				v-model="selectUserAD.first_name"
				:mode="INPUT_MODE.RAW"
				:theme="INPUT_THEMES.LIGHT"
				:disabled="selectUserAD.is_confirmed"
				:errors="validate.firstName.$errors"
				errorZeroHeight
				@blur="validate.firstName.$touch()"
				@focus="validate.firstName.$reset()"
			/>
			<InputField
				:placeholder="t('placeholderSurname')"
				v-model="selectUserAD.second_name"
				:mode="INPUT_MODE.RAW"
				:theme="INPUT_THEMES.LIGHT"
				:disabled="selectUserAD.is_confirmed"
				errorZeroHeight
				v-if="!selectUserAD.is_confirmed || (selectUserAD.is_confirmed && selectUserAD.second_name)"
			/>
		</div>
		<div v-if="selectUserAD.is_confirmed">
			<div class="user-block__active-status">
				<SvgTemplate name="check_icon" />
				<Typography :type="TYPOGRAPHY_TYPES.CAPTION_2" class="green-text">
					{{ t("announcementCreateUserBlockActiveTitle") }}
				</Typography>
			</div>
			<Typography :type="TYPOGRAPHY_TYPES.CAPTION_5" class="gray-text">
				{{ t("announcementCreateUserBlockActiveText") }}
			</Typography>
		</div>
	</div>
	<div class="user-block">
		<div class="user-block__autocmplete">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_1">
				{{ t("announcementCreateContactText") }}:
			</Typography>
			<Typography v-if="unWatchUser" :type="TYPOGRAPHY_TYPES.TEXT" class="user-block__subtitle">
				{{ t("announcementCreateUserBlockContactTitle") }}
			</Typography>
			<Typography
				v-if="!unWatchUser && selectUserAD.user_id"
				:type="TYPOGRAPHY_TYPES.TEXT"
				class="user-block__subtitle"
			>
				{{ t("announcementCreateUserBlockContactAllowed") }}
			</Typography>
			<Typography
				v-if="!unWatchUser && !selectUserAD.user_id"
				:type="TYPOGRAPHY_TYPES.TEXT"
				class="user-block__subtitle"
			>
				{{ t("announcementCreateContactWarningText2") }}
			</Typography>
		</div>

		<div class="user-block__contact-wrapper" v-if="!unWatchUser && !selectUserAD.user_id">
			<div
				v-if="!selectUserAD.is_confirmed || (selectUserAD.is_confirmed && selectUserAD.phone)"
				class="user-block__contact"
			>
				<SvgTemplate class="user-block__contact-icon" name="user-phone" />
				<Phone
					class="user-block__contact-input_full"
					:placeholder="t('announcementCreateContactPhone')"
					v-model="selectUserAD.phone"
					@focus="focusPhone"
					@blur="validate.phone.$touch"
					@isValid="(val) => (isPhoneValid = val)"
					:errors="validate.phone.$errors"
					error-zero-height
				/>
			</div>
			<div class="user-block__contact">
				<SvgTemplate
					class="user-block__contact-icon user-block__contact-icon_mail"
					name="user-email"
				/>
				<InputField
					class="user-block__contact-input_full"
					placeholder="E-mail"
					v-model="selectUserAD.email"
					:errors="validate.email.$errors"
					@focus="validate.email.$reset"
					@blur="validate.email.$touch"
					error-zero-height
					:mode="'raw'"
				/>
			</div>
			<div class="user-block__contact">
				<SvgTemplate
					class="user-block__contact-icon user-block__contact-icon_whatsapp"
					name="whatsapp"
				/>
				<Phone
					class="user-block__contact-input_full"
					:placeholder="t('announcementCreateContactPhone') + ' WhatsApp'"
					v-model="selectUserAD.whats_app_phone"
					@isValid="(val) => (isWhatsAppPhoneValid = val)"
					:errors="validate.wa.$errors"
					@focus="focusWa"
					@blur="validate.wa.$touch"
					error-zero-height
				/>
			</div>
			<div
				v-if="!selectUserAD.is_confirmed || (selectUserAD.is_confirmed && selectUserAD.username)"
				class="user-block__contact"
			>
				<SvgTemplate class="user-block__contact-icon user-block__contact-icon_tg" name="tg" />
				<InputField
					class="user-block__contact-input_full"
					:placeholder="t('placeholderNick') + ' Telegram'"
					v-model="selectUserAD.username"
					:mode="'raw'"
					@focus="focusTG"
					@blur="validate.username.$touch"
					:errors="validate.username.$errors"
					error-zero-height
				/>
			</div>
		</div>
		<Contacts
			v-if="selectUserAD.user_id"
			:tg="{ value: selectUserAD.username, visible: !selectUserAD.is_tg_hide }"
			:phone="{ value: selectUserAD.phone, visible: !selectUserAD.is_phone_hide }"
			:wa="{ value: selectUserAD.whats_app_phone, visible: !selectUserAD.is_wa_hide }"
			:email="{ value: selectUserAD.email, visible: !selectUserAD.is_email_hide }"
		/>
	</div>
</template>

<style lang="scss" scoped>
@import "./AnnouncementCreate.scss";
</style>
