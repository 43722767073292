export default {
	ru: {
		titleAuthLogin: "Вход в аккаунт",
		titleAuthPasswordRecovery: "Восстановление пароля",
		titleAuthRegistration: "Регистрация",
		titleAuthPassword: "Пароль",
		titleAuthConfirmation: "Подтверждение",
		subtitleAuthLogin: "Для продолжения работы необходимо авторизоваться",
		subtitleAuthAuthPasswordRecovery:
			"Введите адрес электронной почты, который вы использовали при регистрации",
		subtitleAuthRegistration: "Введите адрес электронной почты",
		subtitleAuthPassword: "Придумайте пароль для учетной записи",
		subtitleAuthConfirmation: "Введите код подтверждения",
		alternativeAuthTitle: "Войти с помощью:",
		alternativeAuthPretext: "или",
		alternativeAuthForgotPassword: "Забыли свой пароль?",
		alternativeAuthDontAccount: "У вас нет аккаунта?",
		alternativeAuthCreateAccountBtn: "Создать аккаунт",
		alternativeAuthToGetCodeBtn: "Получить код",
		alternativeAuthConfirm: "Подтвердить",
		alternativeAuthReceiveCode: "Получить код повторно",
		alternativeAuthMailInput: "Электронная почта",
		alternativeAuthPasswordInput: "Пароль",
		alternativeAuthCreatePasswordInput: "Придумайте пароль",
		alternativeAuthRepeatPasswordInput: "Повторите пароль",
		alternativeAuthCodeInput: "Код",
		toastAuthSuccessful: "Успешная авторизация",
		toastAuthError: "Что-то пошло не так",
		toastDeletedAds: "Объявление удалено",
		toastArchiveAds: "Объявление перемещено в Архив",
		toastPublishedAds: "Объявление опубликовано",
		toastUnpublishAds: "Объявление снять с публикации",
		toastNotRemovedAds: "Объявление не удалено",
		toastCreateSuccess: "Объявление создано/обновлено",
	},
	en: {
		titleAuthLogin: "Login to account",
		titleAuthPasswordRecovery: "Password recovery",
		titleAuthRegistration: "Registration",
		titleAuthPassword: "Password",
		titleAuthConfirmation: "Confirmation",
		subtitleAuthLogin: "You need to log in to continue",
		subtitleAuthAuthPasswordRecovery: "Enter the email address you used when registering",
		subtitleAuthRegistration: "Enter email address",
		subtitleAuthPassword: "Create a password for your account",
		subtitleAuthConfirmation: "Enter the confirmation code",
		alternativeAuthTitle: "Sign in with:",
		alternativeAuthPretext: "or",
		alternativeAuthForgotPassword: "Forgot your password?",
		alternativeAuthDontAccount: "Don't have an account?",
		alternativeAuthCreateAccountBtn: "Create an account",
		alternativeAuthToGetCodeBtn: "Get code",
		alternativeAuthConfirm: "Confirm",
		alternativeAuthReceiveCode: "Get code again",
		alternativeAuthConfirmBtn: "Confirm",
		alternativeAuthReceiveCodeBtn: "Receive code",
		alternativeAuthMailInput: "Email",
		alternativeAuthPasswordInput: "Password",
		alternativeAuthCreatePasswordInput: "Create a password",
		alternativeAuthRepeatPasswordInput: "Repeat password",
		alternativeAuthCodeInput: "Code",
		toastSuccessfulAuthorization: "Successful authorization",
		toastAuthError: "Something went wrong",
		toastAuthSuccessful: "Successful authorization",
		toastDeletedAds: "Ad deleted",
		toastArchiveAds: "Advertisement moved to Archive",
		toastPublishedAds: "Announcement published",
		toastUnpublishAds: "Unpublish announcement",
		toastNotRemovedAds: "The ad has not been removed",
		toastCreateSuccess: "Ad created/updated",
	},
	ka: {
		titleAuthLogin: "ექაუნთში შესვლა",
		titleAuthPasswordRecovery: "პაროლის აღდგენა",
		titleAuthRegistration: "რეგისტრაცია",
		titleAuthPassword: "პაროლი",
		titleAuthConfirmation: "დადასტურება",
		subtitleAuthLogin: "მუშაობის გასაგრძელებლად საჭიროა ავტორიზაცია",
		subtitleAuthAuthPasswordRecovery:
			"მიუთითეთ თქვენი საფოსტო მეილი რომელიც გამოიყენეთ რეგისტრაციის დროს",
		subtitleAuthRegistration: "შეიყვანეთ თქვენი საფოსტო მეილი",
		subtitleAuthPassword: "მოიფიქრეთ პაროლი ექაუთისთვის",
		subtitleAuthConfirmation: "შეიყვანეთ დასადასტურებლად კოდი",
		alternativeAuthTitle: "შესვლა  დახმარებით:",
		alternativeAuthPretext: "ან",
		alternativeAuthForgotPassword: "დაგავიწყდათ პაროლი?",
		alternativeAuthDontAccount: "არ გაქვთ ექაუნთი?",
		alternativeAuthCreateAccountBtn: "ექაუნთის შექმნა",
		alternativeAuthToGetCodeBtn: "კოდის მიღება",
		alternativeAuthConfirm: "დადასტურება",
		alternativeAuthReceiveCode: "კოდის განმეორებით მიღება",
		alternativeAuthMailInput: "ელექტრონული ფოსტა",
		alternativeAuthPasswordInput: "პაროლი",
		alternativeAuthCreatePasswordInput: "მოიფიქრეთ პაროლი",
		alternativeAuthRepeatPasswordInput: "გაიმეორეთ პაროლი",
		alternativeAuthCodeInput: "კოდი",
		toastAuthSuccessful: "წარმატებული ავტორიზაცია",
		toastAuthError: "დაფიქსირდა შეცდომა",
		toastDeletedAds: "რეკლამა ამოღებულია",
		toastArchiveAds: "რეკლამა გადატანილია არქივში",
		toastPublishedAds: "გამოქვეყნდა განცხადება",
		toastUnpublishAds: "რეკლამა უნდა მოიხსნას გამოქვეყნებიდან.",
		toastNotRemovedAds: "რეკლამა არ არის წაშლილი",
		toastCreateSuccess: "რეკლამა შეიქმნა/განახლებულია",
	},
	tr: {
		titleAuthLogin: "Giriş yap",
		titleAuthPasswordRecovery: "Şifre kurtarma",
		titleAuthRegistration: "Kayıt",
		titleAuthPassword: "Şifre",
		titleAuthConfirmation: "Onay",
		subtitleAuthLogin: "Devam etmek için giriş yapmanız gerekmektedir",
		subtitleAuthAuthPasswordRecovery: "Kayıt olurken kullandığınız email adresinizi giriniz",
		subtitleAuthRegistration: "Email adresi girin",
		subtitleAuthPassword: "Hesabınız için şifre oluşturun",
		subtitleAuthConfirmation: "Onay kodunu giriniz",
		alternativeAuthTitle: "İle oturum açın",
		alternativeAuthPretext: "veya",
		alternativeAuthForgotPassword: "Şifrenizi unuttunuz mu?",
		alternativeAuthDontAccount: "Hesabınız yok mu?",
		alternativeAuthCreateAccountBtn: "Hesap oluştur",
		alternativeAuthToGetCodeBtn: "Kod al",
		alternativeAuthConfirm: "Onayla",
		alternativeAuthReceiveCode: "Tekrar kod al",
		alternativeAuthMailInput: "Email",
		alternativeAuthPasswordInput: "Şifre",
		alternativeAuthCreatePasswordInput: "Şifre oluştur",
		alternativeAuthRepeatPasswordInput: "Şifreyi tekrarlayın",
		alternativeAuthCodeInput: "Kod",
		toastAuthSuccessful: "Giriş başarılı",
		toastAuthError: "Giriş başarısız",
		toastDeletedAds: "Reklam kaldırıldı",
		toastArchiveAds: "Reklam Arşive taşındı",
		toastPublishedAds: "Duyuru yayınlandı",
		toastUnpublishAds: "İlan yayından kaldırılmalıdır",
		toastNotRemovedAds: "Reklam kaldırılmadı",
		toastCreateSuccess: "Reklam oluşturuldu/güncellendi",
	},
};
