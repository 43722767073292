export default {
	ru: {
		placeholderName: "Имя",
		placeholderYourName: "Ваше имя",
		placeholderSurname: "Фамилия",
		placeholderYourSurname: "Ваша фамилия",
		placeholderNick: "Ник",
		placeholderWriteMessage: "Написать сообщение",
		placeholderWriteMessageTellUs: "Расскажите о себе",
		placeholderFeadbackTextarea: "Вам с нами как?",
		placeholderChangeRegionModalCoutry: "Выберите Страну",
		placeholderChangeRegionModalCityEmpty: "Сначала выберите страну",
		placeholderChangeRegionBtnEmpty: "Выберите город",
		placeholderSelectCategory: "Выберите категорию",
		placeholderChangeLocaleModal: "Выберите язык интерфейса",
	},
	en: {
		placeholderName: "Name",
		placeholderYourName: "Your first name",
		placeholderSurname: "Last name",
		placeholderYourSurname: "Your last name",
		placeholderNick: "Nickname",
		placeholderWriteMessage: "Write a message",
		placeholderWriteMessageTellUs: "Tell us about yourself",
		placeholderFeadbackTextarea: "How are you with us?",
		placeholderChangeRegionModalCoutry: "Select a country",
		placeholderChangeRegionModalCityEmpty: "Select a country first",
		placeholderChangeRegionBtnEmpty: "Select a city",
		placeholderSelectCategory: "Select a category",
		placeholderChangeLocaleModal: "Select interface language",
	},
	ka: {
		placeholderName: "სახელი",
		placeholderYourName: "თქვენი სახელი",
		placeholderSurname: "გვარი",
		placeholderYourSurname: "თქვენი გვარი",
		placeholderNick: "ნიკ ნეიმი (დამატებითი სახელი)",
		placeholderWriteMessage: "შეტყობინების გაგზავნა",
		placeholderWriteMessageTellUs: "მოგვიყევით თქვენს შესახებ",
		placeholderFeadbackTextarea: "როგორი კმაყოფილი ხართ ჩვენი აპლიკაციით?",
		placeholderChangeRegionModalCoutry: "აირჩიეთ ქვეყანა",
		placeholderChangeRegionModalCityEmpty: "ჯერ აირჩიეთ ქვეყანა",
		placeholderChangeRegionBtnEmpty: "აირჩიეთ ქალაქი",
		placeholderSelectCategory: "აირჩიეთ კატეგორია",
		placeholderChangeLocaleModal: "აირჩიეთ ინტერფეისის ენა",
	},
	tr: {
		placeholderName: "İsim",
		placeholderYourName: "Adınız",
		placeholderSurname: "Soyisim",
		placeholderYourSurname: "Soyadınız",
		placeholderNick: "Takma ad",
		placeholderWriteMessage: "Bir mesaj yaz",
		placeholderWriteMessageTellUs: "Bize kendinizden bahsedin",
		placeholderFeadbackTextarea: "Bizi nasıl buldunuz?",
		placeholderChangeRegionModalCoutry: "Bir ülke seçiniz",
		placeholderChangeRegionModalCityEmpty: "Önce bir ülke seçiniz",
		placeholderChangeRegionBtnEmpty: "Bir şehir seçiniz",
		placeholderSelectCategory: "Bir kategori seçiniz",
		placeholderChangeLocaleModal: "Arayüz dilini seçin",
	},
};
