export default {
	ru: {
		salerCardData: "На Rexpat c ",
		salerCardDataMoBlockText:
			"Если заблокировать пользователя, доступ к сервису будет ограничен, и все объявления удалены",
		salerCardGradeUser: "Рейтинг пользователя:",
		salerCardLook: "Посмотреть",
		salerCardMoreAdsUser: "Еще объявления пользователя",
		salerCardMoreAds: "Еще объявления",
		salerCardBlockUser: "Заблокировать пользователя",
	},
	en: {
		salerCardData: "On Rexpat ",
		salerCardDataMoBlockText:
			"If you block a user, access to the service will be limited and all ads will be deleted",
		salerCardGradeUser: "User rating:",
		salerCardLook: "View",
		salerCardMoreAdsUser: "More User Ads",
		salerCardMoreAds: "More ads",
		salerCardBlockUser: "Block user",
	},
	ka: {
		salerCardData: "დარეგისტრირდა Rexpat-ზე ამ ",
		salerCardDataMoBlockText:
			"თუ დაბლოკეთ მომხმარებელი სერვისთან ხელწვდომა იქნება შეზღუდული და განცხადებები წაშლილი",
		salerCardGradeUser: "მომხმარებლის რეიტინგი :",
		salerCardLook: "ნახვა",
		salerCardMoreAdsUser: "მეტი მომხმარებლის განცხადებები",
		salerCardMoreAds: "მეტი რეკლამა",
		salerCardBlockUser: "Დაბლოკე მომხმარებელი",
	},
	tr: {
		salerCardData: "Rexpat'ta",
		salerCardDataMoBlockText:
			"Bir kullanıcıyı engellerseniz, hizmete erişim sınırlandırılacak ve tüm ilanlar silinecektir",
		salerCardGradeUser: "Kullanıcı puanı:",
		salerCardLook: "Görüntüle",
		salerCardMoreAdsUser: "Diğer kullanıcı duyuruları",
		salerCardMoreAds: "Daha fazla reklam",
		salerCardBlockUser: "Kullanıcıyı engelle",
	},
};
