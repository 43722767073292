export const FLAG_MAP = {
	0: "global",
	1: "tr",
	2: "me",
	3: "th",
	4: "id",
	5: "vn",
	6: "cy",
	7: "ge",
};
