import { computed, inject, ref } from "vue";

export const useSuspense = (suspensePassedByProps = ref(false)) => {
	const suspensePassedByInject = inject("isSuspense", ref(false));

	const isSuspense = computed(() => {
		const res = suspensePassedByProps.value || !!suspensePassedByInject.value;
		return res;
	});

	return { isSuspense };
};
