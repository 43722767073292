<script setup>
import { nextTick, ref, computed, watchEffect, watch } from "vue";
import { ceil, toLower } from "lodash";
import { SvgTemplate, Image, Spinner } from "@shared/ui";
import {
	withPx,
	useScreenSize,
	useScreenSafeArea,
	useBodyScrollLock,
	useHaptics,
} from "@shared/lib";

import { apiSaveStoryActivity } from "@widgets/Stories";
import {
	useTransition,
	useRafFn,
	TransitionPresets,
	useWindowSize,
	executeTransition,
	useDocumentVisibility,
	useMagicKeys,
} from "@vueuse/core";
import { StoriesTimeline } from "../StoriesTimeline";
import { StoriesLinks } from "../StoriesLinks";
import { getStoriesBuckerUrl } from "../../api";
import { STORY_PLAYER_ANIMATION_DURATATION, IMAGE_DURATION } from "./StoriesViewer.const";

const props = defineProps({
	currentStoryBlockIdx: { type: [Number, null], default: null },
	stories: { type: Array, default: () => [] },
	targetElements: { type: [HTMLElement, null], default: null },
});

const emit = defineEmits(["nextBlock", "prevBlock", "close"]);

const { lock, unlock } = useBodyScrollLock();

const { isMobile } = useScreenSize();

const { impactLight } = useHaptics();

const isOpen = ref(false);

const isShowed = ref(false);

const currentStoryIdx = ref(0);

const isDocumentVisible = useDocumentVisibility();

const { arrowright, arrowleft, escape } = useMagicKeys();

const open = (openIndex = 0) => {
	if (storiesLength.value > 0) {
		currentStoryIdx.value = openIndex;
		isOpen.value = true;
		openAnimation();
		impactLight();
	} else {
		console.warn("Empty Stories List");
	}
};

/** @type {import("vue").ComputedRef<HTMLElement>} */
const targetElement = computed(() => {
	if (props.currentStoryBlockIdx !== null && props.targetElements !== null) {
		return props.targetElements[props.currentStoryBlockIdx];
	}
	return null;
});

const windowSize = useWindowSize();

const isClose = ref(false);

const close = () => {
	closeAnimation();
	isClose.value = true;
	impactLight();
	setTimeout(() => {
		isOpen.value = false;
		emit("close");
	}, STORY_PLAYER_ANIMATION_DURATATION);
};

// async нужен для того, чтобы сначала обновился timpline а потом было переключение на следующую историю
const setCurrentStoryIdx = async (idx) => {
	isStoryReady.value = false;
	currentStoryProgress.value = 0;
	await nextTick(() => {
		currentStoryIdx.value = idx;
	});
};

const imageStoryStartTimestamp = ref(null);

const imageStoryDurationOnPause = ref(null);

const currentStory = computed(() => {
	return props.stories[currentStoryIdx.value];
});

const currentStorySrc = computed(() => {
	return getStoriesBuckerUrl(currentStory.value.storie_url);
});

const getActivityStories = computed(() => {
	return props.stories[currentStoryIdx.value].activity;
});

const isCurrentStoryVideo = computed(() => {
	const url = props.stories[currentStoryIdx.value]?.storie_url;

	return toLower(url.substring(url.length - 4)) === ".mp4";
});

/** @type {import("vue").Ref<HTMLMediaElement>} */
const videoRef = ref(null);

const storiesLength = computed(() => {
	return props.stories.length;
});

watchEffect(() => {
	if (isOpen.value) {
		lock();
	} else {
		unlock();
	}
});

const PAUSE_STORY_DURATION = 500;

const touchStartTimeStamp = ref(null);

const touchStart = () => {
	touchStartTimeStamp.value = performance.now();

	pauseStory();
};

const nextStory = async () => {
	const currentTimeStamp = performance.now();
	if (
		touchStartTimeStamp.value === null ||
		currentTimeStamp - touchStartTimeStamp.value < PAUSE_STORY_DURATION
	) {
		progressPause();
		if (currentStoryIdx.value < storiesLength.value - 1) {
			await setCurrentStoryIdx(currentStoryIdx.value + 1);
			playStory();
		} else {
			await setCurrentStoryIdx(0);
			emit("nextBlock");
		}
	} else {
		resumeStory();
		touchStartTimeStamp.value = null;
	}
};

const prevStory = async () => {
	const currentTimeStamp = performance.now();
	if (
		touchStartTimeStamp.value === null ||
		currentTimeStamp - touchStartTimeStamp.value < PAUSE_STORY_DURATION
	) {
		if (currentStoryIdx.value > 0) {
			await setCurrentStoryIdx(currentStoryIdx.value - 1);
			playStory();
		} else if (currentStoryIdx.value === 0) {
			if (props.currentStoryBlockIdx === 0) {
				if (isCurrentStoryVideo.value) {
					progressPause();
					videoRef.value.pause();
					videoRef.value.currentTime = 0;
					videoRef.value.play();
					progressResume();
				} else {
					playStory();
				}
			} else {
				await setCurrentStoryIdx(0);
				emit("prevBlock");
			}
		}
	} else {
		resumeStory();
		touchStartTimeStamp.value = null;
	}
};

const onChangeStory = async (idx) => {
	progressPause();
	await setCurrentStoryIdx(idx);

	playStory();
};

const closeStoryViewer = async () => {
	await setCurrentStoryIdx(0);
	close();
	progressPause();
};

const isStoryReady = ref(false);

const storyReady = () => {
	isStoryReady.value = true;
	apiSaveStoryActivity({ item_id: currentStory.value.item_id, activity: "SHOW" });
};

const playStoryImage = () => {
	const unwathStoryReady = watch(
		isStoryReady,
		() => {
			if (isStoryReady.value) {
				nextTick(() => {
					unwathStoryReady();
				});

				imageStoryStartTimestamp.value = performance.now();
				progressResume();
				const unwatchNextStory = watch(currentStoryProgress, () => {
					if (currentStoryProgress.value >= 100) {
						nextStory();
						unwatchNextStory();
					}
				});
			}
		},
		{ immediate: true }
	);
};

const playStoryVideo = () => {
	const unwathStoryReady = watch(
		isStoryReady,
		() => {
			if (isStoryReady.value) {
				nextTick(() => {
					unwathStoryReady();
				});
				progressResume();
				const unwatchNextStory = watch(currentStoryProgress, () => {
					if (currentStoryProgress.value >= 100) {
						nextStory();
						unwatchNextStory();
					}
				});
			}
		},
		{ immediate: true }
	);
};

const isStoryPaused = ref(false);

const pauseStoryVideo = () => {
	nextTick(() => {
		isStoryPaused.value = true;
		videoRef.value?.pause();
		nextTick(() => {
			progressPause();
		});
	});
};

const resumeStoryVideo = () => {
	nextTick(() => {
		isStoryPaused.value = false;
		videoRef.value?.play();
		progressResume();
	});
};

const pauseStoryImage = () => {
	nextTick(() => {
		isStoryPaused.value = true;
		imageStoryDurationOnPause.value = performance.now() - imageStoryStartTimestamp.value;
		progressPause();
	});
};

const resumeStoryImage = () => {
	nextTick(() => {
		isStoryPaused.value = false;
		imageStoryStartTimestamp.value = performance.now() - imageStoryDurationOnPause.value;
		progressResume();
	});
};

const pauseStory = () => {
	if (isCurrentStoryVideo.value) {
		pauseStoryVideo();
	} else {
		pauseStoryImage();
	}
};

const resumeStory = () => {
	if (isCurrentStoryVideo.value) {
		resumeStoryVideo();
	} else {
		resumeStoryImage();
	}
};

const playStory = () => {
	if (isCurrentStoryVideo.value) {
		playStoryVideo();
	} else {
		playStoryImage();
	}
};

// Animations

// Progress Animation
const currentStoryProgress = ref(0);

const getStoryProgress = () => {
	if (isCurrentStoryVideo.value) {
		const { duration, currentTime } = videoRef.value;
		currentStoryProgress.value = ((currentTime / duration) * 100).toFixed(3);
	} else {
		const currentDuration = performance.now() - imageStoryStartTimestamp.value;
		currentStoryProgress.value = ((currentDuration / IMAGE_DURATION) * 100).toFixed(3);
	}
};

const { pause: progressPause, resume: progressResume } = useRafFn(getStoryProgress, {
	immediate: false,
});

const transitionIsActive = ref(false);

// Show and Hide Animations
const storiesViewerRef = ref(null);

/** @type {import("vue").Ref<HTMLElement>} */
const storiesViewerWrapperRef = ref(null);

const baseOutletBackgroundOpacity = ref(0);

const transitionOutletOpacity = useTransition(baseOutletBackgroundOpacity, {
	duration: STORY_PLAYER_ANIMATION_DURATATION,
	transition: TransitionPresets.easeInOutCubic,
});

const backgroundOutletOpacity = computed(() => {
	return {
		"background-color": `rgba(23, 26, 31, 0.8)`,
		opacity: transitionOutletOpacity.value.toFixed(2),
	};
});

const storyOutletStyles = computed(() => {
	return { ...backgroundOutletOpacity.value, "backdrop-filter": "blur(16px)" };
});

const showViewerWrapper = () => {
	baseOutletBackgroundOpacity.value = 1;
};

const hideViewerWrapper = () => {
	baseOutletBackgroundOpacity.value = 0;
};

// Player Transform Animation

const baseScaleTransformPlayer = ref(0);

const baseOpacityPlayer = ref(0);

const baseTransformPlayer = ref([0, 0]); // left top

const { topNum: safeAreaTopNum, bottomNum: safeAreaBottomNum } = useScreenSafeArea();

const transformPlayerStyles = computed(() => {
	const topSafeOffset = (safeAreaTopNum.value - safeAreaBottomNum.value) / 2;

	if (transitionIsActive.value) {
		const scale = baseScaleTransformPlayer.value;
		const opacity = baseOpacityPlayer.value;

		const [left, top] = baseTransformPlayer.value;

		const topPos = `${ceil(top + topSafeOffset)}px`;
		const leftPos = `${ceil(left)}px`;

		return {
			transform: `scale(${scale.toFixed(3)}) `,
			top: topPos,
			left: leftPos,
			opacity,
		};
	}

	return {
		transform: `translate(-50%, calc(-50% + ${withPx(topSafeOffset)}))`,
		top: `50%`,
		left: "50%",
	};
});

const playerStyles = computed(() => {
	const height = isMobile.value
		? windowSize.height.value - safeAreaTopNum.value - safeAreaBottomNum.value
		: windowSize.height.value * 0.8;
	const width = isMobile.value ? windowSize.width.value : (height * 9) / 16;

	return { width: withPx(width), height: withPx(height) };
});

const mediaStyle = computed(() => {
	const backgroundData =
		currentStory.value?.background?.color &&
		typeof currentStory.value?.background?.color === "string"
			? currentStory.value.background.color
			: "white";

	return { background: backgroundData };
});

const showPlayer = () => {
	const targetElementBounding = targetElement.value.getBoundingClientRect();

	const xOffset = storiesViewerWrapperRef.value.offsetWidth / 2;
	const yOffset = storiesViewerWrapperRef.value.offsetHeight / 2;

	const fromLeft = targetElementBounding.x + targetElementBounding.width / 2 - xOffset;
	const fromTop = targetElementBounding.y + targetElementBounding.height / 2 - yOffset;

	const toLeft = windowSize.width.value / 2 - xOffset;
	const toTop = windowSize.height.value / 2 - yOffset;

	const transformPlayerFrom = [fromLeft, fromTop];
	const transformPlayerTo = [toLeft, toTop];

	const scaleFrom = 0;
	const scaleTo = 1;

	const opacityFrom = 0.3;
	const opacityTo = 1;

	executeTransition(baseTransformPlayer, transformPlayerFrom, transformPlayerTo, {
		duration: STORY_PLAYER_ANIMATION_DURATATION,
		transition: TransitionPresets.easeInOutCubic,
	});

	executeTransition(baseOpacityPlayer, opacityFrom, opacityTo, {
		duration: STORY_PLAYER_ANIMATION_DURATATION,
		transition: TransitionPresets.easeInOutCubic,
	});

	executeTransition(baseScaleTransformPlayer, scaleFrom, scaleTo, {
		duration: STORY_PLAYER_ANIMATION_DURATATION,
		transition: TransitionPresets.easeInOutCubic,
	});
};

const hidePlayer = () => {
	const targetElementBounding = targetElement.value.getBoundingClientRect();

	isShowed.value = false;

	const xOffset = storiesViewerWrapperRef.value.offsetWidth / 2;
	const yOffset = storiesViewerWrapperRef.value.offsetHeight / 2;
	const fromLeft = windowSize.width.value / 2 - xOffset;
	const fromTop = windowSize.height.value / 2 - yOffset;

	const toLeft = targetElementBounding.x + targetElementBounding.width / 2 - xOffset;
	const toTop = targetElementBounding.y + targetElementBounding.height / 2 - yOffset;

	const transformPlayerFrom = [fromLeft, fromTop];

	const transformPlayerTo = [toLeft, toTop];
	executeTransition(baseTransformPlayer, transformPlayerFrom, transformPlayerTo, {
		duration: STORY_PLAYER_ANIMATION_DURATATION - STORY_PLAYER_ANIMATION_DURATATION * 0.03,
		transition: TransitionPresets.easeInOutCubic,
	});

	const scaleFrom = 1;
	const scaleTo = 0;

	const opacityFrom = 1;
	const opacityTo = 0;
	executeTransition(baseScaleTransformPlayer, scaleFrom, scaleTo, {
		duration: STORY_PLAYER_ANIMATION_DURATATION,
		transition: TransitionPresets.easeInOutCubic,
	});

	setTimeout(() => {
		executeTransition(baseOpacityPlayer, opacityFrom, opacityTo, {
			duration: STORY_PLAYER_ANIMATION_DURATATION - STORY_PLAYER_ANIMATION_DURATATION * 0.07,
			transition: TransitionPresets.easeInOutCubic,
		});
	}, STORY_PLAYER_ANIMATION_DURATATION * 0.07);
};

const isHideTransitionActive = ref(false);

const openAnimation = () => {
	nextTick(() => {
		transitionIsActive.value = true;

		showViewerWrapper();
		showPlayer();
		document.querySelector('meta[name="theme-color"]').setAttribute("content", "#000");

		setTimeout(() => {
			transitionIsActive.value = false;
			isShowed.value = true;
			playStory();
		}, STORY_PLAYER_ANIMATION_DURATATION);
	});
};

// TODO Предзагрузка истории
// eslint-disable-next-line no-unused-vars
const preloadNextImageStory = (src) => {};

const closeAnimation = () => {
	transitionIsActive.value = true;
	isHideTransitionActive.value = true;
	hideViewerWrapper();
	hidePlayer();

	document.querySelector('meta[name="theme-color"]').setAttribute("content", "#fff");

	setTimeout(() => {
		transitionIsActive.value = false;
		isHideTransitionActive.value = false;
	}, STORY_PLAYER_ANIMATION_DURATATION);
};

// Keyboard Controls

watch(arrowright, () => {
	if (arrowright.value && isShowed.value) {
		nextTick(() => nextStory());
	}
});

watch(arrowleft, () => {
	if (arrowleft.value && isShowed.value) {
		nextTick(() => prevStory());
	}
});

watch(escape, () => {
	if (escape.value && isShowed.value) {
		nextTick(() => closeStoryViewer());
	}
});

watch(isDocumentVisible, () => {
	if (isShowed.value) {
		if (isDocumentVisible.value === "visible") {
			resumeStory();
		} else {
			pauseStory();
		}
	}
});

watch(
	() => props.currentStoryBlockIdx,
	(newVal, oldVal) => {
		if (newVal !== oldVal) {
			onChangeStory(0);
		}
	}
);

defineExpose({ open, close, isShowed, currentStory });
</script>

<template>
	<Teleport to="#app">
		<div class="stories-viewer__outlet" :style="storyOutletStyles"></div>
		<div class="stories-viewer" ref="storiesViewerRef">
			<div
				class="stories-viewer__player-wrapper"
				:style="transformPlayerStyles"
				ref="storiesViewerWrapperRef"
			>
				<div
					class="stories-viewer__close"
					:class="{ 'stories-viewer__close_showed': isShowed }"
					@click="closeStoryViewer"
				>
					<SvgTemplate name="close" />
				</div>
				<div class="stories-viewer__player" :style="playerStyles">
					<div class="stories-viewer__control">
						<StoriesTimeline
							:length="storiesLength"
							:current-idx="currentStoryIdx"
							:progress="currentStoryProgress"
							:theme="!isCurrentStoryVideo ? currentStory?.timeline_theme : 'dark'"
							@changeStory="onChangeStory"
						/>
					</div>
					<div
						class="stories-viewer__media-outlet"
						v-if="(!isStoryReady && isCurrentStoryVideo) || transitionIsActive"
					>
						<Spinner v-if="isShowed" />
					</div>
					<video
						v-show="isStoryReady"
						v-if="isCurrentStoryVideo && isShowed"
						:key="'video' + currentStorySrc"
						class="stories-viewer__media"
						:style="mediaStyle"
						:src="currentStorySrc"
						ref="videoRef"
						autoplay
						disable-picture-in-picture
						playsinline
						x-yandex-pip="false"
						@canplay="storyReady"
					/>
					<Image
						v-else-if="!isCurrentStoryVideo && isShowed"
						class="stories-viewer__media"
						:style="mediaStyle"
						:src="currentStorySrc"
						:key="'img' + currentStorySrc"
						:fade-duration="0"
						@success="storyReady"
					>
						<template #pending>
							<div class="stories-viewer__media-outlet">
								<Spinner />
							</div>
						</template>
					</Image>
					<div class="strories-viewer__nav" ref="storiesNav">
						<div
							class="stories-viewer__prev"
							@mousedown.prevent="touchStart"
							@mouseup.prevent="prevStory"
							@touchstart.prevent="touchStart"
							@touchend.prevent="prevStory"
							@contextmenu.prevent
						></div>
						<div
							class="stories-viewer__next"
							@mousedown.prevent="touchStart"
							@mouseup.prevent="nextStory"
							@touchstart.prevent="touchStart"
							@touchend.prevent="nextStory"
							@contextmenu.prevent
						></div>
					</div>
					<StoriesLinks
						v-if="isShowed"
						:stories-activity="getActivityStories"
						:isStoryReady="isStoryReady"
						:item-id="currentStory.item_id"
					/>
				</div>
			</div>
		</div>
	</Teleport>
</template>

<style lang="scss" scoped>
@import "./StoriesViewer.scss";
</style>
