import { REQUST_STATUSES } from "@shared/const";
import { DateTime } from "luxon";

export default {
	status: {
		currentUser: REQUST_STATUSES.OUTDATED,
		users: {},
		auth: REQUST_STATUSES.OUTDATED,
		languages: REQUST_STATUSES.OUTDATED,
		supposedLocation: REQUST_STATUSES.OUTDATED,
	},
	userHandleRegion: {},
	currentUser: {},
	users: {},
	// Была ли показана модалка с выбором региона на главной странице(Нужно найти более элегантное решение(MainPage/model))
	isChangeRegionShown: false,
	// Айди региона для неавторизированного пользователя
	unauthRegionId: null,
	languages: [],
	supposedLocation: null,
	timezone: DateTime.local().zone.name,
	fbToken: null,
	announcementTranslate: 'ru',
};
