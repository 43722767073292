<script setup>
import {
	executeTransition,
	useElementBounding,
	useScrollLock,
	useScroll,
	useSwipe,
	useWindowSize,
} from "@vueuse/core";
import { ceil } from "lodash";
import { withPx, withOutPx, useScreenSafeArea } from "@shared/lib";
import { computed, ref, watch, nextTick } from "vue";
import { SvgTemplate } from "../SvgTemplate";

const props = defineProps({
	fullExpand: { type: Boolean, default: false },
	disableClose: { type: Boolean, default: false },
	hideCloseBtn: { type: Boolean, default: false },
	showBackGround: { type: Boolean, default: false },
	noPaddingContent: { type: Boolean, default: false },
	hideTopBar: { type: Boolean, default: false },
});

const emit = defineEmits(["close", "content-scroll"]);

const { height } = useWindowSize();

/** @type {import("vue").Ref<HTMLElement>} */
const content = ref(null);

const { height: contentHeight } = useElementBounding(content);

const scrollContentLock = useScrollLock(content, false);

const { arrivedState } = useScroll(content);

/** @type {import("vue").Ref<HTMLElement>} */
const modal = ref(null);

const translateY = ref(0);

const offsetYPercantage = computed(() => {
	const percentage = translateY.value / (contentHeight.value / 100);

	return percentage;
});

watch(offsetYPercantage, (val) => {
	if (val === 0) {
		scrollContentLock.value = false;
	} else {
		scrollContentLock.value = true;
	}
});

const overlayOpacity = computed(() => {
	const res = (100 - offsetYPercantage.value) / 100 - 0.1;
	return res;
});

const isSwiping = ref(false);

const swipeTransitionStack = ref([]);

const swipeTransitionTicks = async () => {
	const { transition } = swipeTransitionStack.value.shift();

	await transition();

	if (swipeTransitionStack.value.length !== 0) {
		swipeTransitionTicks();
	}
};

watch(
	() => swipeTransitionStack.value.length,
	async (val, prevVal) => {
		if (prevVal === 0 && val !== 0) {
			swipeTransitionTicks();
		}
	}
);

const executeSwipeTransition = (to) => {
	// swipeTransitionStack.value.push({
	// 	transition: () =>
	// 		executeTransition(translateY, translateY.value, to, {
	// 			duration: 3,
	// 		}),
	// });
	translateY.value = to;
};

const isHandlerSwipe = ref(false);

const onHandlerTouchStart = () => {
	isHandlerSwipe.value = true;
};
const onHandlerTouchEnd = () => {
	isHandlerSwipe.value = false;
};

const onSwipeStart = () => {
	if (isCanSwipe.value && (arrivedState.top || isHandlerSwipe.value)) {
		isSwiping.value = true;
	}
};

const onSwipe = () => {
	const scroll = content.value.scrollTop;

	if (isSwiping.value && (scroll === 0 || isHandlerSwipe.value)) {
		const lengthY = ceil(swipe.lengthY.value);
		if (lengthY < 0) {
			executeSwipeTransition(-lengthY);
		} else {
			executeSwipeTransition(0);
		}
	}
};

const onSwipeEnd = () => {
	if (isSwiping.value) {
		isSwiping.value = false;
		if (offsetYPercantage.value > 20) {
			emit("close");
		} else {
			executeTransition(translateY, translateY.value, 0, {
				duration: 200,
			});
		}
	}
};

const isCanSwipe = computed(() => {
	const { fullExpand, disableClose, hideCloseBtn } = props;
	return !fullExpand && !disableClose && !hideCloseBtn;
});

const swipe = useSwipe(modal, { onSwipe, onSwipeEnd, onSwipeStart, threshold: 0 });

const isOpen = ref(null);

const isHidden = ref(null);

const show = () => {
	isOpen.value = true;
	isHidden.value = false;

	nextTick(() => {
		executeTransition(translateY, contentHeight.value, 0, {
			duration: 200,
		});
	});
};

const hide = async () => {
	await executeTransition(translateY, translateY.value, contentHeight.value, {
		duration: 100,
	});

	isOpen.value = false;
	isHidden.value = true;
};

const { top, bottom } = useScreenSafeArea();

const transfromModal = computed(() => {
	if (offsetYPercantage.value === 0) return null;
	return `translate3d(0px,${offsetYPercantage.value}%,0px)`;
});

const onClose = () => {
	emit("close");
};

const onContentScroll = (e) => {
	emit("content-scroll", e);
};

defineExpose({ show, hide, contentRef: content });

const isCloseVisible = computed(() => {
	return !props.disableClose && !props.hideCloseBtn;
});

const contentMaxHeightStyle = computed(() => {
	return withPx(
		props.fullExpand
			? height.value + 2
			: height.value - 20 - withOutPx(top.value) - withOutPx(bottom.value)
	);
});

const onClickOutlet = () => {
	const { disableClose, hideCloseBtn } = props;
	if (!disableClose && !hideCloseBtn) {
		onClose();
	}
};
</script>
<template>
	<div
		class="modal-mobile__overlay"
		:style="{ opacity: overlayOpacity }"
		@click.self="onClickOutlet"
	></div>
	<div
		class="modal-mobile"
		ref="modal"
		:style="{ transform: transfromModal }"
		:class="{
			'modal-mobile_full': fullExpand,
			'modal-mobile_swipe': isCanSwipe,
		}"
	>
		<div
			class="modal-mobile__handler-wrapper"
			v-if="isCanSwipe"
			@touchstart.passive="onHandlerTouchStart"
			@touchend.passive="onHandlerTouchEnd"
		>
			<div class="modal-mobile__handler"></div>
		</div>
		<div
			class="modal-mobile__content"
			:class="{
				'modal-mobile_background': showBackGround,
				'modal-mobile__nopadding-content': noPaddingContent,
				'modal-mobile__hidden-control': hideTopBar || isCanSwipe,
			}"
			ref="content"
			:style="{ maxHeight: contentMaxHeightStyle }"
			@scroll="onContentScroll"
		>
			<div
				v-if="!hideTopBar && !isCanSwipe"
				class="modal-mobile__control"
				:class="{ 'modal-mobile__control_no-color': showBackGround }"
			>
				<div class="modal-mobile__control-title"><slot name="title"></slot></div>
				<div v-if="isCloseVisible && !isCanSwipe" class="modal-mobile__close" @click="onClose">
					<SvgTemplate name="close" />
				</div>
			</div>
			<slot />
		</div>
	</div>
</template>

<styles lang="scss" scoped>
@import "./ModalMobile.scss";
</styles>
