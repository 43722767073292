<!-- eslint-disable no-unused-vars -->
<script setup>
import { THEME_VARIANTS } from "@shared/const";

const props = defineProps({
	progress: { type: Number, default: 0 },
	length: { type: Number, default: 0 },
	currentIdx: { type: Number, default: 0 },
	theme: { type: String, default: "light" },
});

const emit = defineEmits(["changeStory"]);

const onClick = (idx) => {
	emit("changeStory", idx);
};
</script>

<template>
	<div class="timeline" :class="{ timeline_dark: theme === THEME_VARIANTS.DARK }">
		<div
			class="timeline__control"
			v-for="(_, index) in Array(length).fill()"
			:key="index"
			@click="onClick(index)"
		>
			<div class="timeline__item">
				<div class="timeline__progress" v-if="index > currentIdx"></div>
				<div
					class="timeline__progress timeline__progress_full"
					v-else-if="index < currentIdx"
				></div>
				<div
					class="timeline__progress"
					v-else
					:style="{ transform: `translateX(${progress || 0}%)` }"
				></div>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./StoriesTimeline.scss";
</style>
