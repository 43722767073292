<script setup>
import { computed, ref } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { TYPOGRAPHY_TYPES, Typography, SvgTemplate } from "@shared/ui";
import { Location, DescriptionTextCollapse, Contacts, ProfileSettings } from "@widgets/Profile";
import { useScreenSize, getRegistDate } from "@shared/lib";
import { useTranslation } from "i18next-vue";
// import { MyAnnouncements } from "../MyAnnouncements";

const props = defineProps({
	currentUser: {
		type: Object,
		default: () => {},
	},
});

const store = useStore();
const router = useRouter();
const { t } = useTranslation();

const { isDesktop } = useScreenSize();

const regions = computed(() => {
	return store.getters["announcement/currentCities"];
});

const isVisibleToggle = computed(() => {
	return props.currentUser?.description?.length > 300;
});

const toggle = ref(false);

const onEditClick = () => {
	router.push({ name: "ProfileEdit" });
};
</script>

<template>
	<div class="user-info__title-row">
		<Typography
			v-if="currentUser?.first_name || currentUser?.second_name"
			class="user-info__title"
			:type="TYPOGRAPHY_TYPES.USER_NAME"
		>
			{{ currentUser?.first_name }}&nbsp; {{ currentUser.second_name }}
		</Typography>
		<Typography class="user-info__data" :type="TYPOGRAPHY_TYPES.LABEL_CATEGORY">
			{{ t("salerCardData") }} {{ getRegistDate(currentUser.creation_date) }}
		</Typography>
		<SvgTemplate class="user-info__redact-btn" name="highlighter" @click.stop="onEditClick" />
	</div>
	<div class="user-info__descr-box">
		<Typography
			v-if="!currentUser.description?.length && isDesktop"
			class="user-info__empty-text"
			:type="TYPOGRAPHY_TYPES.TEXT_OLD"
		>
			{{ t("userInfoInfoYourself") }}
		</Typography>
		<DescriptionTextCollapse
			v-if="currentUser.description?.length"
			:description="currentUser.description"
			:is-toggle="toggle"
			:hide-height="140"
		>
			<template v-if="isVisibleToggle" #toggleDown="{ isCanHide }">
				<div class="text-collapse__toggle" @click="toggle = !toggle" v-if="isCanHide">
					<Typography :type="TYPOGRAPHY_TYPES.LABEL_CATEGORY">
						{{ t("userInfoInfoDescrCollaps") }}
					</Typography>
					<SvgTemplate
						class="text-collapse__toggle-icon"
						:class="{ 'text-collapse__toggle-icon_full': toggle }"
						name="polygon"
					/>
				</div>
			</template>
		</DescriptionTextCollapse>
	</div>
	<Location
		v-if="currentUser.country_id"
		:user-id="currentUser.user_id"
		:region="currentUser.region_id"
		:regions="regions"
		:country="currentUser.country_id"
	/>
	<Contacts
		:tg="{ value: currentUser.username, visible: !currentUser.is_tg_hide }"
		:phone="{ value: currentUser.phone, visible: !currentUser.is_phone_hide }"
		:wa="{ value: currentUser.whats_app_phone, visible: !currentUser.is_wa_hide }"
		:email="{ value: currentUser.email, visible: !currentUser.is_email_hide }"
		isProfile
	>
		<template #title>
			<Typography class="user-info__block-title" :type="TYPOGRAPHY_TYPES.TEXT_BODY_LARGE">
				{{ t("contactText") }}
			</Typography>
		</template>
	</Contacts>
	<ProfileSettings
		v-if="currentUser.language_id && isDesktop"
		:language-id="currentUser.language_id"
	>
		<template #title>
			<Typography class="user-info__block-title" :type="TYPOGRAPHY_TYPES.TEXT_BODY_LARGE">
				{{ t("profileSettingsText") }}
			</Typography>
		</template>
	</ProfileSettings>
	<!-- <div v-if="!isDesktop" class="user-info__line" />
	<MyAnnouncements v-if="!isDesktop" /> -->
</template>

<style lang="scss" scoped>
@import "./UserInfo.scss";
</style>
