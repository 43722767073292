import { isEmpty } from "lodash";

export const useTelegram = () => {
	if ("Telegram" in window) {
		const webApp = Telegram?.WebApp;

		Telegram.WebApp.isClosingConfirmationEnabled = true;

		const isTelegramContext = !isEmpty(webApp.initDataUnsafe);

		const { initData, ready, expand } = webApp;

		return { initData, isTelegramContext, ready, expand };
	}
	return {};
};
