export default {
	ru: {
		navigationProfileMyProgile: "Мой профиль",
		navigationProfileMyAds: "Мои объявления",
	},
	en: {
		navigationProfileMyProgile: "My profile",
		navigationProfileMyAds: "My ads",
	},
	ka: {
		navigationProfileMyProgile: "ჩემი პროფაილი",
		navigationProfileMyAds: "ჩემი განცხადებები",
	},
	tr: {
		navigationProfileMyProgile: "Profilim",
		navigationProfileMyAds: "İlanlarım",
	},
};
