<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { BUCKET_URL, BUCKET_PREFIX_MEDIUM, NOT_IMAGES } from "@shared/const";
import { ProfileSettings } from "@widgets/Profile";
import { TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { useScreenSize } from "@shared/lib";
import { useTranslation } from "i18next-vue";
import { Avatar } from "../Avatar";
import { UserInfo } from "../UserInfo";
import { Navigation } from "../Navigation";
// import { FooterProfile } from "../FooterProfile";
import { Logout } from "../Logout";
import { MyActivityMenu } from "../MyActivityMenu";
// import { TechSupport } from "../TechSupport";

const store = useStore();
const { t } = useTranslation();

const currentUser = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const { isDesktop } = useScreenSize();

const isUserLoggedIn = computed(() => {
	return store.getters["user/isUserLoggedIn"];
});

const AvatarUrl = computed(() => {
	if (currentUser.value?.photo) {
		return `${BUCKET_URL}${BUCKET_PREFIX_MEDIUM}/user/${currentUser.value.user_id}/${currentUser.value.photo}`;
	}
	return NOT_IMAGES.ANONYM;
});
</script>
<template>
	<div class="profile__container">
		<Navigation v-if="isDesktop" />
		<div class="profile__info-container">
			<Avatar v-if="isUserLoggedIn" :avatar-url="AvatarUrl" />
			<div class="profile__info">
				<UserInfo :current-user="currentUser" />
			</div>
		</div>
	</div>
	<template v-if="!isDesktop">
		<ProfileSettings v-if="currentUser.language_id" :language-id="currentUser.language_id">
			<template #title>
				<Typography :type="TYPOGRAPHY_TYPES.USER_NAME">{{ t("profileSettingsText") }}</Typography>
			</template>
		</ProfileSettings>
		<MyActivityMenu />
		<!-- <TechSupport /> -->
		<Logout />
	</template>
	<!-- <FooterProfile /> -->
</template>
<style lang="scss" scoped>
@import "./Profile.scss";
</style>
