import { useRoute, useRouter } from "vue-router";
import { computed, nextTick, readonly, ref, watch, watchEffect } from "vue";
import { compact, debounce, keys } from "lodash";
import { filterObjectEmptyValues, useKeepAliveRoute } from "@shared/lib";
import { GROUPS } from "@widgets";
import { computedEager } from "@vueuse/core";
import { useStore } from "vuex";
import { REQUST_STATUSES } from "@shared/const";
import { CATEGORIES_STEPS } from "../const";

export const useCategories = () => {
	const store = useStore();
	const route = useRoute();
	const router = useRouter();
	const keepAliveRoute = useKeepAliveRoute();

	const categoriesStatus = computed(() => {
		return store.getters["announcement/categoriesStatus"];
	});

	// const tagsStatus = computed(() => {
	// 	return store.getters["announcement/tagsStatus"];
	// });

	const sectionId = computedEager(() => {
		const res = keepAliveRoute.value.query.group_id && +keepAliveRoute.value.query.group_id;
		return res;
	});

	const categoryId = computedEager(() => {
		const res = keepAliveRoute.value.query.category_id && +keepAliveRoute.value.query.category_id;
		return res;
	});

	const subCategoryId = computedEager(() => {
		const res =
			keepAliveRoute.value.query.sub_category_id && +keepAliveRoute.value.query.sub_category_id;
		return res;
	});

	const tagCategoryId = computedEager(() => {
		const res =
			keepAliveRoute.value.query.tag_category_id && +keepAliveRoute.value.query.tag_category_id;
		return res;
	});

	const tagId = computedEager(() => {
		const res = keepAliveRoute.value.query.tag_id && +keepAliveRoute.value.query.tag_id;
		return res;
	});

	const categoriesParams = ref({});

	watch(
		[sectionId, categoryId, subCategoryId, tagCategoryId, tagId],
		() => {
			// console.log(keepAliveRoute.value.query);
			const result = filterObjectEmptyValues({
				section_id: sectionId.value,
				category_id: categoryId.value,
				sub_category_id: subCategoryId.value,
				tag_category_id: tagCategoryId.value,
				tag_id: tagId.value,
			});
			categoriesParams.value = result;
		},
		{ immediate: true, flush: "post" }
	);

	/** @type {import('vue').ComputedRef<typeof CATEGORIES_STEPS[keyof typeof CATEGORIES_STEPS]} */
	const currentStep = computed(() => {
		let step = CATEGORIES_STEPS.NULL;

		// Проверяем что указана группа, которая есть в списке групп
		const isGroupSelected =
			sectionId.value && Object.keys(GROUPS).includes(sectionId.value?.toString());

		if (isGroupSelected) {
			step = CATEGORIES_STEPS.GROUP;
		}

		const categoriesIdsByGroupsIds = store.getters["announcement/categoriesIdsBySectionId"](
			sectionId.value
		);

		// Проверяем указана ли категория и она есть в списке категорий по выбранной группе
		const isCategorySelected =
			categoryId.value && categoriesIdsByGroupsIds.includes(+categoryId.value);

		if (isCategorySelected) {
			step = CATEGORIES_STEPS.CATEGORY;
		}

		const subCategoriesIdsByCategoryId = store.getters[
			"announcement/subCategoriesIdsByCategoriesId"
		](categoryId.value);

		// Проверяем указана ли сапкатегория и она есть в списке по сапкатегорий по выбрранной категории
		const isSubCategorySelected =
			subCategoryId.value && subCategoriesIdsByCategoryId.includes(+subCategoryId.value);

		if (isSubCategorySelected) {
			step = CATEGORIES_STEPS.SUB_CATEGORY;
		}
		return step;
	});

	const categoriesByCurrentId = computed(() => {
		return store.getters["announcement/getCategroiesBySelectionId"](sectionId.value);
	});

	const subCategoriesByCurrentId = computed(() => {
		return store.getters["announcement/subCategories"](categoryId.value);
	});

	const tagsByTagCategoryId = computed(() => {
		return store.getters["announcement/tagsByCategoryId"](tagCategoryId.value);
	});

	const selectGroup = (id) => {
		router.push({ query: { group_id: id } });
	};

	const selectCategory = debounce((id) => {
		const category = store.getters["announcement/categoryById"](id);

		if (id === categoryId.value) {
			return;
		}
		if (sectionId.value) {
			nextTick(() => {
				router.push({
					query: {
						...route.query,
						group_id: category?.section_id,
						category_id: id,
					},
				});
			});
		} else {
			nextTick(() => {
				router.replace({
					query: {
						...route.query,
						group_id: category?.section_id,
						category_id: id,
					},
				});
			});
		}
	}, 20);

	const selectSubCategory = debounce((id) => {
		if (id === subCategoryId.value) {
			return;
		}
		nextTick(() => {
			router.push({ query: { ...route.query, sub_category_id: id } });
		});
	}, 10);

	const selectTagCategory = debounce((id) => {
		if (id === tagCategoryId.value) {
			return;
		}
		nextTick(() => {
			router.push({ query: { ...route.query, tag_category_id: id } });
		});
	}, 10);

	const selectTag = debounce((id) => {
		nextTick(() => {
			router.push({ query: { ...route.query, tag_id: id } });
		});
	}, 10);

	const categoryModel = ref();

	const subCategoryModel = ref();

	const tagModel = ref();

	watch(categoryModel, () => {
		selectCategory(categoryModel.value?.id);
	});

	watch(subCategoryModel, () => {
		selectSubCategory(subCategoryModel?.value?.id);
	});

	watch(
		() => tagModel.value,
		() => {
			selectTag(tagModel.value?.tag_id);
		}
	);

	watchEffect(() => {
		if (categoriesStatus.value === REQUST_STATUSES.SUCCESS) {
			if (categoryId.value) {
				const category = store.getters["announcement/categoryById"](categoryId.value);
				if (category) {
					categoryModel.value = category;
				} else {
					categoryModel.value = null;
				}
			} else {
				categoryModel.value = null;
			}
			if (subCategoryId.value && categoryId.value) {
				const subCategory = subCategoriesByCurrentId.value.find(
					(subcat) => subcat.id === subCategoryId.value
				);
				if (subCategory) {
					subCategoryModel.value = subCategory;
				} else {
					subCategoryModel.value = null;
				}
			} else {
				subCategoryModel.value = null;
			}
		}
	});

	const breadCrumbs = computed(() => {
		const category = store.getters["announcement/categoryById"](categoryId.value);
		const subcategory = store.getters["announcement/subCategoriesById"](subCategoryId.value);
		const group = GROUPS[sectionId.value?.toString()];

		return compact([group?.name, category?.category_name, subcategory?.name]);
	});

	const isValid = computed(() => {
		const grpId = sectionId.value;
		const catId = categoryId.value;
		const subCatId = subCategoryModel.value;

		if (!catId && !subCatId && !grpId) {
			return true;
		}

		if (!catId && !subCatId && grpId) {
			return keys(GROUPS).includes(grpId);
		}
		const category = store.getters["announcement/categoryById"](catId);
		if (catId && !subCatId && grpId) {
			return keys(GROUPS).includes(grpId) && category?.section_id === grpId;
		}

		const subCategoriesIdsByCategoryId = store.getters[
			"announcement/subCategoriesIdsByCategoriesId"
		](categoryId.value);
		if (catId && subCatId && grpId) {
			return (
				keys(GROUPS).includes(grpId) &&
				category?.section_id === grpId &&
				subCategoriesIdsByCategoryId.includes(subCatId)
			);
		}

		return false;
	});

	return {
		currentStep,
		categoriesByCurrentId,
		subCategoriesByCurrentId,
		categoryModel,
		subCategoryModel,
		categoriesParams: readonly(categoriesParams),
		categoriesStatus,
		subCategoryId,
		categoryId,
		breadCrumbs,
		selectGroup,
		selectCategory,
		selectSubCategory,
		selectTag,
		isValid,
		tagCategoryId,
		tagId,
		tagModel,
		tagsByTagCategoryId,
		selectTagCategory,
	};
};
