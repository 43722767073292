export default {
	ru: {
		announcementCreateDelete: "Удалить объявление",
		categoriesText: "Категории",
		announcementCreateName: "Наименование",
		announcementCreateNamePlaceholder: "Не более 70 символов",
		announcementCreatePhotoText: "Первое фото будет на обложке объявления",
		announcementCreatePriceTitle: "Цена",
		announcementCreateWithoutPrice: "Не указывать стоимость",
		announcementCreateUserText: "От кого хотите опубликовать объявление?",
		announcementCreateUserPush: "От себя",
		announcementCreateUserAnotherUser: "От другого пользователя",
		announcementCreateContactText: "Как связаться",
		announcementCreateContactWarningText1:
			"Другие пользователи не смогут связаться с вами, пока вы не выбрали ни один вариант",
		announcementCreateContactWarningText2: "Укажите контакты пользователя для связи по объявлению:",
		announcementCreateContactPhone: "Номер телефона",
		announcementCreateContactError: "Выберите способ связи",
		announcementCreateUserBlockSearch1:
			"Введите любую контактную информацию, чтобы найти пользователя.",
		announcementCreateUserBlockSearch2:
			"Если пользователя еще нет в системе, вы можете добавить контакты нового пользователя",
		announcementCreateUserBlockPlaceholder: "Найти пользователя",
		announcementCreateUserBlockCreateBtn: "Создать пользователя",
		announcementCreateUserBlockContactText: "Данные пользователя",
		announcementCreateUserBlockActiveTitle: "Активный",
		announcementCreateUserBlockActiveText:
			"Мы добавили статус активности, так как пользователь идентифицировался в системе Редактирование данных активных пользователей недоступно",
		announcementCreateUserBlockContactTitle:
			"Мы покажем здесь способы связи, доступные для выбранного пользователя",
		announcementCreateUserBlockContactAllowed:
			"Мы оставили контакты, разрешенные пользователем для связи с ним:",
		announcementCreateMoSaveArchive:
			"Мы сохраним объявление в Архиве. Вы сможете опубликовать его в любой момент",
		announcementCreateMoPublish:
			"После публикации ваше объявление увидят другие пользователи. Нажмите для подтверждения",
		announcementCreateMoOutputUnchanged:
			"Внесенные изменения будут потеряны. Подтвердите выход или сохраните изменения",
		announcementCreateMoDelete:
			"При удалении ваше объявление пропадет навсегда, мы не сможем его восстановить",
		announcementCreateNecessarily: "Обязательно",
	},
	en: {
		announcementCreateDelete: "Delete ad",
		categoriesText: "Categories",
		announcementCreateName: "Title",
		announcementCreateNamePlaceholder: "No more than 70 characters",
		announcementCreatePhotoText: "The first photo will be on the cover of the ad.",
		announcementCreatePriceTitle: "Price",
		announcementCreateWithoutPrice: "Do not specify the cost",
		announcementCreateUserText: "Who do you want to publish the ad from?",
		announcementCreateUserPush: "From myself",
		announcementCreateUserAnotherUser: "From another user",
		announcementCreateContactText: "How to contact",
		announcementCreateContactWarningText1:
			"Other users will not be able to contact you until you have selected at least one option",
		announcementCreateContactWarningText2:
			"Specify the user's contact information for communication via the ad:",
		announcementCreateContactPhone: "Phone number",
		announcementCreateContactError: "Select a method of communication",
		announcementCreateUserBlockSearch1: "Enter any contact information to find the user.",
		announcementCreateUserBlockSearch2:
			"If the user is not yet in the system, you can add the contacts of a new user",
		announcementCreateUserBlockPlaceholder: "Find user",
		announcementCreateUserBlockCreateBtn: "Create user",
		announcementCreateUserBlockContactText: "User data",
		announcementCreateUserBlockActiveTitle: "Active",
		announcementCreateUserBlockActiveText:
			"We have added the activity status, as the user has been identified in the system. Editing of active users' data is not available",
		announcementCreateUserBlockContactTitle:
			"We will show here the ways of communication available for the selected user",
		announcementCreateUserBlockContactAllowed:
			"We have left the contacts that the user has allowed to contact him/her:",
		announcementCreateMoSaveArchive:
			"We will save the ad in the Archive. You will be able to publish it at any time",
		announcementCreateMoPublish:
			"After publication, your ad will be seen by other users. Click to confirm",
		announcementCreateMoOutputUnchanged:
			"Unsaved changes will be lost. Confirm exit or save changes.",
		announcementCreateMoDelete:
			"When deleted, your ad will be gone forever, we will not be able to restore it.",
		announcementCreateNecessarily: "Necessarily",
	},
	ka: {
		announcementCreateDelete: "განცხადების წაშლა",
		categoriesText: "კატეგორიები",
		announcementCreateName: "დასახელებები",
		announcementCreateNamePlaceholder: "არა უმეტეს 70 სიმბოლო",
		announcementCreatePhotoText: "პირველი ფოტო იქნება განცხადების ყდა",
		announcementCreatePriceTitle: "ფასი",
		announcementCreateWithoutPrice: "არ მივუთითოთ ფასი",
		announcementCreateUserText: "ვისი სახელით გსურთ განცხადების გამოქვეყნება?",
		announcementCreateUserPush: "საკუთარი თავი",
		announcementCreateUserAnotherUser: "სხვა ადამიანი",
		announcementCreateContactText: "როგორ დაგიკავშირდეთ",
		announcementCreateContactWarningText1:
			"სხვა მომხმარებლები ვერ დაგიკავშირდებიან სანამ არ აირჩევთ ერთ ვარიანტს მაინც.",
		announcementCreateContactWarningText2:
			"მიუთითეთ თქვენი საკონტაქტო განცხადებაში რომ დაგიკავშირდნენ",
		announcementCreateContactPhone: "ტელეფონის ნომერი",
		announcementCreateContactError: "აირჩიეთ დაკავშირების საშუალება",
		announcementCreateUserBlockSearch1:
			"შეიყვანეთ ნებისმიერი საკონტაქტო ინფორმაცია,რომ იპოვოთ მომხმარებელი",
		announcementCreateUserBlockSearch2:
			"თუ მომხმარებელი ჯერ არ არის დარეგისტრირებული,შეგიძლიათ დაამატოთ მომხმარებლის ახალი საკონტაქტო ",
		announcementCreateUserBlockPlaceholder: "მომხმარებლის მოძებნა",
		announcementCreateUserBlockCreateBtn: "ახალი მომხმარებლის შექმნა",
		announcementCreateUserBlockContactText: "მომხმარებლის პირადი ინფორმაცია",
		announcementCreateUserBlockActiveTitle: "აქტიურია",
		announcementCreateUserBlockActiveText:
			"ჩვენ დავამატეთ აქტივობის სტატუსი,რადგან მომხმარებლების იდენტიფიკაცია სისტემაში არ ექვემდებარებოდა რედაქტირებას და იყო ხელმიუწვდომელი",
		announcementCreateUserBlockContactTitle:
			"ჩვენ განახებთ აქ მითითებული მომხმარებლის მიერ მოცემულ საკონტაქტო ინფორმაციას",
		announcementCreateUserBlockContactAllowed:
			"ჩვენ დავტოვეთ საკონტაქტო მომხმარებელთან რომელზეც კავშირზეა:",
		announcementCreateMoSaveArchive: "განცხადება იქნება არქივში რომ გამოაქვეყნოთ ნებისმიერ დროს",
		announcementCreateMoPublish:
			"განცხადების გამოქვეყნების შემდეგ მას ნახავენ სხვა მომხმარებლებიც. გთხოვთ დააჭიროთ დასადასტურებლად",
		announcementCreateMoOutputUnchanged:
			"ცვლილებები იქნება დაკარგული,დაადასტურეთ გასვლა ან შეინახეთ ცვლილებები",
		announcementCreateMoDelete:
			"წაშლის შემთხვევაში განცხადება გაქრება,სამწუხაროდ ვერ შევძლებთ აღდგენაში დაგეხმაროთ.",
		announcementCreateNecessarily: "აუცილებლად",
	},
	tr: {
		announcementCreateDelete: "İlanı sil",
		categoriesText: "Kategoriler",
		announcementCreateName: "Başlık",
		announcementCreateNamePlaceholder: "70 karakterden fazla olmamalıdır",
		announcementCreatePhotoText: "İlk fotoğraf ilanın vitrininde olacaktır",
		announcementCreatePriceTitle: "Fiyat",
		announcementCreateWithoutPrice: "Fiyat belirtmek istemiyorum",
		announcementCreateUserText: "İlanı kimden yayınlamak istiyorsunuz?",
		announcementCreateUserPush: "Kendimden",
		announcementCreateUserAnotherUser: "Başka bir kullanıcıdan",
		announcementCreateContactText: "İletişime geçme yolu",
		announcementCreateContactWarningText1: "En az bir seçeneği seçene kadar diğer kullanıcılar sizinle iletişime geçemeyecektir",
		announcementCreateContactWarningText2: "İlan aracılığıyla iletişim için kullanıcının iletişim bilgilerini belirtin:",
		announcementCreateContactPhone: "Telefon numarası",
		announcementCreateContactError: "Bir iletişim yöntemi seçin",
		announcementCreateUserBlockSearch1: "Kullanıcıyı bulmak için herhangi bir iletişim bilgisi girin.",
		announcementCreateUserBlockSearch2: "Kullanıcı henüz sistemde değilse, yeni bir kullanıcının kişilerini ekleyebilirsiniz",
		announcementCreateUserBlockPlaceholder: "Kullanıcı bul",
		announcementCreateUserBlockCreateBtn: "Kullanıcı oluştur",
		announcementCreateUserBlockContactText: "Kullanıcı verisi",
		announcementCreateUserBlockActiveTitle: "Aktif",
		announcementCreateUserBlockActiveText: "Kullanıcı sistemde tanımlı olduğu için aktivite durumunu ekledik. Aktif kullanıcıların verilerinin düzenlenmesi mümkün değildir",
		announcementCreateUserBlockContactTitle: "Burada seçilen kullanıcı için mevcut olan iletişim yollarını göstereceğiz",
		announcementCreateUserBlockContactAllowed: "Kullanıcının kendisiyle iletişime geçmesine izin verdiği kişileri bıraktık:",
		announcementCreateMoSaveArchive: "İlanı Arşiv'e kaydedeceğiz. İstediğiniz zaman yayınlayabileceksiniz",
		announcementCreateMoPublish: "Yayınlandıktan sonra ilanınız diğer kullanıcılar tarafından görülecektir. Onaylamak için tıklayın",
		announcementCreateMoOutputUnchanged: "Kaydedilmemiş değişiklikler kaybolacaktır. Çıkışı onaylayın veya değişiklikleri kaydedin",
		announcementCreateMoDelete: "Silindiğinde, ilanınız tamamen yok olacak, geri yükleyemeyeceğiz",
		announcementCreateNecessarily: "zorunlu olarak",
	},
};
