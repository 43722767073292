<script setup>
import { computed } from "vue";
import { GROUPS } from "@widgets/const";
import { TYPOGRAPHY_TYPES, Typography, SvgTemplate } from "@shared/ui";
import { useScreenSize } from "@shared/lib";
import { pickBy } from "lodash-es";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	group: {
		type: Number,
		default: null,
	},
	withTags: {
		type: Boolean,
		default: true,
	},
});

const { isDesktop } = useScreenSize();
const { t } = useTranslation();

const emit = defineEmits(["update:group", "update:tagCategory"]);

const getDescription = (id) => {
	const group = shownGroups.value[id];
	const isMobile = !isDesktop.value;

	return isMobile && group.shortDescription ? group.shortDescription : t(group.description);
};

const selectGroup = (id, item) => {
	if (item.tag_category_id) {
		emit("update:tagCategory", +item.tag_category_id);
	} else {
		emit("update:group", +id);
	}
};

const shownGroups = computed(() =>
	props.withTags ? GROUPS : pickBy(GROUPS, (value) => !("tag_category_id" in value))
);
</script>
<template>
	<div class="groups">
		<slot name="title"></slot>
		<div class="groups__selector">
			<div
				:class="[
					'groups__selector-element',
					{ 'groups__selector-element-tag': grp.tag_category_id },
				]"
				v-for="[id, grp] in Object.entries(shownGroups)"
				:key="id"
				@click="selectGroup(id, grp)"
			>
				<div class="groups__title-name">
					<SvgTemplate :name="grp.icon" />
					<img v-if="grp.img" class="catalog-img__picture-ny" :src="grp.img" :alt="grp.img" />
					<Typography class="groups__title" :type="TYPOGRAPHY_TYPES.TITLE_3">
						{{ t(grp.name) }}
					</Typography>
				</div>
				<div>
					<Typography class="groups__description-text" :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
						{{ getDescription(id) }}
					</Typography>
				</div>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
@import "./AnnouncementGroups.scss";
</style>
