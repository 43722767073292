<script setup>
import { computed } from "vue";
// import { Swiper, SwiperSlide } from "swiper/vue";
import { Image, PENDING_KEY_PHOTO_PREVIEW, SvgTemplate } from "@shared/ui";
import { getBucketUrlSmall, getBucketUrlMedium } from "@shared/const";
import { isBlobUrl } from "@shared/lib";
import "swiper/css";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox";
import "@fancyapps/ui/dist/fancybox.css";

// eslint-disable-next-line no-unused-vars
const props = defineProps({
	items: {
		type: Array,
		default: () => [],
	},
	currentId: {
		type: Number,
		default: 0,
	},
	alt: {
		type: String,
		default: "Фото товара",
	},
	smallPhoto: {
		type: Boolean,
		default: true,
	},
	defaultCount: {
		type: Number,
		default: 3,
	},
});

const emit = defineEmits(["deletePhoto"]);

const deletePhoto = (photo) => {
	emit("deletePhoto", photo);
};

const openPhoto = (index) => {
	const mappedItems = imagesMedium.value.map((src) => ({ src }));
	Fancybox.show(mappedItems, { startIndex: index });
};

const imagesSmall = computed(() => {
	return props.items.map((filename) => {
		if (filename === PENDING_KEY_PHOTO_PREVIEW) {
			return filename;
		}
		if (isBlobUrl(filename)) {
			return filename;
		}
		return getBucketUrlSmall(filename, props.currentId);
	});
});

const imagesMedium = computed(() => {
	return props.items.map((filename) => {
		if (filename === PENDING_KEY_PHOTO_PREVIEW) {
			return filename;
		}
		if (isBlobUrl(filename)) {
			return filename;
		}
		return getBucketUrlMedium(filename, props.currentId);
	});
});

const isPending = (filename) => {
	return filename === PENDING_KEY_PHOTO_PREVIEW;
};

const computedImages = computed(() => {
	const images = props.smallPhoto ? imagesSmall.value : imagesMedium.value;

	if (images.length === 0) {
		return new Array(props.defaultCount).fill(null);
	}
	if (images.length === 1) {
		return [...images, ...new Array(props.defaultCount - 1).fill(null)];
	}
	if (images.length === 2) {
		return [...images, null];
	}
	return images.slice(0, 11);
});
</script>

<template>
	<div v-for="(item, index) in computedImages" :key="`${item}${index}`" class="photo-preview__item">
		<button v-if="item" type="button" class="photo-preview__delete" @click.stop="deletePhoto(item)">
			<SvgTemplate name="trash" class="photo-preview__delete-icon" />
		</button>
		<Image
			v-if="item"
			:src="item"
			:alt="alt"
			class="photo-preview__img"
			:suspense="isPending(item)"
			@click="openPhoto(index)"
		/>
		<div v-else class="photo-preview__default" />
	</div>
</template>

<style lang="scss" scoped>
@import "./PhotoPreview.scss";
</style>
