<script setup>
import { computed } from "vue";

const props = defineProps({
	width: {
		type: [String, Number],
		default: undefined,
	},
	height: {
		type: [String, Number],
		default: undefined,
	},
	bRadius: {
		type: [String, Number],
		default: undefined,
	},
});

const width = computed(() => {
	const val = +props.width;
	return !isNaN(val) ? `${val}px` : undefined;
});

const height = computed(() => {
	const val = +props.height;
	return !isNaN(val) ? `${val}px` : undefined;
});

const bRadius = computed(() => {
	const val = +props.bRadius;
	return !isNaN(val) ? `${val}px` : undefined;
});

/** @type {import("vue").ComputedRef<Record<CSSStyleDeclaration, string>>} */
const style = computed(() => {
	/** @type{Record<keyof CSSStyleDeclaration, string>} */
	const res = {
		width: width.value,
		height: height.value,
		borderRadius: bRadius.value,
	};
	return res;
});
</script>

<template>
	<span class="skeleton" :style="style"></span>
</template>

<style lang="scss" scoped>
.skeleton {
	display: block;
	background-color: $blue-5;
	animation: skeleton 1.5s ease-in infinite;
	border-radius: 0.25rem;
}

@keyframes skeleton {
	0% {
		opacity: 0.4;
	}
	50% {
		opacity: 1;
	}
	100% {
		opacity: 0.4;
	}
}
</style>
