import { useBodyScrollLock } from "@shared/lib";
import { createSharedComposable } from "@vueuse/core";
import { difference } from "lodash";
import { ref, watch } from "vue";

const modalScrollLock = () => {
	const { lock, unlock } = useBodyScrollLock();
	const modals = ref([]);

	const addModal = (id) => {
		modals.value.push(id);
	};

	const removeModal = (id) => {
		modals.value = difference(modals.value, [id]);
	};

	watch(
		modals,
		() => {
			if (modals.value.length > 0) {
				lock();
			} else {
				unlock();
			}
		},
		{ immediate: true, deep: true }
	);

	return { addModal, removeModal };
};

export const useModalScrollLock = createSharedComposable(modalScrollLock);
