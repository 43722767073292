import {
	enTransaltions,
	ruTranslations,
	geTransaltions,
	trTransaltions,
	FALLBACK_LANG,
	LANG_WITH_PREFIX,
} from "@shared/lib";
import i18next from "i18next";

export const initI18n = async (lang) => {
	const userLanguage = LANG_WITH_PREFIX.includes(lang) ? lang : FALLBACK_LANG;

	await i18next.init({
		fallbackLng: userLanguage,
		resources: {
			en: {
				translation: enTransaltions,
			},
			ru: {
				translation: ruTranslations,
			},
			ka: {
				translation: geTransaltions,
			},
			tr: {
				translation: trTransaltions,
			},
		},
	});
};
