import { toUpper } from "lodash";
import { SCREEN_SIZES } from "@shared/const";
import { createSharedComposable, eagerComputed, useWindowSize } from "@vueuse/core";

const screenSizeComposamble = () => {
	/**
	 * @type {{
	 * 	XS: "xs";
	 * 	SM: "sm";
	 * 	MD: "md";
	 * 	LG: "lg";
	 * 	XL: "xl";
	 * 	XXL: "xxl";
	 * }}
	 */
	const SCREEN_SIZES_LIST = Object.keys(SCREEN_SIZES).reduce(
		(prev, curr) => ({ ...prev, [toUpper(curr)]: curr }),
		{}
	);

	const mobileBreakPoints = [SCREEN_SIZES_LIST.XS, SCREEN_SIZES_LIST.SM];

	const tabletBreakPoints = [SCREEN_SIZES_LIST.MD];

	const desktopBreakPoints = [SCREEN_SIZES_LIST.LG, SCREEN_SIZES_LIST.XXL, SCREEN_SIZES_LIST.XL];

	const { width } = useWindowSize();

	const screenSize = eagerComputed(() => {
		const screenWidth = width.value;
		const sizes = Object.keys(SCREEN_SIZES);
		// eslint-disable-next-line no-restricted-syntax
		for (const size of sizes) {
			if (screenWidth <= SCREEN_SIZES[size]) return size;
		}
		return SCREEN_SIZES_LIST.XXL;
	});

	const isDesktop = eagerComputed(() => {
		return desktopBreakPoints.includes(screenSize.value);
	});

	const isMobile = eagerComputed(() => {
		return mobileBreakPoints.includes(screenSize.value);
	});

	const isTablet = eagerComputed(() => {
		return tabletBreakPoints.includes(screenSize.value);
	});

	return { screenSize, SCREEN_SIZES_LIST, isDesktop, isMobile, isTablet };
};

export const useScreenSize = createSharedComposable(screenSizeComposamble);
