import { REQUST_STATUSES } from "@shared/const";

export default {
	status: {
		dilesStatus: REQUST_STATUSES.OUTDATED,
		document: REQUST_STATUSES.OUTDATED,
	},
	filesStatus: {},
	files: {},
};
