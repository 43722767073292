export default {
	ru: {
		chatHeaderTypes: "Печатает...",
		chatHeaderOnline: "В сети",
		chatHeaderOnlineWasRecently: "Был недавно",
	},
	en: {
		chatHeaderTypes: "Typing...",
		chatHeaderOnline: "Online",
		chatHeaderOnlineWasRecently: "Was recently",
	},
	ka: {
		chatHeaderTypes: "ბეჭდავს...",
		chatHeaderOnline: "აქტიური",
		chatHeaderOnlineWasRecently: "იყო ცოტახნის წინ",
	},
	tr: {
		chatHeaderTypes: "Yazıyor…",
		chatHeaderOnline: "Çevrimiçi",
		chatHeaderOnlineWasRecently: "Yakın zamanda çevrimiçiydi",
	},
};
