export default {
	ru: {
		couldNotFindAdsCity: "Не нашли в выбранном городе",
		emptyCity: "Если актуально, посмотрите, что удалось найти в других городах",
		emptyCountryOffer: "Если актуально, посмотрите, что удалось найти в других странах",
		foundInYourCity: "Нашли в выбранном городе",
		foundInYourCountry: "Нашли в выбранной стране",
		recentlyAdded: "Недавно добавленные",
		foundInOtherCountries: "Нашли в других странах",
		searchingResults: "Результаты поиска",
		couldNotFindAdsCountry: "Не нашли в выбранной стране",
		adverrtisementsCityOver:
			"Объявления в выбранном городе закончились... Посмотрите, что удалось найти в других городах",
		adverrtisementsCountryOver:
			"Объявления в выбранной стране закончились... Посмотрите, что удалось найти в других странах",
	},
	en: {
		couldNotFindAdsCity: "Not found in the selected city",
		emptyCity: "If applicable, see what was found in other cities",
		emptyCountryOffer: "If applicable, see what was found in other countries",
		foundInYourCity: "Found in the selected city",
		foundInYourCountry: "Found in the selected country",
		recentlyAdded: "Recently added",
		foundInOtherCountries: "Found in other countries",
		searchingResults: "Search results",
		couldNotFindAdsCountry: "No results found in the selected country",
		adverrtisementsCityOver:
			"No more ads in the selected city... See what we found in other cities",
		adverrtisementsCountryOver:
			"No more ads in the selected country... See what we found in other countries",
	},
	ka: {
		couldNotFindAdsCity: "ვერ ვნახეთ მითითებულ ქალაქში",
		emptyCity: "ასევე ნახეთ მსგავსი მოთხოვნა სხვა ქალაქში",
		emptyCountryOffer: "ასევე ნახეთ მსგავსი მოთხოვნა სხვა ქვეყანაში",
		foundInYourCity: "ვიპოვეთ მითითებულ ქალაქში",
		foundInYourCountry: "ვიპოვეთ მითითებულ ქვეყანაში",
		recentlyAdded: "ახალი დამატებული",
		foundInOtherCountries: "ვიპოვეთ სხვა ქვეყნებში",
		searchingResults: "ძიების რეზულტატი",
		couldNotFindAdsCountry: "ვერ ვიპოვეთ არჩეულ ქვეყანაში",
		adverrtisementsCityOver:
			"მითითებულ ქალაქში განცხადებები არ მოიძებნა. ნახეთ რა ვიპოვეთ სხვა ქალაქში",
		adverrtisementsCountryOver:
			"მითითებულ ქვეყანაში განცხადებები არ მოიძებნა. ნახეთ რა ვიპოვეთ სხვა ქვეყანაში",
	},
	tr: {
		couldNotFindAdsCity: "Seçilen şehirde bulunamadı",
		emptyCity: "Varsa, diğer şehirlerde neler bulunduğuna bakın",
		emptyCountryOffer: "Varsa, diğer ülkelerde neler bulunduğuna bakın",
		foundInYourCity: "Seçilen şehirde bulundu",
		foundInYourCountry: "Seçilen ülkede bulundu",
		recentlyAdded: "Yakın zamanda eklendi",
		foundInOtherCountries: "Başka ülkelerde bulundu",
		searchingResults: "Arama sonuçları",
		couldNotFindAdsCountry: "Seçilen ülkede sonuç bulunamadı",
		adverrtisementsCityOver: "Seçilen şehirde başka ilan yok... Diğer şehirlerde ne bulduğumuzu görün",
		adverrtisementsCountryOver: "Seçilen ülkede başka ilan yok... Diğer şehirlerde ne bulduğumuzu görün",
	},
};
