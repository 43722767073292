import { computed } from "vue";
import { useStore } from "vuex";
import { DateTime } from "luxon";

export const createLocalDate = (timestamp) => {
	const store = useStore();

	const timezone = computed(() => {
		return store.getters["user/timezone"];
	});

	const dt = DateTime.fromSQL(timestamp, { zone: "UTC" });
	return dt.setZone(timezone.value);
};
