<script setup>
import { ref, computed, reactive, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Autocomplete, ModalConfirm, TYPOGRAPHY_TYPES, Typography, SvgTemplate } from "@shared/ui";
import { REQUST_STATUSES, COUNTRIES } from "@shared/const";
import { filterObjectEmptyValues } from "@shared/lib";
import { useCategories } from "@entities/announcement";
import { useFilters } from "@features/Filters";
import { useTranslation } from "i18next-vue";
import { isNil, isEmpty } from "lodash";

const store = useStore();
const router = useRouter();
const { t } = useTranslation();

const { filtersParams } = useFilters();
const { categoriesParams } = useCategories();
const modalRef = ref(null);

defineExpose({ open: () => modalRef.value.open() });

const reducer = (item) => {
	return item?.name;
};

const userStatus = computed(() => {
	return store.getters["user/currentUserStatus"];
});

const regionsStatus = computed(() => {
	return store.getters["announcement/regionsStatus"];
});

const formFields = reactive({
	city: null,
	country: null,
});

const currentLocation = computed(() => {
	return store.getters["user/getHandleLocation"];
});

const unwatch = watch(
	[userStatus, regionsStatus],
	() => {
		if (
			userStatus.value !== REQUST_STATUSES.PENDING &&
			regionsStatus.value === REQUST_STATUSES.SUCCESS
		) {
			const city = store.getters["announcement/cityById"](currentLocation.value?.regionId);
			formFields.city = city?.id === 0 ? null : city;

			formFields.country =
				currentLocation.value.countryId !== 0
					? store.getters["announcement/countryByCityId"](currentLocation.value?.regionId)
					: [];

			nextTick(() => {
				unwatch();
			});
		}
	},
	{ immediate: true }
);

watch(
	() => formFields.country,
	() => {
		const newCountryId = formFields.country?.id;

		const currentCityId = formFields.city?.id;

		if (!isNil(newCountryId) && !isNil(currentCityId)) {
			const prevCountryId = store.getters["announcement/countryByCityId"](currentCityId)?.id;

			const isNeedToClearSelectedCity = newCountryId !== prevCountryId;

			if (isNeedToClearSelectedCity || newCountryId === 0) {
				[formFields.city] = cities.value;
			}
		}

		if (isNil(newCountryId)) {
			[formFields.city] = cities.value;
		}
	}
);

const countries = computed(() => {
	return store.getters["announcement/countries"]({
		exclude: [COUNTRIES.RUSSIA.id, COUNTRIES.ALL.id],
	});
});

const cities = computed(() => {
	return store.getters["announcement/getCitiesByCountryId"](formFields.country?.id);
});

const isPending = ref(false);

const onConfirm = () => {
	sendData();
	modalRef.value.close();
};

const clearModel = () => {
	const oldCountry = formFields.country;
	formFields.country = null;
	formFields.city = null;
	if (oldCountry !== formFields.country) sendData();
};

const sendData = () => {
	const cityId = formFields.city?.id || "0";
	const data = {
		regionId: cityId,
		countryId: formFields.country ? formFields.country?.id : 0,
		isoCode: formFields?.country?.isoCode || null,
	};

	isPending.value = true;

	store.commit("user/SET_HANDLE_LOCATION", data);
	store.commit("announcement/SET_CURRENCT_HANDLE_LOCATION", true);
	store.commit("user/SET_UNAUTH_REGION", +cityId);

	isPending.value = false;
	if (!isEmpty(filtersParams.value)) {
		router.push({
			query: filterObjectEmptyValues({
				group_id: categoriesParams.value.section_id,
				category_id: categoriesParams.value.category_id,
				sub_category_id: categoriesParams.value.sub_category_id,
				tag_category_id: categoriesParams.value.tag_category_id,
				tag_id: categoriesParams.value.tag_id,
			}),
		});
	}
};

const onOpenModal = () => {
	formFields.country =
		currentLocation.value?.countryId !== 0
			? store.getters["announcement/countryById"](currentLocation.value?.countryId)
			: null;
	const city = store.getters["announcement/cityById"](currentLocation.value?.regionId);
	formFields.city = city?.id === 0 ? null : city;
};

const isCitiesDisabled = computed(() => {
	return isNil(formFields.country?.id) || formFields.country?.id === 0;
});
</script>

<template>
	<ModalConfirm
		back-text="changeRegionModalBtnBack"
		confirm-text="changeRegionModalBtnConfirm"
		:confirm-pending="isPending"
		:with-cross="true"
		:allow-self-close="true"
		ref="modalRef"
		@confirm="onConfirm"
		@back="clearModel"
		@open="onOpenModal"
		@close="closeModalOutside"
	>
		<div class="region-modal__title">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">{{ t("changeRegionModalTitle") }}</Typography>
			<Typography class="info" :type="TYPOGRAPHY_TYPES.TEXT">
				{{ t("changeRegionModalSubtitle") }}
			</Typography>
		</div>
		<div class="region-modal__input-location">
			<SvgTemplate class="region-modal__svg" name="location" />
			<div class="connection-line" />
			<Autocomplete
				class="country-autocomplete"
				:placeholder="t('placeholderChangeRegionModalCoutry')"
				:items="countries"
				v-model="formFields.country"
				:disable-input="true"
				:reducer="reducer"
			>
				<template #item="{ reducedItem, typographyType }">
					<div class="country-autocomplete__item">
						<Typography :type="typographyType">{{ reducedItem }}</Typography>
					</div>
				</template>
			</Autocomplete>
			<Autocomplete
				class="city-autocomplete"
				:class="{ 'autocomplete__blue-color': formFields.city?.name === 'Все города' }"
				:placeholder="
					isCitiesDisabled
						? t('placeholderChangeRegionModalCityEmpty')
						: t('placeholderChangeRegionBtnEmpty')
				"
				v-model="formFields.city"
				:items="cities"
				:disabled="isCitiesDisabled"
				:empty-items-message="t('placeholderChangeRegionModalCityEmpty')"
				:reducer="reducer"
			>
				<template #item="{ reducedItem, index }">
					<Typography :type="index > 2 ? TYPOGRAPHY_TYPES.INPUT : TYPOGRAPHY_TYPES.INPUT_B">
						{{ reducedItem }}
					</Typography>
				</template>
			</Autocomplete>
		</div>
	</ModalConfirm>
</template>

<style lang="scss" scoped>
@import "./ChangeRegionModal.scss";
</style>
