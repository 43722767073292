<script setup>
import { useRouter } from "vue-router";
import { Typography, TYPOGRAPHY_TYPES, Button } from "@shared/ui";
// eslint-disable-next-line import/no-unresolved
import Error404 from "@shared/ui/Icons/error404.svg?component";

const router = useRouter();

const onComfirm = () => {
	router.push({ name: "Home" });
};
</script>

<template>
	<div class="not-found-container">
		<Error404 />
		<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">Страница не найдена...</Typography>
		<Button class="not-found-container__btn" @click="onComfirm" :pending="confirmIsPending">
			<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">Вернуться на главную</Typography>
		</Button>
	</div>
</template>

<style lang="scss" scoped>
@import "./NotFoundPage.scss";
</style>
