<script setup>
import { TYPOGRAPHY_TYPES, Typography, Button } from "@shared/ui";
import { useTranslation } from "i18next-vue";

defineProps({
	modePage: {
		type: String,
		default: "NOTHING_AD",
	},
});

const { t } = useTranslation();
</script>

<template>
	<div class="empty-favorites">
		<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
			{{ t("favoritesAnnouncementListEmptyTitle") }}
		</Typography>
		<Typography :type="TYPOGRAPHY_TYPES.TEXT" class="empty-favorites__subtitle">
			{{ t("favoritesAnnouncementListEmpty1") }}
			<span class="symbol">&#x2661;</span>
			{{ t("favoritesAnnouncementListEmpty2") }}
		</Typography>
		<RouterLink :to="{ name: 'Home' }">
			<Button>
				<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">{{ t("buttonTextemptyList") }}</Typography>
			</Button>
		</RouterLink>
	</div>
</template>

<style lang="scss" scoped>
@import "./EmptyFavoritesAnnouncementList.scss";
</style>
