<script setup>
import { Preloader, usePreloader } from "@shared/ui";

const { open } = usePreloader();
open();
</script>

<template>
	<div class="success-auth">
		<Preloader />
	</div>
</template>

<style lang="scss" scoped>
@import "./SuccessAuth.scss";
</style>
