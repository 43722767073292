<script setup>
import { FiltersAnnouncementList, CategoriesAnnouncementList } from "@widgets/AnnouncementLists";
import { useFilters } from "@features/Filters";

const { isFiltersLocationEmpty } = useFilters();
</script>

<template>
	<template v-if="isFiltersLocationEmpty">
		<CategoriesAnnouncementList />
	</template>
	<template v-else>
		<FiltersAnnouncementList />
	</template>
</template>

<style lang="scss" scoped>
@import "./ListByCategories.scss";
</style>
