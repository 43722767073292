export default {
	ru: {
		chatNotSelectedPageText: "Выберите, кому хотели бы написать",
	},
	en: {
		chatNotSelectedPageText: "Choose who you would like to write to",
	},
	ka: {
		chatNotSelectedPageText: "აირჩიეთ ვის გინდათ რომ მიწეროთ",
	},
	tr: {
		chatNotSelectedPageText: "Kime yazmak istediğinizi seçin",
	},
};
