export const getFiles = (state) => (entityId) => {
	return state.files[entityId]?.files;
};

export const getDocuments = (state) => {
	return state.documents;
};

export const documentStatus = (state) => {
	return state.status.document;
};
