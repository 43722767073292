<script setup>
import { onKeyStroke } from "@vueuse/core";
import { useRouter, useRoute } from "vue-router";
import { ChatCard } from "../ChatCard";

defineProps({
	chats: {
		type: Array,
		default: () => [],
	},
});

const router = useRouter();
const route = useRoute();

const selectChat = (chatId) => {
	router.replace({ name: "Chat", params: { chat_id: chatId } });
};

onKeyStroke("Escape", (e) => {
	e.preventDefault();
	router.replace({ name: "Chats" });
});
</script>
<template>
	<ChatCard
		v-for="chat in chats"
		@click="selectChat(chat.chat_id)"
		:key="chat.chat_id"
		:chat="chat"
		:selected="+route.params.chat_id === +chat.chat_id"
	/>
</template>
<style lang="scss" scoped>
@import "./ChatsList.scss";
</style>
