<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useScreenSize } from "@shared/lib";
import { Authorization } from "@widgets/Authorization";
import { ChatsMobile } from "../ChatsMobile";
import { ChatsDesktop } from "../ChatsDesktop";

const store = useStore();

const { isDesktop } = useScreenSize();

const isUserLoggedIn = computed(() => {
	return store.getters["user/isUserLoggedIn"];
});
</script>
<template>
	<ChatsDesktop v-if="isDesktop" />
	<ChatsMobile v-else />
	<Authorization v-if="!isUserLoggedIn" />
</template>

<style lang="scss" scoped>
@import "./ChatsPage.scss";
</style>
