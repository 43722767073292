<script setup>
import { RouterView } from "vue-router";
import { useKeepAliveRouterSharedState } from "@shared/lib";

const { includedKeepAlive, currentHistoryPosition } = useKeepAliveRouterSharedState();
</script>

<template>
	<RouterView v-slot="{ Component }">
		<KeepAlive :include="includedKeepAlive">
			<component :is="Component" :key="currentHistoryPosition" />
		</KeepAlive>
	</RouterView>
</template>
