import { orderBy } from "lodash-es";

export const storiesStatus = (state) => {
	return state.status.stories;
};

export const stories = (state) => {
	return state.stories.map((story) => ({
		...story,
		story_item: orderBy(story.story_item, "item_num", "asc"),
	}));
};
