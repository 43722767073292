<script setup>
import { computed, ref, useSlots, watch, onUnmounted } from "vue";
import { useScreenSize } from "@shared/lib";
import { SIZES } from "@shared/const";
import { useEventBus, useMemoize, useVirtualList } from "@vueuse/core";
import { toLower, isEqual, isObject, debounce } from "lodash";
import {
	INPUT_MODE,
	INPUT_THEMES,
	InputField,
	useTooltip,
	Tooltip,
	Modal,
	Typography,
	TYPOGRAPHY_TYPES,
	SvgTemplate,
	MOBILE_MODAL_SHOW_DELAY,
	Spinner,
} from "@shared/ui";
import { useTranslation } from "i18next-vue";
import { AUTOCOMPLETE_MODES } from "./Autocomplete.const";

const emit = defineEmits(["update:modelValue", "focus", "blur"]);

const slots = useSlots();

const props = defineProps({
	/**
	 * Режим в котором работает автокомплит:
	 *
	 * LOCAL:Items передаются через пропсы сверху и являются вариантами для выбора и поиска по ним
	 *
	 * API: Варианты для поиска и выбора получаются из ответа с props.api
	 */
	mode: {
		type: String,
		default: AUTOCOMPLETE_MODES.LOCAL,
		validator: (mode) => {
			return Object.values(AUTOCOMPLETE_MODES).includes(mode);
		},
	},
	/** Только для mode === "API" */
	api: {
		type: Function,
		default: () => undefined,
	},
	/** Функция по которой items которые состоят из объектов, приводятся к строке(для выбора варианта) */
	reducer: {
		type: Function,
		default: (item) => {
			return item;
		},
	},
	placeholder: {
		type: String,
		default: "",
	},
	modelValue: {
		type: [String, Number, null],
		default: "",
	},
	size: {
		type: String,
		default: SIZES.NORMAL,
		validator: (size) => {
			return Object.values(SIZES).includes(size);
		},
	},
	/** Варианты для выбора и поиска */
	items: {
		type: Array,
		default: () => [],
		required: false,
	},
	theme: {
		type: String,
		default: INPUT_THEMES.LIGHT,
	},
	/** Ошибки validators из библиотеки vuelidate */
	errors: {
		type: Array,
		default: undefined,
	},
	/** Скрытие иконки chevron(^ и v) */
	hideIcon: {
		type: Boolean,
	},
	/**
	 * Возможность выбора варианта введенного в VInput(и не представленного в вариантах props.items
	 * или ответа из props.api)
	 */
	acceptNoOption: {
		type: Boolean,
	},
	/** Блокировка инпута(Выбор только из предложеных вариантов)(MODE.LOCAL only) */
	disableInput: {
		type: Boolean,
	},
	/** Дополнительные query параметры для запроса в props.api */
	additionalParams: {
		type: Object,
		default: () => {},
	},
	/** Ожидание после ввода символа в VInput после которого делается запрос в api(MODE.API only) */
	debounceWait: {
		type: Number,
		default: 800,
	},
	leftIcon: {
		type: String,
		default: undefined,
	},
	/** Скрытие кнопки сборса выбора(X) */
	resetDisabled: {
		type: Boolean,
		default: false,
	},
	/** Сообщение при пустом списке items(MODE LOCAL) */
	emptyItemsMessage: {
		type: String,
		default: undefined,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	errorZeroHeight: {
		type: Boolean,
		default: false,
	},
	withDefault: {
		type: Boolean,
		default: false,
	},
	checkIntervalBounding: {
		typeof: Boolean,
		default: false,
	},
	disabledList: {
		type: Boolean,
		default: false,
	},
	resetDisabledOptions: {
		type: Boolean,
		default: false,
	},
});

const bus = useEventBus("autocomplete");
const { t } = useTranslation();

/** @type {import("vue").Ref<InstanceType<typeof InputField>>} */
const inputFieldRef = ref(null);

/** @type {import("vue").Ref<InstanceType<typeof InputField>>} */
const inputFieldModalRef = ref(null);

/** @type {import("vue").Ref<InstanceType<typeof Modal>>} */
const modal = ref();

const inputModel = ref("");

const isInputModelChangedAfterSet = ref(false);

const selectedItem = ref("");

const isOptionsOpen = ref(false);

const apiResponse = ref([]);

defineExpose({
	focus: () => inputFieldRef.value.focus(),
	blur: () => inputFieldRef.value.blur(),
	scrollToElement: () => inputFieldRef.value.scrollToElement(),
});

const hideTooltip = () => {
	hide();
};

const showTooltip = () => {
	show();
};

const subClose = () => {
	bus.on(hideOptions);
};

const unsubClose = () => {
	bus.off(hideOptions);
};

const showModal = () => {
	modal.value?.open();
	setTimeout(() => {
		inputFieldModalRef.value?.focus();
	}, MOBILE_MODAL_SHOW_DELAY);
};

const hideModal = () => {
	modal.value?.close();
};

const memoReducer = useMemoize(props.reducer);

onUnmounted(() => {
	memoReducer.clear();
});

const showOptions = () => {
	if (props.disabled) {
		return;
	}
	if (!isOptionsOpen.value) {
		isOptionsOpen.value = true;
		showTooltip();
		showModal();
		bus.emit(); // Закрываем открытый автокомплит
		subClose();
		isInputModelChangedAfterSet.value = false;
		inputFieldRef.value.focus();
	}
};

const hideOptions = () => {
	if (isOptionsOpen.value) {
		isOptionsOpen.value = false;
		hideModal();
		hideTooltip();
		unsubClose();
		setInputModelOnHide();
	}
};

const toggleOptions = () => {
	if (isOptionsOpen.value) {
		hideOptions();
	} else {
		showOptions();
	}
};

const setInputModelOnHide = () => {
	inputModel.value = itemToString(selectedItem.value);
};

const offsetY = computed(() => {
	return props.errors && !props.errorZeroHeight ? -32 : -11;
});

const { isVisible, show, hide } = useTooltip();

const localItems = computed(() => {
	return props.items.map((item, idx) => ({ id: idx, value: item }));
});

const itemToString = (item) => {
	if (props.reducer && isObject(item)) {
		return props.reducer(item) || "";
	}
	return item || "";
};

const { isDesktop } = useScreenSize();

const responseItems = computed(() => {
	return apiResponse.value.map((item, idx) => ({ id: idx, value: item }));
});

const items = computed(() => {
	if (props.mode === AUTOCOMPLETE_MODES.LOCAL) {
		return localItems.value;
	}
	return responseItems.value;
});

const filteredItems = computed(() => {
	let res = [];

	if (props.mode === AUTOCOMPLETE_MODES.LOCAL) {
		if (inputModel.value.length > 0 && isInputModelChangedAfterSet.value) {
			res = items.value.filter(({ value }) => {
				return toLower(memoReducer(value)).indexOf(toLower(inputModel.value)) !== -1;
			});
		} else {
			res = items.value;
		}
	} else if (props.mode === AUTOCOMPLETE_MODES.API) {
		res = items.value;
	}

	return res;
});

const {
	list: filterdListVirtual,
	containerProps,
	wrapperProps,
} = useVirtualList(filteredItems, { itemHeight: 48, overscan: 30 });

watch(
	() => modal.value?.contentRef,
	(value) => {
		if (value) {
			containerProps.ref.value = value;
		}
	},
	{ deep: true }
);

const isFilteredItemsIsEmpty = computed(() => {
	return filteredItems.value.length === 0;
});

const emptyItemsMessage = computed(() => {
	if (!inputModel.value && props.mode === AUTOCOMPLETE_MODES.API && !props.disableInput) {
		return "Начните вводить";
	}

	if (
		props.mode === AUTOCOMPLETE_MODES.LOCAL &&
		props.emptyItemsMessage &&
		props.items.length === 0
	) {
		return props.emptyItemsMessage || t("emptyTitle");
	}
	return t("emptyTitle");
});
/** @type {{ controller: AbortController; request: Promise<any> }} */
let apiRequest = null;

const isRequestPending = ref(false);

const setResponseData = (data) => {
	apiResponse.value = data;
};

const cancelRequest = () => {
	if (apiRequest) {
		apiRequest.controller.abort();
	}
};

const apiFetch = async (term) => {
	try {
		cancelRequest();
		const controller = new AbortController();
		apiRequest = {
			controller,
			request: props.api({ term, ...props.additionalParams }, controller),
		};
		const response = await apiRequest.request;
		setResponseData(response.data);
	} catch (error) {
		if (error.code !== "ERR_CANCELED") {
			console.error(error);
			return;
		}
	} finally {
		isRequestPending.value = false;
	}
};

const isNeedToSkipFetch = ref(false);

const skipFetch = () => {
	isNeedToSkipFetch.value = true;
};

const debouncedApiFetch = debounce(apiFetch, props.debounceWait, {
	leading: false,
	trailing: true,
});

const setSelectedItem = (id) => {
	const item = items.value.find((item) => item.id === id);

	inputModel.value = itemToString(item);
	selectedItem.value = item.value;
	if (props.mode === AUTOCOMPLETE_MODES.API && item) {
		skipFetch();
	}
};

const resetSelectedItemsWithFocus = () => {
	if (props.disabled) return;
	resetSelectedItem();

	if (isOptionsOpen.value) {
		if (inputFieldModalRef.value) {
			inputFieldModalRef.value?.focus();
		} else {
			inputFieldRef.value?.focus();
		}
	} else if (!props.resetDisabledOptions) {
		showOptions();
	}
};

const resetSelectedItem = () => {
	inputModel.value = "";
	selectedItem.value = null;
};

const onSelectItem = (id) => {
	setSelectedItem(id);
	hideOptions();
};

watch(inputModel, (newVal, oldVal) => {
	if (props.mode === AUTOCOMPLETE_MODES.API) {
		if (!isNeedToSkipFetch.value) {
			isRequestPending.value = true;
			debouncedApiFetch(newVal);
		} else {
			isNeedToSkipFetch.value = false;
		}
	}

	if (newVal !== oldVal) {
		isInputModelChangedAfterSet.value = true;
	}
	if (newVal === "") {
		resetSelectedItem();
	}
});

watch(selectedItem, () => {
	if (!isEqual(props.modelValue, selectedItem.value)) {
		emit("update:modelValue", selectedItem.value);
	}
});

watch(
	() => props.modelValue,
	() => {
		if (!isEqual(props.modelValue, selectedItem.value)) {
			selectedItem.value = props.modelValue;
			inputModel.value = itemToString(props.modelValue);
		}
	},
	{ immediate: true, deep: true }
);

const onInputClick = (e) => {
	if (!e.target.classList.contains("autocomlete__control-chevron")) {
		showOptions();
	}
};
</script>

<template>
	<div class="autocomplete" :class="{ autocomplete_shown: isOptionsOpen }">
		<Tooltip
			:is-visible="isVisible"
			:offset="[0, offsetY]"
			@hide="hideOptions"
			v-if="isDesktop"
			:check-interval-bounding="checkIntervalBounding"
		>
			<InputField
				class="autocomplete__input"
				ref="inputFieldRef"
				:size="size"
				:placeholder="placeholder"
				:theme="theme"
				:mode="INPUT_MODE.RAW"
				v-model="inputModel"
				:disabled="disabled"
				:silent-disabled="disableInput"
				:left-icon="leftIcon"
				:tab-index="-1"
				:errors="errors"
				:error-zero-height="errorZeroHeight"
				@click="onInputClick"
			>
				<template #left><slot name="left"></slot></template>
				<template #rigth>
					<slot name="right"></slot>
					<div class="autocomlete__control" @click.stop>
						<SvgTemplate
							v-if="inputModel && !resetDisabled"
							ref="cleanRef"
							class="autocomlete__control-clean"
							name="clean"
							@click.stop="resetSelectedItemsWithFocus"
						/>
						<SvgTemplate
							ref="chevronRef"
							class="autocomlete__control-chevron"
							:class="{ 'autocomlete__control-chevron_open': isOptionsOpen }"
							name="chevron"
							@click.stop="toggleOptions"
						/>
					</div>
				</template>
			</InputField>
			<template #content>
				<div class="autocomplete__options-wrapper desktop">
					<div class="autocomplete__options">
						<div v-if="isRequestPending" class="autocomplete__spinner">
							<Spinner :size="SIZES.SMALL" />
						</div>
						<template v-else-if="!isFilteredItemsIsEmpty">
							<div v-for="(item, index) in filteredItems" :key="item.id">
								<div
									class="autocomplete__option"
									:class="{
										'autocomplete__option-locations': withDefault && item.id === 0,
									}"
									@click="onSelectItem(item.id)"
								>
									<slot
										name="item"
										:item="item.value"
										:memo-reducer="memoReducer"
										:typography-type="TYPOGRAPHY_TYPES.INPUT"
										:reducedItem="memoReducer(item.value)"
										:index="index"
										v-if="slots.item"
									/>
									<Typography :type="TYPOGRAPHY_TYPES.INPUT" v-else>
										{{ memoReducer(item.value) }}
									</Typography>
								</div>
							</div>
						</template>
						<template v-else>
							<div
								class="autocomplete__option"
								:class="{ 'autocomplete__option-locations-disabled': disabledList }"
							>
								<Typography :type="TYPOGRAPHY_TYPES.INPUT">
									{{ emptyItemsMessage }}
								</Typography>
							</div>
						</template>
					</div>
				</div>
			</template>
		</Tooltip>
		<template v-else>
			<InputField
				class="autocomplete__input"
				ref="inputFieldRef"
				:size="size"
				:placeholder="placeholder"
				:theme="theme"
				:mode="INPUT_MODE.RAW"
				v-model="inputModel"
				:silent-disabled="true"
				:left-icon="leftIcon"
				:tab-index="-1"
				:errors="errors"
				:disabled="disabled"
				:error-zero-height="errorZeroHeight"
				@click="showOptions"
			>
				<template #left><slot name="left"></slot></template>
				<template #rigth>
					<div class="autocomlete__control" @click.stop>
						<SvgTemplate
							v-if="inputModel && !resetDisabled"
							ref="cleanRef"
							class="autocomlete__control-clean"
							name="clean"
							@click.stop="resetSelectedItemsWithFocus"
						/>
						<SvgTemplate
							ref="chevronRef"
							class="autocomlete__control-chevron"
							:class="{ 'autocomlete__control-chevron_open': isOptionsOpen }"
							name="chevron"
							@click.stop="toggleOptions"
						/>
					</div>
				</template>
			</InputField>
			<Modal
				ref="modal"
				full-expand-mobile
				@close="hideOptions"
				@content-scroll="containerProps.onScroll"
			>
				<template #mobile-title>
					<Typography class="autocomplete__placeholder" :type="TYPOGRAPHY_TYPES.TITLE_2">
						{{ placeholder }}
					</Typography>
				</template>
				<div class="autocomplete__options-top" v-if="!disableInput">
					<InputField
						class="autocomplete__input"
						ref="inputFieldModalRef"
						:size="size"
						:placeholder="placeholder"
						:theme="theme"
						:mode="INPUT_MODE.RAW"
						v-model="inputModel"
						:left-icon="leftIcon"
						:tab-index="-1"
					>
						<template #left><slot name="left"></slot></template>
						<template #rigth>
							<slot name="right">
								<div class="autocomlete__control">
									<SvgTemplate
										v-if="inputModel"
										ref="cleanRef"
										class="autocomlete__control-clean"
										name="clean"
										@click.stop="resetSelectedItemsWithFocus"
									/>
								</div>
							</slot>
						</template>
					</InputField>
				</div>
				<div class="autocomplete__options-wrapper mobile">
					<div v-if="isRequestPending" class="autocomplete__spinner">
						<Spinner :size="SIZES.BIG" />
					</div>
					<div class="autocomplete__options" v-else v-bind="wrapperProps">
						<template v-if="!isFilteredItemsIsEmpty">
							<template v-for="(item, index) in filterdListVirtual" :key="item.id">
								<div
									class="autocomplete__option"
									:class="{
										'autocomplete__option-locations': isLocation && item.data.id === 0,
										'autocomplete__option-locations-disabled': disabledList,
									}"
									@click="onSelectItem(item.data.id)"
								>
									<slot
										name="item"
										:item="item.data.value"
										:memoReducer="memoReducer"
										:reducedItem="memoReducer(item.data.value)"
										:typography-type="TYPOGRAPHY_TYPES.TEXT"
										:index="index"
										v-if="slots.item"
									/>
									<Typography :type="TYPOGRAPHY_TYPES.TEXT" v-else>
										{{ memoReducer(item.data.value) }}
									</Typography>
								</div>
							</template>
						</template>
						<template v-else>
							<div class="autocomplete__option">
								<Typography :type="TYPOGRAPHY_TYPES.TEXT">
									{{ emptyItemsMessage }}
								</Typography>
							</div>
						</template>
					</div>
				</div>
			</Modal>
		</template>
	</div>
</template>

<style lang="scss" scoped>
@import "./Autocomplete.scss";
</style>
