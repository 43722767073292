export default {
	ru: {
		technicalSupport: "Техподдержка",
	},
	en: {
		technicalSupport: "Technical support",
	},
	ka: {
		technicalSupport: "ტექ-დახმარება",
	},
	tr: {
		technicalSupport: "Teknik destek",
	},
};
