export default {
	ru: {
		profilePendingEditText: "Для продолжения работы необходимо заполнить данные в профиле",
	},
	en: {
		profilePendingEditText: "To continue, you need to fill in the data in your profile",
	},
	ka: {
		profilePendingEditText: "შეავსეთ პროფილის ინფორმაცია აპლიკაციის სრულად გამოსაყენებლად",
	},
	tr: {
		profilePendingEditText: "Devam etmek için profilinizdeki verileri doldurmanız gerekir",
	},
};
