export default {
	ru: {
		groupsModalTitle: "Выберите категорию для перехода в каталог:",
		groupsGoods: "Товары",
		groupsGoodsText: `От одежды до
		    продуктов питания`,
		groupsServices: "Услуги",
		groupsServicesText: `От юридических
		    до красоты и здоровья`,
		groupsJob: "Работа",
		groupsJobText: `Вакансии
		    и соискатели`,
		groupsEvents: "Мероприятия",
		groupsEventsText: `События от деловых
		    до развлекательных`,
		groupsRealEstate: "Недвижимость",
		groupsRealEstateText: `Покупка и аренда
		    на любой срок`,
		groupsTransport: "Транспорт",
		groupsTransportText: `Наземный, водный,
		    воздушный`,
		groupsTourism: "Туризм",
		groupsTourismText: `Экскурсии, туры,
		    маршруты`,
	},
	en: {
		groupsModalTitle: "Select a category to go to the catalog:",
		groupsGoods: "Goods",
		groupsGoodsText: `From clothes to  
        food`,
		groupsServices: "Services",
		groupsServicesText: `From legal 
        to beauty and health`,
		groupsJob: "Job",
		groupsJobText: `Vacancies
         and job seekers`,
		groupsEvents: "Events",
		groupsEventsText: `Events from business 
         to entertainment`,
		groupsRealEstate: "Real estate",
		groupsRealEstateText: `Purchase and rent 
          for any period`,
		groupsTransport: "Transport",
		groupsTransportText: `Ground, water, \n\t\t air`,
		groupsTourism: "Tourism",
		groupsTourismText: `Excursions, tours, \n\t\t  routes`,
	},
	ka: {
		groupsModalTitle: "აირჩიეთ კატეგორია კატალოგში გადასასვლელად:",
		groupsGoods: "პროდუქცია",
		groupsGoodsText: `ტანსაცმელის და კვების პროდუქტები`,
		groupsServices: "მომსახურეობა",
		groupsServicesText: `იურიდიული მომსახურეობა,ასევე თავის მოვლის და ჯანმრთელობის საშუალებები`,
		groupsJob: "სამუშაოები",
		groupsJobText: `ვაკანსიები და დამსაქმებლები`,
		groupsEvents: "დღესასწაულები",
		groupsEventsText: `გასართობი ღონისძიებები ასევე საქმიანი შეხვედრები`,
		groupsRealEstate: "უძრავი ქონება",
		groupsRealEstateText: `ყიდვა/გაყიდვა/გაქირავება`,
		groupsTransport: "ტრანსპორტი",
		groupsTransportText: `სახმელეთო,საცაო,საზღვაო`,
		groupsTourism: "ტურიზმი",
		groupsTourismText: `ექსკურსიები, ტურები, საფეხმავლო ლაშქრობები`,
	},
	tr: {
		groupsModalTitle: "Kataloğa gitmek için bir kategori seçin:",
		groupsGoods: "Ürünler",
		groupsGoodsText: `Giyimden \n\t\t yiyeceğe kadar`,
		groupsServices: "Hizmetler",
		groupsServicesText: `Yasaldan \n\t\t güzellik ve sağlığa`,
		groupsJob: "İş",
		groupsJobText: `Açık pozisyonlar \n\t\t ve iş arayanlar`,
		groupsEvents: "Etkinlikler",
		groupsEventsText: `İşten \n\t\t eğlenceye kadar etkinlikler`,
		groupsRealEstate: "Gayrimenkül",
		groupsRealEstateText: `Herhangi bir dönem için \n\t\t satın alma ve kiralama`,
		groupsTransport: "Ulaşım",
		groupsTransportText: `Kara, su  \n\t\t hava`,
		groupsTourism: "Turizm",
		groupsTourismText: `Geziler, turlar, \n\t\t rotalar`,
	},
};
