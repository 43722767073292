export default {
	ru: {
		chatSuggestionChooseLocaleText1: "Чтобы стало еще удобнее:",
		chatSuggestionChooseLocaleText2:
			"Перейдите в Профиль, выберите Язык чатов и получайте сообщения в чатах на своем языке",
	},
	en: {
		chatSuggestionChooseLocaleText1: "To make it even more convenient:",
		chatSuggestionChooseLocaleText2:
			"Go to Profile, select Chat Language and receive messages in chats in your language",
	},
	ka: {
		chatSuggestionChooseLocaleText1: "უფრო კომფორტული რომ იყოს :",
		chatSuggestionChooseLocaleText2:
			"გადადით პროფილში აირჩიეთ სასურველი ენა და მიიღეთ შეტყობინება თქვენს მიერ შერჩეულ ენაზე ჩათების მეშვეობით.",
	},
	tr: {
		chatSuggestionChooseLocaleText1: "Daha da kullanışlı hale getirmek için:",
		chatSuggestionChooseLocaleText2: "Profil'e gidin, Sohbet Dili'ni seçin ve sohbetlerdeki mesajları kendi dilinizde alın",
	},
};
