export const SCREEN_SIZES = {
	xs: 320,
	sm: 640,
	md: 768,
	lg: 1024,
	xl: 1280,
	xxl: 1536,
};

export const MobileScreenSizes = [SCREEN_SIZES.xs, SCREEN_SIZES.sm];

export const TabletScreenSizes = [SCREEN_SIZES.md];

export const DesktopScreenSizes = [SCREEN_SIZES.lg, SCREEN_SIZES.xl, SCREEN_SIZES.xxl];
