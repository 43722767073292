import { getApiUrl, HTTP_METHODS } from "@shared/const";
import { httpRequest } from "@shared/lib";

const ENDPOINT = "files";

const url = getApiUrl(ENDPOINT);

export const apiGetADPhoto = (params) => {
	return httpRequest({ method: HTTP_METHODS.GET, url }, { query: params });
};

export const apiPostFiles = (entityId, files) => {
	const method = HTTP_METHODS.POST;
	const query = { entity_id: entityId };

	return httpRequest({ method, url }, { query, formData: files, formDataName: "files" });
};

export const apiDeleteFiles = (entityId, filename) => {
	const method = HTTP_METHODS.DELETE;

	return httpRequest({ method, url }, { query: { entity_id: entityId, filename } });
};

export const apiGetDocuments = (lang) => {
	const url = getApiUrl("dimensions/documents");
	return httpRequest({ method: HTTP_METHODS.GET, url }, { query: lang });
};
