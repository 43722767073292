<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";

import { Skeleton, Typography, TYPOGRAPHY_TYPES } from "@shared/ui";

const props = defineProps({
	items: { type: Array, default: () => [] },
	withBack: { type: Boolean, default: false },
	isPending: { type: Boolean, default: false },
});

const router = useRouter();

const pengingItems = ref(Array(3).fill(""));

const handleBreadcrumbs = (routeParams) => {
	router.push({ ...routeParams });
};
</script>

<template>
	<nav>
		<ol class="breadcrumbs">
			<template v-if="!isPending">
				<li
					class="breadcrumbs__item"
					:class="{ breadcrumbs__item_last: idx === items.length - 1 }"
					v-for="(item, idx) in items"
					:key="item + idx"
					@click="handleBreadcrumbs(item.to)"
				>
					<Typography class="breadcrumb__crumb" :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
						{{ item.title }}
					</Typography>
					<Typography
						v-if="idx !== props.items.length - 1"
						class="breadcrumb__crumb"
						:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
					>
						/
					</Typography>
				</li>
			</template>
			<template v-else>
				<li class="breadcrumbs__item" v-for="(item, idx) in pengingItems" :key="idx">
					<Skeleton />
					<Typography
						no-suspense
						class="breadcrumb__crumb"
						:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
						v-if="idx !== pengingItems.length - 1"
					>
						/
					</Typography>
				</li>
			</template>
		</ol>
	</nav>
</template>

<style lang="scss" scoped>
@import "./Breadcrumbs.scss";
</style>
