import { createSharedComposable } from "@vueuse/core";
import { omit, values } from "lodash-es";
import { computed, ref } from "vue";

const refresh = () => {
	const initDataCallbacks = ref({});

	const deactivatedIds = ref([]);

	const activeInitDataCallBacks = computed(() => {
		return omit(initDataCallbacks.value, deactivatedIds.value);
	});

	const refresh = () => {
		return Promise.all(values(activeInitDataCallBacks.value).map((cb) => cb()));
	};

	const isRefreshable = computed(() => {
		return values(activeInitDataCallBacks.value).length > 0;
	});

	const addInitData = ({ id, cb }) => {
		initDataCallbacks.value[id] = cb;
	};

	const removeInitData = (id) => {
		delete initDataCallbacks.value[id];
	};

	const deactivateInitData = (id) => {
		deactivatedIds.value.push(id);
	};

	const activateInitData = (id) => {
		deactivatedIds.value = deactivatedIds.value.filter((val) => val === id);
	};

	return {
		addInitData,
		removeInitData,
		deactivateInitData,
		activateInitData,
		refresh,
		isRefreshable,
	};
};

export const useRefresh = createSharedComposable(refresh);
