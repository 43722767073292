<script setup>
import { computed } from "vue";
import { IS_IOS, IS_STANDALONE } from "@shared/const";

const isVisible = computed(() => {
	return IS_IOS || IS_STANDALONE;
});
</script>

<template>
	<div class="screen-safe-area-top" v-if="isVisible"></div>
	<slot />
	<div class="screen-safe-area-bottom" v-if="isVisible"></div>
</template>

<style lang="scss" scoped>
@import "./ScreenSafeArea.scss";
</style>
