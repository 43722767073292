<script setup>
import { useScreenSize } from "@shared/lib";
import { onActivated, onDeactivated, ref } from "vue";

const { isDesktop } = useScreenSize();

const isHidden = ref(false);

onActivated(() => {
	isHidden.value = false;
});

onDeactivated(() => {
	isHidden.value = true;
});
</script>

<template>
	<Teleport to="#teleport_back_btn_here" v-if="!isDesktop && !isHidden">
		<slot></slot>
	</Teleport>
</template>
