<script setup>
import { ref, computed, watch, nextTick, onBeforeMount, onMounted, onUnmounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
	apiDeleteADPhoto,
	apiGetADPhoto,
	apiPostADPhoto,
	yaMetrikaAnnounceCreateByUser,
	yaMetrikaAnnounceCreateByAdmin,
} from "@entities/announcement";
import { FileLoader } from "@entities/files";
import { SIZES, STATUSES, THEMES, COUNTRIES } from "@shared/const";
import { useVuelidate } from "@vuelidate/core";
import { useToast } from "vue-toast-notification";
import { isEmpty, isNil } from "lodash";
import { required, requiredIf, useScreenSize } from "@shared/lib";
import { ProfilePendingEdit } from "@features/ProfilePendingEdit";

import {
	InputField,
	Autocomplete,
	Checkbox,
	TYPOGRAPHY_TYPES,
	Typography,
	INPUT_THEMES,
	AUTOCOMPLETE_MODES,
	INPUT_MODE,
	PhotoPreview,
	SvgTemplate,
	PENDING_KEY_PHOTO_PREVIEW,
	Button,
	CategorySelector,
	Modal,
	ModalConfirm,
	TextArea,
} from "@shared/ui";
import { GROUPS } from "@widgets/const";
import { usePhone } from "@features/Phone";
import { useTranslation } from "i18next-vue";
import UserBlock from "./UserBlock.vue";

const toast = useToast();

const props = defineProps({
	group: {
		type: Number,
		default: 1,
	},
	selectCategoriesName: {
		type: String,
		default: "",
	},
	selectCategories: {
		type: Number,
		default: null,
	},
	selectSubCategories: {
		type: Number,
		default: null,
	},
	currentAD: {
		type: Object,
		required: false,
		default: () => {},
	},
});

const { toFormatPhone } = usePhone();
const router = useRouter();
const { t } = useTranslation();

const emit = defineEmits(["endEdit", "editStatus"]);

const store = useStore();

const selectCities = ref();

const selectCountry = ref();

const selectCurrencies = ref();

const nameAd = ref();

const price = ref();

const descriptions = ref();

const formElements = ref({});

const setFormElements = (id, ref) => {
	formElements.value[id] = ref;
};

const onInputCapitalize = (e) => {
	if (e !== null && e !== undefined) {
		descriptions.value =
			e.charAt(0).toUpperCase() + e.slice(1).replace(/\n\s*?\p{Ll}/gu, (v) => v.toUpperCase());
	}
};

const isTg = ref(false);

const isPhone = ref(false);

const isWa = ref(false);

const isEmail = ref(false);

const isNew = ref(false);

const files = ref([]);

const flagContact = ref();

const photoForDelete = ref("");

const userAD = ref(null);

const userADFromBd = ref(null);

const userRef = ref(null);

const isPriceNotSpecified = ref(false);

const modalCategorySelector = ref(null);

const modalRefSave = ref(null);

const modalRefDelete = ref(null);

const modalRefBack = ref(null);

const selectedGroup = ref(props.group);

const selectCategories = ref(props.selectCategories);

const selectSubCategories = ref(props.selectSubCategories);

const modalRefPublic = ref(null);

const isActiveTag = ref(false);

const selectedTag = ref();

// const confirmIsPending = ref(false);

const { isDesktop } = useScreenSize();

const currentUserRegion = computed(() => {
	return store.getters["announcement/currentRegionOmitZeroId"];
});

const unwatch = watch(
	currentUserRegion,
	(value) => {
		if (value && !props.currentAD?.region_id) {
			selectCities.value = value;

			selectCountry.value = store.getters["announcement/countryByCityIdAnnouncement"](
				currentUserRegion.value.id
			);
			nextTick(() => {
				unwatch();
			});
		}
	},
	{ immediate: true }
);

const data = computed(() => {
	return {
		category_id: editFlag.value ? selectCategories.value : selectCategories.value || null,
		sub_category_id: editFlag.value ? selectSubCategories.value : selectSubCategories.value || null,
		item_city: editFlag.value ? selectCities.value?.name : selectCities.value?.name || null,
		ad_name: editFlag.value ? nameAd.value : nameAd.value || null,
		description: editFlag.value ? descriptions.value : descriptions.value || null,
		currency_id: editFlag.value
			? selectCurrencies.value?.currency_id
			: selectCurrencies.value?.currency_id || null,
		price: editFlag.value ? +price.value : +price.value || null,
		is_phone: editFlag.value ? isPhone.value : isPhone.value || null,
		is_tg: editFlag.value ? isTg.value : isTg.value || null,
		is_wa: editFlag.value ? isWa.value : isWa.value || null,
		is_email: editFlag.value ? isEmail.value : isEmail.value || null,
		tittle_photo: files.value[0] || null,
		item_id: currentId.value,
	};
});

const currentId = computed(() => {
	return store.getters["announcement/getADId"];
});

const cities = computed(() => {
	return store.getters["announcement/citiesByCountryId"](selectCountry?.value?.id);
});

const countries = computed(() => {
	return store.getters["announcement/countries"]({
		exclude: [COUNTRIES.ALL.id, COUNTRIES.RUSSIA.id],
	});
});

const currencies = computed(() => {
	return store.getters["announcement/currencies"];
});

const userValue = computed(() => {
	return store.getters["user/getCurrentUser"];
});

watch(
	() => [userValue.value.user_id, currentId.value],
	() => {
		if (userValue.value.user_id && !currentId.value) {
			store.dispatch("announcement/createAD", { user_id: userValue.value.user_id });
		}
	},
	{ immediate: true }
);

onBeforeMount(() => {
	watch(selectCountry, () => {
		const newCountryId = selectCountry?.value?.id;

		const currentCityId = selectCities?.value?.id;

		if (!isNil(newCountryId) && !isNil(currentCityId)) {
			const prevCountryId = store.getters["announcement/countryByCityId"](currentCityId);

			const isNeedToClearSelectedCity = newCountryId !== prevCountryId;

			if (isNeedToClearSelectedCity) {
				selectCities.value = null;
			}
		}
	});
});

onMounted(() => {
	store.commit("announcement/TOGGLE_ACTIVE_EDITOR", false);
});

onUnmounted(() => {
	store.commit("announcement/TOGGLE_ACTIVE_EDITOR", true);
});

const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const isAdmin = computed(() => {
	return store.getters["user/isAdminUser"];
});

const editFlag = computed(() => {
	return !!props.currentAD?.item_id;
});

const tgOnly = computed(() => {
	return !user.value.phone && !user.value.email && user.value.username;
});

const formFields = computed(() => {
	return {
		selectCategories: selectCategories.value,
		selectedTag: selectedTag.value,
		selectCities: selectCities.value,
		nameAd: nameAd.value,
		price: price.value,
		selectCurrencies: selectCurrencies.value,
		flagContact: flagContact.value,
	};
});

const isEmptyContact = computed(() => {
	const selectFlag = isTg.value || isPhone.value || isEmail.value || isWa.value;
	if (tgOnly.value) {
		return false;
	}
	return !selectFlag;
});

const isEmptyActiveTag = computed(() => {
	return isActiveTag.value && !selectedTag.value;
});

const validateRules = {
	selectCategories: { required },
	selectedTag: { requiredIf: requiredIf(isEmptyActiveTag) },
	nameAd: { required },
	selectCities: { required },
	selectCurrencies: {
		requiredIf: requiredIf(() => !isPriceNotSpecified.value, t("announcementCreateNecessarily")),
	},
	price: { requiredIf: requiredIf(() => !isPriceNotSpecified.value) },
	flagContact: { requiredIf: requiredIf(isEmptyContact) },
};

const validate = useVuelidate(validateRules, formFields);

watch(
	() => props.currentAD?.item_id,
	(value) => {
		if (value) {
			const currencyCode = currencies.value.find(
				(x) => x.currency_id === props.currentAD.currency_id
			)?.currency_code;

			isEmail.value = props.currentAD.is_email || false;
			isTg.value = props.currentAD.is_tg || false;
			isWa.value = props.currentAD.is_wa || false;
			isPhone.value = props.currentAD.is_phone || false;
			price.value = props.currentAD.price;
			selectCities.value = store.getters["announcement/cityById"](props.currentAD.region_id);
			selectedTag.value = store.getters["announcement/tagById"](props.currentAD.tag_id);
			selectCountry.value = store.getters["announcement/countryByCityIdAnnouncement"](
				props.currentAD.region_id
			);
			descriptions.value = props.currentAD.description;
			nameAd.value = props.currentAD.ad_name;
			userAD.value = props.currentAD.user_id;
			selectCurrencies.value = {
				currency_id: props.currentAD.currency_id,
				currency_code: currencyCode,
			};
			isPriceNotSpecified.value = props.currentAD.price === 0;
		} else {
			console.error(files.value);
			isNew.value = true;
		}
	},
	{ immediate: true }
);

watch(
	() => isEmpty(user.value),
	(value) => {
		if (!value) {
			userAD.value = props.currentAD?.user_id || user.value.user_id;
		}
	},
	{ immediate: true }
);

watch(
	() => editFlag.value,
	(val) => {
		if (val && !isDesktop.value) {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
	},
	{ immediate: true }
);

const pendingAnnouncementStatus = ref(null);

const saveNoPublic = () => {
	const isValid = !validate.value.$invalid;
	validate.value.$touch();
	if (isValid && !editFlag.value) {
		modalRefSave.value.open();
	} else {
		saveAnnouncement();
	}
};

const onBackSaveArchive = () => {
	modalRefSave.value.close();
};

const saveAnnouncement = async (status) => {
	modalRefBack.value.close();
	modalRefPublic.value.close();
	if (isFilesPending.value) return;

	if (tgOnly.value || (userAD.value !== user.value?.user_id && userAD.value === 0)) {
		isTg.value = true;
	}
	let adStatus;
	if (editFlag.value) {
		if (
			parseInt(props.currentAD?.ad_status, 10) === STATUSES.PUBLIC &&
			status === STATUSES.NOT_PUBLIC
		) {
			adStatus = STATUSES.PUBLIC;
		} else {
			adStatus = status || props.currentAD?.ad_status;
		}
	} else {
		adStatus = status;
	}
	const isValid = !validate.value.$invalid;
	validate.value.$touch();
	if (isValid) {
		let res;
		if (userAD.value !== user.value?.user_id && !editFlag.value) {
			try {
				res = await userRef.value.saveData();
			} catch (e) {
				return;
			}
		}
		const dict = {
			category_id: selectCategories.value,
			sub_category_id: selectSubCategories.value || null,
			user_id: user.value?.user_id,
			item_city: selectCities.value.id,
			ad_name: nameAd.value,
			description: descriptions.value,
			ad_status: adStatus,
			currency_id: isPriceNotSpecified.value ? null : selectCurrencies.value.currency_id,
			price: isPriceNotSpecified.value ? 0 : price.value,
			is_phone: isPhone.value,
			is_tg: isTg.value,
			is_wa: isWa.value,
			is_email: isEmail.value,
			tittle_photo_name: files.value[0],
			item_id: currentId.value,
			publish_flag: adStatus === STATUSES.PUBLIC,
			tag_id: selectedTag.value?.tag_id || null,
		};
		if (userAD.value !== user.value?.user_id && props.currentAD?.user_id) {
			dict.user_id = props.currentAD?.user_id;
		}
		// если пользователь есть в БД, но мы при этом создаем от его имени
		if (
			userAD.value !== user.value?.user_id &&
			!props.currentAD?.user_id &&
			userADFromBd.value !== 0
		) {
			dict.user_id = userADFromBd.value;
			dict.is_phone = !!res.data.phone;
			dict.is_tg = !!res.data.username;
			dict.is_email = !!res.data.email;
			dict.created_by = user.value?.user_id;
		}
		// если пользователя нет в БД, но мы при этом создаем от его имени
		if (userAD.value !== user.value?.user_id && userAD.value === 0 && userADFromBd.value === 0) {
			dict.user_id = res.user_id;
			dict.is_phone = !!res.phone;
			dict.is_tg = !!res.username;
			dict.is_wa = !!res.whats_app_phone;
			dict.is_email = !!res.email;
			dict.created_by = user.value?.user_id;
		}
		modalRefSave.value.close();
		try {
			pendingAnnouncementStatus.value = status;
			if (!editFlag.value) {
				dict.initial_amount = price.value;
			} else if (editFlag.value && props.currentAD.currency_id !== dict.currency_id) {
				dict.initial_amount = price.value;
			}

			await store.dispatch("announcement/createAD", dict);
			toast.success(t("toastCreateSuccess"), { position: "top-right" });

			if (editFlag.value) {
				dict.item_city = selectCities.value.name;
				dict.tittle_photo = dict.tittle_photo_name;
				delete dict.tittle_photo_name;
				store.commit("announcement/UPDATE_AD", dict);
				store.commit("files/SET_FILES", {
					data: { files: files.value },
					entityId: currentId.value,
				});
				emit("endEdit");
				router.push({ name: "AnounceCardPage", params: { ad_id: currentId.value } });
			} else {
				isNew.value = false;
				router.push({
					name: "AnounceCardPage",
					params: { ad_id: currentId.value },
				});
			}

			// Аналитика
			if (!editFlag.value) {
				if (isAdmin.value) {
					yaMetrikaAnnounceCreateByAdmin();
				} else {
					yaMetrikaAnnounceCreateByUser();
				}
			}
		} catch (e) {
			console.error(e);
			toast.error(`Объявление не создано/не обновлено`, { position: "top-right" });
		}
		pendingAnnouncementStatus.value = null;
	}
};

const reducer = (city) => {
	return city.name;
};

const reducerCurrency = (currency) => {
	return currency.currency_code;
};

const updateUser = (value) => {
	userADFromBd.value = value.user_id;
};

// TODO Поменять чекбоксы
const clickCheckbox = (checkBoxParam) => {
	const [type, value] = checkBoxParam;
	if (type === "tg") {
		isTg.value = value;
	} else if (type === "phone") {
		isPhone.value = value;
	} else if (type === "price") {
		isPriceNotSpecified.value = value;
	} else if (type === "wa") {
		isWa.value = value;
	} else {
		isEmail.value = value;
	}
};

const isFilesPending = ref(false);

const filesArray = (filesLoad) => {
	isFilesPending.value = false;
	files.value = filesLoad.map((x) => x.url);
	emit("newFiles", files.value);
};

const pendingFilesArray = (pendingFiles) => {
	isFilesPending.value = true;
	const pendingFilesCount = pendingFiles.length;
	files.value = [...files.value, ...new Array(pendingFilesCount).fill(PENDING_KEY_PHOTO_PREVIEW)];
};

const deletePhoto = (photo) => {
	const [, url] = photo.split(`${currentId.value}/`);
	photoForDelete.value = url;
};

const openModalRemoveConfirm = () => {
	modalCategorySelector.value.open();
};

const subCategories = (categoryId) => {
	return store.getters["announcement/subCategories"](categoryId);
};

const categories = computed(() => {
	return store.getters["announcement/categories"];
});

const selected = computed(() => {
	const gr = t(GROUPS[selectedGroup.value]?.name);
	const cat = categories.value.find((x) => x.id === selectCategories.value).name;
	const subcat = subCategories(selectCategories.value).find(
		(x) => x.id === selectSubCategories.value
	)?.name;

	return subcat ? `${gr} / ${cat} / ${subcat}` : `${gr} / ${cat}`;
});

const closeModal = () => {
	modalCategorySelector.value.close();
};

const onBackSaveRedact = () => {
	modalRefBack.value.close();
	router.back();
};

const onBack = () => {
	const isDataUnchangedCreate = Object.keys(data.value)
		.filter((key) => key !== "item_id")
		.every((key) => {
			const value = data.value[key];
			return value === null;
		});

	if (!editFlag.value && !isDataUnchangedCreate) {
		modalRefBack.value.open();

		return;
	}

	if (
		editFlag.value &&
		Object.keys(data.value).some((key) => data.value[key] !== props.currentAD[key])
	) {
		modalRefBack.value.open();

		return;
	}

	if (editFlag.value) emit("endEdit");
	else {
		router.back();
	}
};

const onConfirmDelete = () => {
	modalRefDelete.value.close();
	emit("editStatus", STATUSES.DELETE);
};

const onBackDelete = () => {
	modalRefDelete.value.close();
};
const openModalDeleteConfirm = () => {
	modalRefDelete.value.open();
};

const publishAnnounceId = computed(() => {
	if (editFlag.value) {
		return "announce-update";
	}
	return "announce-create";
});

const publicAnnouncement = () => {
	const isValid = !validate.value.$invalid;
	validate.value.$touch();
	if (!isValid) {
		const errorOrder = [
			"selectCategories",
			"selectedTag",
			"nameAd",
			"selectCities",
			"selectCurrencies",
			"price",
			"flagContact",
		];
		const errorKeyOrder = validate.value.$errors.map(({ $property }) => $property);
		const firstErrorKey = errorOrder.find((errorKey) => errorKeyOrder.includes(errorKey));

		formElements.value[firstErrorKey]?.scrollToElement();

		if (userAD.value !== user.value?.user_id && firstErrorKey === "flagContact")
			modalRefPublic.value.open();
	} else {
		modalRefPublic.value.open();
	}
};

onMounted(() => {
	if (!editFlag.value) {
		openModalRemoveConfirm();
	}
});
</script>

<template>
	<div class="create-field">
		<button
			type="button"
			class="control-button control-button__back"
			@click="onBack"
			v-if="isDesktop"
		>
			{{ t("buttonTextCancel") }}
		</button>
		<div class="create-field__box">
			<div v-if="!isDesktop" class="create-field__control">
				<button type="button" class="control-button control-button__back" @click="onBack">
					{{ t("buttonTextCancel") }}
				</button>
				<button
					v-if="editFlag"
					type="button"
					class="control-button control-button__delete"
					@click="openModalDeleteConfirm"
				>
					<SvgTemplate :name="'trash'" class="svg-icon__delete" />
					<Typography v-if="!isDesktop" :type="TYPOGRAPHY_TYPES.DELETE_AD" class="red-text">
						{{ t("announcementCreateDelete") }}
					</Typography>
				</button>
			</div>
			<div class="category-container">
				<div class="category-container__header">
					<Typography :type="TYPOGRAPHY_TYPES.TITLE_1" class="header-margin">
						{{ editFlag ? t("categorySelectorTitle") : t("categoriesText") }}
					</Typography>
					<Typography v-if="editFlag" :type="TYPOGRAPHY_TYPES.INPUT" class="header-selected">
						{{ selected }}
					</Typography>
				</div>
				<InputField
					v-if="!editFlag"
					:ref="(el) => setFormElements('selectCategories', el)"
					class="selected__wrapper"
					:model-value="selectCategories ? selected : t('placeholderSelectCategory')"
					:silentDisabled="true"
					:errors="validate.selectCategories.$errors"
					@click="openModalRemoveConfirm"
				>
					<template #left>
						<div class="icon-wrapper">
							<SvgTemplate class="contact-info__icon contact-info__icon_phone" name="list-nav" />
						</div>
					</template>
					<template #rigth>
						<SvgTemplate class="contact-info__icon contact-info__icon_phone" name="highlighter" />
					</template>
				</InputField>
			</div>

			<div class="photo-container">
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_1" class="header-margin">
					{{ t("announcementCreateName") }}
				</Typography>
				<InputField
					:placeholder="t('announcementCreateNamePlaceholder')"
					v-model="nameAd"
					:mode="INPUT_MODE.CAPITALIZE"
					:max-length="70"
					:ref="(el) => setFormElements('nameAd', el)"
					:theme="INPUT_THEMES.LIGHT"
					:errors="validate.nameAd.$errors"
					@blur="validate.nameAd.$touch()"
					@focus="validate.nameAd.$reset()"
				></InputField>
			</div>

			<div class="description_container">
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_1" class="header-margin">
					{{ t("cardInfoBtnCollapse") }}
				</Typography>
				<TextArea
					class="description_container__text"
					v-model="descriptions"
					@modelValue:update="onInputCapitalize"
					:max="1000"
					:placeholder="t('descriptionPlaceholder')"
				/>
			</div>

			<div class="location-container">
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_1">{{ t("changeRegionModalTitle") }}</Typography>
				<Autocomplete
					:size="SIZES.NORMAL"
					:placeholder="t('filterMoCountry')"
					v-model="selectCountry"
					:items="countries"
					disable-input
					reset-disabled
					:mode="'local'"
					:reducer="reducer"
					:theme="INPUT_THEMES.LIGHT"
				/>
				<Autocomplete
					:size="SIZES.NORMAL"
					:placeholder="t('filterMoCity')"
					v-model="selectCities"
					:items="cities"
					:mode="'local'"
					:reducer="reducer"
					:ref="(el) => setFormElements('selectCities', el)"
					:empty-items-message="t('filterMoCountryFirst')"
					:theme="INPUT_THEMES.LIGHT"
					:errors="validate.selectCities.$errors"
					@blur="validate.selectCities.$touch()"
					@focus="validate.selectCities.$reset()"
				>
					<template #item="{ reducedItem, index }">
						<Typography :type="index > 2 ? TYPOGRAPHY_TYPES.INPUT : TYPOGRAPHY_TYPES.INPUT_B">
							{{ reducedItem }}
						</Typography>
					</template>
				</Autocomplete>
			</div>

			<div class="photo-container">
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_1" :class="{ 'header-contact': !isDesktop }">
					{{ t("photoTitle") }}
				</Typography>
				<Typography :type="TYPOGRAPHY_TYPES.TEXT" class="photo-container__subtitle">
					{{ t("announcementCreatePhotoText") }}
				</Typography>
				<div class="photo-container__upload-block">
					<FileLoader
						:entityId="currentId"
						:apiDeleteFile="apiDeleteADPhoto"
						:apiGetFiles="apiGetADPhoto"
						:apiPostFiles="apiPostADPhoto"
						:photoForDelete="photoForDelete"
						@NewFiles="filesArray"
						@pending="pendingFilesArray"
					>
						<template #button>
							<button class="photo-container__upload-btn" type="button">
								<SvgTemplate class="btn_icon" name="camera" />
							</button>
						</template>
					</FileLoader>
					<PhotoPreview
						:items="files"
						:currentId="currentId"
						:key="isNew"
						@deletePhoto="deletePhoto"
					/>
				</div>
			</div>

			<div class="currency-block">
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_1">
					{{ t("announcementCreatePriceTitle") }}
				</Typography>
				<div class="currency-block__wrapper">
					<Autocomplete
						:size="SIZES.NORMAL"
						v-model="selectCurrencies"
						:items="currencies"
						:placeholder="t('filterMoCurrency')"
						:disableInput="true"
						:ref="(el) => setFormElements('selectCurrencies', el)"
						:disabled="isPriceNotSpecified"
						:mode="AUTOCOMPLETE_MODES.LOCAL"
						:reducer="reducerCurrency"
						:theme="INPUT_THEMES.LIGHT"
						:errors="validate.selectCurrencies.$errors"
						:errorZeroHeight="true"
						@blur="validate.selectCurrencies.$touch()"
						@focus="validate.selectCurrencies.$reset()"
						reset-disabled
					/>
					<InputField
						class="currency-block__price"
						:placeholder="t('announcementCreatePriceTitle')"
						v-model="price"
						type="tel"
						:mode="INPUT_MODE.FORMATED_FLOAT_NUMBER"
						:theme="INPUT_THEMES.LIGHT"
						:ref="(el) => setFormElements('price', el)"
						:disabled="isPriceNotSpecified"
						:errors="validate.price.$errors"
						:errorZeroHeight="true"
						@blur="validate.price.$touch()"
						@focus="validate.price.$reset()"
					></InputField>
				</div>
			</div>
			<div class="without-price">
				<Checkbox
					:checkBoxParamName="'price'"
					:is-checked-default="isPriceNotSpecified"
					@toggleCheckbox="clickCheckbox"
				/>
				<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">
					{{ t("announcementCreateWithoutPrice") }}
				</Typography>
			</div>

			<div>
				<div v-if="isAdmin && !editFlag" class="user-block">
					<Typography :type="TYPOGRAPHY_TYPES.TITLE_1">
						{{ t("announcementCreateUserText") }}
					</Typography>
					<div class="user_info__radio-select-cont">
						<div class="user_info__radio-select">
							<input
								id="personalAnnouncement"
								type="radio"
								:value="user.user_id"
								v-model="userAD"
								checked
								class="user_info__radio-select-circle"
							/>
							<label for="personalAnnouncement">
								<Typography :type="TYPOGRAPHY_TYPES.TITLE_1">
									{{ t("announcementCreateUserPush") }}
								</Typography>
							</label>
						</div>
						<div class="user_info__radio-select">
							<input
								id="fromAnotherUser"
								type="radio"
								:value="currentAD?.user_id || 0"
								v-model="userAD"
								class="user_info__radio-select-circle"
							/>
							<label for="fromAnotherUser">
								<Typography :type="TYPOGRAPHY_TYPES.TITLE_1">
									{{ t("announcementCreateUserAnotherUser") }}
								</Typography>
							</label>
						</div>
					</div>
					<UserBlock
						ref="userRef"
						v-if="userAD !== user.user_id"
						:userADId="currentAD?.user_id || 0"
						@updateUser="updateUser"
					></UserBlock>
				</div>

				<div v-if="userAD === user.user_id || editFlag">
					<Typography :type="TYPOGRAPHY_TYPES.TITLE_1" class="header-contact">
						{{ t("announcementCreateContactText") }}?
					</Typography>
					<Typography :type="TYPOGRAPHY_TYPES.TEXT" class="photo-container__subtitle">
						{{
							userAD === user.user_id
								? t("announcementCreateContactWarningText1")
								: t("announcementCreateContactWarningText2")
						}}
					</Typography>
				</div>
				<div class="contacts" v-if="userAD === user.user_id || (isAdmin && editFlag)">
					<div v-if="editFlag ? currentAD?.phone : user.phone" class="one-contact">
						<Checkbox
							:checkBoxParamName="'phone'"
							:is-checked-default="isPhone"
							@toggleCheckbox="clickCheckbox"
						/>
						<div class="contact-info">
							<SvgTemplate class="contact-info__icon contact-info__icon_phone" name="user-phone" />
							<div class="contact-info__description">
								<Typography
									class="contact-info__subtitle"
									:type="TYPOGRAPHY_TYPES.CONTACT_SUBTITLE"
								>
									{{ t("announcementCreateContactPhone") }}
								</Typography>
								<Typography :type="TYPOGRAPHY_TYPES.TITLE_1">
									{{ toFormatPhone(editFlag ? currentAD.phone : user.phone) }}
								</Typography>
							</div>
						</div>
					</div>
					<div
						v-if="editFlag ? currentAD.whats_app_phone : user.whats_app_phone"
						class="one-contact"
					>
						<Checkbox
							:checkBoxParamName="'wa'"
							:is-checked-default="isWa"
							@toggleCheckbox="clickCheckbox"
						/>
						<div class="contact-info">
							<SvgTemplate class="contact-info__icon contact-info__icon_whatsapp" name="whatsapp" />
							<div class="contact-info__description">
								<Typography
									class="contact-info__subtitle"
									:type="TYPOGRAPHY_TYPES.CONTACT_SUBTITLE"
								>
									WhatsApp:
								</Typography>
								<Typography :type="TYPOGRAPHY_TYPES.TITLE_1">
									{{ toFormatPhone(editFlag ? currentAD.whats_app_phone : user.whats_app_phone) }}
								</Typography>
							</div>
						</div>
					</div>
					<div v-if="editFlag ? currentAD.username : user.username" class="one-contact">
						<Checkbox
							:checkBoxParamName="'tg'"
							:is-checked-default="isTg"
							@toggleCheckbox="clickCheckbox"
						/>
						<div class="contact-info">
							<SvgTemplate class="contact-info__icon contact-info__icon_tg" name="tg" />
							<div class="contact-info__description">
								<Typography
									class="contact-info__subtitle"
									:type="TYPOGRAPHY_TYPES.CONTACT_SUBTITLE"
								>
									Telegram:
								</Typography>
								<Typography :type="TYPOGRAPHY_TYPES.TITLE_1">
									@{{ editFlag ? currentAD.username : user.username }}
								</Typography>
							</div>
						</div>
					</div>
					<div v-if="editFlag ? currentAD.email : user.email" class="one-contact">
						<Checkbox
							:checkBoxParamName="'email'"
							:is-checked-default="isEmail"
							@toggleCheckbox="clickCheckbox"
						/>
						<div class="contact-info">
							<SvgTemplate class="contact-info__icon contact-info__icon_mail" name="user-email" />
							<div class="contact-info__description">
								<Typography
									class="contact-info__subtitle"
									:type="TYPOGRAPHY_TYPES.CONTACT_SUBTITLE"
								>
									E-mail:
								</Typography>
								<Typography :type="TYPOGRAPHY_TYPES.TITLE_1">
									{{ editFlag ? currentAD.email : user.email }}
								</Typography>
							</div>
						</div>
					</div>
					<Typography
						:type="TYPOGRAPHY_TYPES.DELETE_AD"
						class="red-text"
						v-if="!isEmpty(validate.flagContact.$errors)"
					>
						{{ t("announcementCreateContactError") }}
					</Typography>
				</div>
			</div>
		</div>
		<button
			v-if="editFlag && isDesktop"
			type="button"
			class="control-button control-button__delete"
			@click="openModalDeleteConfirm"
		>
			<SvgTemplate :name="'trash'" class="svg-icon__delete" />
			<Typography v-if="!isDesktop" :type="TYPOGRAPHY_TYPES.DELETE_AD" class="red-text">
				{{ t("AnnouncementCreateDelete") }}
			</Typography>
		</button>
	</div>
	<div class="btn-container">
		<Button
			:disabled="isFilesPending"
			class="btn"
			:theme="THEMES.TEXT"
			:size="SIZES.BIG"
			:pending="pendingAnnouncementStatus === STATUSES.NOT_PUBLIC"
			@click.stop="saveNoPublic"
			pill
		>
			<Typography class="gray-text" :type="TYPOGRAPHY_TYPES.TITLE_3">
				{{ t("buttonTextSave") }}
			</Typography>
		</Button>
		<Button
			:id="publishAnnounceId"
			:disabled="isFilesPending"
			class="btn"
			:theme="THEMES.CONTAINED"
			:size="SIZES.BIG"
			:pending="pendingAnnouncementStatus === STATUSES.PUBLIC"
			@click.stop="publicAnnouncement"
		>
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">{{ t("buttonTextPublish") }}</Typography>
		</Button>
	</div>

	<ModalConfirm
		ref="modalRefSave"
		@confirm="saveAnnouncement(STATUSES.NOT_PUBLIC)"
		@back="onBackSaveArchive"
		back-text="buttonTextCancel"
		confirm-text="buttonTextSave"
		:hide-cross="true"
	>
		<div class="modal-content__confirm">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="announcement-card__main">
				{{ t("announcementCreateMoSaveArchive") }}
			</Typography>
		</div>
	</ModalConfirm>
	<ModalConfirm
		ref="modalRefPublic"
		@confirm="saveAnnouncement(STATUSES.PUBLIC)"
		@back="modalRefPublic.close()"
		back-text="buttonTextCancel"
		confirm-text="buttonTextPublish"
	>
		<div class="modal-content__confirm">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="announcement-card__main">
				{{ t("announcementCreateMoPublish") }}
			</Typography>
		</div>
	</ModalConfirm>
	<ModalConfirm
		ref="modalRefBack"
		@confirm="saveAnnouncement(STATUSES.NOT_PUBLIC)"
		@back="onBackSaveRedact"
		back-text="buttonTextDontSave"
		confirm-text="buttonTextSave"
		:with-cross="true"
		:allow-self-close="true"
	>
		<div class="modal-content__confirm">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="announcement-card__main">
				{{ t("announcementCreateMoOutputUnchanged") }}
			</Typography>
		</div>
	</ModalConfirm>
	<Modal
		ref="modalCategorySelector"
		back-text="buttonTextCancel"
		confirm-text="buttonTextBlock"
		:hide-cross="true"
	>
		<div class="ad-list__template">
			<CategorySelector
				v-model:group="selectedGroup"
				v-model:category="selectCategories"
				v-model:subcategory="selectSubCategories"
				:isCreate="false"
				@confirm="closeModal"
			/>
		</div>
	</Modal>
	<ModalConfirm
		ref="modalRefDelete"
		@confirm="onConfirmDelete"
		@back="onBackDelete"
		back-text="buttonTextCancel"
		confirm-text="buttonTextDelete"
		:hide-cross="true"
	>
		<div class="modal-content__confirm">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="announcement-card__main">
				{{ t("announcementCreateMoDelete") }}
			</Typography>
		</div>
	</ModalConfirm>
	<ProfilePendingEdit />
</template>

<style lang="scss" scoped>
@import "./AnnouncementCreate.scss";
</style>
