<!-- eslint-disable no-unused-vars -->
<script setup>
import { clamp } from "lodash";
import { computed } from "vue";

const props = defineProps({
	noAnimation: {
		type: Boolean,
		default: false,
	},
	progress: {
		type: Number,
		default: 100,
	},
});

const BLADES_COUNT = 8;

const array = new Array(BLADES_COUNT).fill(null).map((_, idx) => ({ idx }));

const blades = computed(() => {
	if (props.noAnimation) {
		const x = 100 / BLADES_COUNT;

		return array.map(({ idx }) => {
			const opacity = clamp((props.progress - idx * x) / x, 0, 1);
			return { idx, opacity };
		});
	}
	return array;
});
</script>

<template>
	<div class="ios-spinner" :class="{ 'ios-spinner_no-animation': noAnimation }">
		<div
			class="ios-spinner__blade"
			v-for="blade in blades"
			:key="blade.idx"
			:style="{ opacity: blade.opacity }"
		></div>
	</div>
</template>

<style lang="scss" scoped>
@import "./IosSpinner.scss";
</style>
