<!-- eslint-disable no-unused-vars -->
<script setup>
import { computed, ref, watch, nextTick, onMounted } from "vue";
import { useStore } from "vuex";
import { DateTime } from "luxon";
import { SvgTemplate, Spinner } from "@shared/ui";
import { CHAT_EVENTS, CHAT_PROTOCOL } from "@shared/const";
import { useElementBounding, useScroll } from "@vueuse/core";
import { throttle, trim } from "lodash";
import { useScreenSafeArea, useScreenSize, withPx } from "@shared/lib";
import { useTranslation } from "i18next-vue";

import { ChatMessage } from "../ChatMessage";
import { ChatHeader } from "../ChatHeader";
import { ChatTextArea } from "../ChatTextArea";
import { ChatSuggestionChooseLocale } from "../ChatSuggestionChooseLocale";
import { ChatUnseenCounter } from "../ChatUnseenCounter";

const store = useStore();
const { t } = useTranslation();
const emit = defineEmits(["back"]);

const message = ref("");
const timer = ref("");
const messagesBlock = ref(null);

const showSpinner = ref(true);

const props = defineProps({
	chatId: {
		type: Number,
		default: 0,
	},
});

const { isDesktop } = useScreenSize();

const messages = computed(() => {
	return store.getters["chat/chatMessages"](props.chatId);
});

const chat = computed(() => {
	return store.getters["chat/getChat"](props.chatId);
});

const userData = computed(() => {
	return store.getters["user/getCurrentUser"];
});

watch(
	chat,
	(value) =>
		value && !value.messages
			? store.dispatch("chat/loadChatMessages", { chatId: props.chatId })
			: null,
	{ immediate: true }
);

watch(
	messages,
	(value) => {
		if (value) {
			showSpinner.value = false;
			nextTick(() => {
				goDown();
			});
		}
	},
	{ immediate: true }
);

const sendMessage = () => {
	const trimmedMessageValue = trim(message.value);
	if (!trimmedMessageValue) return;
	const content = CHAT_PROTOCOL.MESSAGES.SEND_MESSAGE(
		trimmedMessageValue,
		chat.value.subject,
		props.chatId
	);
	store.commit("chat/SET_MESSAGE", {
		message: {
			text: content.text,
			extKey: content.extKey,
			sender: userData.value?.user_id,
			timestamp: DateTime.now().toSQL({
				includeOffset: false,
				includeOffsetSpace: false,
				includeZone: false,
			}),
			delivered: false,
			viewed: false,
			error: false,
		},
		chatId: props.chatId,
	});
	store.dispatch("chat/emitEvent", {
		event: CHAT_EVENTS.MESSAGE,
		content,
	});
	nextTick(() => {
		message.value = trim("");
		goDown();
	});
};

const sendTyping = () => {
	store.dispatch("chat/emitEvent", {
		event: CHAT_EVENTS.ACTION,
		content: CHAT_PROTOCOL.ACTIONS.USER_TYPING(props.chatId),
	});
	clearTimeout(timer.value);
	timer.value = setTimeout(() => {
		store.dispatch("chat/emitEvent", {
			event: CHAT_EVENTS.ACTION,
			content: CHAT_PROTOCOL.ACTIONS.USER_STOP_TYPING(props.chatId),
		});
	}, 2500);
};

const sendTypingDebounced = throttle(sendTyping, 2000, {
	leading: true,
	trailing: false,
});

const { y: messageBlockY, arrivedState: arrivedStateMessageBlock } = useScroll(messagesBlock, {
	offset: { bottom: 200, top: -1000 },
});

const { arrivedState: arrivedStateBody } = useScroll(window, {
	offset: { bottom: 200, top: -1000 },
});

const { arrivedState: arrivedStateBodyWithoutOffset } = useScroll(window, {});

const isShowIndVisible = computed(() => {
	if (isDesktop.value) {
		return !arrivedStateMessageBlock.bottom && !arrivedStateMessageBlock.top;
	}

	return !arrivedStateBody.bottom && !arrivedStateBody.top;
});

const goDown = () => {
	// ФИКС БАГА С КНОПКОЙ GO DOWN
	messageBlockY.value = 1;
	nextTick(() => {
		if (isDesktop.value) {
			messageBlockY.value = messagesBlock.value?.scrollHeight;
		} else {
			scrollTo({ top: document.body.scrollHeight });
		}
	});
};

watch(
	() => props.chatId,
	() => {
		goDown();
	},
	{ immediate: true }
);

const chatTextAreaRef = ref(null);

const chatTextAreaRefElement = computed(() => {
	return chatTextAreaRef.value?.textAreaRef;
});

const { height: textAreaHeight } = useElementBounding(chatTextAreaRefElement);

const getChatUnseenCounter = computed(() => {
	return store.getters["chat/getChatUnseenCounter"](props.chatId);
});

const { bottomNum } = useScreenSafeArea();

const safeAreaBottom = computed(() => {
	if (arrivedStateBodyWithoutOffset.bottom && arrivedStateBodyWithoutOffset.top) {
		return 0;
	}
	return bottomNum.value;
});
</script>

<template>
	<div class="chat">
		<ChatHeader :chat="chat" />
		<div
			v-if="!isDesktop"
			class="chat__messages"
			:style="{ paddingBottom: withPx(textAreaHeight + safeAreaBottom) }"
		>
			<Spinner class="chat__spinner" v-if="showSpinner" />
			<div class="chat__messages-container">
				<ChatMessage v-for="message in messages" :key="message.id" :message="message" />
			</div>
		</div>
		<div v-else class="chat__messages-desktop" ref="messagesBlock">
			<div class="chat__messages-container-desktop">
				<div class="chat__messages-wrapper-desktop">
					<ChatMessage v-for="message in messages" :key="message.id" :message="message" />
				</div>
			</div>
		</div>
		<ChatTextArea
			:placeholder="t('placeholderWriteMessage')"
			v-model="message"
			ref="chatTextAreaRef"
			@typing="sendTypingDebounced"
			@send="sendMessage()"
		>
			<div class="chat__down-btn-wrapper">
				<ChatSuggestionChooseLocale class="chat__suggestion" />
				<div class="chat__down-btn" v-if="isShowIndVisible || getChatUnseenCounter" @click="goDown">
					<SvgTemplate name="chevron-old" />
					<ChatUnseenCounter class="chat__unseen-count" :count="getChatUnseenCounter" />
				</div>
			</div>
		</ChatTextArea>
	</div>
</template>

<style lang="scss" scoped>
@import "./Chat.scss";
</style>
