<script setup>
import { ref } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { THEME_VARIANTS } from "@shared/const";
import { Modal, useModal, Typography, TYPOGRAPHY_TYPES, Button } from "@shared/ui";
import { useToast } from "vue-toast-notification";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	message: { type: String, default: "Другое" },
});

const modalRef = ref(null);

const router = useRouter();

const store = useStore();

const toast = useToast();

const { t } = useTranslation();

const modal = useModal(modalRef);

defineExpose(modal);

const onReject = () => {
	router.push({ name: "Profile" });
};

const isButtonPending = ref(false);

const onConfirm = async () => {
	isButtonPending.value = true;
	try {
		await store.dispatch("user/deleteProfile", props.message);
		toast.success("Заявка на удаление успешно создана", { position: "top-right" });
		router.push({ name: "Profile" });
	} catch (error) {
		console.error(error);
		toast.error("Что-то пошло не так", { position: "top-right" });
	}
	isButtonPending.value = false;
};
</script>
<template>
	<Modal ref="modalRef">
		<section class="modal-delete-caution">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
				{{ t("deactivateAccountDeleteAccount") }}
			</Typography>
			<Typography :type="TYPOGRAPHY_TYPES.TEXT_1">
				{{ t("deactivateAccountDeleteWarning") }}
			</Typography>
			<Typography :type="TYPOGRAPHY_TYPES.CAPTION_2" class="modal-delete-caution_decsription">
				{{ t("deactivateAccountDeleteProcessingTime") }}
			</Typography>
			<div class="modal-delete-caution__buttons">
				<Button @click="onReject">
					<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">
						{{ t("deleteCautionModalChangedYourMindAbout") }}
					</Typography>
				</Button>
				<Button :variant="THEME_VARIANTS.LIGHT" @click="onConfirm" :pending="isButtonPending">
					<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">
						{{ t("deleteCautionModalDeleteAnyway") }}
					</Typography>
				</Button>
			</div>
		</section>
	</Modal>
</template>

<style lang="scss" scoped>
@import "./DeleteCautionModal.scss";
</style>
