export default {
	ru: {
		reviewsTitle: "Отзывы",
		reviewsBtnLeaveFeedback: "Оставить отзыв",
		noReviewsYet: "У этого объявления еще нет отзывов",
		reviewsreviewsSubbtn: "Чтобы оставить свой отзыв, нужно авторизоваться",
		reviewsEmpty: "У этого объявления пока нет отзывов",
		reviewsDelete: "Удалить отзыв",
		reviewsMoBtnConfirm: "Отправить",
		reviewsConfirmation: "Хотите удалить отзыв?",
		reviewsSubConfirmation: "Мы не сможем его восстановить",
		createReviewsGradeText1: "Вы можете оставить только оценку, остальные поля заполнять",
		createReviewsGradeText2: "не обязательно",
		photoTitle: "Фотографии",
		createReviewsPhotoText: "Добавьте не больше 5 фотографий",
		createReviewsImpression: "Ваши впечатления",
		descriptionPlaceholder: "Введите описание",
		reviewsToastSuccess: "Отзыв успешно отправлен",
		reviewsToastError: "Не удалось отправить отзыв",
		reviewsCountText1: "отзыв",
		reviewsCountText2: "отзыва",
		reviewsCountText3: "отзывов",
	},
	en: {
		reviewsTitle: "Reviews",
		reviewsBtnLeaveFeedback: "Leave a review",
		noReviewsYet: "This ad has no reviews yet",
		reviewsreviewsSubbtn: "To leave your review, you need to log in",
		reviewsEmpty: "This ad has no reviews yet",
		reviewsDelete: "Delete review",
		reviewsMoBtnConfirm: "Submit",
		reviewsConfirmation: "Do you want to delete your review?",
		reviewsSubConfirmation: "We will not be able to restore it",
		createReviewsGradeText1: "You can only leave a rating, the rest of the fields",
		createReviewsGradeText2: "are not required",
		photoTitle: "Photos",
		createReviewsPhotoText: "Add no more than 5 photos",
		createReviewsImpression: "Your impressions",
		descriptionPlaceholder: "Enter a description",
		reviewsToastSuccess: "Review successfully submitted",
		reviewsToastError: "Failed to submit review",
		reviewsCountText1: "review",
		reviewsCountText2: "reviews",
		reviewsCountText3: "reviews",
	},
	ka: {
		reviewsTitle: "გამოხმაურებები",
		reviewsBtnLeaveFeedback: "გამოხმაურების დატოვება",
		noReviewsYet: "ამ განცხადებას ჯერ არააქვს გამოხმაურება",
		reviewsreviewsSubbtn: "რომ განათავსოთ თქვენი შეფასება ჯერ უნდა დარეგისტრირდეთ",
		reviewsEmpty: "ამ განცხადებას ჯერ არააქვს გამოხმაურება",
		reviewsDelete: "გამოხმაურების წაშლა",
		reviewsMoBtnConfirm: "გაგზავნა",
		reviewsConfirmation: "გნებავთ გამოხმაურების წაშლა?",
		reviewsSubConfirmation: "ჩვენ ვერ შევძლებთ მის აღდგენას",
		createReviewsGradeText1: "თქვენ შეგიძლიათ დატოვოთ შეფასება დანარჩენ ველებს შეავსებენ",
		createReviewsGradeText2: "არ არის სავალდებულო",
		photoTitle: "სურათები",
		createReviewsPhotoText: "დაამატეთ არა უმეტეს 5 ფოტო",
		createReviewsImpression: "თქვენი შთაბეჭდილებები?",
		descriptionPlaceholder: "შეიყვანეთ აღწერა",
		reviewsToastSuccess: "გამოხმაურება წარმატებით გაიგზავნა",
		reviewsToastError: "ვერ მოხერხდა გამოხმაურების გაგზავნა",
		reviewsCountText1: "გამოხმაურება",
		reviewsCountText2: "გამოხმაურება",
		reviewsCountText3: "გამოხმაურებები",
	},
	tr: {
		reviewsTitle: "Yorumlar",
		reviewsBtnLeaveFeedback: "Yorum yap",
		noReviewsYet: "Bu ilanın henüz bir yorumu yoktur",
		reviewsreviewsSubbtn: "Yorum yapmak için giriş yapmanız gerekmektedir",
		reviewsEmpty: "Bu ilanın henüz bir yorumu yoktur",
		reviewsDelete: "Yorumu sil",
		reviewsMoBtnConfirm: "Gönder",
		reviewsConfirmation: "Yorumunuzu silmek istiyor musunuz?",
		reviewsSubConfirmation: "Geri yükleyemeyeceğiz",
		createReviewsGradeText1: "Sadece puan bırakabilirsiniz,",
		createReviewsGradeText2: "diğer alanlar zorunlu değildir",
		photoTitle: "Fotoğraflar",
		createReviewsPhotoText: "En fazla 5 fotoğraf ekleyin",
		createReviewsImpression: "İzlenimleriniz",
		descriptionPlaceholder: "Bir açıklama girin",
		reviewsToastSuccess: "Yorum başarıyla gönderildi",
		reviewsToastError: "İnceleme gönderilemedi",
		reviewsCountText1: "Yorum",
		reviewsCountText2: "Yorumlar",
		reviewsCountText3: "Yorumlar",
	},
};
