<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { SvgTemplate } from "@shared/ui";
import { useStorage } from "@vueuse/core";
import { useTranslation } from "i18next-vue";

const store = useStore();
const value = useStorage("suggestion-locale", true);
const { t } = useTranslation();

const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const isUserLoggedIn = computed(() => {
	return store.getters["user/isUserLoggedIn"];
});

const isVisible = computed(() => {
	return value.value && isUserLoggedIn.value && !user.value?.language_id;
});

const close = () => {
	value.value = false;
};
</script>

<template>
	<Transition name="fade">
		<div class="suggestion-locale" v-if="isVisible">
			<div class="suggestion-locale__left">
				<RouterLink :to="{ name: 'ProfileEdit' }">
					<p class="suggestion-locale__title">{{ t("chatSuggestionChooseLocaleText1") }}</p>
					<p class="suggestion-locale__text">
						{{ t("chatSuggestionChooseLocaleText2") }}
					</p>
				</RouterLink>
			</div>
			<div @click="close" class="suggestion-locale__close">
				<SvgTemplate name="close" />
			</div>
		</div>
	</Transition>
</template>

<style lang="scss" scoped>
@import "./ChatSuggestionChooseLocale.scss";
</style>
