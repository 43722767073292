<script setup>
import { reactive, computed, watch, ref, nextTick } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import {
	InputField,
	Autocomplete,
	Checkbox,
	TYPOGRAPHY_TYPES,
	Typography,
	SvgTemplate,
	useSuspense,
	Image,
	ModalConfirm,
	INPUT_MODE,
	Button,
	usePreloader,
	TextArea,
} from "@shared/ui";
import { FileLoader } from "@entities/files";
import {
	SIZES,
	BUCKET_URL,
	BUCKET_PREFIX_MEDIUM,
	THEME_VARIANTS,
	REQUST_STATUSES,
	COUNTRIES,
	NOT_IMAGES,
} from "@shared/const";
import { apiPostUserPhoto, apiGetUserPhoto, apiDeleteUserPhoto } from "@entities/user";
import { useVuelidate } from "@vuelidate/core";
import { isTrue, required, requiredIf, email, useScreenSize } from "@shared/lib";
import { Phone } from "@features/Phone";
import { useTranslation } from "i18next-vue";

const data = reactive({
	firstName: null,
	secondName: null,
	country: null,
	region: null,
	username: null,
	whats_app_phone: null,
	whats_app_phone_ccc: null,
	wa_iso_code: null,
	whatsAppPhoneLikePhone: false,
	tgAppPhoneLikePhone: true,
	phone: null,
	phone_ccc: null,
	phone_iso_code: null,
	email: null,
	photo: null,
});

const store = useStore();
const router = useRouter();
const { t } = useTranslation();

const isPhoneValid = ref(false);
const isWhatsAppPhoneValid = ref(false);

const isPhoneEmptyOrValid = computed(() => {
	return !data.phone || isPhoneValid.value;
});

const isWhatsAppPhoneEmptyOrValid = computed(() => {
	return !data.whats_app_phone || isWhatsAppPhoneValid.value;
});

// const needToBeeSelected = () =>
// 	!(data.is_tg_hide && data.is_email_hide && data.is_phone_hide && data.is_wa_hide);

const validators = {
	firstName: { required },
	region: { required },
	country: { requiredIf: requiredIf(!data.region) },
	username: {
		requiredIf: requiredIf(
			() => !data.phone && !data.whats_app_phone && !data.email,
			t("editProfileValidateContact")
		),
		// needToBeeSelected: helpers.withMessage(
		// 	"Разрешите использовать хотя бы один способ связи",
		// 	needToBeeSelected
		// ),
	},
	phone: {
		requiredIf: requiredIf(
			() => !data.username && !data.whats_app_phone && !data.email,
			t("editProfileValidateContact")
		),
		// TODO Написать нормальный Валидатор
		// Проверяем если есть значение телефона и если есть то проверяем валиден ли он
		isTrue: isTrue(isPhoneEmptyOrValid, t("editProfileValidateInvalidFormat")),
		// needToBeeSelected: helpers.withMessage(
		// 	"Разрешите использовать хотя бы один способ связи",
		// 	needToBeeSelected
		// ),
	},
	whats_app_phone: {
		requiredIf: requiredIf(
			() => !data.phone && !data.username && !data.email,
			t("editProfileValidateContact")
		),
		// TODO Написать нормальный Валидатор
		// Проверяем если есть значение телефона и если есть то проверяем валиден ли он
		isTrue: isTrue(isWhatsAppPhoneEmptyOrValid, t("editProfileValidateInvalidFormat")),
		// needToBeeSelected: helpers.withMessage(
		// 	"Разрешите использовать хотя бы один способ связи",
		// 	needToBeeSelected
		// ),
	},
	email: {
		requiredIf: requiredIf(
			() => !data.phone && !data.username && !data.whats_app_phone,
			t("editProfileValidateContact")
		),
		email,
		// needToBeeSelected: helpers.withMessage(
		// 	"Разрешите использовать хотя бы один способ связи",
		// 	needToBeeSelected
		// ),
	},
};

const validate = useVuelidate(validators, data);

const modalRef = ref(null);

const { isSuspense } = useSuspense(true);

const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const userStatus = computed(() => {
	return store.getters["user/currentUserStatus"];
});

const regionsStatus = computed(() => {
	return store.getters["announcement/regionsStatus"];
});

const { watchByStatuses } = usePreloader();

watchByStatuses([userStatus, regionsStatus]);

const regions = computed(() => {
	return store.getters["announcement/citiesByCountryId"](
		data.country?.id || data.country?.value?.id
	);
});

const countries = computed(() => {
	return store.getters["announcement/countries"]({
		exclude: [COUNTRIES.ALL.id, COUNTRIES.RUSSIA.id],
	});
});

const languages = computed(() => store.getters["user/languages"]);

const entity = computed(() => {
	return `user/${user.value.user_id}`;
});

const countryByCityId = computed(() => {
	return store.getters["announcement/countryByCityId"](+user.value.region_id);
});

const cityById = computed(() => {
	const cityData = store.getters["announcement/cityById"](+user.value.region_id);
	return cityData.id === 0 ? null : cityData;
});

const languageByCode = computed(() => {
	const languageData = store.getters["user/languageByCode"](user.value.language_id);
	return languageData;
});
const photoSrc = computed(() => {
	if (data.photo) {
		return `${BUCKET_URL}${BUCKET_PREFIX_MEDIUM}/${entity.value}/${data.photo}`;
	}
	return NOT_IMAGES.ANONYM;
});

const unwatch = watch(
	[userStatus, regionsStatus],
	() => {
		if (
			userStatus.value === REQUST_STATUSES.SUCCESS &&
			regionsStatus.value === REQUST_STATUSES.SUCCESS
		) {
			data.firstName = user.value.first_name;
			data.secondName = user.value.second_name;
			data.region = cityById.value;
			data.language = languageByCode.value;
			data.username = user.value.username;
			data.email = user.value.email;
			data.phone = user.value.phone;
			data.phone_iso_code = user.value.phone_iso_code;
			data.phone_ccc = user.value.phone_ccc;
			data.whats_app_phone = user.value.whats_app_phone;
			data.wa_iso_code = user.value.wa_iso_code;
			data.country = store.getters["announcement/currentCountry"];
			data.description = user.value.description;
			if (user.value.photo) data.photo = user.value.photo;
			nextTick(() => {
				unwatch();
			});
		}
	},
	{ immediate: true }
);

// При выборе страны, проверяем нужно ли отчистить значение города
watch(
	() => data.country,
	() => {
		nextTick(() => {
			const countryId = data?.country?.id;
			const cities = store.getters["announcement/citiesByCountryId"](countryId);
			const currentCityId = data.region?.id;
			const needToClearCity = !cities.find((city) => +city.id === +currentCityId);
			if (needToClearCity) {
				data.region = null;
			}
		});
	}
);

// TODO зарефакторить(Это ....)
const clickCheckbox = (checkBoxParam) => {
	const [type, value] = checkBoxParam;
	if (type === "tg") {
		data.tgAppPhoneLikePhone = !value;
	} else if (type === "whatsAppPhoneLikePhone") {
		data.whatsAppPhoneLikePhone = value;
		if (data.whatsAppPhoneLikePhone) {
			data.whats_app_phone = data.phone;
			data.wa_iso_code = data.phone_iso_code;
		}
	}
};

watch(
	() => data.phone,
	() => {
		if (data.whatsAppPhoneLikePhone) {
			data.whats_app_phone = data.phone;
			data.wa_iso_code = data.phone_iso_code;
		}
	}
);

const setAvatar = (filesLoad) => {
	data.photo = filesLoad.at(-1)?.url;
};

const back = () => {
	router.push({ name: "Profile" });
};

const isPending = ref(false);

const save = async () => {
	validate.value.$touch();

	const isValid = !validate.value.$invalid;
	if (isValid) {
		isPending.value = true;
		const userData = {
			user_id: user.value.user_id,
			first_name: data.firstName,
			second_name: data.secondName,
			region_id: data.region.id,
			// tgAppPhoneLikePhone: data.tgAppPhoneLikePhone,
			is_email_hide: data.is_email_hide,
			username: data.username,
			phone: data.phone,
			phone_ccc: data.phone_ccc,
			phone_iso_code: data.phone_iso_code,
			whats_app_phone: data.whats_app_phone,
			wa_iso_code: data.wa_iso_code,
			email: data.email,
			description: data.description,
			photo: data.photo || null,
			language_id: data.language?.language_code,
		};
		const exception = ["description", "photo", "phone", "whats_app_phone", "email", "username"];
		const filteredUserData = Object.entries(userData).reduce((acc, [k, v]) => {
			if (exception.includes(k)) {
				return { ...acc, [k]: v || "" };
			}
			return v ? { ...acc, [k]: v } : acc;
		}, {});
		await store.dispatch("user/updateCurrentUser", filteredUserData);
		isPending.value = false;
		back();
	}
};

const denay = async () => {
	const userData = {
		first_name: data.firstName === user.value.first_name,
		second_name: data.secondName === user.value.second_name,
		region_id: data.region?.id === (user.value?.region_id || undefined),
		username: data.username === user.value.username,
		phone: data.phone === user.value.phone || "",
		phone_ccc: data.phone_ccc === user.value.phone_ccc,
		whats_app_phone: data.whats_app_phone === (user.value.whats_app_phone || ""),
		email: data.email === user.value.email || "",
		description: data.description === user.value.description,
		photo: (data?.photo || null) === (user.value?.photo || null),
		country: (data.country?.id || null) === (countryByCityId.value.id || user.value.country_id),
		language: (data.language?.language_code || null) === user.value?.language_id,
	};
	if (Object.values(userData).includes(false)) {
		modalRef.value.open();
	} else {
		back();
	}
};

const onConfirm = () => {
	validate.value.$touch();
	const isValid = !validate.value.$invalid;
	modalRef.value.close();

	if (isValid) {
		save();
	}
};

const onBack = () => {
	if (data.photo !== user.value.photo) {
		photoForDelete.value = data.photo;
	}

	modalRef.value.close();
	back();
};

const photoForDelete = ref("");

const handleRemovePhoto = () => {
	photoForDelete.value = data.photo;
};

const { isDesktop } = useScreenSize();

const udateDescription = (evn) => {
	data.description = evn;
};
</script>

<template>
	<ModalConfirm
		ref="modalRef"
		@confirm="onConfirm"
		@back="onBack"
		back-text="buttonTextDontSave"
		confirm-text="buttonTextSave"
		:with-cross="true"
		:allow-self-close="true"
	>
		<div class="confirm-edit">
			<Typography class="confirm-edit__text" :type="TYPOGRAPHY_TYPES.TITLE_2">
				{{ t("editProfileMoSave") }}
			</Typography>
		</div>
	</ModalConfirm>
	<div class="edit-profile">
		<div class="edit-profile__box">
			<!-- <button v-if="isDesktop" type="button" class="back-button" @click="onBack">
				<SvgTemplate class="back-button__icon" name="left-arrow" />
			</button> -->
			<div class="edit-profile__wrapper">
				<div class="edit-profile__avatar-block">
					<div class="edit-profile__avatar-wrapper">
						<div class="edit-profile__avatar">
							<Image
								:src="photoSrc"
								:alt="'аватарка'"
								:suspense="isSuspense"
								class="edit-profile__avatar-image"
							/>
						</div>
						<button class="edit-profile__avatar-remove" type="button" @click="handleRemovePhoto">
							<SvgTemplate v-if="isDesktop" class="edit-profile__avatar-btn-icon" name="trash" />
							<Typography :type="isDesktop ? TYPOGRAPHY_TYPES.BUTTON_1 : TYPOGRAPHY_TYPES.BUTTON_2">
								{{ t("editProfileDeletePhoto") }}
							</Typography>
						</button>
					</div>

					<FileLoader
						:entityId="entity"
						:apiDeleteFile="apiDeleteUserPhoto"
						:apiGetFiles="apiGetUserPhoto"
						:apiPostFiles="apiPostUserPhoto"
						:photoForDelete="photoForDelete"
						single-file
						@NewFiles="setAvatar"
					>
						<template #button>
							<div class="edit-profile__avatar-btn">
								<SvgTemplate class="edit-profile__avatar-btn-icon" name="camera" />
							</div>
						</template>
					</FileLoader>
					<span v-if="!isDesktop" class="line" />
					<div class="edit-profile__info">
						<div class="edit-profile__fio">
							<SvgTemplate name="person" />
							<div v-if="!isDesktop" class="connection-line" />
							<InputField
								:placeholder="t('placeholderYourName')"
								v-model="data.firstName"
								:mode="INPUT_MODE.ONE_WORD"
								@focus="validate.firstName.$reset"
								@blur="validate.firstName.$touch"
								:errors="validate.firstName.$errors"
								error-zero-height
							/>
							<InputField
								:placeholder="t('placeholderYourSurname')"
								v-model="data.secondName"
								:mode="INPUT_MODE.ONE_WORD"
							/>
						</div>
						<div class="edit-profile__about-wrapper">
							<SvgTemplate name="papyrus" />
							<TextArea
								class="edit-profile__about"
								:placeholder="t('placeholderWriteMessageTellUs')"
								v-model="data.description"
								@modelValue:update="udateDescription"
								:max="500"
							/>
						</div>
					</div>
				</div>

				<span class="line" />

				<div class="edit-profile__row">
					<Typography class="edit-profile__block-title" :type="TYPOGRAPHY_TYPES.TITLE_1">
						{{ t("changeRegionModalTitle") }}
					</Typography>
					<div class="edit-profile__input-location">
						<SvgTemplate name="location" />
						<div v-if="!isDesktop" class="connection-line" />
						<Autocomplete
							class="edit-profile__autocomplete"
							:placeholder="t('filterMoCountry')"
							:size="SIZES.NORMAL"
							disable-input
							reset-disabled
							:items="countries"
							:reducer="(x) => x.name"
							v-model="data.country"
							@focus="validate.country.$reset"
							@blur="validate.country.$touch"
							:errors="validate.country.$errors"
							error-zero-height
						/>
						<Autocomplete
							class="edit-profile__autocomplete"
							:placeholder="t('filterMoCity')"
							:size="SIZES.NORMAL"
							:items="regions"
							:reducer="(x) => x.name"
							:empty-items-message="t('placeholderChangeRegionModalCoutry')"
							v-model="data.region"
							@focus="validate.region.$reset"
							@blur="validate.region.$touch"
							:errors="validate.region.$errors"
							error-zero-height
						>
							<template #item="{ reducedItem, index }">
								<Typography :type="index > 2 ? TYPOGRAPHY_TYPES.INPUT : TYPOGRAPHY_TYPES.INPUT_B">
									{{ reducedItem }}
								</Typography>
							</template>
						</Autocomplete>
					</div>
				</div>

				<span class="line" />

				<div class="edit-profile__row">
					<Typography class="edit-profile__block-title" :type="TYPOGRAPHY_TYPES.TITLE_1">
						{{ t("profileSettingsLanguage") }}
					</Typography>
					<div class="edit-profile__input-language">
						<SvgTemplate name="hyeroglyph" />
						<Autocomplete
							class="edit-profile__autocomplete edit-profile__autocompleate_w436"
							:placeholder="t('profileSettingsLanguage')"
							:size="SIZES.NORMAL"
							disable-input
							reset-disabled
							:items="languages"
							:reducer="(x) => x.display_name_ru"
							v-model="data.language"
							error-zero-height
						/>
						<Typography class="edit-profile__language-text" :type="TYPOGRAPHY_TYPES.BUTTON_2">
							{{ t("editProfileMoSaveSelectLanguageText") }}
						</Typography>
					</div>
				</div>

				<span class="line" />

				<div class="edit-profile__row">
					<Typography class="edit-profile__block-title" :type="TYPOGRAPHY_TYPES.TITLE_1">
						{{ t("contactText") }}
					</Typography>
					<div class="edit-profile__contact-wrapper">
						<div class="edit-profile__contact-input edit-profile__contact">
							<SvgTemplate class="edit-profile__contact-icon" name="user-phone" />
							<Phone
								class="edit-profile__contact-input_full"
								:placeholder="t('announcementCreateContactPhone')"
								v-model="data.phone"
								v-model:code="data.phone_ccc"
								v-model:iso-code="data.phone_iso_code"
								@focus="validate.phone.$reset"
								@blur="validate.phone.$touch"
								@isValid="(val) => (isPhoneValid = val)"
								:errors="validate.phone.$errors"
								error-zero-height
							/>
						</div>
						<div class="edit-profile__contact-input edit-profile__contact">
							<SvgTemplate class="edit-profile__contact-icon" name="user-email" />
							<InputField
								class="edit-profile__contact-input_full"
								placeholder="E-mail"
								v-model="data.email"
								:mode="'raw'"
								@focus="validate.email.$reset"
								@blur="validate.email.$touch"
								:errors="validate.email.$errors"
								error-zero-height
							/>
						</div>
						<div class="edit-profile__input-column edit-profile__contact">
							<div class="edit-profile__contact-input">
								<SvgTemplate
									class="edit-profile__contact-icon edit-profile__contact-icon_whatsapp"
									name="whatsapp"
								/>
								<Phone
									class="edit-profile__contact-input_full"
									:placeholder="t('announcementCreateContactPhone') + ' WhatsApp'"
									v-model="data.whats_app_phone"
									v-model:code="data.whats_app_phone_ccc"
									v-model:iso-code="data.wa_iso_code"
									:disabled="data.whatsAppPhoneLikePhone"
									@focus="validate.whats_app_phone.$reset"
									@isValid="(val) => (isWhatsAppPhoneValid = val)"
									:errors="validate.whats_app_phone.$errors"
									error-zero-height
								/>
							</div>
							<div class="edit-profile__checkbox-text">
								<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">
									{{ t("editProfileWhatsAppMatches") }}
								</Typography>
								<Checkbox
									:checkBoxParamName="'whatsAppPhoneLikePhone'"
									:is-checked-default="data.whatsAppPhoneLikePhone"
									@toggleCheckbox="clickCheckbox"
								/>
							</div>
						</div>
						<div class="edit-profile__input-column edit-profile__contact">
							<div class="edit-profile__contact-input">
								<SvgTemplate
									class="edit-profile__contact-icon edit-profile__contact-icon_tg"
									name="tg"
								/>
								<InputField
									class="edit-profile__contact-input_full"
									:placeholder="t('placeholderNick') + ' Telegram'"
									v-model="data.username"
									:mode="'raw'"
									@focus="validate.username.$reset"
									@blur="validate.username.$touch"
									:errors="validate.username.$errors"
									error-zero-height
								/>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<div class="edit-profile__btn-block">
			<Button
				:variant="THEME_VARIANTS.LIGHT"
				class="edit-profile__btn edit-profile__btn_cancel"
				@click="denay"
				:size="SIZES.NORMAL"
				width-full
			>
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">{{ t("buttonTextCancel") }}</Typography>
			</Button>
			<Button
				:variant="isDesktop ? THEME_VARIANTS.SECONDARY : THEME_VARIANTS.LIGHT"
				class="edit-profile__btn"
				@click="save"
				:size="SIZES.BIG"
				width-full
				:pending="isPending"
			>
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">{{ t("buttonTextSave") }}</Typography>
			</Button>
		</div>
	</div>
</template>
<style lang="scss" scoped>
@import "./EditProfile.scss";
</style>
