<script setup>
import { TYPOGRAPHY_TYPES, Typography, SvgTemplate } from "@shared/ui";
import { usePhone } from "@features/Phone";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	tg: {
		type: Object,
		required: true,
	},
	phone: {
		type: Object,
		default: null,
	},
	email: {
		type: Object,
		default: null,
	},
	wa: {
		type: Object,
		default: null,
	},
	isProfile: {
		type: Boolean,
		default: false,
	},
	modalContact: {
		type: Boolean,
		default: false,
	},
});

const { toFormatPhone } = usePhone();
const { t } = useTranslation();

const isVisible = (data) => {
	if (props.isProfile) {
		return data.value;
	}

	return data.value && data.visible;
};
</script>

<template>
	<div class="content_block">
		<slot name="title" />
		<div class="content_block__wrapper">
			<a
				v-if="isVisible(phone)"
				class="content_block__row"
				:href="!isProfile ? 'tel:' + phone.value : null"
			>
				<div :class="{ 'content_block__circle-img bg-phone': modalContact }">
					<SvgTemplate
						class="content_block__small-icon content_block__small-icon_phone"
						:class="{ 'content_block__small-icon_mo': modalContact }"
						name="user-phone"
					/>
				</div>
				<div>
					<Typography
						v-if="!modalContact"
						class="content_block__contact-title"
						:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
					>
						{{ t("announcementCreateContactPhone") }}
					</Typography>
					<Typography :type="TYPOGRAPHY_TYPES.CONTACT">
						{{ toFormatPhone(phone.value) }}
					</Typography>
				</div>
			</a>
			<a
				v-if="isVisible(wa)"
				class="content_block__row"
				:href="!isProfile ? 'https://wa.me/' + wa.value : null"
				target="_blank"
				rel="noopener noreferrer"
			>
				<div :class="{ 'content_block__circle-img bg-wa': modalContact }">
					<SvgTemplate
						class="content_block__small-icon content_block__small-icon_whatsapp"
						:class="{ 'content_block__small-icon_mo': modalContact }"
						name="whatsapp"
					/>
				</div>
				<div>
					<Typography
						v-if="!modalContact"
						class="content_block__contact-title"
						:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
					>
						What’sApp
					</Typography>
					<Typography :type="TYPOGRAPHY_TYPES.CONTACT">
						{{ toFormatPhone(wa.value) }}
					</Typography>
				</div>
			</a>
			<a
				v-if="isVisible(tg)"
				class="content_block__row"
				:href="!isProfile ? 'https://t.me/' + tg.value : null"
				target="_blank"
				rel="noopener noreferrer"
			>
				<div :class="{ 'content_block__circle-img bg-tg': modalContact }">
					<SvgTemplate
						class="content_block__small-icon content_block__small-icon_tg"
						:class="{ 'content_block__small-icon_mo': modalContact }"
						name="tg"
					/>
				</div>
				<div>
					<Typography
						v-if="!modalContact"
						class="content_block__contact-title"
						:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
					>
						Telegram
					</Typography>
					<Typography :type="TYPOGRAPHY_TYPES.CONTACT">@{{ tg.value }}</Typography>
				</div>
			</a>
			<a
				v-if="isVisible(email)"
				class="content_block__row"
				:href="!isProfile ? 'mailto:' + email.value : null"
				target="_blank"
				rel="noopener noreferrer"
			>
				<div :class="{ 'content_block__circle-img bg-email': modalContact }">
					<SvgTemplate
						class="content_block__small-icon content_block__small-icon_email"
						:class="{ 'content_block__small-icon_mo': modalContact }"
						name="user-email"
					/>
				</div>
				<div>
					<Typography
						v-if="!modalContact"
						class="content_block__contact-title"
						:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
					>
						{{ t("alternativeAuthMailInput") }}
					</Typography>
					<Typography :type="TYPOGRAPHY_TYPES.CONTACT">{{ email.value }}</Typography>
				</div>
			</a>
		</div>
	</div>
</template>
<style lang="scss" scoped>
@import "./Contacts.scss";
</style>
