export default {
	ru: {
		launchingApplicationTitle: "Скачивайте приложение",
		launchingApplicationSubtitle1: "Лучший способ пользоваться сервисом -",
		launchingApplicationSubtitle2: "в мобильном приложении",
		launchingApplicationDownload: "Скачать в",
	},
	en: {
		launchingApplicationTitle: "Download the app",
		launchingApplicationSubtitle1: "The best way to use the service -",
		launchingApplicationSubtitle2: "is in the mobile app",
		launchingApplicationDownload: "Download to",
	},
	ka: {
		launchingApplicationTitle: "გადმოიწერეთ აპლიკაცია",
		launchingApplicationSubtitle1: "საუკეთესო და კომფორტული მეთოდი სერვისის გამოსაყენებლად",
		launchingApplicationSubtitle2: "ტელეფონის აპლიკაცია",
		launchingApplicationDownload: "გადმოწერეთ",
	},
	tr: {
		launchingApplicationTitle: "Uygulamayı indirin",
		launchingApplicationSubtitle1: "Hizmeti kullanmanın en iyi yolu",
		launchingApplicationSubtitle2: "mobil uygulamadır",
		launchingApplicationDownload: "Şuraya indir",
	},
};
