export default {
	ru: {
		prepositionNo: "нет",
		pretextAlready: "уже",
	},
	en: {
		prepositionNo: "No",
		pretextAlready: "already",
	},
	ka: {
		prepositionNo: "არა",
		pretextAlready: "უკვე",
	},
	tr: {
		prepositionNo: "Hayır",
		pretextAlready: "Şimdiden",
	},
};
