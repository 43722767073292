<script setup>
import { ref, computed } from "vue";
import { Image, TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { getBucketUrlSmall } from "@shared/const";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	photoData: {
		type: Array,
		default: () => [],
	},
	itemId: {
		type: Number,
		default: 0,
	},
});

const { t } = useTranslation();

const mainPhoto = ref(props.photoData ? props.photoData[0] : "");

const mainPhotoIndex = ref(0);
const isLastPhoto = ref(false);
const isLoading = ref(false);
const isUpload = ref(false);

const handleMouseMove = async (event) => {
	if (isUpload.value) {
		return;
	}
	const { width } = event.target.getBoundingClientRect();
	const segmentWidth = width / (props.photoData.length >= 5 ? 5 : props.photoData.length);
	const index = Math.floor(event.offsetX / segmentWidth);
	mainPhotoIndex.value = index;

	if (index >= 0 && index < 5) {
		if (!isLoading.value) {
			isUpload.value = true;
			await loadImages();
			isUpload.value = false;
		}

		mainPhoto.value = props.photoData[index];
		isLastPhoto.value = props.photoData.length > 5 && index === 4;
	}
};

const loadImages = async () => {
	const promises = props.photoData.map((photo, index) => {
		return new Promise((resolve) => {
			const img = new window.Image();
			img.src = getBucketUrlSmall(photo, props.itemId);
			img.onload = () => {
				if (index === props.photoData.length - 1) {
					isUpload.value = false;
				}
				resolve();
			};
			img.onerror = () => {
				isUpload.value = false;
				resolve();
			};
		});
	});

	await Promise.allSettled(promises);
	isLoading.value = true;
};

const sliderPhoto = computed(() => {
	return getBucketUrlSmall(mainPhoto.value, props.itemId);
});

const handleMouseLeave = () => {
	mainPhoto.value = props?.photoData[0];
	isLastPhoto.value = false;
	mainPhotoIndex.value = 0;
};
</script>

<template>
	<div
		class="announcement-card__slider"
		:class="{ 'announcement-card__slider_last': isLastPhoto }"
		@mousemove="handleMouseMove"
		@mouseleave="handleMouseLeave"
		@blur="handleMouseLeave"
	>
		<Image :src="sliderPhoto" alt="preview photo" />
		<Typography
			v-if="isLastPhoto"
			class="announcement-card__text-last"
			:type="TYPOGRAPHY_TYPES.TEXT_1"
		>
			{{ t("previewMore") }} {{ photoData?.length - 4 }} {{ t("previewPhoto") }}
		</Typography>
		<div class="announcement-card__slider-bar-wrapper">
			<div
				class="announcement-card__slider-bar"
				v-for="(item, index) in photoData.length <= 5 ? photoData.length : 5"
				:key="item + index"
				:class="{ 'announcement-card__slider-bar-active': index === mainPhotoIndex }"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./DesktopPreviewSlider.scss";
</style>
