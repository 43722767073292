<script setup>
import { AnnouncementCreate } from "@features/AnnouncementCreate";
import { ProfilePendingEdit } from "@features/ProfilePendingEdit";
import { Authorization } from "@widgets/Authorization";
import { computed, ref } from "vue";
import { isNil } from "lodash-es";
import { useStore } from "vuex";

const store = useStore();

const selectCategories = ref(null);

const selectSubCategories = ref(null);

const files = ref([]);

const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const newFiles = (newFiles) => {
	files.value = newFiles;
};
</script>

<template>
	<div v-if="!isNil(user?.user_id)" class="pad">
		<AnnouncementCreate
			:selectCategories="selectCategories"
			:selectSubCategories="selectSubCategories"
			@newFiles="newFiles"
		/>
	</div>
	<Authorization />
	<ProfilePendingEdit />
</template>

<style lang="scss" scoped>
@import "./AnnouncementCreatePad.scss";
</style>
