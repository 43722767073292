import { isString } from "lodash-es";

export const formatByMask = (val, mask) => {
	if (!isString(mask) || !isString(val) || val === "") {
		return val;
	}
	let maskToReplace = mask;

	const charsArray = val.split("");

	charsArray.forEach((char) => {
		maskToReplace = maskToReplace.replace("x", char);
	});

	const [result] = maskToReplace.split("x");

	return result || "";
};
