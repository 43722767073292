<script setup>
import { computed, unref } from "vue";
import { AnnouncementListSection } from "../AnnouncementListSection";

const props = defineProps({
	splitedList: { type: Array, default: () => [] },
	withKeepAlive: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits("updateList");

const splitedListByFullField = computed(() => {
	const res = props.splitedList.reduce((acc, elem, index) => {
		const prevValue = acc[index - 1];
		if (prevValue?.isFullfield || index === 0) {
			return [...acc, elem];
		}
		return acc;
	}, []);
	return res;
});

const isEmpty = computed(() => {
	return props.splitedList.every((splitedListItem) => splitedListItem.isEmpty === true);
});
</script>

<template>
	<section class="announcement-splited-list">
		<template v-if="!isEmpty">
			<AnnouncementListSection
				v-for="list in splitedListByFullField"
				:key="list.id"
				:items="list.items"
				:class="[`announcement-splited-list__${list.id}`]"
				:status="unref(list.status)"
				:is-empty="list.isEmpty"
				:with-keep-alive="withKeepAlive"
				update-by-item
				@update-list="emit('updateList')"
			>
				<template #title="{ isSuspense }">
					<slot
						:name="`title.${list.id}`"
						:is-empty="list.isEmpty"
						:is-fullfield="list.isFullfield"
						:is-suspense="isSuspense"
					></slot>
				</template>
				<template #sub-title="{ isSuspense }">
					<slot
						:name="`sub-title.${list.id}`"
						:is-empty="list.isEmpty"
						:is-fullfield="list.isFullfield"
						:is-suspense="isSuspense"
					></slot>
				</template>
				<template #empty>
					<slot :name="`empty.${list.id}`"></slot>
				</template>
			</AnnouncementListSection>
		</template>
		<template v-else>
			<slot name="empty-list"></slot>
		</template>
	</section>
</template>
