<script setup>
import { ref } from "vue";
import { ModalConfirm } from "@shared/ui";
import { SupposedLocation } from "../SupposedLocation";

defineProps({
	country: {
		type: Object,
		required: true,
	},
	city: {
		type: Object,
		default: () => {},
	},
});

const emit = defineEmits(["onCofirm", "onBack"]);

const modalRef = ref(null);

defineExpose({ open: () => modalRef.value.open() });

const onConfirm = () => {
	modalRef.value.close();
	emit("onConfirm");
};

const onBack = () => {
	modalRef.value.close();
	emit("onBack");
};
</script>

<template>
	<ModalConfirm
		:back-text="`Нет, ${city?.id ? 'anotherText1' : 'anotherText2'}`"
		confirm-text="yesThanks"
		:with-cross="true"
		:allow-self-close="true"
		ref="modalRef"
		@confirm="onConfirm"
		@back="onBack"
	>
		<SupposedLocation :country="country" :city="city" />
	</ModalConfirm>
</template>
