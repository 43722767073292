<script setup>
import {
	ANNOUNCEMENT_PREVIEW_ORIENTATION,
	AnnouncementListSection,
	ON_PAGE_COUNT,
	useAnnouncementStore,
} from "@entities/announcement";
import { REQUST_STATUSES } from "@shared/const";
import { useInitData, useScreenSize } from "@shared/lib";
import { watch, computed } from "vue";
import { useStore } from "vuex";
import MyActiveEmptyList from "./MyActiveEmptyList.vue";

const store = useStore();

const initData = useInitData();

const { isDesktop } = useScreenSize();

const currenUser = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const currenUserStatus = computed(() => {
	return store.getters["user/currentUserStatus"];
});

const params = computed(() => ({
	user_id: currenUser.value?.user_id,
	on_page: ON_PAGE_COUNT.GENERAL_PAGE_COUNT,
}));

const {
	getAnnouncement,
	updateAnnouncements,
	announcementsList,
	announcementsListStatus,
	isEmptyList,
} = useAnnouncementStore(params);

// TODO Избавиться от этой записи и добавить ожидание по статуса
watch(
	currenUserStatus,
	() => {
		if (currenUserStatus.value === REQUST_STATUSES.SUCCESS) {
			initData(async () => {
				await getAnnouncement();
			});
		}
	},
	{ immediate: true, flush: "post" }
);
</script>
<template>
	<AnnouncementListSection
		:items="announcementsList"
		:status="announcementsListStatus"
		with-keep-alive
		:with-favorite="false"
		:orientation="
			isDesktop
				? ANNOUNCEMENT_PREVIEW_ORIENTATION.VERTICAL
				: ANNOUNCEMENT_PREVIEW_ORIENTATION.HORIZONTAL
		"
		:is-empty="isEmptyList"
		@update-list="updateAnnouncements"
	>
		<template #empty>
			<MyActiveEmptyList />
		</template>
	</AnnouncementListSection>
</template>
