<script setup>
import { watch, computed } from "vue";
import {
	AnnouncementListSection,
	ON_PAGE_COUNT,
	useAnnouncementStore,
} from "@entities/announcement";
import { useStore } from "vuex";
import { REQUST_STATUSES } from "@shared/const";
import { useInitData, getUnregisterItemId } from "@shared/lib";
import { DefaultHeader } from "@widgets/DefaultHeader";
import { useTranslation } from "i18next-vue";
import EmptyFavoritesAnnouncementList from "./EmptyFavoritesAnnouncementList.vue";

const store = useStore();
const { t } = useTranslation();

const initData = useInitData();

const isUserLoggedIn = computed(() => {
	return store.getters["user/isUserLoggedIn"];
});

const currenUser = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const currenUserStatus = computed(() => {
	return store.getters["user/currentUserStatus"];
});

const getUnregistAnnouncementsList = computed(() => {
	return store.getters["announcement/getuUnregistereFavoritsList"].items;
});

const getUnregistereFavoritsStatus = computed(() => {
	return store.getters["announcement/getUnregistereFavoritsStatus"];
});

const params = computed(() => {
	return currenUser.value?.user_id
		? {
				user_id: currenUser.value?.user_id,
				is_favorite: true,
				on_page: ON_PAGE_COUNT.GENERAL_PAGE_COUNT,
		  }
		: null;
});

const {
	getAnnouncement,
	updateAnnouncements,
	announcementsList,
	announcementsListStatus,
	isEmptyList,
} = useAnnouncementStore(params);

// TODO Избавиться от этой записи и добавить ожидание по статуса
watch(
	currenUserStatus,
	async () => {
		if (currenUserStatus.value === REQUST_STATUSES.SUCCESS) {
			initData(async () => {
				await getAnnouncement();
			});
		} else if (currenUserStatus.value === REQUST_STATUSES.ERROR) {
			const favoriteData = await getUnregisterItemId();
			initData(async () => {
				store.dispatch("announcement/getFavoritsUnregisteredUser", {
					itemsListId: favoriteData.map((item) => item.item_id),
				});
			});
		}
	},
	{ immediate: true, flush: "post" }
);

const isEmptyUnregisterList = computed(() => {
	const isEmpty = getUnregistAnnouncementsList.value.length === 0;
	return getUnregistereFavoritsStatus.value === "success" && isEmpty;
});

const updateFavoritsUnregisteredUser = async () => {
	const favoriteData = await getUnregisterItemId();
	return store.dispatch("announcement/updateFavoritsUnregisteredUser", {
		itemsListId: favoriteData.map((item) => item.item_id),
	});
};
</script>

<template>
	<AnnouncementListSection
		class="favorites-list"
		:items="isUserLoggedIn ? announcementsList : getUnregistAnnouncementsList"
		:status="isUserLoggedIn ? announcementsListStatus : getUnregistereFavoritsStatus"
		:is-empty="isUserLoggedIn ? isEmptyList : isEmptyUnregisterList"
		with-keep-alive
		@update-list="isUserLoggedIn ? updateAnnouncements() : updateFavoritsUnregisteredUser()"
	>
		<template #title>
			<DefaultHeader :title="t('favoritesAnnouncementListTitle')" class="favorites-list__title" />
		</template>
		<template #empty>
			<EmptyFavoritesAnnouncementList />
		</template>
	</AnnouncementListSection>
</template>

<style lang="scss" scoped>
@import "./FavoritesAnnouncementList.scss";
</style>
