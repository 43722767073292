import { REQUST_STATUSES } from "@shared/const";

/** @typedef {{ items: unknown[]; pagination: {}; isFullfield: boolean; isPending: boolean }} Announcement */

export default {
	status: {
		announcements: REQUST_STATUSES.OUTDATED,
		categories: REQUST_STATUSES.OUTDATED,
		currentAD: REQUST_STATUSES.OUTDATED,
		regions: REQUST_STATUSES.OUTDATED,
		announcementsById: {},
		currency: REQUST_STATUSES.OUTDATED,
		tags: REQUST_STATUSES.OUTDATED,
		unregisterFavoritsStatus: REQUST_STATUSES.OUTDATED,
	},
	announcementsControllers: {},
	announcements: {
		items: [],
		pagination: {},
		isFullfield: false,
		ads: [],
	},
	announcementsSliderList: {},
	announcementsSplitItemsMain: {
		announcementsCity: {
			title: "",
			subTitle: "",
			data: [],
			isFullfield: false,
		},
		announcementsCountry: {
			title: "",
			subTitle: "",
			data: [],
			isFullfield: false,
		},
		announcementsOther: {
			title: "",
			data: [],
			isFullfield: false,
		},
	},
	categories: [],
	regions: [],
	currentADId: null,
	favoritesAnnouncement: [],
	currentAD: {},
	currentSearchLocation: false,
	announcementFilters: {},
	activeAdEditor: true,
	/** @type {Record<string, Announcement>} */
	announcementsById: {},
	announcementsControllersById: {},
	currency: [],
	tags: [],
	documents: [],
	unregistereFavoritsList: {
		items: [],
		page: 0,
		isFullfield: false,
		ads: [],
	},
};
