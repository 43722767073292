const createOrder = (field, direction, orderNumber, value=null) => {
    const order = {
        order_field: field,
        order_direction: direction,
        order_number: orderNumber,
    };
    if (value !== null) {
        order.order_value = String(value);
    }
    return order;
};

const createOrderFunction = (direction) => {
    return (field, value, orderNumber) => createOrder(field, direction, orderNumber, value);
};

export const getAnnouncementOrderParams = createOrderFunction("DESC");

export const FIELD = {
    REGION: "REGION",
    COUNTRY: "COUNTRY",
    DATE: "DATE",
    SUBCATEGORY: "SUBCATEGORY",
};