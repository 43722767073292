<script setup>
import { computed } from "vue";
import { Image, TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { getBucketUrlSmall } from "@shared/const";
import { SwiperSlide, Swiper } from "swiper/vue";
import { Pagination } from "swiper";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	photoData: {
		type: Array,
		default: () => [],
	},
	itemId: {
		type: Number,
		default: 0,
	},
});

const { t } = useTranslation();

const modules = [Pagination];

const slidersScr = computed(() => {
	const photoData = props.photoData.map((photo) => {
		return getBucketUrlSmall(photo, props.itemId);
	});
	return photoData.slice(0, 5);
});
</script>

<template>
	<Swiper :modules="modules" class="photo-preview-single" :pagination="true">
		<SwiperSlide
			v-for="(item, index) in slidersScr"
			:key="`${item}${index}`"
			:class="{
				'announcement-card__slider-last': photoData?.length > 5 && index === slidersScr.length - 1,
			}"
		>
			<Image :src="item" :alt="announcementCard?.ad_name" @success="advShown" />
			<Typography
				v-if="photoData?.length > 5 && index === slidersScr.length - 1"
				:type="TYPOGRAPHY_TYPES.TEXT_1"
				class="announcement-card__slider-text"
			>
				{{ t("previewMore") }} {{ photoData?.length - 4 }} {{ t("previewPhoto") }}
			</Typography>
		</SwiperSlide>
	</Swiper>
</template>

<style lang="scss" scoped>
@import "./MobilePreviewSlider.scss";
</style>
