export default {
	ru: {
		announceCardInstanceLostTitle: "Похожие объявления",
		announceCardInstanceLostSubtitle: "Возможно, его удалили или сняли с публикации",
	},
	en: {
		announceCardInstanceLostTitle: "Related ads",
		announceCardInstanceLostSubtitle: "It may have been deleted or taken down from publication.",
	},
	ka: {
		announceCardInstanceLostTitle: "მსგავსი რეკლამები",
		announceCardInstanceLostSubtitle: "ის შესაძლოა წაშლილია ან წაშლილია პუბლიკაციიდან.",
		announceCardDeletedArchive: "",
	},
	tr: {
		announceCardInstanceLostTitle: "Benzer reklamlar",
		announceCardInstanceLostSubtitle: "Silinmiş veya yayından kaldırılmış olabilir.",
	},
};
