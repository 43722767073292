import { nanoid } from "nanoid";
import { REQUST_STATUSES } from "@shared/const";
import { FIELD, getAnnouncementOrderParams } from "@entities/announcement";
import {
	apiGetAnnouncement,
	apiGetAnnouncment,
	apiGetCategories,
	apiGetCities,
	apiPostAnnouncement,
	apiPostPreferAnnouncement,
	apiGetCurrencies,
	apiGetTags,
} from "../api";
import { ON_PAGE_COUNT } from "../const";

export const getAnnouncment = async ({ commit, getters, rootGetters }, params) => {
	const { countryId, regionId } = rootGetters["user/getHandleLocation"];
	const abortController = new AbortController();
	const abortControllerId = nanoid(4);

	commit("CLEAR_ANNOUNCEMENT");
	commit("SET_STATUS", REQUST_STATUSES.PENDING);
	const controllers = Object.values(getters.announcementsControllers);
	controllers.forEach((controller) => controller.abort());
	commit("SET_CONTROLLER", { controller: abortController, id: abortControllerId });
	try {
		const request = apiGetAnnouncment(
			{
				...params,
				on_page: ON_PAGE_COUNT.GENERAL_PAGE_COUNT,
				ads: params.with_ads ? getters.ads : [],
			},
			abortController
		);
		const { data } = await request;

		commit("SET_STATUS", REQUST_STATUSES.SUCCESS);
		commit("SET_ANNOUNCEMENTS", data);
		commit("UPDATE_ANNOUNCEMENTS_SPLIT_LIST", { data, regionId, countryId });
	} catch (error) {
		if (error?.code !== "ERR_CANCELED") {
			console.error(error);
			commit("SET_STATUS", REQUST_STATUSES.ERROR);
		}
	} finally {
		commit("DELETE_CONTROLLER", abortControllerId);
	}
};

export const getSliderData = async (
	{ commit },
	{ params, onPage = ON_PAGE_COUNT.GENERAL_PAGE_COUNT }
) => {
	const abortController = new AbortController();

	try {
		const request = apiGetAnnouncment(
			{
				...params,
				on_page: onPage,
			},
			abortController
		);
		const { data } = await request;

		commit("SET_ANNOUNCEMENTS_SLIDER", data);
	} catch (error) {
		if (error?.code !== "ERR_CANCELED") {
			console.error(error);
			commit("SET_STATUS", REQUST_STATUSES.ERROR);
		}
	}
};

export const updateAnnouncment = async ({ commit, getters, rootGetters }, params) => {
	const { currentAnnouncement, ads } = getters;
	const { countryId, regionId } = rootGetters["user/getHandleLocation"];
	if (currentAnnouncement.isFullfield) return;
	const nextPage = currentAnnouncement.pagination.page + 1;
	commit("SET_STATUS", REQUST_STATUSES.PENDING);
	try {
		const { data } = await apiGetAnnouncment({
			...params,
			on_page: ON_PAGE_COUNT.GENERAL_PAGE_COUNT,
			page: nextPage,
			ads: params.with_ads ? ads : [],
		});
		commit("SET_STATUS", REQUST_STATUSES.SUCCESS);
		commit("UPDATE_ANNOUNCEMENTS", data);
		commit("UPDATE_ANNOUNCEMENTS_SPLIT_LIST", { data, regionId, countryId });
	} catch (error) {
		commit("SET_STATUS", REQUST_STATUSES.ERROR);
		// TODO Создать буфер ошибок в store
		console.error(error);
	}
};

export const getCategories = async ({ commit }, params) => {
	try {
		commit("SET_CATEGORIES_STATUS", REQUST_STATUSES.PENDING);
		const { data } = await apiGetCategories(params);
		commit("SET_CATEGORIES", data);
		commit("SET_CATEGORIES_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (error) {
		// TODO Создать буфер ошибок в store
		commit("SET_CATEGORIES_STATUS", REQUST_STATUSES.ERROR);
		console.error(error);
	}
};

export const getCities = async ({ commit }, params) => {
	try {
		commit("SET_REGIONS_STATUS", REQUST_STATUSES.PENDING);
		const { data } = await apiGetCities(params);
		commit("SET_REGIONS", data);
		commit("SET_REGIONS_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (error) {
		// TODO Создать буфер ошибок в store
		console.error(error);
		commit("SET_REGIONS_STATUS", REQUST_STATUSES.ERROR);
	}
};

export const createAD = async ({ commit }, query) => {
	try {
		const data = await apiPostAnnouncement(query);
		commit("SET_AD_ID", data.data.item_id);
		if (data.data?.currency_label) {
			commit("UPDATE_AD", {
				item_id: data.data.item_id,
				currency_label: data.data?.currency_label,
				price_rub: data.data?.price_rub,
			});
		}
	} catch (error) {
		// TODO Создать буфер ошибок в store
		console.error(error);
	}
};

export const getFavoritesAnnouncement = async ({ dispatch }, params) => {
	dispatch("getAnnouncment", { ...params, is_favorite: true });
};

export const getMyAnnouncement = async ({ dispatch, rootGetters }, params) => {
	dispatch("getAnnouncment", {
		user_id: rootGetters["user/getCurrentUser"]?.user_id,
		isArchiv: params.isArchiv,
	});
};

export const getActualAnnouncement = async ({ dispatch }, params) => {
	const { region_id: regionId, country_id: countryId, ...otherParams } = params;
	const orders = [getAnnouncementOrderParams(FIELD.DATE, null, 3)];

	if (countryId !== 0) {
		orders.push(getAnnouncementOrderParams(FIELD.COUNTRY, countryId, 2));
	}
	if (regionId !== "0") {
		orders.push(getAnnouncementOrderParams(FIELD.REGION, regionId, 1));
	}

	dispatch("getAnnouncment", { ...otherParams, orders, age: 30 });
};

export const getUserAnnouncement = async ({ dispatch }, params) => {
	dispatch("getAnnouncment", { user_id: params.user_id });
};

export const getSearchAnnouncement = async ({ dispatch }, params) => {
	const { region_id: regionId, ...otherParams } = params;
	const orders = [getAnnouncementOrderParams(FIELD.DATE, null, 2)];
	if (regionId) orders.push(getAnnouncementOrderParams(FIELD.REGION, regionId, 1));
	dispatch("getAnnouncment", { ...otherParams, orders, age: 30 });
};

export const updateFavoritesAnnouncement = async ({ dispatch }) => {
	dispatch("updateAnnouncment", { is_favorite: true });
};

export const getAD = async ({ commit }, query) => {
	const params = {
		...query,
	};
	commit("SET_AD_STATUS", REQUST_STATUSES.PENDING);
	try {
		const data = await apiGetAnnouncement(params);
		commit("SET_AD_ID", data.data.item_id);
		commit("SET_AD", data.data);
		commit("SET_AD_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (error) {
		commit("SET_AD_STATUS", REQUST_STATUSES.ERROR);
		// TODO Создать буфер ошибок в store
		console.error(error);
	}
};

export const addPrefer = async ({ commit }, query) => {
	try {
		await apiPostPreferAnnouncement(query);
		commit("UPDATE_AD", {
			item_id: query.item_id,
			is_favor: query.is_active,
		});
		commit("UPDATE_AD_LIST", {
			itemId: query.item_id,
			isFavor: query.is_active,
		});
		return Promise.resolve();
	} catch (error) {
		// TODO Создать буфер ошибок в store
		console.error(error);
		return Promise.reject();
	}
};

export const getAnnouncementById = async ({ commit, getters }, { id, params }) => {
	const abortController = new AbortController();
	const abortControllerId = nanoid(4);

	commit("SET_ANNOUNCEMENTS_STATUS_BY_ID", { status: REQUST_STATUSES.PENDING, id });

	const controllers = Object.entries(getters.announcementsControllersById(id));

	// Отменяем все запросы по текущему id
	controllers.forEach(([controllerId, controller]) => {
		controller.abort();
		commit("DELETE_CONTROLLER_BY_ID", { id, controllerId });
	});

	commit("SET_CONTROLLER_BY_ID", {
		controller: abortController,
		controllerId: abortControllerId,
		id,
	});
	commit("RESET_ANNOUNCEMENT_BY_ID", id);

	try {
		const response = await apiGetAnnouncment({ ...params, page: 1 }, abortController);
		const { data } = response;
		commit("SET_ANNOUNCEMENTS_STATUS_BY_ID", { status: REQUST_STATUSES.SUCCESS, id });
		commit("SET_ANNOUNCEMENTS_BY_ID", {
			data,
			id,
			onPage: params.on_page || ON_PAGE_COUNT.GENERAL_PAGE_COUNT,
		});
	} catch (error) {
		if (error?.code !== "ERR_CANCELED") {
			console.error(error);
			commit("SET_ANNOUNCEMENTS_STATUS_BY_ID", { status: REQUST_STATUSES.ERROR, id });
		}
	} finally {
		commit("DELETE_CONTROLLER_BY_ID", { controllerId: abortControllerId, id });
	}
};

export const getFavoritsUnregisteredUser = async ({ commit }, { itemsListId }) => {
	commit("SET_UNREG_FAVOR_STATUS", REQUST_STATUSES.PENDING);
	commit("CLEAR_UNREG_FAVORITS");
	if (itemsListId.length === 0) {
		commit("SET_UNREG_FAVOR_STATUS", REQUST_STATUSES.SUCCESS);
		return;
	}
	try {
		const response = await apiGetAnnouncment({
			item_id_list: itemsListId.slice(0, 10),
			page: 1,
		});
		const { data } = response;
		commit("SET_UNREG_FAVORITES", data);
		commit("SET_UNREG_FAVOR_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (error) {
		if (error?.code !== "ERR_CANCELED") {
			console.error(error);
			commit("SET_UNREG_FAVOR_STATUS", REQUST_STATUSES.ERROR);
		}
	}
};

export const updateFavoritsUnregisteredUser = async ({ commit, getters }, { itemsListId }) => {
	const { page } = getters.getuUnregistereFavoritsList;

	const nextPage = page + 1;
	const start = (nextPage - 1) * 10;
	const end = start + 9;
	const itemData = itemsListId.slice(start, end + 1);
	if (itemData.length !== 0) {
		try {
			commit("SET_UNREG_FAVOR_STATUS", REQUST_STATUSES.PENDING);
			const response = await apiGetAnnouncment({
				item_id_list: itemData,
				page: nextPage,
			});
			const { data } = response;
			commit("UPDATE_UNREG_FAVORITS", data);
			commit("SET_UNREG_FAVOR_STATUS", REQUST_STATUSES.SUCCESS);
		} catch (error) {
			if (error?.code !== "ERR_CANCELED") {
				console.error(error);
				commit("SET_UNREG_FAVOR_STATUS", REQUST_STATUSES.ERROR);
			}
		}
	}
};

export const updateAnnouncementById = async ({ commit, getters }, { params, id }) => {
	const currentAnnouncement = getters.announcementsById(id);

	if (currentAnnouncement.isFullfield) return;
	const abortController = new AbortController();
	const abortControllerId = nanoid(4);

	commit("SET_CONTROLLER_BY_ID", {
		controller: abortController,
		controllerId: abortControllerId,
		id,
	});

	const nextPage = currentAnnouncement.pagination.page + 1;
	commit("SET_ANNOUNCEMENTS_STATUS_BY_ID", { status: REQUST_STATUSES.PENDING, id });
	try {
		const { data } = await apiGetAnnouncment({
			...params,
			page: nextPage,
		});
		commit("SET_ANNOUNCEMENTS_STATUS_BY_ID", { status: REQUST_STATUSES.SUCCESS, id });
		commit("UPDATE_ANNOUNCEMENTS_BY_ID", {
			data,
			id,
			onPage: params.on_page || ON_PAGE_COUNT.GENERAL_PAGE_COUNT,
		});
	} catch (error) {
		if (error?.code !== "ERR_CANCELED") {
			console.error(error);
			commit("SET_ANNOUNCEMENTS_STATUS_BY_ID", { status: REQUST_STATUSES.ERROR, id });
		}
	} finally {
		commit("DELETE_CONTROLLER_BY_ID", { controllerId: abortControllerId, id });
	}
};

export const abortAnnouncementsById = ({ commit, getters }, id) => {
	const controllers = Object.entries(getters.announcementsControllersById(id));

	// eslint-disable-next-line no-unused-vars
	controllers.forEach(([controllerId, controller]) => {
		controller.abort();
		commit("DELETE_CONTROLLER_BY_ID", { controllerId, id });
	});
};

export const getCurrencies = async ({ commit }) => {
	try {
		commit("SET_CURRENCY_STATUS", REQUST_STATUSES.PENDING);
		const { data } = await apiGetCurrencies();
		commit("SET_CURRENCY", data);
		commit("SET_CURRENCY_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (error) {
		// TODO Создать буфер ошибок в store
		console.error(error);
		commit("SET_CURRENCY_STATUS", REQUST_STATUSES.ERROR);
	}
};

export const getTags = async ({ commit }) => {
	try {
		commit("SET_TAGS_STATUS", REQUST_STATUSES.PENDING);
		const { data } = await apiGetTags();
		commit("SET_TAGS", data);
		commit("SET_TAGS_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (error) {
		// TODO Создать буфер ошибок в store
		console.error(error);
		commit("SET_TAGS_STATUS", REQUST_STATUSES.ERROR);
	}
};
