<script setup>
import { TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();
</script>

<template>
	<div class="categories-empty">
		<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">{{ t("emptyTitle") }}</Typography>
		<Typography :type="TYPOGRAPHY_TYPES.TEXT_OLD">{{ t("emptySubtitle") }}</Typography>
	</div>
</template>

<style lang="scss" scoped>
@import "./CategoriesAnnouncementEmptyList.scss";
</style>
