import { createStore } from "vuex";
import { announcementModule as announcement } from "@entities/announcement";
import { currencyModule as currency } from "@entities/currency";
import { filesModule as files } from "@entities/files";
import { userModule as user } from "@entities/user";
import { errorsModule as errors } from "@entities/errors";
import { phoneModule as phone } from "@features/Phone";
import { storiesModule as stories } from "@widgets/Stories";
import { chatModule as chat } from "@entities/chat";
import { reviewsModule as reviews } from "@entities/reviews";

export default createStore({
	modules: {
		announcement,
		currency,
		files,
		user,
		errors,
		phone,
		stories,
		chat,
		reviews,
	},
});
