<script setup>
import { ref, computed, provide } from "vue";
import { useStore } from "vuex";
import { Typography, TYPOGRAPHY_TYPES, ModalConfirm, Modal, SvgTemplate, Image } from "@shared/ui";
import { REQUST_STATUSES, getBuckerFbUrlSmall } from "@shared/const";
import { useEventBus } from "@vueuse/core";
import { ReviewList } from "@entities/announcement";
import { Review } from "@widgets/Review";
import { plural, useScreenSize } from "@shared/lib";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox";
import { useToast } from "vue-toast-notification";
import { isNil } from "lodash-es";
import { useTranslation } from "i18next-vue";
import CreateReviews from "./CreateReviews.vue";

const props = defineProps({
	currentAd: {
		type: Object,
		default: () => {},
	},
	reviewsData: {
		type: Object,
		default: () => {},
	},
	status: {
		type: String,
		default: "",
	},
	isMy: {
		type: Boolean,
		default: false,
	},
});

const store = useStore();
const toast = useToast();
const bus = useEventBus("closeCreateModal");
const { isDesktop } = useScreenSize();
const { t } = useTranslation();

const modalRefConfirm = ref(null);
const modalRefReview = ref(null);
const modalReviews = ref(null);
const createReviewsRef = ref(null);
const idRemoveReview = ref(0);
const createPending = ref(false);
const validErr = ref(true);

const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const reviewStatus = computed(() => {
	return store.getters["reviews/getReviewStatus"] === REQUST_STATUSES.SUCCESS;
});

const isAuth = computed(() => {
	return !isNil(user.value?.user_id);
});

const isAdmin = computed(() => {
	return store.getters["user/isAdminUser"];
});

const openModalCreateReviews = (isOpenModalReviews = false) => {
	if (!isOpenModalReviews) {
		modalRefReview.value.open();
		return;
	}

	modalReviews.value.close();
	modalRefReview.value.open();
};

const onBackReviews = () => {
	modalRefReview.value.close();
	validErr.value = true;
};

const openModalReviews = () => {
	modalReviews.value.open();
};

const isShow = computed(() => {
	return props.reviewsData.items.some((item) => {
		return item.created_by === user.value.user_id;
	});
});

const removeReview = async () => {
	try {
		await store.dispatch("reviews/removeFeedbackAnnouncement", idRemoveReview.value);
		await store.dispatch("reviews/getReviewsAnnouncement", { ad_id: props.currentAd.item_id });
		toast.success(`Отзыв успешно удален`, { position: "top-right" });
		if (modalRefConfirm.value !== null) modalRefConfirm.value.close();
	} catch (err) {
		toast.success(`Что то пошло не так, повторите попытку позже`, { position: "top-right" });
		console.error(err);
	}
};

const sendReview = async () => {
	createReviewsRef.value.sendReview();
};

const updateReviews = async (id) => {
	await store.dispatch("reviews/getReviewsAnnouncement", { ad_id: id });
	onBackReviews();
};

const updateList = async () => {
	if (props.reviewsData.isFullfield && reviewStatus.value) {
		await store.dispatch("reviews/updateReviews", { ad_id: props.currentAd.item_id });
	}
};

const openConfirmModal = (id) => {
	idRemoveReview.value = id;
	modalRefConfirm.value.open();
};

const imagesSmall = (reviewData) => {
	return (
		reviewData?.photos !== null &&
		reviewData.photos.map((filename) => {
			return getBuckerFbUrlSmall(filename, reviewData.fb_id);
		})
	);
};

const openPhoto = (idx) => {
	const mappedItems = imagesSmall(props.reviewsData.items[0]).map((url) => ({ src: url }));
	Fancybox.show(mappedItems, { startIndex: idx });
};

const isShowUpCountReview = computed(() => {
	return props.reviewsData.items.length > 1
		? !isDesktop.value || isShow.value || props.isMy || isAdmin.value
		: false;
});

const isDownCountPosition = computed(() => {
	return props.reviewsData.items.length > 1 && !isShow.value && !(!isAdmin.value && props.isMy);
});

provide("closeCreateModal", onBackReviews);

bus.on(onBackReviews);
defineExpose({ openModalReviews });
</script>

<template>
	<div class="announcement-reviews">
		<div class="announcement-reviews__title-wrapper">
			<Typography class="announcement-reviews__title" :type="TYPOGRAPHY_TYPES.CARD_TITLE">
				{{ t("reviewsTitle") }}
			</Typography>
			<button
				v-if="isShowUpCountReview"
				type="button"
				class="announcement-reviews__title-btn"
				@click="openModalReviews"
			>
				<Typography :type="TYPOGRAPHY_TYPES.TEXT_1">
					{{ t("previewMore") }}
					{{
						reviewsData.feedbackCount -
						1 +
						" " +
						plural(
							[t("reviewsCountText1"), t("reviewsCountText2"), t("reviewsCountText3")],
							reviewsData.feedbackCount - 1
						)
					}}
				</Typography>
			</button>
			<button
				v-if="!isShow && !isAdmin && !isMy"
				class="announcement-reviews__btn"
				:class="{ 'announcement-reviews__btn-mobdown': reviewsData.items.length !== 0 }"
				type="button"
				@click="openModalCreateReviews()"
			>
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">{{ t("reviewsBtnLeaveFeedback") }}</Typography>
			</button>
			<div v-if="!isAuth" class="announcement-reviews__btn-clue">
				<Typography class="announcement-reviews__btn-clue-text" :type="TYPOGRAPHY_TYPES.CONTACT">
					{{ t("reviewsreviewsSubbtn") }}
				</Typography>
			</div>
			<Typography
				v-if="reviewsData.items.length === 0"
				class="announcement-reviews__subtitle"
				:class="{ 'announcement-reviews__subtitle-disauth': !isAuth }"
				:type="TYPOGRAPHY_TYPES.TEXT"
			>
				{{ t("noReviewsYet") }}
			</Typography>
		</div>

		<span
			v-if="!isDesktop && reviewsData.items.length !== 0 && !isAdmin && !isMy && !isShow"
			class="line"
		/>

		<div v-if="reviewsData.items.length" class="announcement-reviews__content">
			<Review :data="reviewsData.items[0]" line-content>
				<template #reviewImg v-if="reviewsData.items[0].photos">
					<div class="announcement-reviews__images-wrapper">
						<Image
							v-for="(photoUrl, index) in imagesSmall(reviewsData.items[0])"
							class="announcement-reviews__image"
							:src="photoUrl"
							:key="photoUrl"
							@click="openPhoto(index)"
						/>
					</div>
				</template>
			</Review>
		</div>
		<button
			v-if="user.is_admin && reviewsData.items.length !== 0"
			type="button"
			class="announcement-reviews__delete"
			@click="openConfirmModal(reviewsData.items[0].fb_id)"
		>
			<SvgTemplate :name="'trash'" class="svg-icon__delete" />
			<Typography :type="TYPOGRAPHY_TYPES.TEXT_1" class="announcement-reviews__delete-text">
				{{ t("reviewsDelete") }}
			</Typography>
		</button>
		<button
			v-if="isDownCountPosition && !isAdmin && isDesktop"
			type="button"
			class="announcement-reviews__title-btn announcement-reviews__title-btn_down"
			@click="openModalReviews"
		>
			<Typography :type="TYPOGRAPHY_TYPES.TEXT_1">
				{{ t("previewMore") }}
				{{
					reviewsData.feedbackCount -
					1 +
					" " +
					plural(
						[t("reviewsCountText1"), t("reviewsCountText2"), t("reviewsCountText3")],
						reviewsData.feedbackCount - 1
					)
				}}
			</Typography>
		</button>
	</div>

	<ModalConfirm
		ref="modalRefReview"
		@confirm="sendReview"
		@close="onBackReviews"
		confirm-text="reviewsMoBtnConfirm"
		:allow-self-close="true"
		:with-cross="true"
		:visible-back-btn="false"
		:confirmIsPending="createPending"
		:disable-btn="validErr"
	>
		<CreateReviews
			ref="createReviewsRef"
			:currentAd="currentAd"
			:status="status"
			:data="reviewsData.items"
			:is-created="isCreatedFlag"
			@updateReviews="updateReviews"
			@updatePending="createPending = !createPending"
			@validErr="validErr = false"
		/>
	</ModalConfirm>

	<Modal ref="modalReviews" :hide-cross="true" :full-expand-mobile="true" class="modal-test">
		<template #mobile-title>
			<Typography :type="TYPOGRAPHY_TYPES.CARD_TITLE">{{ t("reviewsTitle") }}</Typography>
		</template>
		<div class="announcement-reviews__modal-wrapper">
			<div class="announcement-reviews__title-wrapper">
				<Typography
					v-if="isDesktop"
					class="announcement-reviews__name"
					:type="TYPOGRAPHY_TYPES.CARD_TITLE"
				>
					{{ t("reviewsTitle") }}
				</Typography>
				<button
					v-if="!isShow && !isAdmin && !isMy"
					class="announcement-reviews__btn announcement-reviews__btn_modal"
					type="button"
					@click="openModalCreateReviews(true)"
				>
					<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">
						{{ t("reviewsBtnLeaveFeedback") }}
					</Typography>
				</button>
				<div v-if="!isAuth" class="announcement-reviews__btn-clue">
					<Typography class="announcement-reviews__btn-clue-text" :type="TYPOGRAPHY_TYPES.CONTACT">
						{{ t("reviewsreviewsSubbtn") }}
					</Typography>
				</div>
			</div>
			<ReviewList
				v-for="review in reviewsData.items"
				class="nnouncement-reviews__review"
				:key="review.fb_id"
				:data="review"
				:emit-on-visible="review.updateOnVisible"
				@visible="updateList"
			>
				<template #reviewRemoveBtn>
					<button
						v-if="user.is_admin"
						type="button"
						class="announcement-reviews__delete announcement-reviews__delete_modal"
						@click="openConfirmModal(review.fb_id)"
					>
						<SvgTemplate :name="'trash'" class="svg-icon__delete" />
						<Typography :type="TYPOGRAPHY_TYPES.TEXT_1" class="announcement-reviews__delete-text">
							{{ t("reviewsDelete") }}
						</Typography>
					</button>
				</template>
			</ReviewList>
		</div>
	</Modal>

	<ModalConfirm
		ref="modalRefConfirm"
		@confirm="removeReview"
		@back="modalRefConfirm.close()"
		back-text="buttonTextCancel"
		confirm-text="buttonTextDelete"
		:with-cross="true"
		:allow-self-close="true"
	>
		<div class="modal-content__confirm">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="announcement-card__main">
				{{ t("reviewsConfirmation") }}
				<br />
				{{ t("reviewsSubConfirmation") }}
			</Typography>
		</div>
	</ModalConfirm>
</template>

<style lang="scss" scoped>
@import "./AnnouncementReviews.scss";
</style>
