import { REQUST_STATUSES } from "@shared/const";
import { ON_PAGE_COUNT } from "@entities/announcement";
import {
	apiPostFeedbackAnnouncement,
	apiGetFeedbackAnnouncement,
	apiRemoveFeedbackAnnouncement,
	apiGetFeedback,
	apiPostFeetdback,
} from "../api";

export const getReviewsAnnouncement = async ({ commit }, data) => {
	try {
		commit("CLEAR_REVIEWS");
		commit("SET_FEEDBACK_STATUS", REQUST_STATUSES.PENDING);
		const response = await apiGetFeedbackAnnouncement(data);
		commit("SET_FEEDBACK_ANNOUNCEMENT", response.data);
		commit("SET_FEEDBACK_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (error) {
		console.error(error);
	}
};

export const getUserReview = async ({ commit }, data) => {
	try {
		commit("CLEAR_USER_REVIEWS");
		commit("SET_USER_FEEDBACK_STATUS", REQUST_STATUSES.PENDING);
		const response = await apiGetFeedbackAnnouncement(data);
		commit("SET_USER_REVIEWS", response.data);
		commit("SET_USER_FEEDBACK_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (error) {
		console.error(error);
	}
};

export const updateReviews = async ({ commit, getters }, payload) => {
	const { getReviews } = getters;
	const nextPage = getReviews.page + 1;
	try {
		const request = {
			...payload,
			on_page: ON_PAGE_COUNT.GENERAL_PAGE_COUNT,
			page: nextPage,
		};

		const { data } = await apiGetFeedbackAnnouncement(request);
		commit("UPDATE_REVIEWS", data);
	} catch (err) {
		console.error(err);
	}
};

export const updateUserReviews = async ({ commit, getters }, payload) => {
	const { getUserReviews } = getters;
	const nextPage = getUserReviews.page + 1;
	try {
		const request = {
			...payload,
			on_page: ON_PAGE_COUNT.GENERAL_PAGE_COUNT,
			page: nextPage,
		};

		const { data } = await apiGetFeedbackAnnouncement(request);
		commit("UPDATE_USER_REVIEWS", data);
	} catch (err) {
		console.error(err);
	}
};

// eslint-disable-next-line consistent-return
export const postFeedbackAnnouncement = async (_, data) => {
	try {
		const res = await apiPostFeedbackAnnouncement(data);
		return res;
	} catch (error) {
		console.error(error);
	}
};

export const removeFeedbackAnnouncement = async (_, id) => {
	try {
		await apiRemoveFeedbackAnnouncement(id);
	} catch (error) {
		console.error(error);
	}
};

export const getFeedback = async ({ commit }) => {
	try {
		const data = await apiGetFeedback();
		commit("SET_FEEDBACK_COUNT", data.data);
	} catch (err) {
		console.error(err);
	}
};

export const postFeedback = async ({ commit }, feedbackData) => {
	try {
		const data = await apiPostFeetdback(feedbackData);
		commit("SET_FEEDBACK_PFD", data.data.pfb_id);
	} catch (e) {
		console.error(e);
	}
};
