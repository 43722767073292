<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { TYPOGRAPHY_TYPES, Typography, Modal } from "@shared/ui";
import { ModalPolicyContent } from "@widgets/ModalPolicyContent";

const store = useStore();

const modalRefPolicy = ref(null);

const isOpenDropdown = ref(false);
const index = ref(0);
const isFirstDocumentIndex = ref(null);

const getDocuments = computed(() => {
	return store.getters["files/getDocuments"];
});

const documents = ref(getDocuments);

const closePolicyModal = () => {
	modalRefPolicy.value.close();
	isFirstDocumentIndex.value = null;
};

const openDropdown = async () => {
	isOpenDropdown.value = true;
};

const closeDropdown = () => {
	isOpenDropdown.value = false;
};

const openModal = (idx) => {
	index.value = idx;
	modalRefPolicy.value.open();
};

const updateDocument = (documentCode) => {
	const indxDocument = getDocuments.value.findIndex(
		(document) => document.document_code === documentCode
	);
	if (indxDocument === isFirstDocumentIndex.value) {
		isFirstDocumentIndex.value = null;
		index.value = indxDocument;

		return;
	}
	if (!isFirstDocumentIndex.value) isFirstDocumentIndex.value = index.value;
	index.value = indxDocument;
};

const back = () => {
	index.value = isFirstDocumentIndex.value;
	isFirstDocumentIndex.value = null;
};

defineExpose({ openDropdown, closeDropdown });
</script>

<template>
	<div class="dropdown-list" :class="{ 'dropdown-list__active': isOpenDropdown }">
		<button
			v-for="(document, index) in documents"
			type="button"
			class="dropdown-list__link"
			@click="openModal(index)"
			:key="document.document_code"
		>
			<Typography :type="TYPOGRAPHY_TYPES.TEXT_OLD">{{ document.document_name }}</Typography>
		</button>
	</div>
	<Modal ref="modalRefPolicy" :buttonDefault="false" :class-name="'test'" @close="closePolicyModal">
		<ModalPolicyContent
			:document-data="documents[index]"
			@updateDocument="updateDocument"
			:show-back-btn="isFirstDocumentIndex !== null"
			@backBtn="back"
		/>
	</Modal>
</template>

<style lang="scss" scoped>
@import "./DropdownPolicy.scss";
</style>
