import { Navigation, Scrollbar, FreeMode } from "swiper";

export const SHORT_SWIPER_OPTIONS = {
	modules: [Navigation, Scrollbar, FreeMode],
	slidesPerView: 2,
	spaceBetween: 16,
	freeMode: true,
	scrollbar: false,
	navigation: {
		nextEl: ".swiper-next",
		prevEl: ".swiper-prev",
	},
	breakpoints: {
		375: {
			slidesPerView: 2,
		},
		768: {
			slidesPerView: 3,
		},
		1024: {
			slidesPerView: 5,
			slidesPerGroup: 2,
			spaceBetween: 20,
			scrollbar: {
				el: ".swiper-scrollbar",
				draggable: true,
			},
		},
	},
};
