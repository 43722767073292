import * as SentryVue from "@sentry/vue";
import { DOMAIN_URL, IS_NATIVE_APP } from "@shared/const";

export const initSentry = async (app, router) => {
	const SentryCapacitor = await import("@sentry/capacitor");
	if (!IS_NATIVE_APP) {
		SentryCapacitor.init(
			{
				app,
				dsn: import.meta.env.VITE_SENTRY_DSN,
				integrations: [
					new SentryVue.BrowserTracing({
						tracePropagationTargets: ["localhost", DOMAIN_URL],
						routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
					}),
				],
				// Performance Monitoring
				tracesSampleRate: 1.0,
				replaysSessionSampleRate: 0.1,
				replaysOnErrorSampleRate: 1.0,
				environment: import.meta.env.VITE_SENTRY_MODE,
			},
			SentryVue.init
		);
	} else {
		SentryVue.init({
			app,
			dsn: import.meta.env.VITE_SENTRY_DSN,
			integrations: [
				new SentryVue.BrowserTracing({
					routingInstrumentation: SentryVue.vueRouterInstrumentation(router),
				}),
			],
			// Performance Monitoring
			tracesSampleRate: 1.0,
			replaysSessionSampleRate: 0.1,
			replaysOnErrorSampleRate: 1.0,
			environment: import.meta.env.VITE_SENTRY_MODE,
		});
	}
};
