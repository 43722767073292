<script setup>
import { ref, computed, watch } from "vue";
import { MyActiveAnnouncementList, ArchiveAnnouncementList } from "@widgets/AnnouncementLists";
import { useRoute, useRouter } from "vue-router";
import { useScreenSize } from "@shared/lib";
import {
	TabsBar,
	TABS_BAR_TYPES,
	NavigationProfile,
	SvgTemplate,
	Typography,
	TYPOGRAPHY_TYPES,
	DeactivateAccount,
} from "@shared/ui";
import { useTranslation } from "i18next-vue";

const route = useRoute();
const router = useRouter();
const { t } = useTranslation();

const annpuncementTitle = computed(() => {
	return {
		ACTIVE: t("myActiveActive"),
		ARCHIVE: t("myActiveArchive"),
	};
});

const tabs = computed(() => annpuncementTitle.value);
const selectedItemType = ref(route.query.tab || t("myActiveActive"));

const isArchive = computed(() => {
	return selectedItemType.value === t("myActiveArchive");
});

const isActive = computed(() => {
	return selectedItemType.value === t("myActiveActive") || !isArchive.value;
});

watch(
	selectedItemType,
	() => {
		router.replace({ query: { tab: selectedItemType.value } });
	},
	{ immediate: true }
);

watch(annpuncementTitle, () => {
	selectedItemType.value = t("myActiveActive");
});

const { isDesktop } = useScreenSize();

const onBack = () => {
	router.back();
};
</script>

<template>
	<div v-if="isDesktop" class="my-announce__container">
		<NavigationProfile />
		<DeactivateAccount />
	</div>
	<div class="my-announce__content">
		<nav class="my-announce__nav">
			<button v-if="!isDesktop" type="button" class="my-announce__nav-back" @click="onBack">
				<SvgTemplate class="back-button__icon" name="left-arrow" />
			</button>
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">{{ t("navigationProfileMyAds") }}</Typography>
		</nav>
		<TabsBar
			:type="TABS_BAR_TYPES.MAIN"
			:tabs="tabs"
			v-model="selectedItemType"
			class="my-announce__tabs-bar"
		></TabsBar>
		<MyActiveAnnouncementList v-if="isActive" />
		<ArchiveAnnouncementList v-if="isArchive" />
	</div>
</template>

<style lang="scss" scoped>
@import "./MyAnnouncement.scss";
</style>
