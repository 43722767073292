import { getApiUrl, HTTP_METHODS } from "@shared/const";
import { httpRequest } from "@shared/lib";

const FEEDBACK = "private_feedback";

const urlFeedback = getApiUrl(FEEDBACK);

export const apiGetFeedbackAnnouncement = (params) => {
	const url = getApiUrl("feedback");
	return httpRequest({ method: HTTP_METHODS.GET, url }, { query: params });
};

export const apiPostFeedbackAnnouncement = (data) => {
	const url = getApiUrl("feedback");
	return httpRequest({ method: HTTP_METHODS.POST, url }, { body: data });
};

export const apiRemoveFeedbackAnnouncement = (params) => {
	const url = getApiUrl("feedback");
	return httpRequest({ method: HTTP_METHODS.DELETE, url }, { query: { fb_id: params } });
};

export const apiPostFeetdback = (feedbackData) => {
	const method = HTTP_METHODS.POST;
	return httpRequest({ url: urlFeedback, method }, { body: feedbackData });
};

export const apiGetFeedback = () => {
	return httpRequest({ method: HTTP_METHODS.GET, url: urlFeedback });
};
