<script setup>
import { computed } from "vue";

const props = defineProps({
	checkBoxParamName: { type: [String, Number], required: false, default: "0" },
	isCheckedDefault: { type: Boolean, default: false },
	disabled: { type: Boolean, required: false, default: false },
});
const checkBoxChecked = computed(() => {
	return props.isCheckedDefault;
});
const emit = defineEmits(["toggleCheckbox"]);

const toggleCheckBox = () => {
	if (props.disabled) {
		return;
	}

	emit("toggleCheckbox", [props.checkBoxParamName, !checkBoxChecked.value]);
};
</script>

<template>
	<div class="checkbox__wrapper">
		<label class="switch">
			<input
				type="checkbox"
				:checked="checkBoxChecked"
				:value="checkBoxChecked"
				:disabled="disabled"
				@click.stop="toggleCheckBox"
			/>
			<span :class="['slider', 'round', { slider_disabled: !disabled }]"></span>
		</label>
		<slot></slot>
	</div>
</template>
<style lang="scss" scoped>
@import "./Checkbox.scss";
</style>
