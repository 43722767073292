export const ANNOUNCEMENT_FILTER_KEYS = /** @type {const} */ ({
	COUNTRY_ID: "country_id",
	REGION_ID: "region_id",
	CURRENCY_ID: "currency_id",
	CURRENCY_DOWN: "currency_down",
	CURRENCY_UP: "currency_up",
});

export const INTEGER_FILTERS = [
	ANNOUNCEMENT_FILTER_KEYS.COUNTRY_ID,
	ANNOUNCEMENT_FILTER_KEYS.REGION_ID,
];
