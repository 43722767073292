import store from "@app/store";
import { router } from "@pages";
import { FB_CONFIG, IS_NATIVE_APP } from "@shared/const";
import { initializeApp } from "firebase/app";
import { getMessaging, onMessage, getToken } from "firebase/messaging";

const initFirebaseWeb = async () => {
	const firebaseConfig = FB_CONFIG;
	const app = initializeApp(firebaseConfig);
	const messaging = getMessaging(app);

	try {
		// Дожидаемся регистрации сервис воркера через VitePwa
		const registration = await navigator.serviceWorker.ready;

		const token = await getToken(messaging, {
			vapidKey: import.meta.env.VITE_FB_VAPIDKEY,
			serviceWorkerRegistration: registration,
		});

		if (token) {
			sessionStorage.setItem("fcm_reg_token", token);
			store.dispatch("user/setFBToken", token);
		}

		onMessage(messaging, (payload) => {
			console.log("Received foreground message: ", payload);
		});
	} catch (error) {
		console.log("An error occurred while retrieving token");
		console.error(error);
	}
};

const initFirebaseNative = async () => {
	const { PushNotifications } = await import("@capacitor/push-notifications");
	// Request permission to use push notifications
	// iOS will prompt user and return if they granted permission or not
	// Android will just grant without prompting
	PushNotifications.requestPermissions().then((result) => {
		if (result.receive === "granted") {
			// Register with Apple / Google to receive push via APNS/FCM
			PushNotifications.register();
		}
	});

	PushNotifications.addListener("registration", (token) => {
		localStorage.setItem("fbm_token", token.value);
		store.dispatch("user/setFBToken", token.value);
	});

	PushNotifications.addListener("pushNotificationReceived", (notification) => {
		console.log("Push notification received: ", notification);
	});

	PushNotifications.addListener("pushNotificationActionPerformed", ({ notification }) => {
		if (notification.data.url) {
			const url = new URL(notification.data.url);
			router.push(url.pathname);
		}
	});
};

export const initFirebase = () => {
	if (IS_NATIVE_APP) {
		initFirebaseNative();
	} else {
		initFirebaseWeb();
	}
};
