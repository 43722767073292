<script setup>
import { ref, computed, watch } from "vue";
import { useStore } from "vuex";
import { SIZES } from "@shared/const";
import {
	TYPOGRAPHY_TYPES,
	Typography,
	SvgTemplate,
	Autocomplete,
	INPUT_THEMES,
	Image,
} from "@shared/ui";
import { getCountryFlag } from "@shared/lib";

const store = useStore();

const props = defineProps({
	userId: {
		type: Number,
		required: true,
	},
	region: {
		type: Number,
		default: 0,
	},
	country: {
		type: Number,
		default: 0,
	},
	regions: {
		type: Array,
		default: () => [],
	},
});

const region = ref();

watch(
	() => props.regions,
	() => {
		if (props.regions.length > 0) {
			region.value = {
				value: {
					id: +props.region,
					name: store.getters["announcement/cityById"](+props.region)?.name,
				},
			};
		}
	},
	{ immediate: true }
);

const SelectedRegion = computed(() => {
	return store.getters["announcement/cityById"](props.region);
});

const selectedCountry = computed(() => {
	return store.getters["announcement/countryByCityIdAnnouncement"](SelectedRegion.value?.id);
});

const edit = ref(false);

const ChangeRegion = () => {
	edit.value = false;
	store.dispatch("user/updateCurrentUser", {
		region_id: region.value.id,
		user_id: props.userId,
	});
};

const location = computed(() => {
	return selectedCountry.value?.name
		? `${selectedCountry.value?.name}, ${SelectedRegion.value?.name}`
		: SelectedRegion.value?.name;
});

const currentCountryFlag = computed(() => {
	return getCountryFlag({
		countryId: selectedCountry.value?.id,
		countryIsoCode: selectedCountry.value?.iso_code,
	});
});
</script>
<template>
	<div class="content_block">
		<div v-if="!edit" class="content_block__row content_block__row_down">
			<Image class="location_icon" :src="currentCountryFlag" :key="currentCountryFlag" />
			<Typography :type="TYPOGRAPHY_TYPES.LABEL_CATEGORY">{{ location }}</Typography>
		</div>
		<div v-else class="content_block__row content_block__row_edit">
			<Autocomplete
				class="content_block__autocomplete"
				:size="SIZES.NORMAL"
				:disableInput="true"
				:hideIcon="true"
				:theme="INPUT_THEMES.DARK"
				:items="regions"
				:reducer="(x) => x.name"
				:modelValue="region.value"
				v-model:modelValue="region"
			></Autocomplete>
			<SvgTemplate @click="ChangeRegion" class="content_block_medium_icon" name="confirm" />
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./Location.scss";
</style>
