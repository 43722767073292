export default {
	ru: {
        announcementListSectionError: "Не удалось загрузить",
    },
    en: {
        announcementListSectionError: "Failed to load",
    },
    ka: {
        announcementListSectionError: "ჩატვირთვა ვერ მოხერხდა",
    },
    tr: {
        announcementListSectionError: "Yüklenemedi",
    },
};