<script setup>
import { reactive, ref, computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import {
	Autocomplete,
	Button,
	SvgTemplate,
	TYPOGRAPHY_TYPES,
	Typography,
	useModal,
} from "@shared/ui";
import useVuelidate from "@vuelidate/core";
import { REQUST_STATUSES } from "@shared/const";
import { Authorization } from "@widgets/Authorization";
import { required } from "@shared/lib";
import { useTranslation } from "i18next-vue";
import { DeleteCautionModal } from "../DeleteCautionModal";

const router = useRouter();

const store = useStore();

const { t } = useTranslation();

const items = [
	"deleteProfileAccountHacked",
	"Нет страны, в которой я нахожусь",
	"У меня есть другой аккаунт",
	"Беспокоюсь за свои персональные данные",
	"Не пользуюсь сервисом",
	"Другое",
];

const formFields = reactive({
	reason: null,
});

const validators = {
	reason: { required },
};

const validate = useVuelidate(validators, formFields, { $scope: false });

const deleteModalRef = ref(null);

const deleteModal = useModal(deleteModalRef);

const onDelete = () => {
	validate.value.$touch();
	if (validate.value.$invalid) {
		return;
	}
	deleteModal.open();
};

const onBack = () => {
	router.push({ name: "Profile" });
};

const currentUser = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const currentUserStatusSuccess = computed(() => {
	return store.getters["user/currentUserStatus"] === REQUST_STATUSES.SUCCESS;
});
</script>

<template>
	<header class="delete-porfile__header" @click="onBack">
		<SvgTemplate name="left-arrow" />
		<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
			{{ t("deactivateAccountDeleteTitle") }}
		</Typography>
	</header>
	<section class="delete-profile" v-if="currentUserStatusSuccess">
		<template v-if="!currentUser.delete_request">
			<Autocomplete
				v-model="formFields.reason"
				:items="items"
				disable-input
				placeholder="Выберите причину удаления"
				:errors="validate.reason.$errors"
				@focus="validate.reason.$reset"
				@blur="validate.reason.$touch"
			/>
			<Button
				class="delete-profile__delete-btn"
				:silent-disabled="validate.$invalid"
				@click="onDelete"
			>
				<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">{{ t("buttonTextDelete") }}</Typography>
			</Button>
			<DeleteCautionModal ref="deleteModalRef" :message="formFields.reason" />
		</template>
		<template v-else>
			<div class="delete-profile__pending">
				<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
					{{ t("deactivateAccountDeleteBeing") }}
				</Typography>
			</div>
		</template>
	</section>
	<Authorization />
</template>

<style lang="scss" scoped>
@import "./DeleteProfile.scss";
</style>
