export default {
	ru: {
		chatsDesktopDescr_1: "Чтобы начать чат с пользователем, нажмите",
		chatsDesktopDescr_2: "Связаться с продавцом",
		chatsDesktopDescr_3: "и выберите",
		chatsDesktopDescr_4: "внутри заинтересовавшего вас объявления",
	},
	en: {
		chatsDesktopDescr_1: "To start a chat with a user, click",
		chatsDesktopDescr_2: "Contact Seller",
		chatsDesktopDescr_3: "and select",
		chatsDesktopDescr_4: "inside the ad that interests you",
	},
	ka: {
		chatsDesktopDescr_1: "მომხმარებელთან ჩატის დასაწყებად დააჭირეთ",
		chatsDesktopDescr_2: "დაუკავშირდით გამყიდველს",
		chatsDesktopDescr_3: "და აირჩიეთ",
		chatsDesktopDescr_4: "თქვენთვის საინტერესო განცხადების შიგნით",
	},
	tr: {
		chatsDesktopDescr_1: "Bir kullanıcıyla sohbet başlatmak için tıklayın",
		chatsDesktopDescr_2: "Satıcıyla İletişime Geçin",
		chatsDesktopDescr_3: "ve seçin",
		chatsDesktopDescr_4: "ilginizi çeken ilan içerisinde",
	},
};
