import { ON_PAGE_COUNT } from "@entities/announcement";

export const SET_FEEDBACK_ANNOUNCEMENT = (state, data) => {
	if (data.items.length >= ON_PAGE_COUNT.GENERAL_PAGE_COUNT) {
		state.reviews.isFullfield = true;
	}

	state.reviews.items.push(...data.items);
	state.reviews.feedbackCount = data.feedback_count;
	state.reviews.avg_rate = data.avg_rate;
	state.reviews.page = 1;
};

export const UPDATE_REVIEWS = (state, payload) => {
	if (payload.items.length >= ON_PAGE_COUNT.GENERAL_PAGE_COUNT) {
		state.reviews.isFullfield = true;
	} else {
		state.reviews.isFullfield = false;
	}

	state.reviews.items.push(...payload.items);
	state.reviews.page += 1;
};

export const SET_USER_REVIEWS = (state, payload) => {
	if (payload.items.length >= ON_PAGE_COUNT.GENERAL_PAGE_COUNT) {
		state.userReviews.isNeedUpdate = true;
	}

	state.userReviews.grade = payload.avg_rate;
	state.userReviews.reviewsCount = payload.feedback_count;
	state.userReviews.items.push(...payload.items);
	state.userReviews.page = 1;
};

export const UPDATE_USER_REVIEWS = (state, payload) => {
	if (payload.items.length >= ON_PAGE_COUNT.GENERAL_PAGE_COUNT) {
		state.userReviews.isNeedUpdate = true;
	} else {
		state.userReviews.isNeedUpdate = false;
	}

	state.userReviews.items.push(...payload.items);
	state.userReviews.page += 1;
};

export const SET_FEEDBACK_STATUS = (state, status) => {
	state.status.review = status;
};

export const CLEAR_REVIEWS = (state) => {
	state.reviews = {
		avg_rate: 0,
		feedbackCount: 0,
		isFullfield: false,
		items: [],
		page: 1,
	};
};

export const CLEAR_USER_REVIEWS = (state) => {
	state.userReviews = {
		grade: 0,
		reviewsCount: 0,
		items: [],
	};
};

export const SET_USER_FEEDBACK_STATUS = (state, status) => {
	state.status.userReviews = status;
};

export const SET_FEEDBACK_COUNT = (state, dataCount) => {
	state.feedbackCount = dataCount;
};

export const SET_FEEDBACK_PFD = (state, id) => {
	state.feedbackId = id;
};
