<script setup>
import { ref, computed } from "vue";
import { useStore } from "vuex";
import { RouterLink, useRoute, useRouter } from "vue-router";
import { SvgTemplate, TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { CatalogModal } from "@features/CatalogModal";
import { useTranslation } from "i18next-vue";

const route = useRoute();
const router = useRouter();
const store = useStore();
const { t } = useTranslation();

const routes = {
	home: { name: "Home" },
	favorites: { name: "Favorites" },
	profile: { name: "Profile" },
	search: { name: "SearchAnnouncementPage" },
	createAnnouncement: { name: "AnnouncementCreate" },
	catalog: { name: "Announcements" },
};

const isActive = (path) => {
	return route.name === path.name;
};

const openCatalogModal = () => {
	catalogModal.value.open();
};

const catalogModal = ref(null);

const enabledFooter = computed(() => {
	if (!store.getters["announcement/activeAdEditor"]) {
		return false;
	}
	return !route.meta.footerDisable;
});

const onSelectGroup = (id) => {
	router.push({ name: "Announcements", query: { group_id: id } });
};

const selectTagCategory = (id) => {
	router.push({ name: "Announcements", query: { tag_category_id: id } });
};
</script>
<template>
	<footer v-if="enabledFooter" class="footer">
		<nav class="footer-nav container">
			<ul class="footer-nav__list">
				<li
					class="footer-nav__item"
					:class="{
						'footer-nav__item_active': isActive(routes.home) || isActive(routes.search),
					}"
				>
					<RouterLink class="footer-nav__link" :to="routes.home">
						<SvgTemplate class="footer-nav__icon" name="wire-house" />
						<Typography class="footer-nav__text" :type="TYPOGRAPHY_TYPES.BUTTON_3">
							{{ t("headerMain") }}
						</Typography>
					</RouterLink>
				</li>
				<li
					class="footer-nav__item"
					:class="{
						'footer-nav__item_active':
							!isActive(routes.favorites) &&
							!isActive(routes.profile) &&
							!isActive(routes.home) &&
							!isActive(routes.search),
					}"
					@click="openCatalogModal"
				>
					<div class="footer-nav__link">
						<SvgTemplate class="footer-nav__icon" name="list-nav" />
						<Typography class="footer-nav__text" :type="TYPOGRAPHY_TYPES.BUTTON_3">
							{{ t("headerCatalog") }}
						</Typography>
					</div>
				</li>
				<li class="footer-nav__item footer-nav__item_add">
					<RouterLink class="footer-nav__link" :to="routes.createAnnouncement">
						<button type="button" class="footer-nav__add">
							<SvgTemplate class="footer-nav__icon" name="plus" />
						</button>
						<Typography class="footer-nav__text" :type="TYPOGRAPHY_TYPES.BUTTON_3">
							{{ t("createAnnoucementBtn2") }}
						</Typography>
					</RouterLink>
				</li>
				<li
					class="footer-nav__item"
					:class="{ 'footer-nav__item_active': isActive(routes.favorites) }"
				>
					<RouterLink class="footer-nav__link" :to="routes.favorites">
						<SvgTemplate class="footer-nav__icon" name="heart" />

						<Typography class="footer-nav__text" :type="TYPOGRAPHY_TYPES.BUTTON_3">
							{{ t("headerFavorites") }}
						</Typography>
					</RouterLink>
				</li>
				<li
					class="footer-nav__item"
					:class="{ 'footer-nav__item_active': isActive(routes.profile) }"
				>
					<RouterLink class="footer-nav__link" :to="routes.profile">
						<SvgTemplate class="footer-nav__icon" name="person" />
						<Typography class="footer-nav__text" :type="TYPOGRAPHY_TYPES.BUTTON_3">
							{{ t("footerMain") }}
						</Typography>
					</RouterLink>
				</li>
			</ul>
			<div class="footer-nav_porabola"></div>
		</nav>
	</footer>
	<CatalogModal
		ref="catalogModal"
		@select-group="onSelectGroup"
		@select-tag-category="selectTagCategory"
	>
		<template #title>{{ t("groupsModalTitle") }}</template>
	</CatalogModal>
</template>

<style lang="scss" scoped>
@import "./FooterNav.scss";
</style>
