import { computed } from "vue";

export const useModal = (modalRef) => {
	const isOpen = computed(() => {
		return modalRef.value?.isOpen || false;
	});

	const open = () => {
		modalRef.value?.open();
	};

	const close = () => {
		modalRef.value?.close();
	};

	return { open, isOpen, close };
};
