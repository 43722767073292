<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { SwiperSlide } from "swiper/vue";

import { Typography, TYPOGRAPHY_TYPES, AdaptiveSlider } from "@shared/ui";
import { SHORT_SWIPER_OPTIONS } from "@shared/const";
import {
	AnnouncementPreview,
	ON_PAGE_COUNT,
	getAnnouncementOrderParams,
	FIELD,
} from "@entities/announcement";
import { useScreenSize } from "@shared/lib";
import { useTranslation } from "i18next-vue";

const store = useStore();
const { isDesktop, isTablet } = useScreenSize();
const { t } = useTranslation();

const currentAD = computed(() => {
	return store.getters["announcement/getADById"];
});

const similarProducsSlider = ref([]);
const recenttlyWatchedSlider = ref([]);

const getProducts1 = async (params) => {
	recenttlyWatchedSlider.value = [];
	similarProducsSlider.value = [];
	await store.dispatch("announcement/getSliderData", {
		params: params.params,
		onPage: params.pageCount,
	});

	const sliderList = store.getters["announcement/announcementsSliderList"];

	params.variable.value.push({
		announcementsSliderList: sliderList,
		titleSlider: params.titleSliders,
	});
};

const currentRegionId = computed(() => {
	return store.getters["announcement/currentRegion"]?.id;
});

const getSimilarProducs = (paramsData, currentRegionId) => {
	return {
		params: {
			category_id: paramsData.category_id,
			country_id: paramsData.country_id,
			item_id: paramsData.item_id,
			orders: [
				getAnnouncementOrderParams(FIELD.SUBCATEGORY, paramsData.sub_category_id, 1),
				getAnnouncementOrderParams(FIELD.REGION, currentRegionId, 2),
			],
			page: 1,
		},
		pageCount: ON_PAGE_COUNT.ON_SLIDER_COUNT,
		titleSliders: t("sliderTitleSimilarProducts"),
		variable: similarProducsSlider,
	};
};

const getRecenttlyWatched = (paramsData) => {
	return {
		params: {
			recently: true,
			item_id: paramsData.item_id,
		},
		pageCount: ON_PAGE_COUNT.ON_SLIDER_COUNT,
		titleSliders: t("sliderTitleRecenttlyWatched"),
		variable: recenttlyWatchedSlider,
	};
};

watch(
	() => currentAD.value.item_id,
	(newValue) => {
		if (newValue) {
			getProducts1(getSimilarProducs(currentAD.value, currentRegionId.value));
			getProducts1(getRecenttlyWatched(currentAD.value));
		}
	},
	{ immediate: false }
);

const suspensePreviewCount = computed(() => {
	if (isDesktop.value) {
		return 5;
	}
	if (isTablet.value) {
		return 3;
	}

	return 2;
});
</script>

<template>
	<div class="slider-wrapper">
		<div v-if="similarProducsSlider[0]?.announcementsSliderList.length > 3" class="slider-box">
			<AdaptiveSlider
				v-for="(slider, index) in similarProducsSlider"
				:key="index"
				:swiperOptions="SHORT_SWIPER_OPTIONS"
				:slider-length="similarProducsSlider[0]?.announcementsSliderList.length"
				:countSlider="suspensePreviewCount"
			>
				<template #title>
					<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="groups-header">
						{{ slider.titleSlider }}
					</Typography>
				</template>
				<template #swiperSlide>
					<SwiperSlide v-for="item in slider.announcementsSliderList" :key="item.item_id">
						<AnnouncementPreview :announcementCard="item" :with-favorite="true" />
					</SwiperSlide>
				</template>
			</AdaptiveSlider>
			<template v-if="similarProducsSlider.length === 0">
				<AdaptiveSlider :swiperOptions="SHORT_SWIPER_OPTIONS">
					<template #title>
						<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="groups-header">
							Загрузка...
						</Typography>
					</template>
					<template #swiperSlide>
						<SwiperSlide
							v-for="item in [...Array(suspensePreviewCount).keys()]"
							:key="item.item_id"
						>
							<AnnouncementPreview :suspense="true" />
						</SwiperSlide>
					</template>
				</AdaptiveSlider>
			</template>
		</div>

		<div v-if="recenttlyWatchedSlider[0]?.announcementsSliderList.length > 3" class="slider-box">
			<AdaptiveSlider
				v-for="(slider, index) in recenttlyWatchedSlider"
				:key="index"
				:swiperOptions="SHORT_SWIPER_OPTIONS"
				:slider-length="recenttlyWatchedSlider[0]?.announcementsSliderList.length"
				:countSlider="suspensePreviewCount"
			>
				<template #title>
					<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="groups-header">
						{{ slider.titleSlider }}
					</Typography>
				</template>
				<template #swiperSlide>
					<SwiperSlide v-for="item in slider.announcementsSliderList" :key="item.item_id">
						<AnnouncementPreview :announcementCard="item" :with-favorite="true" />
					</SwiperSlide>
				</template>
			</AdaptiveSlider>
			<template v-if="recenttlyWatchedSlider.length === 0">
				<AdaptiveSlider :swiperOptions="SHORT_SWIPER_OPTIONS">
					<template #title>
						<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="groups-header">
							Загрузка...
						</Typography>
					</template>
					<template #swiperSlide>
						<SwiperSlide v-for="item in [...Array(suspensePreviewCount).keys()]" :key="item">
							<AnnouncementPreview :suspense="true" />
						</SwiperSlide>
					</template>
				</AdaptiveSlider>
			</template>
		</div>
	</div>
</template>

<style scoped lang="scss">
@import "./AnnounceCardSliders.scss";
</style>
