<script setup>
import { TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { useScreenSize } from "@shared/lib";
import { computed } from "vue";
import { useTranslation } from "i18next-vue";

const { isDesktop } = useScreenSize();

const { t } = useTranslation();

const rubricList = computed(() => [
	{
		title: t("goods"),
		src: "/img/cart.svg",
		query: { group_id: 1 },
	},
	{
		title: t("services"),
		src: "/img/wrench-screwdriver.svg",
		query: { group_id: 2 },
	},
	{
		title: t("jobs"),
		src: "/img/briefcase.svg",
		query: { group_id: 3 },
	},
	{
		title: t("events"),
		src: "/img/theatermasks.svg",
		query: { group_id: 4 },
	},
	{
		title: t("realEstate"),
		src: "/img/house.svg",
		query: { group_id: 5 },
	},
	{
		title: t("transport"),
		src: "/img/car.svg",
		query: { group_id: 6 },
	},
	{
		title: t("tourism"),
		src: "/img/figure-walk.svg",
		query: { group_id: 7 },
	},
]);
</script>

<template>
	<section class="rubricator">
		<RouterLink
			v-for="(rubric, index) in rubricList"
			class="rubricator__item"
			:to="{ name: 'Announcements', query: rubric.query }"
			:key="index"
		>
			<div class="rubricator__text-container">
				<Typography
					class="rubricator__title"
					:type="isDesktop ? TYPOGRAPHY_TYPES.BOLD_TEXT : TYPOGRAPHY_TYPES.PRO_TEXT"
				>
					{{ rubric.title }}
				</Typography>
				<img class="rubricator__img" :src="rubric.src" :alt="rubric.title" />
			</div>
		</RouterLink>
	</section>
</template>

<style lang="scss" scoped>
@import "./RubricatorDesktop.scss";
</style>
