<script setup>
import { ref } from "vue";
import { SIZES } from "@shared/const";

defineProps({
	/** Размер */
	size: {
		type: String,
		default: SIZES.BIG,
		valiadator(size) {
			return Object.values(SIZES.BIG).includes(size);
		},
	},
});

const element = ref(null);

const adaptiveStyles = ref(null);
</script>
<template>
	<div class="spinner" :class="[`spinner_${size}`]" ref="element" :style="adaptiveStyles"></div>
</template>

<style lang="scss" scoped>
@import "./Spinner.scss";
</style>
