export default {
	ru: {
		myActiveEmptyListPleasePress: "Нажмите, чтобы создать первое:",
	},
	en: {
		myActiveEmptyListPleasePress: "Click to create your first:",
	},
	ka: {
		myActiveEmptyListPleasePress: "დააჭირეთ რომ შექმნათ პირველი:",
	},
	tr: {
		myActiveEmptyListPleasePress: "İlkini oluşturmak için tıklayın:",
	},
};
