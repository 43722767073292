import { createSharedComposable, useCssVar } from "@vueuse/core";
import { reactive, computed, nextTick } from "vue";
import { IS_IOS } from "@shared/const";
import { SafeArea } from "capacitor-plugin-safe-area";
import { withOutPx } from "../format";

const screenSafeAreaComposable = () => {
	const safeAreaInset = reactive({
		top: 0,
		bottom: 0,
		left: 0,
		right: 0,
	});

	const top = computed(() => {
		return safeAreaInset.top;
	});
	const bottom = computed(() => {
		return safeAreaInset.bottom;
	});
	const left = computed(() => {
		return safeAreaInset.left;
	});
	const right = computed(() => {
		return safeAreaInset.right;
	});

	const topNum = computed(() => {
		return withOutPx(safeAreaInset.top);
	});
	const bottomNum = computed(() => {
		return withOutPx(safeAreaInset.bottom);
	});
	const leftNum = computed(() => {
		return withOutPx(safeAreaInset.left);
	});
	const rightNum = computed(() => {
		return withOutPx(safeAreaInset.right);
	});

	const init = async () => {
		if (IS_IOS) {
			await SafeArea.getSafeAreaInsets().then(({ insets }) => {
				// eslint-disable-next-line no-restricted-syntax
				for (const [key, value] of Object.entries(insets)) {
					document.documentElement.style.setProperty(`--safe-area-${key}`, `${value}px`);
					safeAreaInset[key] = value;
				}
			});

			// when safe-area changed
			const eventListener = await SafeArea.addListener("safeAreaChanged", (data) => {
				const { insets } = data;
				// eslint-disable-next-line no-restricted-syntax
				for (const [key, value] of Object.entries(insets)) {
					document.documentElement.style.setProperty(`--safe-area-${key}`, `${value}px`);
					safeAreaInset[key] = value;
				}
			});

			eventListener.remove();
		} else {
			const topVarName = "--safe-area-top";
			const rightVarName = "--safe-area-right";
			const bottomVarName = "--safe-area-bottom";
			const leftVarName = "--safe-area-left";

			const topCssVar = useCssVar(topVarName);
			const rightCssVar = useCssVar(rightVarName);
			const bottomCssVar = useCssVar(bottomVarName);
			const leftCssVar = useCssVar(leftVarName);

			topCssVar.value = "env(safe-area-inset-top, 0px)";
			rightCssVar.value = "env(safe-area-inset-right, 0px)";
			bottomCssVar.value = "env(safe-area-inset-bottom, 0px)";
			leftCssVar.value = "env(safe-area-inset-left, 0px)";
			const update = () => {
				const areaInsets = {
					top: getComputedStyle(document.documentElement).getPropertyValue(topVarName),
					right: getComputedStyle(document.documentElement).getPropertyValue(rightVarName),
					bottom: getComputedStyle(document.documentElement).getPropertyValue(bottomVarName),
					left: getComputedStyle(document.documentElement).getPropertyValue(leftVarName),
				};

				safeAreaInset.top = withOutPx(areaInsets.top);
				safeAreaInset.right = withOutPx(areaInsets.right);
				safeAreaInset.bottom = withOutPx(areaInsets.bottom);
				safeAreaInset.left = withOutPx(areaInsets.left);
			};
			document.addEventListener("DOMContentLoaded", () => {
				nextTick(() => {
					update();
				});
				// Я не знаю почему это сразу не срабатывает на IOS
				setTimeout(() => update(), 100);
			});
		}
	};

	return { top, bottom, left, right, topNum, bottomNum, leftNum, rightNum, init };
};

export const useScreenSafeArea = createSharedComposable(screenSafeAreaComposable);
