import { getApiUrl, HTTP_METHODS } from "@shared/const";
import { httpRequest } from "@shared/lib";

export const apiGetAd = (params) => {
	const url = getApiUrl("ads");
	return httpRequest({ method: HTTP_METHODS.GET, url }, { query: params });
};

export const apiPostAdActivity = (params) => {
	const url = getApiUrl("ads");
	return httpRequest({ method: HTTP_METHODS.POST, url }, { body: params }, { silentError: true });
};
