import { REQUST_STATUSES } from "@shared/const";

import { apiGetStories } from "../api";

export const getStories = async ({ commit }) => {
	// if (checkNeedToFetchData(storiesStatus)) {
	commit("SET_STORIES_STATUS", REQUST_STATUSES.PENDING);
	try {
		const { data } = await apiGetStories();
		commit("SET_STORIES_STATUS", REQUST_STATUSES.SUCCESS);
		commit("SET_STORIES", data);
	} catch {
		commit("SET_STORIES_STATUS", REQUST_STATUSES.ERROR);
	}
	// }
};
