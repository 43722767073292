<script setup>
import { ref, computed } from "vue";
import { TransitionPresets, executeTransition, useSwipe } from "@vueuse/core";
import { IS_IOS } from "@shared/const";
import { useHaptics, withPx } from "@shared/lib";
import { IosSpinner } from "../Spinner";
import { useRefresh } from "./useRefresh";

const refreshRef = ref(null);

const height = ref(0);

const { isRefreshable, refresh } = useRefresh();

const MAX_SWIPE_Y_OFFSET = 75;

const windowScrollYOnStart = ref(0);

const THRESHOLD = 50;

const STEPS = {
	IDLE: "IDLE",
	START: "START",
	REFRESH_START: "REFRESH_START",
	REFRESH_END: "REFRESH_END",
	END: "END",
};

const setHeight = (num) => {
	executeTransition(height, height.value, num, {
		transition: TransitionPresets.easeInOutCubic,
		duration: 150,
	});
};

const currentStep = ref(STEPS.IDLE);

const refreshContentStyles = computed(() => {
	const res = {
		height: withPx(Math.ceil(height.value)),
	};
	return res;
});

const swipeYOffsetRef = ref(null);

const onRefreshSucces = () => {
	currentStep.value = STEPS.REFRESH_END;
	setHeight(0);
};

const { impactLight } = useHaptics();

const onRefresh = async () => {
	currentStep.value = STEPS.REFRESH_START;
	impactLight();
	await refresh();
	onRefreshSucces();
};

const currentProgress = computed(() => {
	return height.value / (MAX_SWIPE_Y_OFFSET / 100);
});

const onSwipeStart = () => {
	if (!isRefreshable.value) return;
	if (
		currentStep.value === STEPS.IDLE ||
		currentStep.value === STEPS.REFRESH_END ||
		currentStep.value === STEPS.END
	) {
		windowScrollYOnStart.value = window.scrollY;
		currentStep.value = STEPS.START;
	}
};

const onSwipe = () => {
	if (currentStep.value === STEPS.START) {
		const swipeYOffset = (windowScrollYOnStart.value + lengthY.value + THRESHOLD) * -1;
		swipeYOffsetRef.value = swipeYOffset;

		if (swipeYOffset > MAX_SWIPE_Y_OFFSET) {
			const logBase = swipeYOffset - MAX_SWIPE_Y_OFFSET;
			height.value = MAX_SWIPE_Y_OFFSET + logBase / 8;
			if (currentStep.value === STEPS.START) {
				onRefresh();
			}
		} else {
			height.value = swipeYOffset;
		}
	}
};

const onSwipeEnd = () => {
	if (currentStep.value === STEPS.REFRESH_START) {
		setHeight(MAX_SWIPE_Y_OFFSET);
	} else {
		setHeight(0);
		currentStep.value = STEPS.END;
	}
};

const { lengthY } = useSwipe(refreshRef, {
	onSwipe,
	onSwipeEnd,
	onSwipeStart,
	threshold: THRESHOLD,
});
</script>

<template>
	<div class="refresh" ref="refreshRef" v-if="IS_IOS">
		<div class="refresh-content" :style="refreshContentStyles" v-if="isRefreshable">
			<IosSpinner
				:progress="currentProgress"
				:no-animation="currentStep === STEPS.START || currentStep === STEPS.END"
			/>
		</div>
		<slot></slot>
	</div>
	<slot v-else></slot>
</template>

<style lang="scss" scoped>
@import "./Refresh.scss";
</style>
