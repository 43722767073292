export default {
	ru: {
		createAnnoucementBtn: "Создать объявление",
		createAnnoucementBtn2: "Создать",
	},
	en: {
		createAnnoucementBtn: "Create ad",
		createAnnoucementBtn2: "Create",
	},
	ka: {
		createAnnoucementBtn: "განცხადების შექმნა",
		createAnnoucementBtn2: "შექმნა",
	},
	tr: {
		createAnnoucementBtn: "İlan oluştur",
		createAnnoucementBtn2: "Oluştur",
	},
};
