<script setup>
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { SvgTemplate, TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { SUPPORT_TELEGRAM, SUPPORT_WHATSAPP, SCREEN_SIZES } from "@shared/const";
import { useWindowSize } from "@vueuse/core";
import { useTranslation } from "i18next-vue";

const route = useRoute();
const { width } = useWindowSize();
const { t } = useTranslation();

const isShowBlock = computed(() => {
	const disallowedPages = [
		"Profile",
		"AnnouncementCreate",
		"ProfileEdit",
		"AnounceCardPage",
		"myAnnouncements",
		"Chat",
		"Chats",
	];
	return !disallowedPages.includes(route.name);
});

const isActive = ref(false);

const isVisibleContent = computed(() => {
	if (width.value >= SCREEN_SIZES.xxl) {
		return true;
	}

	return isActive.value;
});

const toggleBtnForm = () => {
	if (width.value >= SCREEN_SIZES.xxl) {
		return;
	}

	isActive.value = !isActive.value;
};

const hidenScrollHandler = () => {
	isActive.value = false;
};

watch(
	() => isActive.value,
	(newIsActive, oldIsActive) => {
		if (width.value <= SCREEN_SIZES.xxl) {
			if (newIsActive && !oldIsActive) {
				document.addEventListener("scroll", hidenScrollHandler);
				setTimeout(() => {
					document.addEventListener("click", hidenScrollHandler);
				}, 100);
			} else if (!newIsActive && oldIsActive) {
				document.removeEventListener("scroll", hidenScrollHandler);
				document.removeEventListener("click", hidenScrollHandler);
			}
		}
	}
);
</script>

<template>
	<div v-if="isShowBlock" class="support" :class="{ rectangular: isActive }" @click="toggleBtnForm">
		<SvgTemplate v-if="!isVisibleContent" class="content_block__big_icon" name="support-icon" />
		<Typography v-if="isVisibleContent" :type="TYPOGRAPHY_TYPES.BUTTON_2">
			{{ t("technicalSupport") }}
		</Typography>
		<a
			v-if="isVisibleContent"
			:href="'https://wa.me/' + SUPPORT_WHATSAPP"
			aria-label="whatsapp"
			target="_blank"
			rel="noopener noreferrer"
			class="support__link"
		>
			<SvgTemplate class="content_block__big_icon" name="whatsapp" />
		</a>
		<a
			v-if="isVisibleContent"
			:href="'https://t.me/' + SUPPORT_TELEGRAM"
			aria-label="telegram"
			target="_blank"
			rel="noopener noreferrer"
			class="support__link"
		>
			<SvgTemplate class="support__link-tg" name="tg" />
		</a>
	</div>
</template>

<style scoped lang="scss">
@import "./TechnicalSupport.scss";
</style>
