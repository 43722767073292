<script setup>
import { SvgTemplate } from "../SvgTemplate";

const props = defineProps({
	disableClose: { type: Boolean, default: false },
	hideCloseBtn: { type: Boolean, default: false },
	showBackGround: { type: Boolean, default: false },
	noPaddingContent: { type: Boolean, default: false },
});

const emit = defineEmits(["close"]);

const onClose = () => {
	emit("close");
};

const onClickOutlet = () => {
	const { disableClose, hideCloseBtn } = props;
	if (!disableClose && !hideCloseBtn) {
		onClose();
	}
};
</script>
<template>
	<div class="modal-desktop__overlay" @click.self="onClickOutlet"></div>
	<div class="modal-desktop" :class="{ 'modal-desktop_background': showBackGround }">
		<div v-if="!disableClose && !hideCloseBtn" class="modal-desktop__close" @click="onClose">
			<SvgTemplate name="close" />
		</div>
		<div
			class="modal-desktop__content"
			:class="{ 'modal-desktop__nopadding-content': noPaddingContent }"
		>
			<slot />
		</div>
	</div>
</template>

<styles lang="scss" scoped>
@import "./ModalDesktop.scss";
</styles>
