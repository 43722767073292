<script setup>
import { computed } from "vue";

import { SearchAnnouncementList } from "@widgets/AnnouncementLists";
import { useKeepAliveRoute } from "@shared/lib";

const keepAliveRoute = useKeepAliveRoute();

const term = computed(() => {
	return keepAliveRoute.value.params.term;
});
</script>
<template>
	<section class="search-page">
		<SearchAnnouncementList :term="term" />
	</section>
</template>

<style lang="scss" scoped>
@import "./SearchAnnouncementPage.scss";
</style>
