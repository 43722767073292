import { REQUST_STATUSES } from "@shared/const";
import { difference, entries, keyBy, orderBy, pickBy, last } from "lodash";
import { computed, ref, unref } from "vue";

export const useSplitList = (list, isFullfield, status) => {
	const splitReducers = ref({});

	const setSplitReducer = (id, reducer, order) => {
		splitReducers.value[id] = { order, reducer };
	};

	const removeSplitReducer = (id) => {
		delete splitReducers.value[id];
	};

	const splitReducerByOrder = computed(() => {
		const splitReducersCollection = entries(splitReducers.value).map(
			([id, { reducer, order }]) => ({ id, reducer, order })
		);

		const res = orderBy(splitReducersCollection, "order", "asc");
		return res;
	});

	const getItemsByReducer = (items, reducer) => {
		return items.filter((item) => {
			if (item.is_ad) {
				return item;
			}
			return reducer(item);
		});
	};

	const splitedList = computed(() => {
		let result = [];

		let announcementList = unref(list).filter((item) => !item.is_ad);

		const adsWithPositions = pickBy(unref(list), (item) => item.is_ad);

		// Явно обозначаем, по какому объявлению будет апдейт списка
		if (announcementList.length > 0) {
			announcementList[announcementList.length - 1].updateOnVisible = true;
		}

		let currentLength = 0;

		splitReducerByOrder.value.forEach(({ id, reducer }) => {
			const items = getItemsByReducer(announcementList, reducer);

			const lastItem = last(items);

			const lastItemIndex = unref(list).findIndex((item) => item === lastItem) + 1;

			const adsByPosition = pickBy(
				adsWithPositions,
				(val, key) => key >= currentLength && key <= lastItemIndex + currentLength
			);

			Object.keys(adsByPosition).forEach((idx) => {
				items.splice(idx - currentLength, 0, adsByPosition[idx]);
			});

			const isEmpty = items.length === 0;
			result.push({ id, items, isEmpty });
			// Исключаем из списка те элементы, который были взяты текущим редюсером
			announcementList = difference(announcementList, items);

			currentLength += items.length;
		});

		result = result.map((splitItem, index) => {
			const isLastItem = index === result.length - 1;

			let isItemFullfield = false;

			const isListEmpty = status.value === REQUST_STATUSES.SUCCESS && unref(list).length === 0;

			// Проверка заполнен ли список по редюсеру
			if (!isListEmpty) {
				// Если это последний список, завязываемся на статус самого списка
				if (isLastItem) {
					isItemFullfield = isFullfield.value;
				} else {
					// Если нет, то проверяем  что если какой-то из следующих списков не пустой и если он не пустой то считаем что текущий заполнен
					isItemFullfield = result.slice(index + 1).some((item) => !item.isEmpty);
				}
			} else {
				isItemFullfield = true;
			}

			const isEmpty = isItemFullfield ? splitItem.isEmpty : false;
			const listStatus = isItemFullfield ? REQUST_STATUSES.SUCCESS : status.value;
			const res = { ...splitItem, isFullfield: isItemFullfield, isEmpty, status: listStatus };

			return res;
		});

		return result;
	});

	const splitedListDict = computed(() => {
		const res = keyBy(splitedList.value, "id");
		return res;
	});

	return { splitedList, splitedListDict, setSplitReducer, removeSplitReducer };
};
