<script setup>
import { ref } from "vue";
import { THEME_VARIANTS } from "@shared/const";
import { useTranslation } from "i18next-vue";

import { Modal } from "../Modal";
import { Button } from "../Button";
import { TYPOGRAPHY_TYPES, Typography } from "../Typography";

const props = defineProps({
	confirmText: { type: String, default: "buttonTextSave" },
	backText: { type: String, default: "buttonTextCancel" },
	visibleBackBtn: { type: Boolean, default: true },
	allowSelfClose: { type: Boolean, default: false },
	fullExpandMobile: { type: Boolean, default: false },
	showBackGround: { type: Boolean, default: false },
	withCross: { type: Boolean, default: false },
	confirmIsPending: { type: Boolean, default: false },
	backIsPending: { type: Boolean, default: false },
	disableBtn: { type: Boolean, default: false },
	hideTopBar: { type: Boolean, default: false },
	themeConfirmBtn: { type: String, default: THEME_VARIANTS.PRIMARY },
	themeBackBtn: { type: String, default: THEME_VARIANTS.LIGHT },
});

const { t } = useTranslation();

const modal = ref(null);

const emit = defineEmits(["confirm", "back", "open", "close"]);

const onComfirm = () => {
	emit("confirm");
};

const onBack = () => {
	emit("back");
};

const onClose = () => {
	if (props.allowSelfClose) {
		modal.value.close();
	}
	emit("close");
};

const onOpen = () => {
	emit("open");
};

defineExpose({ open: () => modal.value.open(), close: () => modal.value.close() });
</script>
<template>
	<Modal
		ref="modal"
		:disable-close="!allowSelfClose"
		:hide-close-btn="allowSelfClose && !withCross"
		:full-expand-mobile="fullExpandMobile"
		:show-back-ground="showBackGround"
		:hide-top-bar="hideTopBar"
		@close="onClose"
		@open="onOpen"
	>
		<div :class="['modal-confirm', { 'modal-confirm_height': hideTopBar }]">
			<div class="modal-confirm__content">
				<slot />
			</div>
			<div :class="['confirm-control', { 'confirm-control__hidden-back': !visibleBackBtn }]">
				<Button
					@click="onComfirm"
					:variant="themeConfirmBtn"
					:pending="confirmIsPending"
					:disabled="disableBtn"
				>
					<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">{{ t(confirmText) }}</Typography>
				</Button>
				<Button
					v-if="visibleBackBtn"
					@click="onBack"
					:variant="themeBackBtn"
					:pending="backIsPending"
				>
					<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">{{ t(backText) }}</Typography>
				</Button>
			</div>
		</div>
	</Modal>
</template>

<style lang="scss" scoped>
@import "./ModalConfirm.scss";
</style>
