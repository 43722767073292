<script setup>
import { ref, computed, nextTick } from "vue";
import { useStore } from "vuex";
import {
	Typography,
	TYPOGRAPHY_TYPES,
	SvgTemplate,
	PhotoPreview,
	TextArea,
	PENDING_KEY_PHOTO_PREVIEW,
} from "@shared/ui";
import { FileLoader } from "@entities/files";
import { apiDeleteADPhoto, apiGetADPhoto, apiPostADPhoto } from "@entities/announcement";
import { ProfilePendingEdit } from "@features/ProfilePendingEdit";
import { Authorization } from "@widgets/Authorization";
import { useToast } from "vue-toast-notification";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	currentAd: {
		type: Object,
		default: () => {},
	},
	status: {
		type: String,
		default: "",
	},
	data: {
		type: Array,
		default: () => [],
	},
});

const emit = defineEmits(["updateReviews", "validReviews", "updatePending", "validErr"]);
const store = useStore();
const toast = useToast();
const { t } = useTranslation();

const fileLoaderRef = ref(null);
const selectedGrade = ref(0);
const files = ref([]);
const isFilesPending = ref(false);
const descriptions = ref("");
const entityId = ref(null);
const updateFilesArray = ref([]);
const isSendReview = ref(false);

const filesArray = (filesLoad) => {
	isFilesPending.value = false;
	files.value = filesLoad.map((x) => x.url);
	if (isSendReview.value) {
		emit("updateReviews", props.currentAd.item_id);
		toast.success(t("reviewsToastSuccess"), { position: "top-right" });
		isSendReview.value = false;
	}
};

const pendingFilesArray = (pendingFiles) => {
	isFilesPending.value = true;
	const pendingFilesCount = pendingFiles.length;
	files.value = [...files.value, ...new Array(pendingFilesCount).fill(PENDING_KEY_PHOTO_PREVIEW)];
};

const entity = computed(() => {
	return `fb/${entityId.value}`;
});

const selectGrade = (grade) => {
	emit("validErr");
	selectedGrade.value = grade;
};

const sendReview = async () => {
	const dict = {
		ad_id: props.currentAd.item_id,
		fb_text: descriptions.value,
		rate: selectedGrade.value,
	};
	if (dict.rate !== 0) {
		try {
			const response = await store.dispatch("reviews/postFeedbackAnnouncement", dict);
			entityId.value = await response.data.fb_id;
			if (files.value.length !== 0) {
				await nextTick(() => fileLoaderRef.value.uploadDeferredFiles());
				isSendReview.value = true;
				emit("updatePending", isSendReview.value);
			} else {
				emit("updateReviews", props.currentAd.item_id);
			}
		} catch (e) {
			toast.error(t("reviewsToastError"), { position: "top-right" });
			console.error(e);
		}
	} else {
		emit("validErr");
	}
};

const deletePhoto = (photo) => {
	const newFiles = files.value.filter((file) => file !== photo);
	files.value = newFiles;
	updateFilesArray.value = photo;
};

defineExpose({ sendReview });
</script>

<template>
	<Typography class="create-reviews__title" :type="TYPOGRAPHY_TYPES.CARD_TITLE">
		{{ t("reviewsBtnLeaveFeedback") }}
	</Typography>
	<div class="create-reviews__grade-wrapper">
		<button
			type="button"
			v-for="item in 5"
			:class="[
				'create-reviews__grade',
				{
					'create-reviews__grade-selected': item <= selectedGrade,
				},
			]"
			:key="item + 'create-grade'"
			@click="selectGrade(item)"
		>
			<SvgTemplate name="star" />
		</button>
	</div>
	<Typography
		class="create-reviews__grade-text grade-text"
		:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
	>
		{{ t("createReviewsGradeText1") }}
		<br />
		{{ t("createReviewsGradeText2") }}
	</Typography>

	<div class="create-reviews__foto-block">
		<Typography :type="TYPOGRAPHY_TYPES.CARD_TITLE">{{ t("photoTitle") }}</Typography>
		<Typography
			class="create-reviews__foto-text grade-text"
			:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
		>
			{{ t("createReviewsPhotoText") }}
		</Typography>
		<div class="create-reviews__upload-block">
			<FileLoader
				ref="fileLoaderRef"
				:entityId="entity"
				:apiDeleteFile="apiDeleteADPhoto"
				:apiGetFiles="apiGetADPhoto"
				:apiPostFiles="apiPostADPhoto"
				:photoForDelete="photoForDelete"
				:updateFilesArray="updateFilesArray"
				:file-limit="5"
				with-deferred-save
				@NewFiles="filesArray"
				@pending="pendingFilesArray"
			>
				<template #button>
					<button class="create-reviews__upload-btn" type="button">
						<SvgTemplate
							class="create-reviews__upload-btn create-reviews__upload-btn_icon"
							name="camera"
						/>
					</button>
				</template>
			</FileLoader>
			<PhotoPreview
				:items="files"
				:currentId="currentAd.item_id"
				:key="isNew"
				:default-count="files.length !== 0 ? 1 : 4"
				@deletePhoto="deletePhoto"
			/>
		</div>
	</div>

	<div class="create-reviews__impression-block">
		<Typography :type="TYPOGRAPHY_TYPES.CARD_TITLE">{{ t("createReviewsImpression") }}</Typography>
		<TextArea
			class="create-reviews__impression-text"
			v-model="descriptions"
			@modelValue:update="(text) => (descriptions = text)"
			:max="1000"
			:placeholder="t('descriptionPlaceholder')"
		/>
	</div>

	<ProfilePendingEdit prevent-back />
	<Authorization prevent-back />
</template>

<style lang="scss" scoped>
@import "./CreateReviews.scss";
</style>
