<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

import { Typography, TYPOGRAPHY_TYPES, Spinner } from "@shared/ui";
import { REQUST_STATUSES } from "@shared/const";
import { useTranslation } from "i18next-vue";
import { ChatsList } from "../ChatsList";

const store = useStore();
const { t } = useTranslation();

const chats = computed(() => {
	return store.getters["chat/chats"];
});

const chatsLoaded = computed(() => {
	return store.getters["chat/chatsStatus"] === REQUST_STATUSES.SUCCESS;
});

const anyChats = computed(() => {
	return chats.value.length > 0 && chatsLoaded.value;
});
</script>
<template>
	<div v-if="anyChats" class="chats-desktop">
		<div class="chats-desktop__chat-list-wrapper">
			<div class="chats-desktop__chat-list-container">
				<ChatsList :chats="chats" />
			</div>
		</div>
		<div class="chats-desktop__chat-container">
			<RouterView />
		</div>
	</div>
	<div class="no-chats" v-else-if="!chatsLoaded">
		<Spinner />
	</div>
	<div class="no-chats" v-else>
		<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
			{{ t("favoritesAnnouncementListEmptyTitle") }}
		</Typography>
		<Typography :type="TYPOGRAPHY_TYPES.TEXT">
			{{ t("chatsDesktopDescr_1") }}
			<Typography tag="span" :type="TYPOGRAPHY_TYPES.TEXT_CARD_NAME">
				{{ t("chatsDesktopDescr_2") }}
			</Typography>
			{{ t("chatsDesktopDescr_3") }}
			<Typography tag="span" :type="TYPOGRAPHY_TYPES.TEXT_CARD_NAME">
				{{ t("buttonTextCahtWrite") }}
			</Typography>
			{{ t("chatsDesktopDescr_4") }}
		</Typography>
	</div>
</template>
<style lang="scss" scoped>
@import "./ChatsDesktop.scss";
</style>
