<script setup>
import { computed, ref, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import {
	SvgTemplate,
	Typography,
	TYPOGRAPHY_TYPES,
	PhotoPreviewSingle,
	SuspenseProvider,
	Skeleton,
	Breadcrumbs,
	ModalConfirm,
	Modal,
	Button,
} from "@shared/ui";
import { GROUPS } from "@widgets";
import { Authorization } from "@widgets/Authorization";
import { REQUST_STATUSES, STATUSES, DOMAIN_URL, SIZES, THEMES } from "@shared/const";
import { toLocaleNumber, useScreenSize, saveData, fetchIsFavorite } from "@shared/lib";
import { DescriptionTextCollapse, Contacts } from "@widgets/Profile";
import { SalerCard } from "@widgets/SalerCard";
import { Share } from "@capacitor/share";
import { AnnouncementReviews } from "@widgets/AnnouncementReviews";
import { TeleportToHeaderNavMobile } from "@widgets/Layout";
import { AnnouncementTag } from "@widgets/AnnouncementTag";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	currentAD: {
		type: Object,
		default: null,
	},
	isMy: {
		type: Boolean,
		default: false,
	},
	photoUrls: {
		type: Array,
		default: () => [],
	},
});
const route = useRoute();
const router = useRouter();
const store = useStore();
const { t, i18next } = useTranslation();

const emit = defineEmits(["editAD"]);

const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const getAnnouncementLanguage = computed(() => {
	return store.getters["user/getAnnouncementTranslate"];
});

const MX_DESCR_LENGTH = 120;

const modalRefRemove = ref(null);

const modalRefPublish = ref(null);

const isBlocked = ref(false);

const modalRefDelete = ref(null);

const reviewsRef = ref(null);

const isFavorite = ref(false);

const categoryId = computed(() => {
	const categoryIdData = [
		{
			to: {
				path: "/announcements?",
				query: {
					group_id: props.currentAD.section_id,
				},
			},
			title: t(GROUPS[props.currentAD?.section_id]?.name),
		},
		{
			to: {
				path: "/announcements?",
				query: {
					group_id: props.currentAD.section_id,
					category_id: props.currentAD.category_id,
				},
			},
			title: store.getters["announcement/categoryById"](props.currentAD?.category_id)
				?.category_name,
		},
		{
			to: {
				path: "/announcements?",
				query: {
					group_id: props.currentAD.section_id,
					category_id: props.currentAD.category_id,
					sub_category_id: props.currentAD.sub_category_id,
				},
			},
			title: store.getters["announcement/subCategoriesById"](props.currentAD?.sub_category_id)
				?.name,
		},
	];
	const filteredCategoryIdData = categoryIdData.filter((item) => item.title);

	return filteredCategoryIdData;
});

const isUserLoggedIn = computed(() => {
	return store.getters["user/isUserLoggedIn"];
});

const translatedData = computed(() => {
	const { currentAD, isMy } = props;
	const announcementLanguage = getAnnouncementLanguage.value;

	if (currentAD?.translates) {
		const translate = currentAD.translates.find((translate) => {
			const isShortList = currentAD.translates.length === 4;
			const isOriginLanguage = translate.is_origin;
			const isMatchingLanguage =
				isShortList && announcementLanguage !== "is_origin"
					? translate.translate_code === announcementLanguage
					: isOriginLanguage;

			return isMy ? isOriginLanguage : isMatchingLanguage;
		});

		if (translate) {
			return {
				title: translate.name,
				description: translate.description,
			};
		}
	}

	return {
		title: currentAD?.ad_name,
		description: currentAD?.description,
	};
});

const translatedInterfaceData = computed(() => {
	const { currentAD } = props;

	if (currentAD.translates) {
		const isMatchingLanguage = currentAD.translates.find(
			(translate) => translate.translate_code === i18next.language
		);

		return {
			itemCity: isMatchingLanguage?.item_city || currentAD?.item_city,
			categoryName: isMatchingLanguage?.category_name || currentAD?.category_name,
			itemCreated: isMatchingLanguage?.item_created || currentAD?.item_created,
		};
	}

	return {
		itemCity: currentAD?.item_city,
		categoryName: currentAD?.category_name,
		itemCreated: currentAD?.item_created,
	};
});

const currentUserStatus = computed(() => {
	const res = store.getters["user/currentUserStatus"];
	return res === REQUST_STATUSES.PENDING ? res : REQUST_STATUSES.SUCCESS;
});

const reviews = computed(() => {
	const data = { ...store.getters["reviews/getReviews"] };

	if (data.items && data.items.length > 0) {
		const lastItemIndex = data.items.length - 1;

		data.items[lastItemIndex] = {
			...data.items[lastItemIndex],
			updateOnVisible: true,
		};
	}
	return data;
});

const editOpen = () => {
	emit("editAD");
};

const editOpenModal = () => {
	modalRefPublish.value.close();
	setTimeout(() => {
		editOpen();
	}, 50);
};

watch(
	() => [isUserLoggedIn, props.currentAD],
	async ([userLoggedIn, currentData]) => {
		if (currentData && userLoggedIn.value) {
			isFavorite.value = currentData?.is_favor || false;
		} else if (!userLoggedIn.value && currentData?.item_id) {
			isFavorite.value = await fetchIsFavorite(props.currentAD?.item_id);
		}
	}
);

const addPreffer = async () => {
	if (!isBlocked.value && isUserLoggedIn.value) {
		isFavorite.value = !isFavorite.value;
		const dict = {
			item_id: props.currentAD?.item_id,
			user_id: user.value?.user_id,
			is_active: isFavorite.value,
		};
		try {
			isBlocked.value = true;
			await store.dispatch("announcement/addPrefer", dict);
		} catch (e) {
			console.log(e);
		} finally {
			isBlocked.value = false;
		}
	}
	if (!isUserLoggedIn.value) {
		const success = await saveData(props.currentAD.item_id);
		if (!success) authModal.value.open();
		isFavorite.value = await fetchIsFavorite(props.currentAD?.item_id);
	}
};

const openModalRemoveConfirm = () => {
	modalRefRemove.value.open();
};

const onConfirmRemove = () => {
	modalRefRemove.value.close();
	emit("editStatus", STATUSES.ARCHIVE);
};

const onBackRemove = () => {
	modalRefRemove.value.close();
	modalRefDelete.value.close();
};

const adStatus = computed(() => {
	return store.getters["announcement/adStatus"];
});

const countryById = computed(() => {
	return store.getters["announcement/countryById"](props.currentAD?.country_id);
});

const userData = computed(() => {
	return props.currentAD;
});

const downwardPriceChange = computed(() => {
	return (
		props.currentAD?.initial_amount && props.currentAD?.initial_amount > props.currentAD?.price
	);
});

const share = async () => {
	const shareData = {
		url: `${DOMAIN_URL}${route.path}`,
	};
	if (await Share.canShare()) {
		Share.share(shareData);
	}
};

const { isDesktop } = useScreenSize();

const toggle = ref(false);

const publishModal = () => {
	modalRefPublish.value.open();
};

const publish = () => {
	modalRefPublish.value.close();
	emit("editStatus", STATUSES.PUBLIC);
};

const isAdmin = () => {
	return user.value.is_admin && !props.isMy;
};

const openModalDeleteConfirm = () => {
	modalRefDelete.value.open();
};

const onBackDelete = () => {
	modalRefDelete.value.close();
};

const onConfirmDelete = () => {
	modalRefDelete.value.close();
	emit("editStatus", STATUSES.DELETE);
};

const isHideShortDescr = computed(() => {
	return props.currentAD?.description?.length >= MX_DESCR_LENGTH;
});

const isDescriptionShow = computed(() => {
	return isDesktop.value && Boolean(props.currentAD?.description);
});

// Todo как-то зарефакторить
const isPending = computed(() => {
	return [adStatus.value, currentUserStatus.value].includes(REQUST_STATUSES.PENDING);
});

/** @type{import("vue").Ref<InstanceType<typeof Authorization>>} */
const authModal = ref(null);

const modalRefContact = ref(null);

const isOneContact = ref(false);

const contactHandle = () => {
	const visibleContact = [
		userData.value.is_wa,
		userData.value.is_tg,
		userData.value.is_email,
		userData.value.is_phone,
	].filter((item) => item).length;

	if (visibleContact < 2) {
		isOneContact.value = true;
	}

	modalRefContact.value.open();
};

const closeContact = () => {
	isOneContact.value = false;
	modalRefContact.value.close();
};

const onBack = () => {
	router.back();
};

const tagCategoryId = computed(
	() => store.getters["announcement/tagById"](props.currentAD?.tag_id)?.tag_category_id
);

const chatId = computed(() => store.getters["chat/getChatBySubject"](props.currentAD.item_id));

const chatIsPending = ref(false);

const openChat = () => {
	if (chatId.value) {
		router.push({ name: "Chat", params: { chat_id: chatId.value } });
	} else {
		chatIsPending.value = true;
		store.dispatch("chat/initChat", {
			to: props.currentAD.user_id,
			entityId: props.currentAD.item_id,
		});
		const unwatch = watch(chatId, (value) => {
			if (value) {
				chatIsPending.value = false;

				router.push({ name: "Chat", params: { chat_id: value } });

				nextTick(() => {
					unwatch();
				});
			}
		});
	}
};

const reviewStatus = computed(() => {
	return store.getters["reviews/getReviewStatus"] === REQUST_STATUSES.SUCCESS;
});
</script>

<template>
	<SuspenseProvider :status="[adStatus, currentUserStatus]" #default="{ isSuspense }" no-fallback>
		<div class="announcement-nav">
			<button type="button" class="back-button" @click="onBack" v-if="isDesktop">
				<SvgTemplate class="back-button__icon" name="left-arrow" />
			</button>
			<TeleportToHeaderNavMobile v-else>
				<button type="button" class="back-button" @click="onBack">
					<SvgTemplate class="back-button__icon" name="left-arrow" />
				</button>
			</TeleportToHeaderNavMobile>
			<Breadcrumbs
				v-if="isDesktop"
				class="breadcrumbs"
				:items="categoryId"
				:is-pending="isSuspense"
			/>
		</div>
	</SuspenseProvider>

	<div :class="['announcement-card__container']">
		<SuspenseProvider :status="[adStatus, currentUserStatus]">
			<div class="announcement-card">
				<div
					class="announcement-card__photo-container"
					:class="{ 'announcement-card__photo-container_grid-full': isHideShortDescr }"
				>
					<PhotoPreviewSingle
						:items="photoUrls"
						:currentId="currentAD?.item_id"
						:smallPhoto="false"
					/>
					<button
						class="annoucement-card__add-to-fav"
						:class="{ 'annoucement-card__add-to-fav_added': isFavorite }"
						type="submit"
						@click.stop="addPreffer"
					>
						<SvgTemplate :name="isFavorite ? `heart-active` : `heart-disactive`" />
					</button>
					<div class="announcement-card__tag" v-if="tagCategoryId">
						<AnnouncementTag :tag-category-id="tagCategoryId" />
					</div>
				</div>
				<div class="announcement-card__info">
					<div class="announcement-card__title-container">
						<Typography :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
							{{ translatedInterfaceData.categoryName }}
						</Typography>
						<Typography :type="TYPOGRAPHY_TYPES.CARD_TITLE">
							{{ translatedData.title }}
						</Typography>
						<SvgTemplate
							v-if="!isDesktop"
							@click="share"
							class="announcement-card__svg_share"
							name="back"
						/>
					</div>
					<div class="announcement-card__button-currency">
						<div class="announcement-card__price-container" v-if="currentAD?.price">
							<div class="announcement-card__currency">
								<div class="announcement-card__lighting-svg-container">
									<SvgTemplate
										v-if="downwardPriceChange"
										name="lighting"
										class="announcement-card__lighting-svg"
									/>
									<Typography
										:type="TYPOGRAPHY_TYPES.CARD_INFO_PRICE"
										class="announcement-card__no-wrap"
									>
										{{ toLocaleNumber(currentAD?.price) }} {{ currentAD?.currency_label }}
									</Typography>
								</div>
								<div
									v-if="currentAD?.currency_label !== currentAD?.rub_label"
									class="announcement-card__price_convector-svg"
								>
									<SvgTemplate :name="'convector'" />
								</div>
								<Typography
									v-if="currentAD?.currency_label !== currentAD?.rub_label"
									:type="TYPOGRAPHY_TYPES.CARD_INFO_PRICE"
									class="announcement-card__gray announcement-card__no-wrap"
								>
									{{ toLocaleNumber(currentAD?.price_rub) }} {{ currentAD?.rub_label }}
								</Typography>
							</div>
							<div v-if="downwardPriceChange" class="announcement-card__initial-cont">
								<Typography
									:type="TYPOGRAPHY_TYPES.ANNOUNCEMENT_CARD_PRICE"
									class="announcement-card__gray announcement-card__no-wrap"
								>
									{{ toLocaleNumber(currentAD?.initial_amount) }}
									{{ currentAD?.currency_label }}
								</Typography>
							</div>
						</div>
						<div v-else class="announcement-card__currency-default">
							<Typography :type="TYPOGRAPHY_TYPES.TEXT_1">{{ t("cardInfoNotPrice") }}</Typography>
						</div>
					</div>
					<div class="announcement-card-date-location">
						<div class="announcement-card__location">
							<SvgTemplate :name="'oblique_arrow'" />
							<Typography
								class="announcement-card__location-name"
								:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
								:suspense="isSuspense"
							>
								{{ translatedInterfaceData.itemCity }}, {{ countryById?.name }}
							</Typography>
						</div>
						<div class="announcement-card__date">
							<Typography :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY" :suspense="isSuspense">
								{{ translatedInterfaceData.itemCreated }}
							</Typography>
						</div>
					</div>
					<div
						class="announcement-card__button-status"
						:class="{ 'announcement-card__button-status_short': !isHideShortDescr }"
					>
						<button
							v-if="!isMy && !isAdmin()"
							class="btn-announcement btn-announcement__favorites"
							type="button"
							@click="contactHandle"
						>
							<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">{{ t("cardInfoBtnCall") }}</Typography>
						</button>
						<template
							v-if="(isMy || isAdmin()) && parseInt(currentAD?.ad_status) === STATUSES.PUBLIC"
						>
							<button
								class="btn-announcement btn-announcement__publish"
								type="button"
								@click="openModalRemoveConfirm"
							>
								<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">
									{{ t("buttonTextRemove") }}
								</Typography>
							</button>
						</template>
						<button
							class="btn-announcement btn-announcement__publish"
							type="button"
							v-else-if="
								parseInt(currentAD?.ad_status) === STATUSES.NOT_PUBLIC ||
								parseInt(currentAD?.ad_status) === STATUSES.ARCHIVE
							"
							@click="publishModal"
						>
							<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">{{ t("buttonTextPublish") }}</Typography>
						</button>
						<button
							v-if="isMy || isAdmin()"
							class="announcement-card__redact-announcement"
							type="button"
							@click="editOpen"
						>
							<SvgTemplate class="announcement-card__svg" name="highlighter" />
						</button>
						<button
							v-if="!isMy && isAdmin()"
							class="announcement-card__remove"
							type="button"
							@click="openModalDeleteConfirm"
						>
							<SvgTemplate :name="'trash'" class="svg-icon__delete" />
						</button>
					</div>
				</div>
				<div
					v-if="isDescriptionShow"
					class="announcement-card__description-box"
					:class="{ 'announcement-card__description-box_down-position': isHideShortDescr }"
				>
					<DescriptionTextCollapse
						:description="translatedData.description"
						:is-toggle="!toggle"
						:hide-height="70"
					>
						<template #toggleUp="{ isCanHide }">
							<div class="text-collapse__toggle" @click="toggle = !toggle">
								<Typography :type="TYPOGRAPHY_TYPES.CARD_TITLE">
									{{ t("cardInfoBtnCollapse") }}
								</Typography>
								<SvgTemplate
									v-if="isCanHide"
									class="text-collapse__toggle-icon"
									:class="{ 'text-collapse__toggle-icon_full': !toggle }"
									name="polygon"
								/>
							</div>
						</template>
					</DescriptionTextCollapse>
				</div>
				<div v-if="!isDesktop" class="announcement-card__saler-mobile">
					<div class="saller__line"></div>
					<SalerCard
						:currentAd="currentAD"
						:description-data="translatedData"
						:status="adStatus"
						:is-pending="isPending"
						:is-my="isMy"
						:is-admin="user.is_admin"
					/>
				</div>
			</div>
			<ModalConfirm
				ref="modalRefPublish"
				@confirm="publish"
				@back="editOpenModal"
				:back-text="t('buttonTextEdit')"
				:confirm-text="t('buttonTextPublish')"
				:with-cross="true"
				:allow-self-close="true"
			>
				<div class="modal-content__confirm">
					<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="announcement-card__main">
						{{ t("cardInfoMoPublish") }}
					</Typography>
				</div>
			</ModalConfirm>
			<ModalConfirm
				ref="modalRefRemove"
				@confirm="onConfirmRemove"
				@back="onBackRemove"
				back-text="buttonTextCancel"
				confirm-text="buttonTextRemove"
				:hide-cross="true"
			>
				<div class="modal-content__confirm">
					<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="announcement-card__main">
						{{ t("cardInfoMoRemoveArchive") }}
					</Typography>
				</div>
			</ModalConfirm>
			<ModalConfirm
				ref="modalRefDelete"
				@confirm="onConfirmDelete"
				@back="onBackDelete"
				back-text="buttonTextCancel"
				confirm-text="buttonTextDelete"
				:hide-cross="true"
			>
				<div class="modal-content__confirm">
					<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="announcement-card__main">
						{{ t("cardInfoMoDelete") }}
					</Typography>
				</div>
			</ModalConfirm>

			<Modal
				ref="modalRefContact"
				@close="closeContact"
				confirm-text="buttonTextBlock"
				:hide-cross="true"
			>
				<div class="confirm-edit">
					<div class="confirm-edit__chat-btn">
						<!-- prettier-ignore -->
						<Typography class="confirm-edit__title" :type="TYPOGRAPHY_TYPES.TEXT">
							{{ t("cardInfoMoContactCahtText1") }} <span v-if="!isUserLoggedIn">(<Typography tag="span" class="confirm-edit__title_bold" :type="TYPOGRAPHY_TYPES.TEXT_CARD_NAME">{{ t("cardInfoMoContactCahtText2") }}</Typography>, {{ t("cardInfoMoContactCahtText3") }}):</span>
						</Typography>
						<Button
							class="create-annoucement-btn"
							:theme="THEMES.CONTAINED"
							:size="SIZES.BIG"
							:pending="chatIsPending"
							v-if="isUserLoggedIn"
							@click.stop="openChat"
						>
							<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">
								{{ t("buttonTextCahtWrite") }}
							</Typography>
						</Button>
						<Button
							v-else
							class="create-annoucement-btn"
							:theme="THEMES.CONTAINED"
							:size="SIZES.BIG"
							@click.stop="authModal.open()"
						>
							<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">
								{{ t("cardInfoMoContactCahtBtnLogin") }}
							</Typography>
						</Button>
					</div>
					<Typography class="confirm-edit__title" :type="TYPOGRAPHY_TYPES.TEXT">
						{{ t("cardInfoMoContactMessengers") }}
					</Typography>
					<Contacts
						class="saller__contacts"
						:tg="{ value: userData.username, visible: userData.is_tg }"
						:phone="{ value: userData.phone, visible: userData.is_phone }"
						:wa="{ value: userData.whats_app_phone, visible: userData.is_wa }"
						:email="{ value: userData.email, visible: userData.is_email }"
						modal-contact
					/>
				</div>
			</Modal>

			<template #fallback>
				<div class="announcement-card">
					<div class="announcement-card__photo-container">
						<Skeleton class="skeleton-image" />
					</div>
					<div class="announcement-card__info">
						<div class="announcement-card__title-container">
							<Skeleton class="skeleton-cat" />
							<Skeleton class="skeleton-title" />
						</div>
						<Skeleton class="skeleton-price" />
						<div class="announcement-card-date-location">
							<Skeleton class="skeleton-location" v-if="isDesktop" />
							<Skeleton class="skeleton-date" v-if="isDesktop" />
						</div>
						<Skeleton class="skeleton-fav-btn" />
					</div>
					<div v-if="!isDesktop" class="announcement-card__saler-mobile">
						<div class="saller__line"></div>
						<SalerCard
							:currentAd="currentAD"
							:description-data="translatedData"
							:status="adStatus"
							:is-pending="isPending"
							:is-my="isMy"
							:is-admin="user.is_admin"
						/>
						<Skeleton class="skeleton-decr" />
					</div>
					<Skeleton v-if="isDesktop" class="skeleton-decr" />
				</div>
			</template>
		</SuspenseProvider>
		<div class="announcement-card__saler-wrapper">
			<div v-show="isDesktop" class="announcement-card__saler-box">
				<div v-if="isDesktop" class="announcement-card__saler-content">
					<SalerCard
						:is-pending="isPending"
						:currentAd="currentAD"
						:status="adStatus"
						:is-my="isMy"
						:is-admin="user.is_admin"
						@openReviewsModal="reviewsRef.openModalReviews()"
					/>
				</div>
			</div>
		</div>
		<div class="announcement-card__reviews-wrapper">
			<AnnouncementReviews
				v-if="!isPending && reviewStatus"
				ref="reviewsRef"
				:currentAd="currentAD"
				:status="adStatus"
				:isMy="isMy"
				:reviewsData="reviews"
			/>
			<div v-else class="reviews__sceleton-wrapper">
				<Skeleton class="reviews__reviews-text" />
				<div class="reviews__sceleton-content">
					<Skeleton class="reviews__reviews-circle" />
					<Skeleton class="reviews__reviews-fatline" />
				</div>
			</div>
		</div>
	</div>

	<Authorization ref="authModal" prevent-self-open />
</template>

<style lang="scss" scoped>
@import "./CardDirectInfo.scss";
</style>
