const CLASSES = {
	NAME: "fade-show",
	VISIBLE: "fade-show_visible",
	HIDDEN: "fade-show_hidden",
};

export const VFadeShow = {
	mounted(el, binding) {
		el.classList.add(CLASSES.NAME);
		if (binding.value) {
			el.classList.add(CLASSES.VISIBLE);
		} else {
			el.classList.add(CLASSES.HIDDEN);
		}
	},
	updated(el, binding) {
		if (binding.value) {
			el.classList.remove(CLASSES.HIDDEN);
			el.classList.add(CLASSES.VISIBLE);
		} else {
			el.classList.remove(CLASSES.VISIBLE);
			el.classList.add(CLASSES.HIDDEN);
		}
	},
};
