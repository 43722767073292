import { useStore } from "vuex";
import { GoogleAuth } from "@codetrix-studio/capacitor-google-auth";
import {
	GOOGLE_CLIENT,
	GOOGLE_CLIENT_IOS,
	IS_ANDROID,
	IS_IOS,
	IS_WEB_APP,
	IS_NATIVE_APP,
} from "@shared/const";
import { once } from "lodash";

export const useGoogleAuth = () => {
	const store = useStore();

	const registerGoogleAuth = once(() => {
		let clientId = GOOGLE_CLIENT;

		if (IS_IOS) {
			clientId = GOOGLE_CLIENT_IOS;
		} else if (IS_ANDROID) {
			// TODO Добавить android по надобности
			clientId = GOOGLE_CLIENT;
		}

		GoogleAuth.initialize({
			clientId,
			scopes: ["profile", "email"],
			grantOfflineAccess: true,
		});
	});

	const signInNative = async () => {
		const client = await GoogleAuth.signIn();
		try {
			await store.dispatch("user/authGoogleUser", {
				credential: client.authentication.accessToken,
			});
			await store.dispatch("user/getCurrentUser");
			return Promise.resolve();
		} catch {
			await GoogleAuth.signOut();
			return Promise.reject();
		}
	};

	const signInWeb = () => {
		return new Promise((res, rej) => {
			const client = window.google.accounts.oauth2.initTokenClient({
				client_id: GOOGLE_CLIENT,
				callback: async (data) => {
					try {
						await store.dispatch("user/authGoogleUser", { credential: data.access_token });
						await store.dispatch("user/getCurrentUser");
						res();
					} catch {
						rej();
					}
				},
				error_callback: () => rej(),
				prompt: "",
				scope: "email profile",
			});
			client.requestAccessToken();
		});
	};

	const signIn = async () => {
		if (IS_NATIVE_APP) {
			await signInNative();
		}
		if (IS_WEB_APP) {
			await signInWeb();
		}
	};

	return { registerGoogleAuth, signIn };
};
