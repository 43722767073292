<script setup>
import { useKeepAliveRoute } from "@shared/lib";
import { UserAnnouncementList } from "@widgets/AnnouncementLists";
import { DefaultHeader } from "@widgets/DefaultHeader";
import { useTranslation } from "i18next-vue";

const keepAliveRoute = useKeepAliveRoute();
const { t } = useTranslation();
</script>
<template>
	<UserAnnouncementList :user-id="keepAliveRoute.params.user_id" class="user-announcement">
		<template #title><DefaultHeader :title="t('userAnouncementsPage')" /></template>
	</UserAnnouncementList>
</template>
<style lang="scss" scoped>
@import "./UserAnouncementsPage.scss";
</style>
