export const THEME_TYPES = { Simple: "simple", Big: "big" };

export const FORMAT_TYPES = {
	Price: "price",
	PriceWithCurrency: "priceWithCurrency",
	Raw: "raw",
	Phone: "phone",
	SingleWord: "singleWord",
};

export const INPUT_MODE = /** @type {const} */ ({
	NUMBER: "number",
	FLOAT_NUMBER: "floatNumber",
	FORMATED_NUMBER: "formatedNumber",
	FORMATED_FLOAT_NUMBER: "formatedFloatNumber",
	RAW: "raw",
	PHONE: "phone",
	ONE_WORD: "oneWord",
	CAPITALIZE: "capitalize",
});

export const INPUT_THEMES = {
	DARK: "dark",
	LIGHT: "light",
};
