<script setup>
import { ref, toRefs, computed, watch, nextTick } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { ANNOUNCEMENT_STATUSES } from "@entities/announcement";
import { apiPostAdActivity } from "@entities/ads";
import { Image, SvgTemplate, TYPOGRAPHY_TYPES, Typography, useSuspense } from "@shared/ui";
import { Authorization } from "@widgets/Authorization";
import { AnnouncementTag } from "@widgets/AnnouncementTag";
import { useElementVisibility } from "@vueuse/core";
import { getBucketUrlMedium, getBucketBanner, ADS_ACTIVITY } from "@shared/const";
import {
	toLocaleNumber,
	useScreenSize,
	saveData,
	fetchIsFavorite,
	useKeepAliveRouter,
} from "@shared/lib";
import { useTranslation } from "i18next-vue";
import AnnouncementPreviewSuspense from "./AnnouncementPreviewSuspense.vue";
import { ANNOUNCEMENT_PREVIEW_ORIENTATION } from "./AnnouncementPreview.const";
import DesktopPreviewSlider from "./DesktopPreviewSlider.vue";
import MobilePreviewSlider from "./MobilePreviewSlider.vue";

const props = defineProps({
	announcementCard: {
		type: Object,
		default: () => {},
	},
	suspense: {
		type: Boolean,
		default: false,
	},
	withFavorite: {
		type: Boolean,
		default: true,
	},
	emitOnVisible: {
		type: Boolean,
		default: false,
	},
	orientation: {
		type: String,
		default: ANNOUNCEMENT_PREVIEW_ORIENTATION.VERTICAL,
	},
	// При редиректе сохраняет состояник страницы с которой был соверщен редирект
	withKeepAlive: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits("visible");
const store = useStore();
const route = useRoute();
const { t, i18next } = useTranslation();

const keepAliveRouter = useKeepAliveRouter();
const router = useRouter();

const { isDesktop } = useScreenSize();

const { suspense } = toRefs(props);

const { isSuspense } = useSuspense(suspense);

const isFavorite = ref(props.announcementCard?.is_favor);

const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const isUserLoggedIn = computed(() => {
	return store.getters["user/isUserLoggedIn"];
});

const countryById = computed(() => {
	return store.getters["announcement/countryById"](props.announcementCard.country_id);
});

const getAnnouncementLanguage = computed(() => {
	return store.getters["user/getAnnouncementTranslate"];
});

/** @param {Event} e */
const addToFavorite = async (e) => {
	e.stopImmediatePropagation();
	e.stopPropagation();
	if (isUserLoggedIn.value) {
		isFavorite.value = !isFavorite.value;
		const dict = {
			item_id: props.announcementCard?.item_id,
			user_id: user.value?.user_id,
			is_active: isFavorite.value,
		};
		try {
			if (user.value) {
				await store.dispatch("announcement/addPrefer", dict);
			}
			if (!isFavorite.value && route.path === "/favorites") {
				store.commit("announcement/DELETE_AD_BY_ID", { itemId: props.announcementCard?.item_id });
			}
		} catch (e) {
			console.log(e);
		}
	} else {
		const success = await saveData(props.announcementCard.item_id);
		if (!success) authModal.value.open();
		isFavorite.value = await fetchIsFavorite(props.announcementCard?.item_id);
	}
};

watch(
	[isUserLoggedIn, props.announcementCard],
	async ([userLoggedIn, announcementData]) => {
		if (!userLoggedIn && announcementData) {
			isFavorite.value = await fetchIsFavorite(props.announcementCard?.item_id);
		}
	},
	{ immediate: true }
);

const isInactive = computed(() => {
	const inactiveStatusesId = [ANNOUNCEMENT_STATUSES.ARCHIVE.id, ANNOUNCEMENT_STATUSES.CLOSE.id];
	const result = inactiveStatusesId.includes(props.announcementCard?.ad_status);
	return result;
});

const routerTo = computed(() => {
	return { name: "AnounceCardPage", params: { ad_id: props.announcementCard?.item_id } };
});

const imageSrc = computed(() => {
	if (props.announcementCard?.is_ad && props.announcementCard?.item_id) {
		return getBucketBanner(props.announcementCard?.tittle_photo);
	}
	if (props.announcementCard?.tittle_photo && props.announcementCard?.item_id) {
		return getBucketUrlMedium(
			props.announcementCard?.tittle_photo,
			props.announcementCard?.item_id
		);
	}
	return null;
});

const oldPriced = computed(() => {
	return (
		props.announcementCard?.initial_amount &&
		props.announcementCard?.initial_amount !== props.announcementCard?.price &&
		props.announcementCard?.price !== 0
	);
});

const downwardPriceChange = computed(() => {
	return props.announcementCard?.price < props.announcementCard?.initial_amount;
});

const announcementPreviewRef = ref(null);

if (props.emitOnVisible) {
	const isVisible = useElementVisibility(announcementPreviewRef);

	const unwatch = watch(
		isVisible,
		() => {
			if (isVisible.value) {
				emit("visible");
				nextTick(() => {
					unwatch();
				});
			}
		},
		{ immediate: true }
	);
}

/** @type {import("vue").Ref<InstanceType<typeof Authorization>>} */
const authModal = ref(null);

const isAd = computed(() => props.announcementCard?.is_ad);

const advShown = () => {
	if (!props.announcementCard.is_ad) return;
	apiPostAdActivity({
		ad_id: props.announcementCard.item_id,
		activity: ADS_ACTIVITY.SHOW,
		nonce: props.announcementCard.nonce,
	});
};

const onLinkClick = () => {
	if (!isAd.value) {
		if (props.withKeepAlive) {
			keepAliveRouter.push(routerTo.value);
		} else {
			router.push(routerTo.value);
		}
	} else {
		apiPostAdActivity({
			ad_id: props.announcementCard.item_id,
			activity: ADS_ACTIVITY.CLICK,
		});
		window.open(props.announcementCard.target, "_blank");
	}
};

const tagCategoryId = computed(
	() => store.getters["announcement/tagById"](props.announcementCard?.tag_id)?.tag_category_id
);

const isSlider = computed(() => {
	return props.announcementCard?.ad_photos?.length > 1;
});

const translatedTitle = computed(() => {
	const { announcementCard, isMy } = props;
	const announcementLanguage = getAnnouncementLanguage.value;

	if (announcementCard?.translates) {
		const translate = announcementCard.translates.find((translate) => {
			const isShortList = announcementCard.translates.length === 4;
			const isOriginLanguage = translate.is_origin;
			const isMatchingLanguage =
				isShortList && announcementLanguage !== "is_origin"
					? translate.translate_code === announcementLanguage
					: isOriginLanguage;

			return isMy ? isOriginLanguage : isMatchingLanguage;
		});

		if (translate) {
			return translate.name;
		}
	}

	return announcementCard?.ad_name;
});

const translatedInterfaceData = computed(() => {
	const { announcementCard } = props;

	if (announcementCard.translates) {
		const isMatchingLanguage = announcementCard.translates.find(
			(translate) => translate.translate_code === i18next.language
		);

		return {
			itemCity: isMatchingLanguage?.item_city || announcementCard?.item_city,
			categoryName: isMatchingLanguage?.category_name || announcementCard?.category_name,
			itemCreated: isMatchingLanguage?.item_created || announcementCard?.item_created,
		};
	}

	return {
		itemCity: announcementCard?.item_city,
		categoryName: announcementCard?.category_name,
		itemCreated: announcementCard?.item_created,
	};
});
</script>

<template>
	<article
		v-if="!isSuspense"
		ref="announcementPreviewRef"
		class="announcement-card"
		:class="{
			'announcement-card_inactive': isInactive,
			'announcement-card_horizontal': orientation === ANNOUNCEMENT_PREVIEW_ORIENTATION.HORIZONTAL,
		}"
	>
		<button
			v-if="withFavorite && !isAd"
			class="annoucement-card__add-to-fav"
			:class="{ 'annoucement-card__add-to-fav_added': isFavorite }"
			type="submit"
			@click.stop="addToFavorite"
		>
			<SvgTemplate :name="isFavorite ? `heart-active` : `heart-disactive`" />
		</button>
		<RouterLink :to="routerTo" custom v-slot="{ href }">
			<a class="announcement-card__inner" :href="href" @click.prevent="onLinkClick">
				<div class="announcement-card__image-wrapper">
					<div class="announcement-card__image">
						<DesktopPreviewSlider
							v-if="isSlider && isDesktop"
							:photo-data="announcementCard?.ad_photos"
							:item-id="announcementCard?.item_id"
						/>
						<MobilePreviewSlider
							v-if="!isDesktop && isSlider"
							:photo-data="announcementCard?.ad_photos"
							:item-id="announcementCard?.item_id"
						/>
						<Image
							v-if="!isSlider"
							:src="imageSrc"
							:alt="announcementCard?.ad_name"
							@success="advShown"
						/>
						<div class="announcement-card__tag" v-if="tagCategoryId">
							<AnnouncementTag :tag-category-id="tagCategoryId" />
						</div>
					</div>
					<Typography
						:type="TYPOGRAPHY_TYPES.TEXT_BODY_LARGE"
						v-if="isInactive"
						class="announcement-card__incative-message"
					>
						{{ t("previewWithdrawn") }}
					</Typography>
				</div>
				<div class="announcement-card__content">
					<div class="announcement-card__content-category-label">
						<Typography
							class="announcement-card__category"
							:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
						>
							{{ translatedInterfaceData.categoryName }}
						</Typography>
					</div>
					<h3 class="announcement-card__title">
						<Typography :type="TYPOGRAPHY_TYPES.TEXT_CARD_NAME">
							{{ translatedTitle }}
						</Typography>
					</h3>
					<div class="announcement-card__price-container" v-if="!isAd">
						<div class="announcement-card__price">
							<div class="announcement-card__price-row">
								<SvgTemplate
									v-if="downwardPriceChange && announcementCard?.price"
									name="lighting"
									class="lighting-green"
								/>
								<Typography
									:type="
										announcementCard?.price === 0
											? TYPOGRAPHY_TYPES.CARD_PRICE_2
											: TYPOGRAPHY_TYPES.CARD_PRICE
									"
									class="announcement-card__price_main"
									:class="{ 'announcement-card__price_no-price': announcementCard?.price === 0 }"
								>
									{{
										announcementCard?.price
											? `${toLocaleNumber(announcementCard?.price)} ${
													announcementCard?.currency_label
											  }`
											: t("cardInfoNotPrice")
									}}
								</Typography>
								<div class="announcement-card__price_convector-svg">
									<SvgTemplate
										v-if="
											announcementCard?.currency_label !== announcementCard?.rub_label &&
											announcementCard?.price_rub
										"
										:name="'convector'"
									/>
								</div>
								<Typography
									v-if="
										announcementCard?.currency_label !== announcementCard?.rub_label &&
										announcementCard?.price_rub
									"
									:type="TYPOGRAPHY_TYPES.CARD_PRICE"
									:class="[`announcement-card__price_sub`]"
								>
									{{ toLocaleNumber(announcementCard?.price_rub) }}
									{{ announcementCard?.rub_label }}
								</Typography>
							</div>
							<div v-if="downwardPriceChange" class="announcement-card__price-row">
								<div class="announcement-card__amount-initial" v-if="oldPriced">
									<Typography
										:type="TYPOGRAPHY_TYPES.CARD_PRICE_2"
										class="announcement-card__amount-initial-sub"
									>
										{{ toLocaleNumber(announcementCard?.initial_amount) }}
										{{ announcementCard?.currency_label }}
									</Typography>
								</div>
							</div>
						</div>
					</div>
					<div class="announcement-card__info" v-if="!isAd">
						<div class="announcement-card__badges">
							<div class="announcement-card__location">
								<SvgTemplate :name="'oblique_arrow'" />
								<Typography
									class="announcement-card__location-text"
									:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
								>
									{{ translatedInterfaceData.itemCity }}, {{ countryById?.name }}
								</Typography>
							</div>
							<div class="announcement-card__date">
								<Typography :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
									{{ translatedInterfaceData.itemCreated }}
								</Typography>
							</div>
						</div>
					</div>
				</div>
			</a>
		</RouterLink>
	</article>
	<AnnouncementPreviewSuspense
		v-else
		:class="{
			'announcement-card_horizontal': orientation === ANNOUNCEMENT_PREVIEW_ORIENTATION.HORIZONTAL,
		}"
	/>
	<Authorization @closed="closeModal" ref="authModal" prevent-self-open />
</template>

<style lang="scss" scoped>
@import "./AnnouncementPreview.scss";
@import "swiper/css/pagination";
</style>
