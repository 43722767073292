<script setup>
import { computed } from "vue";
import { Swiper } from "swiper/vue";
import { SvgTemplate } from "@shared/ui";
import { useScreenSize } from "@shared/lib";

const props = defineProps({
	swiperOptions: {
		type: Object,
		required: false,
		default: () => {},
	},
	sliderLength: {
		type: Number,
		default: 0,
	},
	countSlider: {
		type: Number,
		default: 0,
	},
});

const { isDesktop, screenSize } = useScreenSize();

const isShowNavBtn = computed(() => {
	if (isDesktop.value && screenSize.value !== "lg") {
		return props.sliderLength > props.countSlider;
	}
	if (screenSize.value === "lg") {
		return props.sliderLength > props.countSlider;
	}

	return false;
});
</script>

<template>
	<div>
		<slot name="title"></slot>
		<Swiper v-bind="{ ...swiperOptions, allowTouchMove: !isDesktop }">
			<slot name="swiperSlide"></slot>
			<div v-show="isDesktop && isShowNavBtn">
				<button class="swiper-prev" type="button">
					<SvgTemplate name="chevron" />
				</button>
				<button class="swiper-next" type="button">
					<SvgTemplate name="chevron" />
				</button>
			</div>
			<div class="swiper-scrollbar"></div>
		</Swiper>
	</div>
</template>

<style lang="scss" scoped>
@import "./AdaptiveSlider.scss";
</style>
