<script setup>
import { ref, computed, nextTick, watch } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useInitData } from "@shared/lib";
import { REQUST_STATUSES, STORAGE_KEY } from "@shared/const";
import { StoriesViewer } from "./StoriesViewer";
import { StoriesPreview } from "./StoriesPreview";

const storiesViewer = ref(null);
const store = useStore();

const route = useRoute();

const currentStoryBlockIdx = ref(null);

const isViewerActive = ref(false);

const openViewer = (storyBlockInx, storyIdx) => {
	if (!isPending.value) {
		currentStoryBlockIdx.value = storyBlockInx;
		isViewerActive.value = true;
		nextTick(() => storiesViewer.value.open(storyIdx));
	}
};

const onClose = () => {
	isViewerActive.value = false;
};

const initData = useInitData();

initData(async () => {
	await store.dispatch("stories/getStories");
});
const storiesStatus = computed(() => {
	return store.getters["stories/storiesStatus"];
});

const isPending = computed(() => {
	return storiesStatus.value === REQUST_STATUSES.PENDING;
});

const unwatch = watch(storiesStatus, () => {
	if (storiesStatus.value === REQUST_STATUSES.SUCCESS) {
		unwatch();
		const storyId = route.query.strory_id;
		if (storyId) {
			stories.value.some((storyBlock, storyBlockIdx) => {
				const storyIdx = storyBlock.story_item.findIndex(
					(story) => +story.item_id === +storyId && !story.storie_url.endsWith(".mp4")
				);
				if (storyIdx !== -1) {
					openViewer(storyBlockIdx, storyIdx);
					return true;
				}
				return false;
			});
		}
	}
});

const stories = computed(() => {
	const res = store.getters["stories/stories"];
	if (isPending.value) {
		return Array(res.length || 5).fill(null);
	}

	return res.sort((lhs, rhs) => rhs.place_id - lhs.place_id);
});

const currentStoriesByBlock = computed(() => {
	let res = [];
	if (currentStoryBlockIdx.value !== null) {
		const items = stories.value[currentStoryBlockIdx.value].story_item;
		res = items;
	}
	return res;
});

const previewsImagesRefs = ref([]);

const addPreviewRef = (index, ref) => {
	if (ref?.imageRef) {
		previewsImagesRefs.value[index] = ref.imageRef;
	}
};

const viewedStories = ref(
	new Set(JSON.parse(localStorage.getItem(STORAGE_KEY.VIEW_STORIES) || "[]"))
);

const onNextBlock = () => {
	viewedStories.value.add(stories.value[currentStoryBlockIdx.value].place_id);
	localStorage.setItem(STORAGE_KEY.VIEW_STORIES, JSON.stringify(Array.from(viewedStories.value)));
	if (currentStoryBlockIdx.value < stories.value.length - 1) {
		currentStoryBlockIdx.value += 1;
	} else {
		storiesViewer.value.close();
	}
};

const onPrevBlock = () => {
	if (currentStoryBlockIdx.value > 0) {
		currentStoryBlockIdx.value -= 1;
	}
};

const isViewed = (id) => {
	return viewedStories.value.has(id);
};
</script>
<template>
	<div class="stories-wrapper">
		<div class="stories">
			<StoriesPreview
				v-for="(story, index) in stories"
				:key="index"
				:story="story"
				:is-pending="isPending"
				:ref="(ref) => addPreviewRef(index, ref)"
				:is-viewed="isViewed(story?.place_id)"
				@click="openViewer(index)"
			/>
		</div>

		<StoriesViewer
			v-if="isViewerActive"
			ref="storiesViewer"
			:stories="currentStoriesByBlock"
			:target-elements="previewsImagesRefs"
			:current-story-block-idx="currentStoryBlockIdx"
			@nextBlock="onNextBlock"
			@prevBlock="onPrevBlock"
			@close="onClose"
		/>
	</div>
</template>

<styles lang="scss" scoped>
@import "./Stories.scss";
</styles>
