import { flatten, isPlainObject } from "lodash";

export const categories = (state) => {
	return state.categories.map((x) => ({
		id: x.id,
		name: x.category_name,
		section_id: x.section_id,
	}));
};

export const allSubCategories = (state) => {
	return flatten(state.categories.map((category) => category.sub_categories));
};

export const subCategoriesById = (state, getters) => (id) => {
	return getters.allSubCategories.find((subcategory) => subcategory.id === id);
};

export const categoriesStatus = (state) => {
	return state.status.categories;
};

export const categoryById = (state) => (id) => {
	return state.categories.find((category) => category.id === +id);
};

export const subCategories = (state) => (id) => {
	return state.categories.find((x) => x.id === +id)?.sub_categories || [];
};

export const getADId = (state) => {
	return state.currentADId || null;
};

export const allCities = (state) => {
	return state.regions.flatMap((currentCounrty) => {
		return currentCounrty.cities;
	});
};

export const citiesByCountryId = (state) => (countryId) => {
	const cities = state.regions.find((citiesByCountry) => +citiesByCountry.id === +countryId);
	return cities?.cities || [];
};

export const getFilters = (state) => {
	return state.announcementFilters;
};

export const countries =
	(state) =>
	({ exclude } = {}) => {
		const countries = state.regions
			.filter((countriy) => (exclude ? !exclude.includes(countriy.id) : true))
			.reduce((countries, citiesByCountry) => {
				const { id, name, iso_code: isoCode } = citiesByCountry;

				return [...countries, { id, name, isoCode }];
			}, []);
		return countries;
	};

export const cityById = (_, getters) => (id) => {
	const { allCities } = getters;
	const result = allCities.find((city) => +city.id === +id);
	return result;
};

export const citiesByFilters = (state, getters) => (countryId) => {
	if (countryId !== 0) state.currentSearchLocation = true;
	return getters.citiesByCountryId(+countryId);
};

export const getCitiesByCountryId = (state, getters) => (countryId) => {
	return getters.citiesByCountryId(+countryId);
};

export const countryById = (state, getters) => (id) => {
	return getters.countries().find((countryData) => countryData.id === +id);
};

export const countryByCityId = (state) => (id) => {
	const result = state.regions.find((country) => country.cities.find((city) => city.id === +id));

	return result;
};

export const countryByCityIdAnnouncement = (state) => (id) => {
	return state.regions.find((country) => country.cities.find((city) => city.id === id));
};

export const regions = (state) => {
	return state.regions;
};

export const regionsStatus = (state) => {
	return state.status.regions;
};

export const currentUserRegion = (state, getters, rootState, rootGetters) => {
	const currentRegionId =
		rootGetters["user/getCurrentUser"]?.region_id || rootGetters["user/unauthRegionId"];
	const result = getters.cityById(currentRegionId);
	return result;
};

export const currentRegion = (state, getters, rootState, rootGetters) => {
	const currentRegionId = rootGetters["user/getHandleLocation"]?.regionId || 0;
	const result = getters.cityById(currentRegionId);

	return result;
};

export const currentRegionOmitZeroId = (state, getters) => {
	const currentCity = getters.currentUserRegion;

	if (isPlainObject(currentCity) && "id" in currentCity && currentCity?.id !== 0) {
		return currentCity;
	}
	return null;
};

export const announcementsSliderList = (state) => {
	return state.announcementsSliderList;
};

export const currentCountry = (state, getters, rootState, rootGetters) => {
	const currentRegionId = rootGetters["user/getCurrentUser"]?.region_id;
	const result = state.regions.find((country) =>
		country.cities.find((city) => city.id === currentRegionId)
	);
	return result;
};

export const currentCities = (state, getters) => {
	const { currentCountry } = getters;
	const result = getters.citiesByCountryId(currentCountry?.id);

	return result;
};

export const announcementsList = (state) => {
	return state.announcements.items;
};

export const actualPagination = (state) => {
	return state.announcements.pagination;
};

export const status = (state) => {
	return state.status.announcements;
};

export const currentAnnouncement = (state) => {
	return state.announcements;
};

export const getADById = (state) => {
	return state.currentAD || {};
};

export const getCategroriesIds = (state) => {
	return state.categories.map((category) => category.id) || [];
};

export const getCategroiesBySelectionId = (state) => (sectionId) => {
	return state.categories.filter((category) => category.section_id === +sectionId);
};

export const categoriesIdsBySectionId = (state, getters) => (sectionId) => {
	return getters.getCategroiesBySelectionId(+sectionId).map((category) => category.id);
};

export const subCategoriesIdsByCategoriesId = (state, getters) => (categoryId) => {
	return getters.subCategories(+categoryId).map((subCategory) => subCategory.id);
};

export const announcementsControllers = (state) => {
	return state.announcementsControllers;
};

export const adStatus = (state) => {
	return state.status.currentAD;
};

export const activeAdEditor = (state) => {
	return state.activeAdEditor;
};

export const ads = (state) => {
	return state.announcements.ads;
};

export const announcementsListById = (state) => (id) => {
	return state.announcementsById[id]?.items || [];
};

export const announcementsControllersById = (state) => (id) => {
	return state.announcementsControllersById[id] || {};
};

export const announcementsById = (state) => (id) => {
	return state.announcementsById[id];
};

export const announcementsStatusById = (state) => (id) => {
	return state.status.announcementsById[id];
};

export const currencies = (state) => {
	return state.currency;
};

export const currencyById = (state) => (id) => {
	return state.currency.find((currency) => currency.currency_id === id);
};

export const currenciesStatus = (state) => {
	return state.status.currency;
};

export const tagsStatus = (state) => {
	return state.status.tags;
};

export const tags = (state) => {
	return state.tags;
};

export const tagsByCategoryId = (state) => (tagCategoryId) => {
	return state.tags.filter((tag) => tag.tag_category_id === tagCategoryId);
};

export const tagById = (state) => (tagId) => {
	return state.tags.find((tag) => tag.tag_id === tagId);
};

export const getuUnregistereFavoritsList = (state) => {
	return state.unregistereFavoritsList;
};

export const getUnregistereFavoritsStatus = (state) => {
	return state.status.unregisterFavoritsStatus;
};
