<script setup>
import { reactive, computed, watch, ref } from "vue";
import { useStore } from "vuex";
import { getBucketBanner, ADS_ACTIVITY } from "@shared/const";
import { Image, TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { useWindowSize } from "@vueuse/core";
import { apiGetAd, apiPostAdActivity } from "@entities/ads";
import { isNil } from "lodash";
import { useInitData } from "@shared/lib";

const store = useStore();

const { width } = useWindowSize();

const props = defineProps({
	typeCode: {
		type: String,
		default: null,
	},
	placeCode: {
		type: String,
		default: null,
	},
});

const ad = reactive({
	adName: null,
	adId: 0,
	target: null,
	resolution: null,
	nonce: null,
	min_resolution: null,
	max_resolution: null,
});

const countryId = computed(() => store.getters["user/getHandleLocation"]?.countryId);

const regionId = computed(() => store.getters["user/getHandleLocation"]?.regionId);

const initData = useInitData();

const getAd = async () => {
	try {
		const { data } = await apiGetAd({
			resolution: width.value,
			place_code: props.placeCode,
			country_id: countryId.value,
			region_id: regionId.value,
		});

		ad.adFile = data.tittle_photo;
		ad.adId = data.ad_id;
		ad.target = data.target;
		ad.min_resolution = data.min_resolution;
		ad.max_resolution = data.max_resolution;
		ad.nonce = data.nonce;
	} catch (e) {
		console.error(e);
	}
};

watch(countryId, (value) => {
	if (isNil(value)) return;
	getAd();
});

initData(() => {
	getAd();
});

const url = computed(() => (ad.adId !== 0 ? getBucketBanner(ad.adFile) : null));

const showAd = computed(
	() => url.value && ad.min_resolution <= width.value && ad.max_resolution > width.value
);

const goTo = () => {
	apiPostAdActivity({ ad_id: ad.adId, activity: ADS_ACTIVITY.CLICK });
	window.open(ad.target, "_blank");
};

const isAdvImageLoaded = ref(false);

const advShown = () => {
	isAdvImageLoaded.value = true;
	apiPostAdActivity({
		ad_id: ad.adId,
		activity: ADS_ACTIVITY.SHOW,
		nonce: ad.nonce,
	});
};
</script>

<template>
	<div class="adv-block" v-if="showAd" v-show="isAdvImageLoaded">
		<Image
			v-if="url"
			class="adv-block__image"
			:src="url"
			force-load
			@success="advShown"
			@click="goTo"
		/>
		<div class="adv-block__text-block">
			<Typography :type="TYPOGRAPHY_TYPES.BUTTON_3">Реклама</Typography>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./Ads.scss";
</style>
