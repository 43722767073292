<script setup>
import { TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { TABS_BAR_TYPES } from "./TabsBar.const";

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
	type: {
		type: String,
		default: TABS_BAR_TYPES.HIGHLITE,
		validator: (type) => {
			return Object.values(TABS_BAR_TYPES).includes(type);
		},
	},
	/**
	 * Список вкладок
	 *
	 * @type {string[]}
	 */
	tabs: {
		type: Array,
		required: true,
	},
	modelValue: {
		type: String,
		required: true,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
});

const pickTab = (tab) => {
	if (!props.disabled) {
		emit("update:modelValue", tab);
	}
};
</script>

<template>
	<div class="tabs-bar" :class="{ [`tabs-bar_${type}`]: type, 'tabs-bar_disabled': disabled }">
		<div class="tabs-bar__wrapper">
			<div
				class="tabs-bar__tab"
				:class="{ 'tabs-bar__tab_active': tab === modelValue }"
				v-for="(tab, index) in tabs"
				:key="tab + index"
				@click="pickTab(tab)"
			>
				<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">
					{{ tab }}
				</Typography>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./TabsBar.scss";
</style>
