import axios from "axios";
import qs from "qs";
import { HTTP_METHODS, IS_DEV, JWT_TOKEN } from "@shared/const";
import store from "@app/store";
import * as Sentry from "@sentry/vue";

const clearCurrentUser = () => {
	store.commit("user/CLEAR_CURRENT_USER");
};

const onNetworkError = () => {
	store.commit("errors/SET_NETWORK_ERROR");
};

const request = axios.create({
	paramsSerializer: {
		serialize: (params) => qs.stringify(params, { arrayFormat: "brackets" }),
	},
	timeout: 60000,
});

const requestWithSilentError = axios.create({
	paramsSerializer: {
		serialize: (params) => qs.stringify(params, { arrayFormat: "brackets" }),
	},
	timeout: 60000,
});

request.interceptors.response.use(
	(response) => {
		return Promise.resolve(response);
	},
	/**
	 * @param {import("axios").AxiosError} error
	 * @returns {error}
	 */
	(error) => {
		if (error.code === "ERR_NETWORK") {
			onNetworkError();
			Sentry.setExtra("error", error);
			Sentry.captureException(error);
		}

		if (error.response?.status === 401) {
			clearCurrentUser();
		}

		return Promise.reject(error);
	}
);

requestWithSilentError.interceptors.response.use(
	(response) => {
		return Promise.resolve(response);
	},
	/**
	 * @param {import("axios").AxiosError} error
	 * @returns {error}
	 */
	(error) => {
		if (error.response?.status === 401) {
			clearCurrentUser();
		}

		return Promise.reject(error);
	}
);

const getHeaders = (params = {}) => {
	const { body, formData, formDataName } = params;
	const jwt = localStorage.getItem("jwtToken");

	/** @type {import("axios").RawAxiosRequestHeaders} */
	const headers = {};

	if (formData && formDataName) {
		headers["Content-Type"] = "multipart/form-data";
	}

	if (body) {
		headers["Content-Type"] = "application/json";
	}

	if (IS_DEV && !!JWT_TOKEN) {
		headers.Authorization = JWT_TOKEN;
		headers["Access-Control-Allow-Origin"] = "*";
	} else if (jwt) {
		headers.Authorization = jwt;
		headers["Access-Control-Allow-Origin"] = "*";
	}

	return headers;
};
/**
 * @typedef {{
 * 	body?: Record<string, any>;
 * 	query?: Record<string, any>;
 * 	controller?: AbortController;
 * 	headers?: import("axios").RawAxiosRequestHeaders;
 * 	formData?: string | Blob;
 * 	formDataName?: string;
 * }} HttpRequestParams
 */

/**
 * @typedef {{
 * 	silentError?: boolean;
 * }} HttpRequestOptions
 */

/**
 * @param {{ method: import("@shared/const").HTTP_METHODS_TYPES; url: string }} requestInfo
 * @param {HttpRequestParams} [params]
 * @param {HttpRequestOptions} [options]
 */
export const httpRequest = (requestInfo, params = {}, options = {}) => {
	const { method = HTTP_METHODS.GET, url } = requestInfo;
	const { body, query, controller, headers, formData, formDataName } = params;
	const { silentError } = options;

	let data = null;

	if (body) {
		data = JSON.stringify(body);
	} else if (formData && formDataName) {
		data = new FormData();
		data.append(formDataName, formData);
	}

	/** @type {import("axios").AxiosRequestConfig<any>} */
	const requestConfig = {
		method,
		url,
		data,
		withCredentials: true,
		params: query,
		signal: controller?.signal,
		headers: { ...axios.defaults.headers, ...getHeaders(params), ...headers },
	};
	const requestInstance = silentError ? requestWithSilentError : request;

	return requestInstance(requestConfig);
};
