<script setup>
import { computed, ref, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import { SwiperSlide } from "swiper/vue";

import { useScreenSize } from "@shared/lib";
import { SHORT_SWIPER_OPTIONS, REQUST_STATUSES } from "@shared/const";
import { TYPOGRAPHY_TYPES, Typography, AdaptiveSlider } from "@shared/ui";
import {
	AnnouncementPreview,
	ON_PAGE_COUNT,
	getAnnouncementOrderParams,
	FIELD,
} from "@entities/announcement";
import { debounce } from "lodash";
import { useTranslation } from "i18next-vue";

const store = useStore();
const { isDesktop, isMobile, screenSize } = useScreenSize();
const { t } = useTranslation();

const sliderData = ref([]);

const hasRunOnce = ref(false);

const currentRegionId = computed(() => {
	return store.getters["announcement/currentRegion"]?.id;
});

const countryByCityId = computed(() => {
	return store.getters["announcement/countryByCityId"](currentRegionId);
});

const getProducts = async (paramsAction) => {
	await store.dispatch("announcement/getSliderData", {
		params: paramsAction,
		onPage: ON_PAGE_COUNT.ON_SLIDER_COUNT,
	});

	sliderData.value = store.getters["announcement/announcementsSliderList"];
};

const fetchProducts = debounce((countryId) => {
	const params = {
		country_id: countryId || 0,
		forYou: true,
		orders: [getAnnouncementOrderParams(FIELD.REGION, currentRegionId.value, 1)],
		page: 1,
	};

	try {
		getProducts(params);
	} catch (err) {
		console.error(err);
	}
}, 100);

const suspensePreviewCount = computed(() => {
	if (isDesktop.value && screenSize.value !== "lg") {
		return 5;
	}
	if (screenSize.value === "lg") {
		return 3;
	}

	return 2;
});

const currentUserExists = computed(() => {
	return store.getters["user/currentUserStatus"] === REQUST_STATUSES.SUCCESS;
});

watch(
	() => countryByCityId.value,
	(newVal, oldVal) => {
		if (oldVal && oldVal !== newVal) {
			fetchProducts(newVal?.id);
		}
	}
);

watchEffect(() => {
	if (!hasRunOnce.value) {
		const currentUser = store.getters["user/getCurrentUser"];
		if (!countryByCityId.value?.id && currentUser.username) {
			fetchProducts(currentUser.country_id);
			hasRunOnce.value = true;
		} else if (countryByCityId.value?.id) {
			fetchProducts(countryByCityId.value?.id);
		}
	}
});
</script>

<template>
	<AdaptiveSlider
		v-if="currentUserExists && sliderData.length > 3"
		:swiperOptions="SHORT_SWIPER_OPTIONS"
		:slider-length="sliderData.length"
		:countSlider="suspensePreviewCount"
		class="slider-box"
	>
		<template #title>
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="groups-header">
				{{ t("sliderTitleRecomendationProduct") }}
			</Typography>
		</template>
		<template #swiperSlide>
			<SwiperSlide v-for="item in sliderData" :key="item.item_id">
				<AnnouncementPreview
					with-keep-alive
					:announcementCard="item"
					:with-favorite="true"
					:isMobile="isMobile"
				/>
			</SwiperSlide>
			<template v-if="sliderData.length === 0">
				<SwiperSlide v-for="item in suspensePreviewCount" :key="item">
					<AnnouncementPreview :suspense="true" />
				</SwiperSlide>
			</template>
		</template>
	</AdaptiveSlider>
</template>

<style scoped lang="scss">
@import "./AnnouncementRecommended.scss";
</style>
