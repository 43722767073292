export default {
	ru: {
		buttonTextDontSave: "Не сохранять",
		buttonTextBlock: "Заблокировать",
		buttonTextBack: "Назад",
		buttonTextSave: "Сохранить",
		buttonTextCancel: "Отменить",
		buttonTextRemove: "Cнять с публикации",
		buttonTextPublish: "Опубликовать",
		buttonTextEdit: "Редактировать",
		buttonTextCahtWrite: "Написать в чат",
		buttonTextemptyList: "К объявлениям",
		buttonTextDelete: "Удалить",
		buttonTextNotNow: "Не сейчас",
		buttonTextGoProfile: "Перейти в профиль",
		buttonTextRefresh: "Обновить",
	},
	en: {
		buttonTextDontSave: "Do not save",
		buttonTextBlock: "Block",
		buttonTextBack: "Back",
		buttonTextSave: "Save",
		buttonTextCancel: "Cancel",
		buttonTextRemove: "Unpublish",
		buttonTextPublish: "Publish",
		buttonTextEdit: "Edit",
		buttonTextCahtWrite: "Send a message in chat",
		buttonTextemptyList: "To ads",
		buttonTextDelete: "Delete",
		buttonTextNotNow: "Not now",
		buttonTextGoProfile: "Go to profile",
		buttonTextRefresh: "Refresh",
	},
	ka: {
		buttonTextDontSave: "არ შევინახო",
		buttonTextBlock: "დაბლოკვა",
		buttonTextBack: "უკან",
		buttonTextSave: "შენახვა",
		buttonTextCancel: "გაუქმება",
		buttonTextRemove: "გაუქმება",
		buttonTextPublish: "განცხადების განთავსება",
		buttonTextEdit: "რედაქტირება",
		buttonTextCahtWrite: "ჩათში მიწერა",
		buttonTextemptyList: "განცხადებების ნახვა",
		buttonTextDelete: "წაშლა",
		buttonTextNotNow: "სხვა დროს...",
		buttonTextGoProfile: "პროფაილზე გადასვლა",
		buttonTextRefresh: "განახლება",
	},
	tr: {
		buttonTextDontSave: "Kaydetme",
		buttonTextBlock: "Engelle",
		buttonTextBack: "Geri",
		buttonTextSave: "Kaydet",
		buttonTextCancel: "İptal",
		buttonTextRemove: "Yayından kaldır",
		buttonTextPublish: "Yayınla",
		buttonTextEdit: "Düzenle",
		buttonTextCahtWrite: "Sohbette bir mesaj gönderin",
		buttonTextemptyList: "İlanlar",
		buttonTextDelete: "Sil",
		buttonTextNotNow: "Şimdi değil",
		buttonTextGoProfile: "Profile git",
		buttonTextRefresh: "Yenile",
	},
};
