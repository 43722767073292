export default {
	ru: {
		updateModalText: "Доступна новая версия",
	},
	en: {
		updateModalText: "New version available",
	},
	ka: {
		updateModalText: "ახალი ვერსია ხელმისაწვდომია!",
	},
	tr: {
		updateModalText: "Yeni sürüm mevcut",
	},
};
