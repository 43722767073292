export default {
	ru: {
		myActivityMenuText: "Перейти к моим объявлениям",
		myActivityMenuTextSutext1: "У вас нет отзывов",
		myActivityMenuTextSutext2: "У вас",
		myActivityMenuTextNotActiveAds: "У вас нет активных объявлений",
		myActivityMenuTextActive1: "активное",
		myActivityMenuTextActive2: "активных",
		myActivityMenuTextActive3: "активных",
		myActivityMenuTextAds1: "объявление",
		myActivityMenuTextAds2: "объявления",
		myActivityMenuTextAds3: "объявлений",
	},
	en: {
		myActivityMenuText: "Go to my ads",
		myActivityMenuTextSutext1: "You have no reviews",
		myActivityMenuTextSutext2: "You have",
		myActivityMenuTextNotActiveAds: "You have no active ads",
		myActivityMenuTextActive1: "active",
		myActivityMenuTextActive2: "active",
		myActivityMenuTextActive3: "active",
		myActivityMenuTextAds1: "ad",
		myActivityMenuTextAds2: "ads",
		myActivityMenuTextAds3: "ads",
	},
	ka: {
		myActivityMenuText: "ჩემი განცხადებების ნახვა",
		myActivityMenuTextSutext1: "თქვენ არ გაქვთ შეფასება",
		myActivityMenuTextSutext2: "თქვენ",
		myActivityMenuTextNotActiveAds: "თქვენ არ გაქვთ აქტიური განცხადებები",
		myActivityMenuTextActive1: "აქტიურია",
		myActivityMenuTextActive2: "აქტიურიბი",
		myActivityMenuTextActive3: "აქტიურები",
		myActivityMenuTextAds1: "განცხადება",
		myActivityMenuTextAds2: "განცხადება",
		myActivityMenuTextAds3: "განცხადებები",
	},
	tr: {
		myActivityMenuText: "İlanlarıma git",
		myActivityMenuTextSutext1: "Henüz yorumunuz yok",
		myActivityMenuTextSutext2: "Sizde bulunan",
		myActivityMenuTextNotActiveAds: "Aktif ilanınız bulunmamaktadır",
		myActivityMenuTextActive1: "aktif",
		myActivityMenuTextActive2: "aktif",
		myActivityMenuTextActive3: "aktif",
		myActivityMenuTextAds1: "ilan",
		myActivityMenuTextAds2: "ilanlar",
		myActivityMenuTextAds3: "ilanlar",
	},
};
