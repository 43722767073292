<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { TYPOGRAPHY_TYPES, Typography, SvgTemplate } from "@shared/ui";
import { useElementVisibility } from "@vueuse/core";
import { CHAT_EVENTS, CHAT_PROTOCOL } from "@shared/const";
import { createLocalDate } from "@shared/lib";
import { useTranslation } from "i18next-vue";

const store = useStore();
const { t } = useTranslation();

const props = defineProps({
	message: {
		type: Object,
		required: true,
	},
});

const messageBlock = ref(null);
const showOriginal = ref(false);

const isVisible = useElementVisibility(messageBlock);

const userData = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const myMessage = computed(() => {
	return userData.value?.user_id === props.message.sender;
});

const messageTime = computed(() => {
	return props.message.timestamp ? createLocalDate(props.message.timestamp).toFormat("T") : null;
});

const messageText = computed(() => {
	if (myMessage.value) return props.message.text;
	return props.message.translated || props.message.text;
});

const messageIndicationIcon = computed(() => {
	if (myMessage.value && props.message.viewed) return "viewed";
	if (myMessage.value && props.message.delivered) return "delivered";
	return null;
});

watch(isVisible, (value) => {
	if (value && !props.message.viewed && !myMessage.value) {
		store
			.dispatch("chat/emitEvent", {
				event: CHAT_EVENTS.ACTION,
				content: CHAT_PROTOCOL.ACTIONS.MESSAGE_SEEN(
					props.message.chat_id,
					props.message.message_id
				),
			})
			.then(() =>
				store.commit("chat/SET_MESSAGE_SEEN", {
					chatId: props.message.chat_id,
					messageId: props.message.message_id,
				})
			);
	}
});
</script>

<template>
	<div class="message" ref="messageBlock" :class="{ message_my: myMessage }">
		<div class="message__text" :class="{ message__text_my: myMessage }">
			<Typography :type="TYPOGRAPHY_TYPES.TEXT_1" class="message__raw-text">
				{{ messageText }}
			</Typography>
			<div class="message__info">
				<div
					v-if="!myMessage && message.translated && userData.language_id"
					class="message__original"
					@click="showOriginal = !showOriginal"
				>
					<SvgTemplate name="hyeroglyph" />
					<Typography class="message__button" :type="TYPOGRAPHY_TYPES.BUTTON_3">
						{{ t("ChatMessageBtnOriginal") }}
					</Typography>
				</div>
				<div class="message__indication">
					<Typography :type="TYPOGRAPHY_TYPES.BUTTON_3">
						{{ messageTime }}
					</Typography>
					<SvgTemplate v-if="messageIndicationIcon" :name="messageIndicationIcon" />
					<!-- <div v-if="myMessage" class="messages__block__indication" :class="messageStatusClass" /> -->
				</div>
			</div>
			<div v-show="showOriginal" class="message__original-container">
				<Typography class="message__original-text" :type="TYPOGRAPHY_TYPES.TEXT">
					{{ message.text }}
				</Typography>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./ChatMessage.scss";
</style>
