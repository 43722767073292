export default {
	ru: {
		categorySelectorTitle: "Категория",
		categorySelectorSubTitle: "Выберите категорию, в которой будет размещено объявление:",
	},
	en: {
		categorySelectorTitle: "Category",
		categorySelectorSubTitle: "Select the category in which the ad will be placed:",
	},
	ka: {
		categorySelectorTitle: "კატეგორია",
		categorySelectorSubTitle: "აირჩიეთ კატეგორია რომელშიც იქნება განთავსებული განცხადება:",
	},
	tr: {
		categorySelectorTitle: "Kategori",
		categorySelectorSubTitle: "İlanın yerleştirileceği kategoriyi seçin:",
	},
};
