<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { Typography, TYPOGRAPHY_TYPES, Image, useSuspense, SvgTemplate } from "@shared/ui";
import { BUCKET_URL, BUCKET_PREFIX_MEDIUM, NOT_IMAGES } from "@shared/const";
import { getCountryFlag, useScreenSize } from "@shared/lib";

const props = defineProps({
	data: {
		type: Object,
		default: () => {},
	},
	lineContent: {
		type: Boolean,
		default: false,
	},
	showInfo: {
		type: Boolean,
		default: true,
	},
});

const store = useStore();
const { isSuspense } = useSuspense(true);
const { isDesktop, screenSize } = useScreenSize();

const countryById = computed(() => {
	return store.getters["announcement/countryById"](props.data?.country_id);
});

const cityById = computed(() => {
	return store.getters["announcement/cityById"](props.data?.region_id);
});

const avatarUrl = computed(() => {
	if (!props.data.creator_photo) {
		return NOT_IMAGES.ANONYM;
	}
	return `${BUCKET_URL}${BUCKET_PREFIX_MEDIUM}/user/${props.data.created_by}/${props.data.creator_photo}`;
});

const currentCountryFlag = computed(() => {
	return getCountryFlag({
		countryId: countryById.value?.id,
		countryIsoCode: countryById.value?.isoCode,
	});
});

const getDate = computed(() => {
	const dateObj = new Date(props.data.fb_date);

	const day = dateObj.getDate();
	const month = dateObj.getMonth() + 1;
	const year = dateObj.getFullYear();

	return `${day.toString().padStart(2, "0")}.${month.toString().padStart(2, "0")}.${year}`;
});
</script>

<template>
	<div class="review__content-wrapper">
		<div :class="{ 'review__content-info': lineContent && isDesktop && screenSize !== 'lg' }">
			<div class="review__content-grade">
				<div
					v-for="(item, index) in 5"
					:class="['review__grade', { 'review__grade-active': data.rate >= index + 1 }]"
					:key="item + 1"
				>
					<SvgTemplate name="star" />
				</div>
				<Typography class="review__grade-date" :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
					{{ getDate }}
				</Typography>
			</div>
			<div v-if="showInfo" class="review__info">
				<div class="review__avatar">
					<div class="review__circle_block">
						<Image :src="avatarUrl" :alt="аватарка" :suspense="isSuspense" />
					</div>
				</div>
				<div>
					<Typography class="review__name" :type="TYPOGRAPHY_TYPES.CARD_TITLE">
						{{ data?.first_name }}&nbsp; {{ data.second_name }}
					</Typography>
				</div>
				<div class="review__location">
					<Image
						class="review__location-icon"
						:src="currentCountryFlag"
						:key="currentCountryFlag"
						:suspense="isSuspense"
					/>
					<Typography
						class="review__location-name"
						:type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY"
						:suspense="isSuspense"
					>
						{{ cityById?.name }}, {{ countryById?.name }}
					</Typography>
				</div>
			</div>
		</div>

		<Typography
			v-if="data.fb_text"
			class="my-review__announcement-descr"
			:type="TYPOGRAPHY_TYPES.TEXT_1"
		>
			{{ data.fb_text }}
		</Typography>
		<slot name="reviewImg" />
	</div>
</template>

<style lang="scss" scoped>
@import "./Review.scss";
</style>
