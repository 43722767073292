<script setup>
import { computed, ref, nextTick, watchEffect, watch } from "vue";
import { countBy, isEqual } from "lodash";
import { useScreenSize, getCountryFlagByIsoCode } from "@shared/lib";
import {
	INPUT_MODE,
	INPUT_THEMES,
	InputField,
	Image,
	SvgTemplate,
	Tooltip,
	useTooltip,
	Typography,
	TYPOGRAPHY_TYPES,
	Modal,
	MOBILE_MODAL_SHOW_DELAY,
} from "@shared/ui";
import { useEventBus } from "@vueuse/core";
import { useStore } from "vuex";
import { usePhone } from "../lib";

const props = defineProps({
	modelValue: {
		type: String,
		requied: true,
		default: "",
	},
	code: {
		type: String,
		default: "",
	},
	isoCode: {
		type: String,
		default: "",
	},
	theme: {
		type: String,
		default: INPUT_THEMES.DARK,
	},
	errors: {
		type: Array,
		default: undefined,
	},
	errorZeroHeight: {
		type: Boolean,
		default: false,
	},
	disabled: {
		type: Boolean,
		default: false,
	},
	silentDisabled: {
		type: Boolean,
		default: false,
	},
});

const store = useStore();
const { findPhoneCountry } = usePhone();
const { isDesktop } = useScreenSize();

const emit = defineEmits([
	"update:modelValue",
	"update:code",
	"update:isoCode",
	"isValid",
	"focus",
	"blur",
]);

const phonesByCountry = computed(() => store.getters["phone/withFirstRussian"]);

const selectedCountry = ref(null);

const setSelectedCountry = (country) => {
	if (!isEqual(country, selectedCountry.value)) {
		selectedCountry.value = country;
		inputModel.value = "";
	}
	hideOptions();
	nextTick(() => {
		inputRef.value.focus();
	});
};

/** @type {import("vue").Ref<InstanceType<typeof InputField>>} */
const inputRef = ref(null);

const inputModel = ref("");

watchEffect(() => {
	if (selectedCountry.value) {
		const phone = inputModel.value ? `${selectedCountry.value.code}${inputModel.value}` : "";
		const code = inputModel.value ? selectedCountry.value.code : null;
		const isoCode = selectedCountry.value.iso_code || null;

		emit("update:modelValue", phone);
		emit("update:code", code);
		emit("update:isoCode", isoCode);
	}
});

watchEffect(() => {
	if (selectedCountry.value) {
		const { mask } = selectedCountry.value;
		const numberLength = countBy(mask).x;

		if (numberLength === inputModel.value?.length) {
			emit("isValid", true);
		} else {
			emit("isValid", false);
		}
	}
});

watch(
	[phonesByCountry, () => props.isoCode, () => props.modelValue],
	() => {
		if (!phonesByCountry.value.length) return;
		const counrtyByIsoCode = phonesByCountry.value.find(
			(phone) => phone.iso_code === props.isoCode
		);

		if (counrtyByIsoCode) {
			selectedCountry.value = counrtyByIsoCode;
			inputModel.value = props.modelValue?.slice(counrtyByIsoCode.code.toString().length) || "";
		} else if (props.modelValue) {
			// Полный матч по коду и длине номера
			const currentPhoneCountry = findPhoneCountry(props.modelValue);

			// Сетим страну и значение в инпут по полному совподению
			if (currentPhoneCountry) {
				selectedCountry.value = currentPhoneCountry;
				inputModel.value =
					props.modelValue?.slice(currentPhoneCountry.code.toString().length) || "";
			} else {
				// Матч только по коду
				const currentPhoneCountryByStart = phonesByCountry.value.find((phone) => {
					return props.modelValue.startsWith(phone.code);
				});
				if (currentPhoneCountryByStart) {
					selectedCountry.value = currentPhoneCountryByStart;

					inputModel.value = props.modelValue?.slice(
						currentPhoneCountryByStart.code.toString().length || ""
					);
				} else if (!selectedCountry.value) {
					// Ставим дефолтную страну(Россия) если не выбрана другая страна
					[selectedCountry.value] = phonesByCountry.value;
				}
			}
		} else if (!selectedCountry.value) {
			// Ставим дефолтную страну(Россия) если не выбрана другая страна
			[selectedCountry.value] = phonesByCountry.value;
		}
	},
	{ immediate: true }
);

const { isVisible, show, hide } = useTooltip();

const hideTooltip = () => {
	hide();
};

const showTooltip = () => {
	show();
};

/** @type {import("vue").Ref<InstanceType<typeof Modal>>} */
const modal = ref();

/** @type {import("vue").Ref<InstanceType<typeof InputField>>} */
const inputFieldModalRef = ref(null);

const isOptionsOpen = ref(false);

const offsetY = computed(() => {
	return props.errors && !props.errorZeroHeight ? -35 : -16;
});

const showModal = () => {
	modal.value?.open();
	setTimeout(() => {
		inputFieldModalRef.value?.focus();
	}, MOBILE_MODAL_SHOW_DELAY);
};

const hideModal = () => {
	modal.value?.close();
};

const hideOpenedTooltips = useEventBus("phone-hide");

const showOptions = () => {
	hideOpenedTooltips.emit();
	if (isOptionsOpen.value === false) {
		isOptionsOpen.value = true;
		showTooltip();
		showModal();
	}
};

const hideOptions = () => {
	if (isOptionsOpen.value === true) {
		isOptionsOpen.value = false;
		hideModal();
		hideTooltip();
	}
};

hideOpenedTooltips.on(hideOptions);

const toggleOptions = () => {
	if (isOptionsOpen.value) {
		hideOptions();
	} else {
		showOptions();
	}
};
</script>

<template>
	<div class="phone" :class="{ phone_shown: isOptionsOpen }">
		<Tooltip :is-visible="isVisible" :offset="[0, offsetY]" @hide="hideOptions" v-if="isDesktop">
			<InputField
				v-model="inputModel"
				ref="inputRef"
				:mode="INPUT_MODE.NUMBER"
				:mask="selectedCountry?.mask"
				:errors="errors"
				:focused="isOptionsOpen"
				@focus="emit('focus')"
				@blur="emit('blur')"
				type="tel"
				:disabled="disabled"
				:silent-disabled="silentDisabled"
				:error-zero-height="errorZeroHeight"
			>
				<template #left>
					<div v-if="selectedCountry" class="phone__select-country" @click.stop="toggleOptions">
						<Image
							class="phone__country-flag"
							:src="getCountryFlagByIsoCode(selectedCountry.iso_code)"
						/>
						<div class="phone__selected-code" v-show="selectedCountry">
							<Typography class="phone__selected-code-num" :type="TYPOGRAPHY_TYPES.BUTTON_2">
								+ {{ selectedCountry?.code }}
							</Typography>
							<SvgTemplate
								class="phone__country-polygon"
								:class="{ 'phone__country-polygon_shown': isVisible }"
								name="chevron"
							/>
						</div>
					</div>
				</template>
			</InputField>
			<template #content>
				<ul class="phone__country-list">
					<li v-for="country in phonesByCountry" :key="country.iso_code">
						<button type="button" class="phone__country-btn" @click="setSelectedCountry(country)">
							<Image class="phone__country-flag" :src="getCountryFlagByIsoCode(country.iso_code)" />

							<div class="phone__country-name">{{ country.country_name }}</div>
							<div class="phone__country-phone-code">+ {{ country.code }}</div>
						</button>
					</li>
				</ul>
			</template>
		</Tooltip>
		<template v-else>
			<InputField
				v-model="inputModel"
				ref="inputRef"
				class="phone__input"
				:mode="INPUT_MODE.NUMBER"
				:mask="selectedCountry?.mask"
				:errors="errors"
				@focus="emit('focus')"
				@blur="emit('blur')"
				type="tel"
				:disabled="disabled"
				:silent-disabled="silentDisabled"
				:error-zero-height="errorZeroHeight"
			>
				<template #left>
					<div v-if="selectedCountry" class="phone__select-country" @click.stop="toggleOptions">
						<Image
							class="phone__country-flag"
							:fade-duration="0"
							:src="getCountryFlagByIsoCode(selectedCountry.iso_code)"
						/>
						<div class="phone__selected-code" v-show="selectedCountry">
							<Typography class="phone__selected-code-num" :type="TYPOGRAPHY_TYPES.BUTTON_2">
								+ {{ selectedCountry?.code }}
							</Typography>
							<SvgTemplate
								class="phone__country-polygon"
								:class="{ 'phone__country-polygon_shown': isVisible }"
								name="chevron"
							/>
						</div>
					</div>
				</template>
			</InputField>
			<Modal ref="modal" @close="hideOptions">
				<Typography class="phone__placeholder" :type="TYPOGRAPHY_TYPES.TITLE_2">
					Код страны
				</Typography>
				<div class="phone__options-top"></div>
				<div class="phone__options-wrapper mobile">
					<div class="phone__options">
						<ul class="phone__country-list">
							<li v-for="country in phonesByCountry" :key="country.iso_code">
								<button
									type="button"
									class="phone__country-btn"
									@click="setSelectedCountry(country)"
								>
									<Image
										class="phone__country-flag"
										:fade-duration="0"
										:src="getCountryFlagByIsoCode(country.iso_code)"
									/>

									<div class="phone__country-name">{{ country.country_name }}</div>
									<div class="phone__country-phone-code">+ {{ country.code }}</div>
								</button>
							</li>
						</ul>
					</div>
				</div>
			</Modal>
		</template>
	</div>
</template>

<style lang="scss" scoped>
@import "./Phone.scss";
</style>
