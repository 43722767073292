<script setup>
import { ref, computed, nextTick, watch, useSlots } from "vue";
import { useElementVisibility } from "@vueuse/core";
import { Skeleton } from "../Skeleton";
// eslint-disable-next-line import/no-unresolved
import NotFound from "./assest/notFound.svg?component";

const props = defineProps({
	src: {
		type: String,
		default: "",
	},
	alt: {
		type: String,
		default: "Картинка",
	},
	suspense: {
		type: Boolean,
		default: false,
	},
	srcOnError: {
		type: String,
		default: null,
	},
	fadeDuration: {
		type: Number,
		default: 200,
	},
	forceLoad: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(["success", "pending", "error"]);

const slots = useSlots();

const IMAGE_STATES = /** @type {const} */ ({
	INITED: "INITED",
	PENDING: "PENDING",
	SUCCESS: "SUCCESS",
	PLUG_SUCCESS: "PLUG_SUCCESS",
	PLUG_PENDING: "PLUG_PENDING",
	PLUG_ERROR: "PLUG_ERROR",
});

/** @type {import("vue").Ref<HTMLElement>} */
const imageRef = ref(null);

/** @type {import("vue").Ref<(typeof IMAGE_STATES)[keyof typeof IMAGE_STATES]>} */
const imageState = ref(IMAGE_STATES.INITED);

const isImageCached = () => {
	const image = new Image();
	image.src = props.src;
	return image.complete;
};

const loadImage = () => {
	if (imageState.value !== IMAGE_STATES.PLUG_SUCCESS) {
		imageState.value = IMAGE_STATES.PENDING;
	}
};

const fadeDuration = ref(props.fadeDuration);

const isImageVisible = useElementVisibility(imageRef);

const queueLoadImage = () => {
	if (!props.forceLoad) {
		const clearWatch = watch(
			isImageVisible,
			() => {
				if (isImageVisible.value) {
					loadImage();
					nextTick(() => clearWatch());
				}
			},
			{ immediate: true }
		);
	} else {
		loadImage();
	}
};

const initImage = () => {
	if (props.src) {
		if (isImageCached()) {
			emit("success");

			imageState.value = IMAGE_STATES.SUCCESS;
		} else {
			imageState.value = IMAGE_STATES.INITED;
			queueLoadImage();
		}
	} else if (props.srcOnError) {
		imageState.value = IMAGE_STATES.PLUG_PENDING;
	} else {
		imageState.value = IMAGE_STATES.PLUG_SUCCESS;
	}
};

watch(
	() => props.src,
	(newVal, oldVal) => {
		if (newVal !== oldVal) {
			initImage();
		}
	},
	{ immediate: true }
);

const onError = () => {
	emit("error");
	if (!props.srcOnError) {
		imageState.value = IMAGE_STATES.PLUG_SUCCESS;
		setTimeout(() => {
			fadeDuration.value = 0;
		}, fadeDuration.value);
	} else {
		imageState.value = IMAGE_STATES.PLUG_PENDING;
	}
};

const onSucces = () => {
	if (imageState.value !== IMAGE_STATES.SUCCESS) {
		emit("success");
		imageState.value = IMAGE_STATES.SUCCESS;
		setTimeout(() => {
			fadeDuration.value = 0;
		}, fadeDuration.value);
	}
};

const onPlugSuccess = () => {
	imageState.value = IMAGE_STATES.PLUG_SUCCESS;
};

const onPlugError = () => {
	imageState.value = IMAGE_STATES.PLUG_ERROR;
};

const isPending = computed(() => {
	const pendingStates = [IMAGE_STATES.INITED, IMAGE_STATES.PENDING, IMAGE_STATES.PLUG_PENDING];

	const res = pendingStates.includes(imageState.value) || props.suspense;
	return res;
});

const isSuccess = computed(() => {
	const res = imageState.value === IMAGE_STATES.SUCCESS;
	return res;
});

const isImgReady = computed(() => {
	const res =
		(imageState.value === IMAGE_STATES.PENDING || imageState.value === IMAGE_STATES.SUCCESS) &&
		!props.suspense;
	return res;
});

const isError = computed(() => {
	const { PLUG_SUCCESS, PLUG_PENDING, PLUG_ERROR } = IMAGE_STATES;

	const errorStates = [PLUG_SUCCESS, PLUG_PENDING, PLUG_ERROR];

	const res = errorStates.includes(imageState.value);

	return res;
});

const isErrorReady = computed(() => {
	const res = imageState.value === IMAGE_STATES.PLUG_SUCCESS;
	return res;
});

const isSvgPlugVisible = computed(() => {
	const res = imageState.value === IMAGE_STATES.PLUG_ERROR || !props.srcOnError;
	return res;
});

const isImagePlugVisible = computed(() => {
	const res = imageState.value === IMAGE_STATES.PLUG_ERROR || props.srcOnError;
	return res;
});
</script>

<template>
	<div ref="imageRef" class="image-wrapper">
		<template v-if="isPending">
			<slot name="pending" v-if="slots.pending"></slot>
			<Skeleton class="image__skeleton" v-else />
		</template>
		<img
			draggable="false"
			v-if="isImgReady"
			class="image__img"
			:style="{ animationDuration: `${fadeDuration}ms` }"
			:class="{ image__img_success: isSuccess }"
			v-show="isSuccess"
			@load="onSucces"
			@error="onError"
			:src="src"
			:alt="alt"
		/>
		<div class="image__error" v-if="isError" :target-src="src">
			<NotFound
				v-if="isSvgPlugVisible"
				class="image__error-svg"
				:style="{ animationDuration: `${fadeDuration}ms` }"
			/>
			<img
				draggable="false"
				v-if="isImagePlugVisible"
				class="image__error-img"
				:class="{ 'image__error-img_ready': isErrorReady }"
				@load="onPlugSuccess"
				@error="onPlugError"
				:src="srcOnError"
				:alt="alt"
			/>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./Image.scss";
</style>
