<script setup>
import { ref, computed } from "vue";
import { SvgTemplate, Typography, TYPOGRAPHY_TYPES, Button } from "@shared/ui";
import { THEME_VARIANTS } from "@shared/const";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	country: {
		type: Object,
		required: true,
	},
	city: {
		type: Object,
		default: () => {},
	},
	withButtons: {
		type: Boolean,
		default: false,
	},
});

const { t } = useTranslation();

const emit = defineEmits(["onConfirm", "onBack"]);

defineExpose({ open: () => modalRef.value.open() });

const modalRef = ref(null);

const onConfirm = () => {
	emit("onConfirm");
};

const onBack = () => {
	emit("onBack");
};

const location = computed(() => {
	if (props.city?.name && props.city.id !== 0) {
		return `${props.city.name}, ${props.country.name}`;
	}
	return props.country?.name;
});
</script>

<template>
	<div class="supposed-location">
		<div class="supposed-location__title">
			<SvgTemplate class="supposed-location__svg" name="location" />
			<Typography class="supposed-location__title-text" :type="TYPOGRAPHY_TYPES.TEXT_1">
				{{ city?.id ? "Ваш город" : "Ваша страна" }}:
			</Typography>
		</div>
		<div class="supposed-location__city">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
				{{ location }}
			</Typography>
		</div>
	</div>
	<div v-if="withButtons">
		<Button @click="onConfirm" class="supposed-location__btn-confirm">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">{{ t("yesThanks") }}</Typography>
		</Button>
		<Button @click="onBack" :variant="THEME_VARIANTS.LIGHT" class="supposed-location__btn-back">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">
				{{ t("prepositionNo") }}, {{ city?.id ? t("anotherText1") : t("anotherText2") }}
			</Typography>
		</Button>
	</div>
</template>

<style lang="scss" scoped>
@import "./SupposedLocation.scss";
</style>
