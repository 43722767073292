<script setup>
import { computed, ref } from "vue";
import { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Image, SvgTemplate } from "@shared/ui";
import { getBucketUrlSmall, getBucketUrlMedium } from "@shared/const";
import "swiper/css";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox";
import "@fancyapps/ui/dist/fancybox.css";

const modules = [Navigation];
// eslint-disable-next-line no-unused-vars
const props = defineProps({
	items: {
		type: Array,
		default: () => [],
	},
	currentId: {
		type: Number,
		default: 0,
	},
	alt: {
		type: String,
		default: "Фото товара",
	},
	smallPhoto: {
		type: Boolean,
		default: true,
	},
	srcOnError: {
		type: String,
		default: undefined,
	},
});

const openPhoto = (index) => {
	const mappedItems = imagesMedium.value.map((src) => ({ src }));
	Fancybox.show(mappedItems, { startIndex: index });
};

const imagesSmall = computed(() => {
	return props.items.map((filename) => {
		return getBucketUrlSmall(filename, props.currentId);
	});
});

const imagesMedium = computed(() => {
	return props.items.map((filename) => {
		return getBucketUrlMedium(filename, props.currentId);
	});
});

const currentSlide = ref(0);

const onSlideChange = (info) => {
	currentSlide.value = info.activeIndex;
};

const prevRef = ref(null);

const nextRef = ref(null);
</script>

<template>
	<div class="photo-preview-single__container">
		<div class="photo-preview-single__inner">
			<Swiper
				ref="swiperRef"
				:modules="modules"
				:navigation="{
					prevEl: prevRef,
					nextEl: nextRef,
				}"
				@swiper="getRef"
				@slide-change="onSlideChange"
				:slides-per-view="1"
				:space-between="0"
				class="photo-preview-single"
				v-if="items.length > 0"
			>
				<SwiperSlide
					v-for="(item, index) in smallPhoto ? imagesSmall : imagesMedium"
					:key="`${item}${index}`"
					@click="openPhoto(index)"
				>
					<div class="photo-preview-single__item">
						<Image
							:src="item"
							:alt="alt"
							class="photo-preview-single__img"
							:src-on-error="srcOnError"
						/>
					</div>
				</SwiperSlide>
			</Swiper>
			<Image
				v-else
				:src="item"
				:alt="alt"
				class="photo-preview-single"
				:src-on-error="srcOnError"
			/>
			<div v-if="props.items.length > 1" class="swiper-btn">
				<button class="swiper-prev" type="button" ref="prevRef">
					<SvgTemplate name="chevron" />
				</button>
				<button class="swiper-next" type="button" ref="nextRef">
					<SvgTemplate name="chevron" />
				</button>
			</div>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./PhotoPreviewSingle.scss";
</style>
