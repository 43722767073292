export default {
	ru: {
		profileSettingsLanguage: "Язык чатов",
		profileSettingsCoin: "Валюта просмотра объявлений",
		profileSettingsRub: "Рубли",
	},
	en: {
		profileSettingsLanguage: "Chat language",
		profileSettingsCoin: "Currency for viewing ads",
		profileSettingsRub: "Rubles",
	},
	ka: {
		profileSettingsLanguage: "ჩათების ენა",
		profileSettingsCoin: "ვალუტის მიხედვით განცხადების ნახვა",
		profileSettingsRub: "რუბლი",
	},
	tr: {
		profileSettingsLanguage: "Sohbet dili",
		profileSettingsCoin: "İlan görüntüleme için para birimi",
		profileSettingsRub: "Ruble",
	},
};
