import { isEmpty, toUpper } from "lodash-es";

export const phonesByCountry = (state) => {
	return state.phones || [];
};

export const withFirstRussian = (state) => {
	const { phones } = state;
	if (!isEmpty(state.phones)) {
		const newPhones = new Set([
			phones.find((phone) => toUpper(phone.iso_code) === "RU"),
			...phones,
		]);

		return Array.from(newPhones);
	}

	return phones;
};

export const getPhoneByCode = (state) => (code) => {
	return state.phones.find((phone) => phone.code === code);
};
