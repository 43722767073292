<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { useScreenSize } from "@shared/lib";
import { PrivacyPolicy } from "@features/PrivacyPolicy";
import { AgreementsWindow } from "@features/AgreementsWindow";
import { Refresh } from "@shared/ui";
// import { LaunchingApplication } from "@features/LaunchingApplication";
import { FooterNav } from "../FooterNav";
import { HeaderNav } from "../HeaderNav";
import { HeaderNavMobile } from "../HeaderNavMobile";
import { TechnicalSupport } from "../TechnicalSupport";

const route = useRoute();
const store = useStore();

const disabledFooter = computed(() => {
	return route.meta.footerDisable;
});

const isSubHeaderEnabled = computed(() => {
	return !route.meta.subHeaderDisable;
});

const { isDesktop } = useScreenSize();

// TODO изменить видимость футера хедера мобильного устройства через роутер
const isShowMobileHeader = computed(() => {
	return (
		route.name !== "Profile" &&
		route.name !== "myAnnouncements" &&
		store.getters["announcement/activeAdEditor"]
	);
});
</script>
<template>
	<HeaderNav v-if="isDesktop" />
	<HeaderNavMobile v-if="!isDesktop" v-show="isShowMobileHeader" />
	<TechnicalSupport />
	<Refresh>
		<main
			class="main-content container"
			:class="{
				'main-content_header-hidden': !isShowMobileHeader,
				'main-content_no-footer_no-subheader': disabledFooter && !isSubHeaderEnabled,
			}"
		>
			<slot></slot>
		</main>
	</Refresh>
	<FooterNav v-if="!isDesktop" />
	<PrivacyPolicy />
	<AgreementsWindow />
</template>

<style lang="scss" scoped>
@import "./DefaultLayuout.scss";
</style>
