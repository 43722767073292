const regex = /[\u{FE00}-\u{FE0F}]|[\u{1F3FB}-\u{1F3FF}]/gu;
// \u{FE00}-\u{FE0F} -- селекторы начертания
// \u{1F3FB}-\u{1F3FF} - модификаторы цвета

const regexSymbolWithCombiningMarks = /(\P{Mark})(\p{Mark}+)/gu; // Поиск комбинируемых символов
const joiner = "200d"; // Объединитель нулевой ширины («Zero Width Joiner», ZWJ)
//  Проверяет, является ли символ ZWJ
const isJoiner = (char) => char.charCodeAt(0).toString(16) === joiner;

const normalizeText = (text) => {
	return (
		text
			// нормализуем строку
			.normalize("NFC")
			// удаляем селекторы начертания и модификаторы цвета
			.replace(regex, "")
			// удаляем комбинируемые символы
			.replace(regexSymbolWithCombiningMarks, (_, symbol) => {
				return symbol;
			})
	);
};

export const getCountGraphemes = (text) => {
	const normalizedText = normalizeText(text);

	// Разделяем строку на токены. Кодовые точки из суррогатных пар будут корректно
	// выделены в одну графему.
	const tokens = Array.from(normalizedText);
	let length = 0;
	let isComplexChar = false; // Обработка комплексных символов, склеенных через ZWJ

	// Итеррируемся по массиву токенов и комбинации, склеенные через ZWJ
	// считаем за одиу графему
	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < tokens.length; i++) {
		const char = tokens[i];
		const nextChar = tokens[i + 1];

		if (!nextChar) {
			length += 1;
			// eslint-disable-next-line no-continue
			continue;
		}

		if (isJoiner(nextChar)) {
			isComplexChar = true;
			// eslint-disable-next-line no-continue
			continue;
		}

		if (!isJoiner(char) && isComplexChar) {
			isComplexChar = false;
		}

		if (!isComplexChar) {
			length += 1;
		}
	}

	return length;
};

export const sliceGraphemesByLength = (text, length = 0) => {
	const normalizedText = normalizeText(text);

	const tokens = Array.from(normalizedText);
	let currentLength = 0;
	let isComplexChar = false; // Обработка комплексных символов, склеенных через ZWJ
	let complexCharStart = null;

	let result = "";

	tokens.forEach((char, index) => {
		if (currentLength < length) {
			const nextChar = tokens[index + 1];
			if (!nextChar) {
				currentLength += 1;
				if (complexCharStart !== null) {
					result += tokens.slice(complexCharStart, index + 1).join("");
					complexCharStart = null;
				} else {
					result += char;
				}
				return;
			}

			if (isJoiner(nextChar)) {
				isComplexChar = true;
				if (complexCharStart === null) {
					complexCharStart = index;
				}
				return;
			}

			if (!isJoiner(char) && isComplexChar) {
				isComplexChar = false;
			}

			if (!isComplexChar) {
				currentLength += 1;
				if (complexCharStart !== null) {
					result += tokens.slice(complexCharStart, index + 1).join("");
					complexCharStart = null;
				} else {
					result += char;
				}
			}
		}
	});

	return result;
};
