<script setup>
import { computed, onUnmounted, ref, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import { AnnouncementCreate } from "@features/AnnouncementCreate";
import { STATUSES, REQUST_STATUSES } from "@shared/const";
import { CardDirectInfo } from "@widgets/ui";
import { Typography, TYPOGRAPHY_TYPES } from "@shared/ui";
import { AD_TYPES } from "@entities/announcement";
import { useToast } from "vue-toast-notification";
import { isNil } from "lodash";
import { useTranslation } from "i18next-vue";
import { AnnounceCardSliders } from "../AnnounceCardSliders";

const store = useStore();
const route = useRoute();
const router = useRouter();
const toast = useToast();
const { t, i18next } = useTranslation();

const editMode = ref(false);

const openEdit = () => {
	editMode.value = true;
};

const currentAD = computed(() => {
	return store.getters["announcement/getADById"];
});

const endEdit = () => {
	editMode.value = false;
};
const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const isMy = computed(() => {
	return (
		user.value?.user_id === currentAD.value?.user_id &&
		!isNil(user.value?.user_id) &&
		!isNil(currentAD.value?.user_id)
	);
});

const photoUrls = computed(() => {
	const files = store.getters["files/getFiles"](route.params.ad_id);
	return files;
});

const adStatus = computed(() => {
	return store.getters["announcement/adStatus"];
});

onUnmounted(() => {
	store.commit("announcement/SET_AD", {});
	store.commit("announcement/SET_AD_ID", null);
});

const isErrorPlugVisible = computed(() => {
	if (currentAD.value.ad_status === STATUSES.DELETE) {
		return false;
	}
	if (currentAD.value?.user_id) {
		return (
			!isMy.value &&
			currentAD.value.ad_status !== STATUSES.PUBLIC &&
			adStatus.value !== REQUST_STATUSES.PENDING
		);
	}
	return (
		!isMy.value &&
		currentAD.value.ad_status !== STATUSES.PUBLIC &&
		adStatus.value !== REQUST_STATUSES.PENDING
	);
});

watch(
	() => route.params.ad_id,
	async (value) => {
		if (value) {
			store.dispatch("announcement/getAD", { item_id: value, language: i18next.language });
			store.dispatch("files/getFiles", value);
		}
	},
	{ immediate: true }
);

const isInitialReviewDispatch = ref(true);

watch(
	() => currentAD.value,
	async (newValue, oldValue) => {
		if (newValue.item_id !== oldValue.item_id) {
			isInitialReviewDispatch.value = true;
		}
		if (Object.values(newValue).length !== 0 && isInitialReviewDispatch.value) {
			isInitialReviewDispatch.value = false;
			store.dispatch("reviews/getReviewsAnnouncement", { ad_id: newValue.item_id });
			store.dispatch("reviews/getUserReview", {
				seller_id: newValue.user_id,
				on_page: 10,
				page: 1,
			});
		}
	}
);

const editStatus = async (status) => {
	const dict = {
		item_id: currentAD.value?.item_id,
		ad_status: status,
	};
	if (status === STATUSES.PUBLIC) {
		dict.publish_flag = true;
	}
	try {
		await store.dispatch("announcement/createAD", dict);
		store.commit("announcement/UPDATE_AD", dict);
		if ([STATUSES.ARCHIVE, STATUSES.DELETE].includes(status)) {
			const route = isMy.value ? "myAnnouncements" : "Home";
			const query = isMy.value ? { tab: AD_TYPES.ARCHIV.RUS } : {};
			toast.success(status === STATUSES.DELETE ? t("toastDeletedAds") : t("toastArchiveAds"), {
				position: "top-right",
			});
			console.log(query);
			router.push({ name: route, query });
		} else if (status === STATUSES.PUBLIC) {
			toast.success(t("toastPublishedAds"), { position: "top-right" });
		} else if (status === STATUSES.NOT_PUBLIC) {
			toast.success(t("toastUnpublishAds"), { position: "top-right" });
		}
	} catch (e) {
		console.error(e);
		if (status === STATUSES.ARCHIVE) {
			toast.error(t("toastNotRemovedAds"), { position: "top-right" });
		}
	}
};
</script>

<template>
	<div class="pad">
		<div class="pad__cannot-show" v-if="isErrorPlugVisible">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
				{{ t("announceCardInstanceLostTitle") }}
			</Typography>
			<Typography :type="TYPOGRAPHY_TYPES.TEXT_OLD">
				{{ t("announceCardInstanceLostSubtitle") }}
			</Typography>
		</div>
		<div v-else-if="!editMode">
			<CardDirectInfo
				:currentAD="currentAD"
				:is-my="isMy"
				@editAD="openEdit"
				@editStatus="editStatus"
				:photoUrls="photoUrls"
			/>
		</div>
		<div v-else>
			<AnnouncementCreate
				:group="currentAD.section_id"
				:selectCategories="currentAD.category_id"
				:selectSubCategories="currentAD.sub_category_id"
				:currentAD="currentAD"
				@endEdit="endEdit"
				@editStatus="editStatus"
			/>
		</div>
		<AnnounceCardSliders v-show="!editMode && !isErrorPlugVisible" />
	</div>
</template>

<style lang="scss" scoped>
@import "./AnnounceCardInstance.scss";
</style>
