<script setup>
import { onMounted, watch, watchEffect, computed, ref } from "vue";
import { Routing } from "@pages";
import { useBackOrHome, useTelegram } from "@shared/lib";
import { useGoogleAuth } from "@entities/user";
import { SvgSprites } from "@shared/ui";
import { IS_NATIVE_APP, SOCKET_STATUSES } from "@shared/const";
import "@shared/styles/index.scss";
import { useStore } from "vuex";
import { useDocumentVisibility, useMouse, watchThrottled } from "@vueuse/core";
import { throttle } from "lodash";
import { useTranslation } from "i18next-vue";
import { Layout } from "./Layout";

const { initData, isTelegramContext, ready, expand } = useTelegram();
const { registerGoogleAuth } = useGoogleAuth();

const store = useStore();

const visibility = useDocumentVisibility();

const { x, y } = useMouse();

const socketState = computed(() => store.getters["chat/socketState"]);

const offlineTimeout = ref();

const sendOnlineTrottled = throttle(() => store.dispatch("chat/userOnline"), 10000, {
	leading: true,
	trailing: false,
});

// Попытка авторизации если открыт в webApp телегамма
if (isTelegramContext) {
	store
		.dispatch("user/authUser", { init_data: initData })
		.then(() => store.dispatch("user/getCurrentUser"));
} else {
	store.dispatch("user/getCurrentUser");
}

// действия мышкой
watchThrottled(
	[x, y],
	() => {
		if (socketState.value === SOCKET_STATUSES.CONNECTED && visibility.value === "visible") {
			clearTimeout(offlineTimeout.value);
			sendOnlineTrottled();
		} else if (socketState.value === SOCKET_STATUSES.CONNECTED) {
			offlineTimeout.value = setTimeout(() => {
				store.dispatch("chat/userOffline");
			}, 60000);
		}
	},
	{ throttle: 5000 }
);

// Статус пользователя
watch(
	[visibility, socketState],
	() => {
		if (socketState.value === SOCKET_STATUSES.CONNECTED && visibility.value === "visible") {
			clearTimeout(offlineTimeout.value);
			sendOnlineTrottled();
		} else if (socketState.value === SOCKET_STATUSES.CONNECTED) {
			offlineTimeout.value = setTimeout(() => {
				store.dispatch("chat/userOffline");
			}, 60000);
		}
	},
	{ immediate: true }
);
const { i18next } = useTranslation();

const switchLanguage = computed(() => {
	return i18next.language;
});

// Получение списка словарей
store.dispatch("announcement/getCurrencies");
store.dispatch("user/getLanguages");
store.dispatch("announcement/getTags");
store.dispatch("phone/getPhones");
store.dispatch("user/getCurrentLocation");

onMounted(() => {
	useBackOrHome();
	if (IS_NATIVE_APP) {
		registerGoogleAuth();
	}
	if (isTelegramContext) {
		ready();
		expand();
	}
});

watchEffect(() => {
	store.dispatch("files/getDocuments", {
		translate_code: switchLanguage.value === "ru" ? switchLanguage.value : "en",
	});
	store.dispatch("announcement/getCities", { translate_code: switchLanguage.value });
	store.dispatch("announcement/getCategories", { translate_code: switchLanguage.value });
});
</script>

<template>
	<SvgSprites />
	<Layout>
		<Routing />
	</Layout>
</template>
