/* eslint-disable no-underscore-dangle */
import { useRoute, useRouter } from "vue-router";
import { createSharedComposable } from "@vueuse/core";
import { computed, nextTick, ref, watch } from "vue";
import { isMatch } from "lodash";

export const useKeepAliveRouterSharedState = createSharedComposable(() => {
	const cachedRoutes = ref([]);
	const router = useRouter();

	const includedKeepAlive = computed(() => {
		const res = [];
		cachedRoutes.value.forEach((cachedRoute) => {
			const routeOptions = router.options.routes.find(({ name }) => name === cachedRoute.name);

			if (routeOptions?.component?.__name) {
				res.push(routeOptions.component.__name);
			}
		});

		return res;
	});

	const checkIsCachedRoute = (routeName) => {
		const currentRoute = {
			position: history.state.position,
			path: history.state.current,
			name: routeName,
		};
		const currentCachedRoute = cachedRoutes.value.find((cachedRoute) =>
			isMatch(cachedRoute, currentRoute)
		);

		if (currentCachedRoute) {
			cachedRoutes.value = cachedRoutes.value.filter((route) => currentCachedRoute !== route);
		}
		return !!currentCachedRoute;
	};

	const currentHistoryPosition = ref(history.state.position);

	watch(
		() => router.currentRoute.value,
		() => {
			currentHistoryPosition.value = history.state.position;
		}
	);

	const addCachedRoutes = (route) => {
		cachedRoutes.value.push(route);
	};

	return {
		cachedRoutes,
		addCachedRoutes,
		includedKeepAlive,
		currentHistoryPosition,
		checkIsCachedRoute,
	};
});

export const useKeepAliveRouter = () => {
	const router = useRouter();
	const route = useRoute();

	const { addCachedRoutes } = useKeepAliveRouterSharedState();

	const push = (...params) => {
		addCachedRoutes({
			position: history.state.position,
			path: route.fullPath,
			name: route.name,
		});

		nextTick(() => {
			router.push(...params);
		});
	};

	return { ...router, push };
};
