export default {
	ru: {
		emptyTitle: "Ничего не найдено",
		emptySubtitle: "Попробуйте изменить запрос",
		emptyNotAds: "Вы еще не размещали объявлений",
	},
	en: {
		emptyTitle: "Nothing found",
		emptySubtitle: "Try changing your request",
		emptyNotAds: "You haven't posted any ads yet",
	},
	ka: {
		emptyTitle: "არაფერი მოიძებნა",
		emptySubtitle: "შეცვალეთ მოთხოვნა",
		emptyNotAds: "თქვენ ჯერ არ განგითავსებიათ განცხადება",
	},
	tr: {
		emptyTitle: "Sonuç bulunamadı",
		emptySubtitle: "Talebinizi değiştirmeyi deneyin",
		emptyNotAds: "Henüz ilan paylaşmadınız",
	},
};
