import { SOCKET_STATUSES, REQUST_STATUSES } from "@shared/const";

export default {
	status: {
		socket: SOCKET_STATUSES.DISCONNECTED,
		chats: REQUST_STATUSES.OUTDATED,
	},
	socket: null,
	chats: [],
	actions: [],
};
