<!-- eslint-disable no-unused-vars -->
<script setup>
import { computed, ref, watch, reactive, nextTick } from "vue";
import {
	TYPOGRAPHY_TYPES,
	Typography,
	ModalConfirm,
	InputField,
	Autocomplete,
	INPUT_THEMES,
	AUTOCOMPLETE_MODES,
	INPUT_MODE,
} from "@shared/ui";
import { SIZES, COUNTRIES } from "@shared/const";
import { useStore } from "vuex";
import { isNil, assign, isEmpty, cloneDeep } from "lodash";
import { useTranslation } from "i18next-vue";
import { ANNOUNCEMENT_FILTER_KEYS, useFilters } from "../../lib";

const modalRef = ref(null);

const store = useStore();
const { t } = useTranslation();

const reducerCurrency = (currency) => {
	return currency.currency_code;
};

const { filtersParams, setFilters } = useFilters();

const initialFilters = {
	country: null,
	city: null,
	currency: null,
	currencyUp: null,
	currencyDown: null,
};

const filterFields = reactive({ ...initialFilters });

const setFilterFieldsFromQuery = () => {
	filterFields.country = store.getters["announcement/countryById"](
		filtersParams.value[ANNOUNCEMENT_FILTER_KEYS.COUNTRY_ID]
	);
	filterFields.city = store.getters["announcement/cityById"](
		filtersParams.value[ANNOUNCEMENT_FILTER_KEYS.REGION_ID]
	);
	filterFields.currency = store.getters["announcement/currencyById"](
		filtersParams.value[ANNOUNCEMENT_FILTER_KEYS.CURRENCY_ID]
	);
	filterFields.currencyDown = filtersParams.value[ANNOUNCEMENT_FILTER_KEYS.CURRENCY_DOWN];
	filterFields.currencyUp = filtersParams.value[ANNOUNCEMENT_FILTER_KEYS.CURRENCY_UP];
};

const filterParams = computed(() => {
	const params = {
		[ANNOUNCEMENT_FILTER_KEYS.COUNTRY_ID]: filterFields.country?.id,
		[ANNOUNCEMENT_FILTER_KEYS.REGION_ID]: filterFields.city?.id,
		[ANNOUNCEMENT_FILTER_KEYS.CURRENCY_ID]: filterFields.currency?.currency_id,
		[ANNOUNCEMENT_FILTER_KEYS.CURRENCY_UP]: filterFields.currencyUp,
		[ANNOUNCEMENT_FILTER_KEYS.CURRENCY_DOWN]: filterFields.currencyDown,
	};

	return params;
});

const cities = computed(() => {
	const cityData = store.getters["announcement/citiesByFilters"](
		filterFields.country?.id !== undefined ? filterFields.country?.id : filtersParams.country_id
	);

	return cityData;
});

const countries = computed(() => {
	return store.getters["announcement/countries"]({
		exclude: [COUNTRIES.RUSSIA.id, COUNTRIES.ALL.id],
	});
});

const currencies = computed(() => {
	return store.getters["announcement/currencies"];
});

watch(
	() => filterFields.country,
	() => {
		const newCountryId = filterFields.country?.id;

		const currentCityId = filterFields.city?.id;

		if (!isNil(newCountryId) && !isNil(currentCityId)) {
			const prevCountryId = store.getters["announcement/countryByCityId"](currentCityId)?.id;

			const isNeedToClearSelectedCity = newCountryId !== prevCountryId;

			if (isNeedToClearSelectedCity) {
				filterFields.city = cities.value.find((x) => x.id === currentCityId);
			}
			if (newCountryId === 0) {
				filterFields.city = null;
			}
		} else {
			filterFields.city = null;
		}
	}
);

watch(
	() => filterFields.currency,
	() => {
		if (!filterFields.currency) {
			filterFields.currencyDown = null;
			filterFields.currencyUp = null;
		}
	}
);

const openModal = () => {
	modalRef.value.open();
	setFilterFieldsFromQuery();
};

let needToSetFiltersFormFromParams = true;

const closeModal = () => {
	modalRef.value.close();
};

const onClose = () => {
	if (needToSetFiltersFormFromParams) {
		setFilterFieldsFromQuery();
	} else {
		needToSetFiltersFormFromParams = true;
	}
};

const onConfirm = () => {
	setFilters(filterParams.value);
	needToSetFiltersFormFromParams = false;
	closeModal();
};

const onBack = async () => {
	clearForm();
	setFilters(filterParams.value);
	needToSetFiltersFormFromParams = false;
};

const reducer = (item) => {
	return item.name;
};

const clearForm = () => {
	assign(filterFields, initialFilters);
};

defineExpose({ open: openModal, close: closeModal });
</script>

<template>
	<ModalConfirm
		ref="modalRef"
		@confirm="onConfirm"
		@back="onBack"
		@close="onClose"
		:allow-self-close="true"
		back-text="changeRegionModalBtnBack"
		confirm-text="changeRegionModalBtnConfirm"
		with-cross
	>
		<div class="filter-modal__container">
			<Typography :type="TYPOGRAPHY_TYPES.CARD_NAME_2">{{ t("filterMoTitle") }}</Typography>
			<div class="filter-modal__local">
				<Autocomplete
					class="country-autocomplete"
					:class="{ 'autocomplete__blue-color': filterFields.country?.id === 0 }"
					:placeholder="t('placeholderChangeRegionModalCoutry')"
					:disable-input="true"
					:items="countries"
					v-model="filterFields.country"
					:reducer="reducer"
				>
					<template #left>
						<div class="filter-modal__header-margin">
							<Typography :type="TYPOGRAPHY_TYPES.SLOT_INPUT">
								{{ t("filterMoCountry") }}:
							</Typography>
						</div>
					</template>
				</Autocomplete>
				<Autocomplete
					class="city-autocomplete"
					:class="{ 'autocomplete__blue-color': filterFields.city?.name === 'Все города' }"
					:placeholder="
						!filterFields?.country
							? t('filterMoCountryFirst')
							: t('placeholderChangeRegionBtnEmpty')
					"
					v-model="filterFields.city"
					:items="cities"
					:isCurrentAllCountryValue="filterFields?.country?.value?.id === 0"
					:mode="'local'"
					:empty-items-message="'Выберите сначала страну'"
					:reducer="reducer"
					:disabled="isNil(filterFields.country?.id)"
				>
					<template #left>
						<div class="filter-modal__header-margin">
							<Typography :type="TYPOGRAPHY_TYPES.SLOT_INPUT">{{ t("filterMoCity") }}:</Typography>
						</div>
					</template>
					<template #item="{ reducedItem, index }">
						<Typography :type="index > 2 ? TYPOGRAPHY_TYPES.INPUT : TYPOGRAPHY_TYPES.INPUT_B">
							{{ reducedItem }}
						</Typography>
					</template>
				</Autocomplete>
			</div>
			<div class="filter-modal__currency-block">
				<Autocomplete
					:placeholder="t('filterMoSelectCurrency')"
					class="currency-autocomplete"
					:size="SIZES.NORMAL"
					v-model="filterFields.currency"
					:items="currencies"
					:mode="AUTOCOMPLETE_MODES.LOCAL"
					:reducer="reducerCurrency"
					:theme="INPUT_THEMES.LIGHT"
				>
					<template #left>
						<div class="filter-modal__header-margin">
							<Typography :type="TYPOGRAPHY_TYPES.SLOT_INPUT">
								{{ t("filterMoCurrency") }}:
							</Typography>
						</div>
					</template>
				</Autocomplete>

				<InputField
					:placeholder="
						filterFields.currency ? t('filterMoStartingPrice') : t('filterMoSelectCurrency')
					"
					v-model="filterFields.currencyDown"
					:mode="INPUT_MODE.NUMBER"
					:disabled="!filterFields.currency"
					:theme="INPUT_THEMES.LIGHT"
					type="tel"
					class="filter-modal__input-begin filter-modal__input"
				>
					<template #left>
						<div class="filter-modal__header-margin">
							<Typography :type="TYPOGRAPHY_TYPES.SLOT_INPUT">{{ t("filterMoFrom") }}</Typography>
						</div>
					</template>
				</InputField>
				<InputField
					:placeholder="
						filterFields.currency ? t('filterMoMaximumCost') : t('filterMoSelectCurrency')
					"
					v-model="filterFields.currencyUp"
					:mode="INPUT_MODE.NUMBER"
					:disabled="!filterFields.currency"
					type="tel"
					:theme="INPUT_THEMES.LIGHT"
					class="filter-modal__input filter-modal__input-end"
				>
					<template #left>
						<div class="filter-modal__header-margin">
							<Typography :type="TYPOGRAPHY_TYPES.SLOT_INPUT">{{ t("filterMoBefore") }}</Typography>
						</div>
					</template>
				</InputField>
			</div>
		</div>
	</ModalConfirm>
</template>

<style lang="scss" scoped>
@import "./FiltersModal.scss";
</style>
