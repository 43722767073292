<script setup>
import { ref, nextTick } from "vue";
import { ModalConfirm, TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { AppUpdate } from "@capawesome/capacitor-app-update";
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();

const modalRef = ref(null);

const openAppStore = async () => {
	try {
		await AppUpdate.openAppStore();
	} catch (error) {
		modalRef.value?.close();
	}
};

const init = async () => {
	try {
		const { currentVersion, availableVersion } = await AppUpdate.getAppUpdateInfo();

		if (+currentVersion < +availableVersion) {
			modalRef.value?.open();
		}
	} catch (error) {
		console.log(error);
	}
};

nextTick(() => {
	init();
});

const onBack = async () => {
	modalRef.value?.close();
};
</script>
<template>
	<ModalConfirm
		ref="modalRef"
		confirm-text="buttonTextRefresh"
		@confirm="openAppStore"
		hide-top-bar
		@back="onBack"
	>
		<Typography :type="TYPOGRAPHY_TYPES.TITLE_2" class="update-modal__title">
			{{ t("updateModalText") }}
		</Typography>
	</ModalConfirm>
</template>

<style lang="scss" scoped>
@import "./UpdateModal.scss";
</style>
