export default {
	ru: {
		myReviewsRemove: "Объявление было удалено или снято с публикации",
		myReviewsRemoveEmpty: "Отзывов пока нет",
		myReviewsReviewsMe: "Оставили мне",
		myReviewsMy: "Оставил я",
	},
	en: {
		myReviewsRemove: "The ad has been removed or unpublished",
		myReviewsRemoveEmpty: "No reviews yet",
		myReviewsReviewsMe: "They left me",
		myReviewsMy: "I left",
	},
	ka: {
		myReviewsRemove: "განცხადება იყო წაშლილი ან მოხსნილი გამოქვეყნებიდან",
		myReviewsRemoveEmpty: "შეფასებები ჯერ არ არის",
		myReviewsReviewsMe: "დამიტოვეს მე",
		myReviewsMy: "მე წავედი",
	},
	tr: {
		myReviewsRemove: "Bu ilan silinmiş ya da yayından kaldırılmış",
		myReviewsRemoveEmpty: "Henüz yorum yapılmamış",
		myReviewsReviewsMe: "Aldığım yorumlar",
		myReviewsMy: "Henüz yorum yapılmamış",
	},
};
