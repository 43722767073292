export default {
	ru: {
		previewWithdrawn: "Снято с публикации",
		previewMore: "Еще",
		previewPhoto: "фото",
	},
	en: {
		previewWithdrawn: "Unpublished",
		previewMore: "More",
		previewPhoto: "photos",
	},
	ka: {
		previewWithdrawn: "განცხადება  არ არის გამოქვეყნებული",
		previewMore: "კიდევ?",
		previewPhoto: "ფოტო",
	},
	tr: {
		previewWithdrawn: "Yayından kaldırılmış",
		previewMore: "Daha fazla",
		previewPhoto: "Fotoğraflar",
	},
};
