export default {
	ru: {
		userAnouncementsPage: "Объявление пользователя",
	},
	en: {
		userAnouncementsPage: "User announcement",
	},
	ka: {
		userAnouncementsPage: "მომხმარებლის განცხადება",
	},
	tr: {
		userAnouncementsPage: "Kullanıcı duyurusu",
	},
};
