export default {
	ru: {
		headerMobileTitle: "Сервис №1 для жизни за границей",
	},
	en: {
		headerMobileTitle: "Service #1 for living abroad",
	},
	ka: {
		headerMobileTitle: "მომსახურება №1 უცხოს ქვეყნში ცხოვრებისთვის",
	},
	tr: {
		headerMobileTitle: "",
	},
};
