export default {
	ru: {
		feedbackGratitude: "Спасибо за обратную связь!",
		feedbackTryLater: "Что-то пошло не так...Попробуйте, пожалуйста, позже",
		feedbackTitle: "Почему заходишь на",
		feedbackSubtitle: "Жми подходящий вариант",
		feedbackHelpful: "Полезно при переезде в другую страну",
		feedbackPretextConvenient1: "Удобно для",
		feedbackPretextConvenient2: "путешествий",
		feedbackMoTitle: "Что вы думаете о Rexpat?",
		feedbackTextareaTitle: "Расскажите, что круто, а что нужно переделать или добавить?",
		feedbackTextareaSubtitle: "Мы хотим становиться лучше для вас!",
		feedbackMoMailText1: "Вы можете оставить email (или авторизоваться),",
		feedbackMoMailText2: "и мы свяжемся с вами:",
	},
	en: {
		feedbackGratitude: "Thanks for the feedback!",
		feedbackTryLater: "Something went wrong... Please try again later",
		feedbackTitle: "Why are you going to",
		feedbackSubtitle: "Click the appropriate option",
		feedbackHelpful: "Useful when moving to another country",
		feedbackPretextConvenient1: "Convenient for",
		feedbackPretextConvenient2: "travel",
		feedbackMoTitle: "What do you think about Rexpat?",
		feedbackTextareaTitle: "Tell us what's cool and what needs to be redone or added?",
		feedbackTextareaSubtitle: "We want to be better for you!",
		feedbackMoMailText1: "You can leave your email (or log in),",
		feedbackMoMailText2: "and we will contact you:",
	},
	ka: {
		feedbackGratitude: "მადლობა რომ დაგვიკავშირდით!",
		feedbackTryLater: "დაფიქსირდა შეცდომა... გთხოვთ გაიმეორეთ მოგვიანებით",
		feedbackTitle: "რატომ შემოდიხარ ამ",
		feedbackSubtitle: "დააჭირე სასურველ ვარიანტს",
		feedbackHelpful: "სასარგებლოა როცა გადადიხარ სხვა ქვეყანაში",
		feedbackPretextConvenient1: "კომფორტულია რომ",
		feedbackPretextConvenient2: "მოგზაურობა",
		feedbackMoTitle: "რას ფიქრობთ Rexpat-ის შესახებ?",
		feedbackTextareaTitle:
			"მოგვიყევით თქვენი შთაბეჭდილებები, რა მოგწოთნ,რას შეცვლიდით ან დაამატებდით",
		feedbackTextareaSubtitle: "ჩვენ გვინდა მაქსიმალურად გავხდეთ უკეთესები სათქვენოდ!",
		feedbackMoMailText1: "თქვენ შეგიძლიათ დატოვოთ მეილი ან გაიაროთ ავტორიზაცია",
		feedbackMoMailText2: "და ჩვენ დაგიკავშირდებით თქვენ:",
	},
	tr: {
		feedbackGratitude: "Geri bildirimizin için teşekkür ederiz!",
		feedbackTryLater: "Bir şeyler yanlış gitti... Lütfen daha sonra tekrar deneyin",
		feedbackTitle: "Neden gidiyorsunuz",
		feedbackSubtitle: "Uygun seçeneğe tıklayın",
		feedbackHelpful: "Başka bir ülkeye taşınırken kullanışlıdır",
		feedbackPretextConvenient1: "Bunun için uygundur",
		feedbackPretextConvenient2: "seyahat",
		feedbackMoTitle: "Rexpat hakkında ne düşünüyorsunuz?",
		feedbackTextareaTitle: "Bize neyin havalı olduğunu ve neyin yeniden yapılması veya nelerin eklenmesi gerektiğini söyleyin",
		feedbackTextareaSubtitle: "Sizin için daha iyi olmak istiyoruz!",
		feedbackMoMailText1: "Email adresinizi bırakabilirsiniz (veya giriş yapabilirsiniz)",
		feedbackMoMailText2: "ve biz sizinle iletişime geçeceğiz",
	},
};
