import { computed } from "vue";
import { useStore } from "vuex";
import { IS_DEV } from "@shared/const";

export const useErrors = () => {
	const store = useStore();

	/** @type {import("vue").ComputedRef<boolean>} */
	const isNetworkError = computed(() => {
		return IS_DEV ? false : store.getters["errors/isNetworkError"];
	});

	return { isNetworkError };
};
