<script setup>
import { getCountGraphemes, sliceGraphemesByLength } from "@shared/lib";
import { ref, watch, watchEffect, computed } from "vue";

const props = defineProps({
	modelValue: { type: [String, null], default: "" },
	placeholder: { type: [String, null], default: "" },
	disableLength: { type: Boolean, default: false },
	max: { type: Number, default: 300 },
});

const emit = defineEmits(["modelValue:update"]);

const textAreaModel = ref(props.modelValue);

watchEffect(() => {
	emit("modelValue:update", textAreaModel.value);
});

watch(
	() => props.modelValue,
	(newVal) => {
		if (newVal !== textAreaModel.value) {
			textAreaModel.value = newVal;
		}
	}
);

const textAreaModelLength = computed(() => {
	const res = getCountGraphemes(textAreaModel.value || "");
	return res;
});

const onInput = (e) => {
	e.preventDefault();
	const { value } = e.target;
	textAreaModel.value = sliceGraphemesByLength(value, props.max);
	e.target.value = textAreaModel.value;
};
</script>

<template>
	<div class="textarea">
		<textarea
			class="textarea__field"
			:placeholder="placeholder"
			:value="textAreaModel"
			spellcheck="false"
			@input="onInput"
		/>
		<div class="textarea__length" v-if="!disableLength">
			{{ `${textAreaModelLength} / ${max}` }}
		</div>
	</div>
</template>

<style lang="scss">
@import "./TextArea.scss";
</style>
