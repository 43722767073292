import { isNil } from "lodash-es";

const API_URL = import.meta.env.VITE_API_URL;
const API_PREFIX = import.meta.env.VITE_API_PREFIX;
const API_PREFIX_V2 = "api/v2";

export const getApiUrl = (endpoint) => {
	return `${API_URL}/${API_PREFIX}/${endpoint}`;
};

export const getApiUrlV2 = (endpoint) => {
	return `${API_URL}/${API_PREFIX_V2}/${endpoint}`;
};

const BUCKET_URL_SMALL = `${import.meta.env.VITE_BUCKET_URL}${
	import.meta.env.VITE_BUCKET_PREFIX_SMALL
}`;

const BUCKET_URL_MEDIUM = `${import.meta.env.VITE_BUCKET_URL}${
	import.meta.env.VITE_BUCKET_PREFIX_MEDIUM
}`;

const BUCKET_URL_BANNERS = `${import.meta.env.VITE_BUCKET_URL}${
	import.meta.env.VITE_BUCKET_PREFIX_BANNERS
}`;

export const getBucketUrlSmall = (filename, entityId) => {
	if (!isNil(filename) && !isNil(entityId)) {
		return `${BUCKET_URL_SMALL}/${entityId}/${filename}`;
	}
	return null;
};

export const getBucketUrlMedium = (filename, entityId) => {
	if (!isNil(filename) && !isNil(entityId)) {
		return `${BUCKET_URL_MEDIUM}/${entityId}/${filename}`;
	}
	return null;
};

export const getBucketBanner = (filename) => {
	return `${BUCKET_URL_BANNERS}/${filename}`;
};

export const getBuckerFbUrlSmall = (filename, entityId) => {
	if (!isNil(filename) && !isNil(entityId)) {
		return `${BUCKET_URL_SMALL}/fb/${entityId}/${filename}`;
	}
	return null;
};
