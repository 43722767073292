<script setup>
import {
	AnnouncementListSection,
	ON_PAGE_COUNT,
	useAnnouncementStore,
} from "@entities/announcement";
import { useInitData } from "@shared/lib";
import { computed } from "vue";
import UserEmptyList from "./UserEmptyList.vue";

const props = defineProps({
	userId: {
		type: Number,
		required: true,
	},
});

const initData = useInitData();

const params = computed(() => ({
	user_id: props.userId,
	on_page: ON_PAGE_COUNT.GENERAL_PAGE_COUNT,
}));

const {
	getAnnouncement,
	updateAnnouncements,
	announcementsList,
	announcementsListStatus,
	isEmptyList,
} = useAnnouncementStore(params);

initData(async () => {
	await getAnnouncement();
});
</script>
<template>
	<AnnouncementListSection
		with-keep-alive
		:items="announcementsList"
		:status="announcementsListStatus"
		:is-empty="isEmptyList"
		@update-list="updateAnnouncements"
	>
		<template #title><slot name="title" /></template>
		<template #empty>
			<UserEmptyList />
		</template>
	</AnnouncementListSection>
</template>
