<script setup>
import { ref, onMounted } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";

import { TYPOGRAPHY_TYPES, Typography, Button, Modal } from "@shared/ui";
import { SUPPORT_TELEGRAM, THEME_VARIANTS, SIZES } from "@shared/const";
import { useScreenSize } from "@shared/lib";
import { useTranslation } from "i18next-vue";

const store = useStore();
const route = useRoute();
const { t } = useTranslation();

const { isDesktop } = useScreenSize();

const modalUserBlocked = ref(null);

onMounted(() => {
	modalUserBlocked.value.open();
});

const closeModal = () => {
	modalUserBlocked.value.close();
	store.dispatch("user/logout");
};

const enter = () => {
	window.open(`https://t.me/${SUPPORT_TELEGRAM}`, "_blank");
	modalUserBlocked.value.close();
	store.dispatch("user/logout");
	route.push({ name: "Home" });
};
</script>

<template>
	<div class="modal-baned">
		<Modal
			ref="modalUserBlocked"
			@close="closeModal"
			confirm-text="buttonTextBlock"
			:hide-cross="true"
			:showBackGround="!isDesktop"
		>
			<div class="confirm-edit">
				<Typography class="confirm-edit__text" :type="TYPOGRAPHY_TYPES.TITLE_2">
					{{ t("forbiddenUserSuspiciousText") }}
				</Typography>
				<Button
					:variant="THEME_VARIANTS.PRIMARY"
					class="modal-baned__btn"
					@click="enter"
					:size="SIZES.NORMAL"
					:pill="false"
					width-full
				>
					<Typography class="modal-auth_white" :type="TYPOGRAPHY_TYPES.BUTTON_2">
						{{ t("forbiddenUserContactSupport") }}
					</Typography>
				</Button>
			</div>
		</Modal>
	</div>
</template>

<style scoped lang="scss">
@import "./ForbiddenUser.scss";
</style>
