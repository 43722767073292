export default {
	ru: {
		deactivateAccountSignOut: "Выйти из аккаунта",
		deactivateAccountDeleteAccount: "Удалить аккаунт",
		deactivateAccountReasonDelete: "Укажите причину удаления аккаунта",
		deactivateAccountDeleteTitle: "Удаление профиля",
		deactivateAccountDeleteBeing: "Заявка в обработке",
		deleteTitleSelectReason: "Выберите причину удаления",
		deactivateAccountDeleteWarning:
			"После удаления аккаунта все объявления и данные профиля будут удалены безвозвратно, мы не сможем восстановить их.",
		deactivateAccountDeleteProcessingTime:
			"Время рассмотрения заявки на удаление аккаунта - до 30 рабочих дней",
		deleteProfileAccountHacked: "Аккаунт взломали",
		deleteProfileNoCountry: "Нет страны, в которой я нахожусь",
		deleteProfileAnotherAccount: "У меня есть другой аккаунт",
		deleteProfileWorried: "Беспокоюсь за свои персональные данные",
		deleteProfileDontUseService: "Не пользуюсь сервисом",
		deleteProfileOther: "Другое",
		deactivateAccountToastSuccess: "Заявка на удаление успешно создана",
	},
	en: {
		deactivateAccountSignOut: "Log out of account",
		deactivateAccountDeleteAccount: "Delete account",
		deactivateAccountReasonDelete: "Specify the reason for deleting the account",
		deactivateAccountDeleteTitle: "Profile deletion",
		deactivateAccountDeleteBeing: "Application in process",
		deleteTitleSelectReason: "Select the reason for deletion",
		deactivateAccountDeleteWarning:
			"After deleting your account, all ads and profile data will be deleted permanently, we will not be able to restore them.",
		deactivateAccountDeleteProcessingTime:
			"The processing time for an application for account deletion is up to 30 working days.",
		deleteProfileAccountHacked: "Account hacked",
		deleteProfileNoCountry: "There is no country where I am located",
		deleteProfileAnotherAccount: "I have another account",
		deleteProfileWorried: "I'm worried about my personal data",
		deleteProfileDontUseService: "I don't use the service",
		deleteProfileOther: "Other",
		deactivateAccountToastSuccess: "Account deletion request successfully created",
	},
	ka: {
		deactivateAccountSignOut: "ექაუნთიდან გასვლა",
		deactivateAccountDeleteAccount: "ექაუნთის წაშლა",
		deactivateAccountReasonDelete: "მიუთითეთ ექაუნთის წაშლის მიზეზი",
		deactivateAccountDeleteTitle: "პროფაილის წაშლა",
		deactivateAccountDeleteBeing: "მოთხოვნა მუშავდება...",
		deleteTitleSelectReason: "მიუთითეთ წაშლის მიზეზი",
		deactivateAccountDeleteWarning:
			"ექაუნთის წაშლის შემდეგ თქვენი განცხადებები იქნება წაშლილი.ჩვენ ვერ დაგეხმარებით მათ აღდგენაში.",
		deactivateAccountDeleteProcessingTime: "განხილვა წაშლის შესახებ მუშავდება 30 დღის განმავლობაში",
		deleteProfileAccountHacked: "ექაუნთი გამიტეხეს",
		deleteProfileNoCountry: "არარის ქვეყანა სადაც მე ვიმყოფები",
		deleteProfileAnotherAccount: "მაქვს სხვა ექაუნთი",
		deleteProfileWorried: "ვნერვიულობ ჩემს პირად ინფორმაციაზე",
		deleteProfileDontUseService: "არ ვხმარობ სერვისს",
		deleteProfileOther: "სხვა...",
		deactivateAccountToastSuccess: "წაშლის განაცხადი წარმატებით შეიქმნა",
	},
	tr: {
		deactivateAccountSignOut: "Hesaptan çıkış yap",
		deactivateAccountDeleteAccount: "Hesabı sil",
		deactivateAccountReasonDelete: "Hesabın silinme nedenini belirtin",
		deactivateAccountDeleteTitle: "Profil silme",
		deactivateAccountDeleteBeing: "Başvuru süreci devam ediyor",
		deleteTitleSelectReason: "Silme nedenini seçiniz",
		deactivateAccountDeleteWarning: "Hesabınızı sildikten sonra, tüm reklamlar ve profil verileri kalıcı olarak silinecektir, bunları geri yükleyemeyeceğiz.",
		deactivateAccountDeleteProcessingTime: "Hesap silme başvurusu için işlem süresi 30 iş gününe kadardır",
		deleteProfileAccountHacked: "Hesap ele geçirilmiş",
		deleteProfileNoCountry: "Benim bulunduğum ülke yok",
		deleteProfileAnotherAccount: "Başka bir hesabım var",
		deleteProfileWorried: "Kişisel verilerim hakkında endişeliyim",
		deleteProfileDontUseService: "Hizmeti kullanmıyorum",
		deleteProfileOther: "Diğer",
		deactivateAccountToastSuccess: "Hesap silme talebi başarıyla oluşturuldu",
	},
};
