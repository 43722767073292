import { getApiUrl } from "@shared/const";
import { httpRequest } from "@shared/lib";

const endpoint = "stories";

export const apiGetStories = async () => {
	const url = getApiUrl(endpoint);

	return httpRequest({ method: "get", url });
	// return new Promise((res) => {
	// 	setTimeout(() => res({ data: STORIES_MOCK }), 1000);
	// });
};

export const apiSaveStoryActivity = async (body) => {
	const url = getApiUrl(endpoint);

	return httpRequest({ method: "patch", url }, { body });
};
