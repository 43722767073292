import { computedEager, refWithControl, watchDebounced } from "@vueuse/core";
import { ref, onDeactivated, onActivated } from "vue";
import { useRoute } from "vue-router";

export const useKeepAliveRoute = () => {
	const route = useRoute();

	const routeRefControl = refWithControl({ ...route });

	const isActive = ref(true);

	watchDebounced(
		route,
		() => {
			if (isActive.value) {
				routeRefControl.value = { ...route };
			}
		},
		{ debounce: 1, deep: true }
	);

	onDeactivated(() => {
		isActive.value = false;
	});

	onActivated(() => {
		isActive.value = true;
	});

	const keepAliveRoute = computedEager(() => {
		return routeRefControl.value;
	});

	return keepAliveRoute;
};
