export const LANG_KEYS = /** @type {const} */ ({
	ru: "ru",
	tr: "tr",
	ka: "ka",
	en: "en",
});

export const FALLBACK_LANG = LANG_KEYS.ru;

export const LANG_WITH_PREFIX = [LANG_KEYS.en, LANG_KEYS.ka, LANG_KEYS.tr];
