import { REQUST_STATUSES, STORAGE_KEY } from "@shared/const";
import { isNil } from "lodash-es";

/** @param {typeof import("./state").default} state */
export const currentUserStatus = (state) => {
	return state.status.currentUser;
};

/** @param {typeof import("./state").default} state */
export const userStatus = (state) => (userId) => {
	return state.status.users[userId] || REQUST_STATUSES.OUTDATED;
};

export const getCurrentUser = (state) => {
	return state.currentUser;
};

export const getHandleLocation = (state) => {
	const locationDefault = { regionId: 0, countryId: 0 };
	const data =
		Object.keys(state.userHandleRegion).length !== 0
			? state.userHandleRegion
			: JSON.parse(localStorage.getItem(STORAGE_KEY.HANDLE_LOCATION));
	return data || locationDefault;
};

export const isAdminUser = (state) => {
	return state.currentUser.is_admin;
};

export const isUserLoggedIn = (state) => {
	if ([REQUST_STATUSES.PENDING, REQUST_STATUSES.OUTDATED].includes(state.status.currentUser)) {
		return null;
	}
	return !isNil(state.currentUser.user_id);
};

export const getUser = (state) => (userId) => {
	return state.users[userId] || {};
};
// Была ли показана модалка с выбором региона на главной странице(Нужно найти более элегантное решение(MainPage/model))
export const isChangeRegionShown = (state) => {
	return state.isChangeRegionShown;
};

export const unauthRegionId = (state) => state.unauthRegionId;

export const isCurrentUserExists = (state) => state.currentUser?.id;

export const languages = (state) => state.languages;

export const languageByCode = (state) => (code) => {
	return state.languages.find((x) => x.language_code === code);
};

export const getSupposedUserLocation = (state) => {
	return state.supposedLocation;
};

export const getSupposedUserLocationStatus = (state) => {
	return state.status.supposedLocation;
};

export const timezone = (state) => state.timezone;

export const fbToken = (state) => state.fbToken;

export const getAnnouncementTranslate = ( state) => state.announcementTranslate;