<script setup>
import { customAlphabet } from "nanoid";
import { ref, computed, watch } from "vue";
import { isBlobUrl } from "@shared/lib";
import { ALLOWED_TYPES } from "./fileLoader.const";

const props = defineProps({
	entityId: {
		type: Number,
		required: true,
	},
	apiGetFiles: {
		type: Function,
		required: true,
	},
	apiPostFiles: {
		type: Function,
		required: true,
	},
	apiDeleteFile: {
		type: Function,
		required: true,
	},
	photoForDelete: {
		type: String,
		required: false,
		default: "",
	},
	singleFile: {
		type: Boolean,
		default: false,
	},
	withDeferredSave: {
		type: Boolean,
		default: false,
	},
	updateFilesArray: {
		type: Array,
		default: () => [],
	},
	fileLimit: {
		type: Number,
		default: 10,
	},
});

const inputFiles = ref([]);
const deferredFiles = ref([]);
const fileInput = ref(null);
const fixHiddenBlock = ref(true);

const acceptTypes = computed(() => {
	return ALLOWED_TYPES;
});

const emit = defineEmits(["NewFiles", "pending"]);

const nanoid = customAlphabet("1234567890", 6);

const loadDocuments = async () => {
	if (!props.withDeferredSave) {
		try {
			const getDict = {
				entity_id: props.entityId,
			};

			const data = await props.apiGetFiles(getDict);
			const newFiles = data.data.files.map((item) => ({
				url: item,
				id: nanoid(),
			}));
			inputFiles.value = newFiles;
			emit("NewFiles", newFiles);
		} catch (error) {
			console.log(error);
		}
	}
};

const addFiles = () => {
	fileInput.value.click();
};

const sendFiles = async (e) => {
	let filesArray = [...e.target.files];
	filesArray = filesArray.filter((file) => ALLOWED_TYPES.includes(file.type));
	const newFiles = filesArray.map((item) => ({
		file: item,
	}));

	if (
		newFiles.length > props.fileLimit ||
		deferredFiles.value.length + newFiles.length > props.fileLimit
	) {
		newFiles.splice(props.fileLimit - deferredFiles.value.length);
	}

	emit("pending", newFiles);
	if (props.singleFile) {
		await deleteAllPhoto();
	}
	if (props.withDeferredSave) {
		newFiles.forEach((file) => {
			const url = URL.createObjectURL(file.file);
			deferredFiles.value.push({ url, id: nanoid(), file: file.file });
		});
		emit("NewFiles", [...inputFiles.value, ...deferredFiles.value]);
	} else {
		postFiles(newFiles);
	}
};

const postFiles = async (newFiles) => {
	const allFilesPromises = [];
	if (props.withDeferredSave) fixHiddenBlock.value = deferredFiles.value.length < 5;

	// eslint-disable-next-line no-restricted-syntax
	for (const file of newFiles) {
		const query = {
			entity_id: props.entityId,
		};
		const filePromise = props
			.apiPostFiles({ file: file.file, query })
			.then((data) => {
				const [url] = data.data.files;
				const fileLoaded = { url, id: nanoid() };
				deferredFiles.value = deferredFiles.value.filter((item) => item.id !== file.id);
				inputFiles.value.push(fileLoaded);
			})
			.catch((e) => {
				console.log(e);
			});
		allFilesPromises.push(filePromise);
	}

	Promise.all(allFilesPromises)
		.then(() => {
			emit("NewFiles", inputFiles.value);
		})
		.catch((error) => {
			console.error(error);
			emit("reject");
		});
};

const deletePhoto = async (url) => {
	try {
		if (!props.singleFile) {
			if (!isBlobUrl(url)) {
				const fileToDelete = inputFiles.value.find((file) => file.url === url);
				const params = {
					entity_id: props.entityId,
					filename: fileToDelete.url,
				};
				await props.apiDeleteFile(params);
				inputFiles.value = inputFiles.value.filter((file) => file.url !== url);
			}
			deferredFiles.value = deferredFiles.value.filter((file) => file.url !== url);
			emit("NewFiles", [...inputFiles.value, ...deferredFiles.value]);
		} else {
			deleteAllPhoto();
			inputFiles.value = [];
			emit("NewFiles", inputFiles.value);
		}
	} catch (error) {
		console.error(error);
	}
};

const deleteAllPhoto = async () => {
	deferredFiles.value = [];
	const deletePromises = inputFiles.value.map((file) => {
		const params = {
			entity_id: props.entityId,
			filename: file.url,
		};
		return props.apiDeleteFile(params);
	});
	await Promise.all(deletePromises);
};

watch(
	() => props.entityId,
	async (value) => {
		if (value) {
			if (!props.fileForReviews) await loadDocuments();
		}
	},
	{ immediate: true }
);

watch(
	() => props.photoForDelete,
	async (value) => {
		if (value && !props.withDeferredSave) {
			await deletePhoto(props.photoForDelete);
		}
	}
);

watch(
	() => props.updateFilesArray,
	(value) => {
		if (props.withDeferredSave) {
			const filesArray = Array.from(deferredFiles.value);
			deferredFiles.value = filesArray.filter((file) => {
				return file.url !== value;
			});
		}
	}
);

const uploadDeferredFiles = async () => {
	postFiles(deferredFiles.value);
};

defineExpose({ uploadDeferredFiles });
</script>

<template>
	<div
		v-if="withDeferredSave ? fixHiddenBlock && deferredFiles.length < 5 : true"
		class="file-preview__button_block"
		@click="addFiles"
	>
		<slot name="button"></slot>
	</div>
	<div class="file-preview"></div>
	<input
		type="file"
		ref="fileInput"
		:accept="acceptTypes.join(',')"
		@input="sendFiles"
		style="display: none"
		:multiple="!singleFile"
	/>
</template>
