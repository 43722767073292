export default {
	ru: {
		sliderTitleRecomendationProduct: "Рекомендованные",
	},
	en: {
		sliderTitleRecomendationProduct: "Recommended",
	},
	ka: {
		sliderTitleRecomendationProduct: "რეკომენდაციები",
	},
	tr: {
		sliderTitleRecomendationProduct: "Önerilenler",
	},
};
