export const getReviews = (state) => {
	return state.reviews;
};

export const getUserReviews = (state) => {
	return state.userReviews;
};

export const getReviewStatus = (state) => {
	return state.status.review;
};

export const getUserReviewStatus = (state) => {
	return state.status.userReviews;
};

export const getFeedbackId = (state) => {
	return state.feedbackId;
};

export const getFeedbackCount = (state) => {
	return state.feedbackCount;
};
