<script setup>
import { ref, provide, nextTick } from "vue";
import { PRELOADER_INJECTION_KEY } from "./Preloader.const";

const isOpen = ref(false);

const open = () => {
	isOpen.value = true;
};

const close = () => {
	nextTick(() => {
		isOpen.value = false;
	});
};

const onBeforeEnter = () => {
	const div = document.createElement("div");

	div.style.overflowY = "scroll";
	div.style.width = "50px";
	div.style.height = "50px";

	document.body.append(div);
	const scrollWidth = div.offsetWidth - div.clientWidth;
	div.remove();
	const hasVerScroll = document.body.scrollHeight > document.body.clientHeight;
	window.document.body.style["overflow-y"] = "hidden";
	if (hasVerScroll) {
		window.document.body.style["padding-right"] = `${scrollWidth}px`;
	}
};

const onLeave = () => {
	window.document.body.style["overflow-y"] = "auto";
	window.document.body.style["padding-right"] = "0";
};

provide(PRELOADER_INJECTION_KEY, { open, close, isOpen });
</script>

<template>
	<Transition @leave="onLeave" @before-enter="onBeforeEnter">
		<div class="preloader-wrapper" v-if="isOpen">
			<div class="preloader">
				<div class="dot"></div>
				<div class="dot"></div>
				<div class="dot"></div>
			</div>
		</div>
	</Transition>
	<slot></slot>
</template>

<style lang="scss" scoped>
@import "./Preloader.scss";
</style>
