import { CleanLayout, DefaultLayout } from "../ui";

export const LAYOUTS_TYPES = /** @type {const} */ ({
	CLEAN: "CLEAN",
	DEFAULT: "DEFAULT",
});

export const LAYOUTS = {
	[LAYOUTS_TYPES.CLEAN]: CleanLayout,
	[LAYOUTS_TYPES.DEFAULT]: DefaultLayout,
};
