<script setup>
import { TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { useTranslation } from "i18next-vue";
import { useScreenSize } from "@shared/lib";
import RubricatorDesktop from "./RubricatorDesktop.vue";

const { isDesktop } = useScreenSize();

const { t } = useTranslation();
</script>

<template>
	<Typography class="rubricator__title" :type="TYPOGRAPHY_TYPES.TITLE_2">
		{{ t("popularCatTitle") }}
	</Typography>
	<Typography v-if="isDesktop" class="rubricator__subtitle" :type="TYPOGRAPHY_TYPES.TEXT">
		{{ $t("popularCatSubTitle") }}
	</Typography>
	<RubricatorDesktop />
</template>

<style scoped lang="scss">
@import "./Rubricator.scss";
</style>
