<script setup>
import { ref, watch, nextTick } from "vue";
import { Image } from "@shared/ui";
import { getBuckerFbUrlSmall } from "@shared/const";
import { Review } from "@widgets/Review";
import { useElementVisibility } from "@vueuse/core";
import { Fancybox } from "@fancyapps/ui/src/Fancybox/Fancybox";

const props = defineProps({
	data: {
		type: Object,
		default: () => {},
	},
	showInfo: {
		type: Boolean,
		default: true,
	},
	emitOnVisible: {
		type: Boolean,
		default: false,
	},
});

const emit = defineEmits(["visible"]);

const reviewRef = ref(null);

const imagesSmall = (reviewData) => {
	return (
		reviewData?.photos !== null &&
		reviewData.photos.map((filename) => {
			return getBuckerFbUrlSmall(filename, reviewData.fb_id);
		})
	);
};

if (props.emitOnVisible) {
	const isVisible = useElementVisibility(reviewRef);

	const unwatch = watch(
		isVisible,
		() => {
			if (isVisible.value) {
				emit("visible");
				nextTick(() => {
					unwatch();
				});
			}
		},
		{ immediate: true }
	);
}

const openPhoto = (idx) => {
	const mappedItems = imagesSmall(props.data).map((url) => ({ src: url }));
	Fancybox.show(mappedItems, { startIndex: idx });
};
</script>

<template>
	<div ref="reviewRef">
		<slot name="reviewTitleBtn" />
		<Review :data="data" :showInfo="showInfo">
			<template #reviewImg v-if="data.photos">
				<div class="my-review__images-wrapper">
					<Image
						v-for="(photoUrl, index) in imagesSmall(data)"
						class="my-review__image"
						:src="photoUrl"
						:key="photoUrl"
						@click="openPhoto(index)"
					/>
				</div>
			</template>
		</Review>
		<slot name="reviewRemoveBtn" />
	</div>
</template>

<style lang="scss" scoped>
@import "./ReviewList.scss";
</style>
