import { REQUST_STATUSES } from "@shared/const";
import { apiGetPhones } from "../api";

export const getPhones = async ({ commit }) => {
	try {
		commit("SET_PHONES_STATUS", REQUST_STATUSES.PENDING);
		const { data } = await apiGetPhones();
		commit("SET_PHONES", data);
		commit("SET_PHONES_STATUS", REQUST_STATUSES.SUCCESS);
	} catch (error) {
		commit("SET_PHONES_STATUS", REQUST_STATUSES.ERROR);
	}
};
