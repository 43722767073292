export default {
	ru: {
		filterMoTitle: "Фильтры",
		filterMoCountryFirst: "Выберите сначала страну",
		filterMoSelectCurrency: "Выберите Валюту",
		filterMoCurrency: "Валюта",
		filterMoStartingPrice: "Укажите начальную стоимость",
		filterMoFrom: "От:",
		filterMoMaximumCost: "Укажите предельную стоимость",
		filterMoBefore: "До:",
		filterMoCity: "Город",
		filterMoCountry: "Страна",
	},
	en: {
		filterMoTitle: "Filters",
		filterMoCountryFirst: "Select a country first.",
		filterMoSelectCurrency: "Select Currency",
		filterMoCurrency: "Currency",
		filterMoStartingPrice: "Specify the starting price ",
		filterMoFrom: "From:",
		filterMoMaximumCost: "Specify the maximum price",
		filterMoBefore: "To:",
		filterMoCity: "City",
		filterMoCountry: "Country",
	},
	ka: {
		filterMoTitle: "ფილტრი",
		filterMoCountryFirst: "ჯერ აირჩიეთ ქვეყანა",
		filterMoSelectCurrency: "აირჩიეთ ვალუტა",
		filterMoCurrency: "ვალუტა",
		filterMoStartingPrice: "მიუთითეთ საწყისი ფასი",
		filterMoFrom: "0 დან:",
		filterMoMaximumCost: "მიუთითეთ საშუალო ფასი",
		filterMoBefore: "0 მდე",
		filterMoCity: "ქალაქი",
		filterMoCountry: "ქვეყანა",
	},
	tr: {
		filterMoTitle: "Filtreler",
		filterMoCountryFirst: "Önce bir ülke seçiniz",
		filterMoSelectCurrency: "Para birimi seçiniz",
		filterMoCurrency: "Para birimi",
		filterMoStartingPrice: "Başlangıç fiyatını belirtin ",
		filterMoFrom: "Minimum:",
		filterMoMaximumCost: "Maksimum fiyatı belirtin",
		filterMoBefore: "Maksimum:",
		filterMoCity: "Şehir",
		filterMoCountry: "Ülke",
	},
};
