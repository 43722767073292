<script setup>
import { isEmpty, isEqual, isNumber } from "lodash";
import { nanoid } from "nanoid";
import { ref, computed, watch, nextTick } from "vue";
import { nonDigitRegExp, SIZES, whiteSpacesRegExp } from "@shared/const";
import {
	toRawPhone,
	toOneWord,
	toFloatNumber,
	toLocaleFloatNumber,
	deleteEmojiFromString,
	toLocaleNumber,
	toLocalePhone,
	toCapitalize,
	formatByMask,
} from "@shared/lib";
import { useTranslation } from "i18next-vue";
import { INPUT_MODE, INPUT_THEMES } from "./InputField.const";

const EMIT_EVENTS = {
	UPDATE_V_MODEL: "update:modelValue",
};

const emit = defineEmits(["update:modelValue", "focus", "blur", "input"]);

const props = defineProps({
	placeholder: {
		type: String,
		default: "",
	},
	modelValue: {
		default: "",
	},
	/** Мод инпута @see INPUT_MODE */
	mode: {
		type: String,
		default: INPUT_MODE.RAW,
		validator: (mode) => {
			return Object.values(INPUT_MODE).includes(mode);
		},
	},
	maxLength: {
		type: Number,
		default: null,
	},
	/** Работает только c mode - FORMATED_NUMBER */
	digit: {
		type: String,
		default: null,
	},
	focused: {
		type: Boolean,
		default: false,
	},
	tabIndex: {
		type: Number,
		default: 1,
	},
	errors: {
		type: Array,
		default: undefined,
	},
	disabled: {
		type: Boolean,
	},
	silentDisabled: {
		type: Boolean,
	},
	type: {
		type: String,
		default: "text",
	},
	mask: {
		type: String,
		default: undefined,
	},
	autocomplete: {
		type: String,
		default: "disabled",
	},
	errorZeroHeight: {
		type: Boolean,
		default: false,
	},
});

const { t } = useTranslation();

/** @type {import("vue").Ref<HTMLElement>} */
const VInputRef = ref(null);

/** @type {import("vue").Ref<boolean>} */
const skipModelValueChange = ref(false);

/** @type {import("vue").Ref<string>} */
const displayValue = ref("");

/** @type {import("vue").Ref<string>} */
const rawValue = ref("");

const leftRef = ref(null);

// const leftRefBounding = useElementBounding(leftRef);

/** @type {import("vue").Ref<{ start: number; end: number }>} */
const currentCaretPositions = ref({ start: 0, end: 0 });

/** @type {import("vue").Ref<HTMLInputElement>} */
const inputRef = ref(null);

/** @type {import("vue").Ref<boolean>} */
const isFocused = ref(false);

/** @type {import("vue").Ref<boolean>} */
const isFilled = computed(() => {
	return displayValue.value || isAutofilled.value;
});

const isFocusedOrFeeled = computed(() => {
	return isFocused.value || isFilled.value;
});

const isAutofilled = ref(false);

const focus = () => {
	inputRef.value.focus();
};

const blur = () => {
	inputRef.value.blur();
};

const scrollToElement = () => {
	const { y } = VInputRef.value.getBoundingClientRect();
	const top = y + scrollY - 45;

	scrollTo({
		top,
		behavior: "smooth",
	});
};

defineExpose({ VInputRef, focus, blur, scrollToElement });

const setCurrentCaretPosition = () => {
	currentCaretPositions.value.start = inputRef.value.selectionStart;
	currentCaretPositions.value.end = inputRef.value.selectionEnd;
};

const getDisplayValue = (val) => {
	const value = deleteEmojiFromString(subStringValueByMaxLength(val));

	let displayValue = value;

	if (props.mode === INPUT_MODE.RAW) {
		displayValue = value;
	}
	if (props.mode === INPUT_MODE.NUMBER) {
		displayValue = value.replaceAll(nonDigitRegExp, "");
	}
	if (props.mode === INPUT_MODE.FORMATED_FLOAT_NUMBER) {
		displayValue = toLocaleFloatNumber(value);
	}
	if (props.mode === INPUT_MODE.FLOAT_NUMBER) {
		displayValue = toFloatNumber(value);
	}
	if (props.mode === INPUT_MODE.FORMATED_NUMBER) {
		displayValue = toLocaleNumber(value || "");
	}
	if (props.mode === INPUT_MODE.PHONE) {
		displayValue = toLocalePhone(value || "");
	}
	if (props.mode === INPUT_MODE.ONE_WORD) {
		displayValue = toOneWord(value || "");
	}
	if (props.mode === INPUT_MODE.CAPITALIZE) {
		displayValue = toCapitalize(value);
	}

	if (props.mask) {
		displayValue = formatByMask(value, props.mask);
	}

	return displayValue;
};

const setDisplayValue = (value) => {
	const newDisplayValue = getDisplayValue(value);

	displayValue.value = newDisplayValue;

	setRawValue(newDisplayValue);

	nextTick(() => {
		if (inputRef.value) {
			inputRef.value.value = displayValue.value;
		}
	});
};

const getRawValue = (value) => {
	let result = value;
	if (props.mode === INPUT_MODE.RAW) {
		result = value;
	}
	if (props.mode === INPUT_MODE.NUMBER) {
		result = value.replaceAll(nonDigitRegExp, "");
	}
	if (props.mode === INPUT_MODE.FORMATED_FLOAT_NUMBER) {
		result = toFloatNumber(value);
	}
	if (props.mode === INPUT_MODE.FLOAT_NUMBER) {
		result = toFloatNumber(value);
	}
	if (props.mode === INPUT_MODE.FORMATED_NUMBER) {
		result = value.replaceAll(nonDigitRegExp, "");
	}
	if (props.mode === INPUT_MODE.PHONE) {
		result = toRawPhone(value);
	}
	if (props.mode === INPUT_MODE.ONE_WORD) {
		result = toOneWord(value || "");
	}
	if (props.mode === INPUT_MODE.CAPITALIZE) {
		result = toCapitalize(value);
	}
	return result;
};

const setRawValue = (value) => {
	const newRawValue = getRawValue(value);
	rawValue.value = newRawValue;
};

const DELETE_INPUT_TYPES = /** @type {const} */ ({
	DELETE_CONTENT_BACKWARD: "deleteContentBackward",
	DELETE_SOFT_LINE_BACKWARD: "deleteSoftLineBackward",
	DELETE_WORD_BACKWARD: "deleteWordBackward",
	DELETE_CONTENT_FORWARD: "deleteContentForward",
	DELETE_SOFT_LINE_FORWARD: "deleteSoftLineForward",
	DELETE_WORD_FORWARD: "deleteWordForward",
});

const INPUT_TYPES = /** @type {const} */ ({
	INSERT_TEXT: "insertText",
	INSERT_LINE_BREAK: "insertLineBreak",
	INSERT_REPLACEMENT_TEXT: "insertReplacementText",
	...DELETE_INPUT_TYPES,
});

const onInput = (e) => {
	if (e.target.value !== displayValue.value) {
		setRawValue(e.target.value);
		setDisplayValue(getRawValue(e.target.value));
	}
};

/** @param {InputEvent} e */
const onBeforeInput = (e) => {
	const { inputType } = e;

	emit("input", e);

	setCurrentCaretPosition();

	// Простая вставка
	if (inputType === INPUT_TYPES.INSERT_TEXT) {
		e.preventDefault();

		addKey(e.data);
		// Все виды удаления
	} else if (inputType.startsWith("delete")) {
		e.preventDefault();
		deleteKeys(inputType);
	}
};

/** @param {typeof DELETE_INPUT_TYPES)[keyof typeof DELETE_INPUT_TYPES]} DELETE_TYPE */
const deleteKeys = (DELETE_TYPE) => {
	const [newValue, charInfo] = removeKeyFromDisplayValue(
		displayValue.value,
		currentCaretPositions,
		DELETE_TYPE
	);

	const newRawValue = getRawValue(newValue);

	setDisplayValue(newRawValue);
	calcCaretPosByDelete(newRawValue, charInfo);
};

const addKey = (key) => {
	const [newValue, charInfo] = addKeyToDisplayValue(displayValue.value, key, currentCaretPositions);

	const newRawValue = getRawValue(newValue);

	setDisplayValue(newRawValue);
	calcCaretPosByAdd(newRawValue, charInfo);
};

const getCharPos = (string, charInfo) => {
	const res = string.split("").reduce(
		(acc, char, idx) => {
			if (acc.seted === null && char === charInfo.key) {
				if (acc.remain !== 0) {
					acc.remain -= 1;
				} else {
					acc.seted = idx;
				}
			}
			return acc;
		},
		{ seted: null, remain: charInfo.idx }
	);

	return res.seted;
};

const subStringValueByMaxLength = (value) => {
	if (props.maxLength) {
		if (value.length > props.maxLength) {
			return value.slice(0, props.maxLength);
		}
	}
	return value;
};

const calcCaretPosByDelete = (newValue, charInfo) => {
	const newDisplayValue = getDisplayValue(newValue);

	const nextCharPosDisplay =
		getCharPos(newDisplayValue, charInfo) === null ? 0 : getCharPos(newDisplayValue, charInfo) + 1;

	nextTick(() => {
		inputRef.value.selectionStart = nextCharPosDisplay;
		inputRef.value.selectionEnd = nextCharPosDisplay;
	});
};

const calcCaretPosByAdd = (newValue, charInfo) => {
	const newDisplayValue = getDisplayValue(newValue);
	const newRawValue = getRawValue(newDisplayValue);
	const newCharPosDisplay = getCharPos(newDisplayValue, charInfo);
	const newCharPosRaw = getCharPos(newRawValue, charInfo);

	if (isNumber(newCharPosDisplay) && isNumber(newCharPosRaw)) {
		const nextRawChar = newRawValue.split("")[newCharPosRaw + 1];
		const nextDisplayValueCharByRaw =
			nextRawChar === undefined
				? newDisplayValue.length
				: newDisplayValue.split("").findIndex((char, idx) => {
						if (idx > newCharPosDisplay && char === nextRawChar) {
							return true;
						}
						return false;
						// eslint-disable-next-line no-mixed-spaces-and-tabs
				  });
		nextTick(() => {
			if (isNumber(newCharPosDisplay)) {
				inputRef.value.selectionStart = nextDisplayValueCharByRaw;
				inputRef.value.selectionEnd = nextDisplayValueCharByRaw;
				// Это сделано для того, чтобы если текст длинее ширины инпута, вьюпорт скроллился к каретке
				blur();
				nextTick(() => {
					focus();
				});
			}
		});
	}
};

/**
 * @param {string} value
 * @param {string} key
 * @param {import("vue").Ref<{ start: number; end: number }>} caretPositions
 */
const addKeyToDisplayValue = (value, key, caretPositions) => {
	const leftPart = value.slice(0, caretPositions.value.start);
	const newValue = leftPart + key + value.slice(caretPositions.value.end);
	const indexOfKey = leftPart.split("").reduce((count, char) => {
		if (char === key) {
			return count + 1;
		}
		return count;
	}, 0);

	const charInfo = { idx: indexOfKey, key };
	return [newValue, charInfo];
};

/**
 * @param {string} value
 * @param {import("vue").Ref<{ start: number; end: number }>} caretPositions
 * @param {(typeof DELETE_INPUT_TYPES)[keyof typeof DELETE_INPUT_TYPES]} type
 */
const removeKeyFromDisplayValue = (value, caretPositions, type) => {
	const { start: caretStart, end: caretEnd } = caretPositions.value;

	const startOffset = caretStart === caretEnd && caretStart !== 0 ? 1 : 0;

	let leftPart = value.slice(0, caretPositions.value.start - startOffset);
	let rightPart = value.slice(caretEnd);

	switch (type) {
		case DELETE_INPUT_TYPES.DELETE_SOFT_LINE_BACKWARD:
			leftPart = "";
			rightPart = value.slice(caretEnd);
			break;
		case DELETE_INPUT_TYPES.DELETE_CONTENT_BACKWARD:
			leftPart = value.slice(0, caretPositions.value.start - startOffset);
			rightPart = value.slice(caretEnd);
			break;
		case DELETE_INPUT_TYPES.DELETE_WORD_BACKWARD:
			leftPart = value.slice(0, caretPositions.value.start).trim().split(whiteSpacesRegExp);
			leftPart.pop();
			leftPart = leftPart.join(" ").trim();
			rightPart = value.slice(caretEnd);
			break;
		case DELETE_INPUT_TYPES.DELETE_CONTENT_FORWARD:
			leftPart = value.slice(0, caretPositions.value.start);
			rightPart = value.slice(caretEnd + 1);
			break;
		case DELETE_INPUT_TYPES.DELETE_SOFT_LINE_FORWARD:
			leftPart = value.slice(0, caretPositions.value.start);
			rightPart = "";
			break;
		case DELETE_INPUT_TYPES.DELETE_WORD_FORWARD:
			leftPart = value.slice(0, caretPositions.value.start);

			rightPart = value.slice(caretEnd).trim().split(whiteSpacesRegExp);
			rightPart.shift();
			rightPart = rightPart.join(" ").trim();
			break;
		default:
			console.error("Unknown delete type");
			break;
	}

	const newValue = `${leftPart}${rightPart}`;

	const rawLeftPart = getRawValue(leftPart);

	const key = rawLeftPart.slice(-1);

	const indexOfKey = leftPart.split("").reduce((count, char) => {
		if (char === key) {
			return count + 1;
		}
		return count;
	}, -1);

	const charInfo = { idx: indexOfKey, key };

	return [newValue, charInfo];
};

/** @param {ClipboardEvent} event */
const onPaste = (event) => {
	event.preventDefault();
	const [newDisplayValue] = addKeyToDisplayValue(
		displayValue.value,
		event.clipboardData.getData("text"),
		currentCaretPositions
	);
	const newRawValue = getRawValue(newDisplayValue);
	setDisplayValue(newRawValue);
};

const id = nanoid(5);

const onFocus = (e) => {
	emit("focus", e);
	isFocused.value = true;
};

const onBlur = (e) => {
	emit("blur", e);
	isFocused.value = false;
};

// const isDigitVisible = computed(() => {
// 	const allowedModes = [
// 		INPUT_MODE.FORMATED_NUMBER,
// 		INPUT_MODE.FLOAT_NUMBER,
// 		INPUT_MODE.FORMATED_FLOAT_NUMBER,
// 		INPUT_MODE.NUMBER,
// 	];
// 	return allowedModes.includes(props.mode);
// });

watch(rawValue, (newVal) => {
	if (!isEqual(newVal, props.modelValue)) {
		emit(EMIT_EVENTS.UPDATE_V_MODEL, newVal);
		skipModelValueChange.value = true;
	}
});

watch(
	() => props.modelValue,
	(newVal) => {
		if (!skipModelValueChange.value) {
			setRawValue(newVal?.toString() || "");
			setDisplayValue(getRawValue(newVal?.toString() || ""));
		} else {
			skipModelValueChange.value = false;
		}
	},
	{ immediate: true }
);

const isDisplayValueEmpty = computed(() => {
	return isEmpty(displayValue.value);
});

watch(
	() => props.mask,
	() => {
		setRawValue(props.modelValue);
		setDisplayValue(getRawValue(props.modelValue));
	}
);
const isError = computed(() => {
	if (!props.errors) {
		return false;
	}
	return props.errors.length > 0;
});

const errorMessage = computed(() => {
	if (!props.errors) {
		return false;
	}
	return props.errors?.[0]?.$message;
});
</script>

<template>
	<div ref="VInputRef">
		<div
			class="v-input"
			:class="{
				'v-input_focused': isFocused || focused,
				'v-input_filled': isFilled,
				'v-input_blured': !isFocusedOrFeeled,
				'v-input_empty': isDisplayValueEmpty,
				'v-input_error': isError,
				[`v-input_${SIZES.NORMAL}`]: true,
				[`v-input_${INPUT_THEMES.DARK}`]: true,
				'v-input_disabled': disabled,
				'v-input_zero-height-error': errorZeroHeight,
			}"
			@click="focus"
		>
			<div class="v-input__left" ref="leftRef">
				<slot name="left" :root-element="VInputRef"></slot>
			</div>
			<div class="v-input__input-wrapper">
				<span class="v-input__placeholder">{{ placeholder }}</span>
				<input
					:disabled="disabled || silentDisabled"
					ref="inputRef"
					:id="id"
					class="v-input__input"
					:tab-index="tabIndex"
					:autocomplete="autocomplete"
					@animationstart="checkAutofill"
					@input="onInput"
					@beforeinput="onBeforeInput"
					@focus.stop="onFocus"
					@blur.stop="onBlur"
					@paste="onPaste"
					:type="type"
					:spellcheck="false"
				/>
			</div>
			<slot name="rigth" :root-element="VInputRef"></slot>
			<div class="v-input__overlay" v-if="silentDisabled"></div>
		</div>
		<div
			class="v-input__error"
			:class="{ 'v-input__error_zero-height': errorZeroHeight }"
			@click.stop
			v-if="errors"
		>
			{{ t(errorMessage) }}
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./InputField.scss";
</style>
