import { nanoid } from "nanoid";
import { useRefresh } from "@shared/ui";
import { onActivated, onDeactivated, onUnmounted } from "vue";

export const useInitData = () => {
	const id = nanoid(10);
	const { addInitData, removeInitData, activateInitData, deactivateInitData } = useRefresh();

	const initData = (cb) => {
		addInitData({ id, cb });
		return cb();
	};

	onUnmounted(() => removeInitData(id));

	onDeactivated(() => {
		deactivateInitData(id);
	});

	onActivated(() => {
		activateInitData(id);
	});

	return initData;
};
