<script setup>
import { computed } from "vue";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { useScreenSize } from "@shared/lib";
import { TYPOGRAPHY_TYPES, Typography, SvgTemplate } from "@shared/ui";
import { useTranslation } from "i18next-vue";

defineProps({
	headerMobile: {
		type: Boolean,
		default: false,
	},
});

const router = useRouter();
const store = useStore();
const { isDesktop, screenSize, SCREEN_SIZES_LIST } = useScreenSize();
const { t } = useTranslation();

const toCreatePage = () => {
	store.commit("announcement/SET_AD", {});
	store.commit("announcement/SET_AD_ID", null);
	router.push("/create_page");
};

const createBtn = computed(() => {
	return screenSize.value !== SCREEN_SIZES_LIST.LG && isDesktop.value
		? t("createAnnoucementBtn")
		: t("createAnnoucementBtn2");
});
</script>

<template>
	<button
		class="create-annoucement-btn"
		:class="{ 'mobile-header': headerMobile }"
		type="button"
		@click="toCreatePage"
	>
		<SvgTemplate v-if="!isDesktop" name="plus" />
		<Typography :type="TYPOGRAPHY_TYPES.TITLE_3">{{ createBtn }}</Typography>
	</button>
</template>

<style lang="scss" scoped>
@import "./AddAnnoucementButton.scss";
</style>
