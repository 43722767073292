export default {
	ru: {
		forbiddenUserSuspiciousText: "We have detected suspicious activity and blocked your profile.",
		forbiddenUserContactSupport: "Contact support",
	},
	en: {
		forbiddenUserSuspiciousText: "We detected suspicious activity and blocked your profile.",
		forbiddenUserContactSupport: "Contact support",
	},
	ka: {
		forbiddenUserSuspiciousText: "ჩვენ შევამჩნიეთ საეჭვო აქტივობა და დავბლოკეთ თქვენი გვერდი.",
		forbiddenUserContactSupport: "მიმართეთ ტექნიკური სამსასხურის ჯგუფს",
	},
	tr: {
		forbiddenUserSuspiciousText: "Şüpheli bir etkinlik tespit ettik ve profilinizi engelledik.",
		forbiddenUserContactSupport: "Destek ile iletişime geçin",
	},
};
