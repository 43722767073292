<script setup>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { Image, TYPOGRAPHY_TYPES, Typography, SvgTemplate } from "@shared/ui";
import { useScreenSize } from "@shared/lib";
import { getBucketUrlSmall } from "@shared/const";
import { isEmpty } from "lodash-es";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	chat: {
		type: Object,
		default: () => {},
		required: true,
	},
});

const store = useStore();
const { isDesktop } = useScreenSize();
const { t } = useTranslation();
const router = useRouter();

const adsAvatar = computed(() => {
	return getBucketUrlSmall(props.chat?.ad_photo, props.chat?.subject);
});

const isUserTyping = computed(() => {
	return store.getters["chat/isUserTyping"](props.chat?.chat_id);
});

const routerTo = computed(() => {
	if (props.chat?.subject)
		return { name: "AnounceCardPage", params: { ad_id: props.chat?.subject } };
	return { name: "Home" };
});
</script>

<template>
	<div class="chat-header">
		<template v-if="!isEmpty(chat)">
			<SvgTemplate
				v-if="!isDesktop"
				name="left-arrow"
				@click="router.back()"
				class="chat-header__back"
			/>
			<RouterLink :to="routerTo" class="chat-header__main">
				<Image :src="adsAvatar" class="chat-header__title-photo" />
				<div class="chat-header__details" v-if="!isDesktop">
					<Typography class="chat-card__text" :type="TYPOGRAPHY_TYPES.TITLE_1">
						{{ chat?.second_name }} {{ chat?.first_name }}
					</Typography>
					<Typography class="chat-card__text" :type="TYPOGRAPHY_TYPES.TITLE_1">
						{{ chat?.ad_name }}
					</Typography>
					<Typography v-if="isUserTyping" class="chat-card__text_sub" :type="TYPOGRAPHY_TYPES.TEXT">
						{{ t("chatHeaderTypes") }}
					</Typography>
					<Typography
						v-else-if="chat?.user_online"
						class="chat-card__text_sub"
						:type="TYPOGRAPHY_TYPES.TEXT"
					>
						{{ t("chatHeaderOnline") }}
					</Typography>
					<Typography v-else class="chat-card__text_sub" :type="TYPOGRAPHY_TYPES.TEXT">
						{{ t("chatHeaderOnlineWasRecently") }}
					</Typography>
				</div>

				<div v-else class="chat-header__details-desktop">
					<Typography class="chat-card__text-desktop" :type="TYPOGRAPHY_TYPES.USER_NAME">
						{{ chat?.second_name }} {{ chat?.first_name }}
						<Typography
							tag="span"
							:type="TYPOGRAPHY_TYPES.CONTACT"
							class="chat-card__ad-name-desktop"
						>
							{{ chat?.ad_name }}
						</Typography>
					</Typography>

					<Typography
						v-if="isUserTyping"
						class="chat-card__text_sub-desktop"
						:type="TYPOGRAPHY_TYPES.TEXT"
					>
						{{ t("chatHeaderTypes") }}
					</Typography>
					<Typography
						v-else-if="chat?.user_online"
						class="chat-card__text_sub-desktop"
						:type="TYPOGRAPHY_TYPES.TEXT"
					>
						{{ t("chatHeaderOnline") }}
					</Typography>
					<Typography v-else class="chat-card__text_sub-desktop" :type="TYPOGRAPHY_TYPES.TEXT">
						{{ t("chatHeaderOnlineWasRecently") }}
					</Typography>
				</div>
			</RouterLink>
		</template>
	</div>
</template>

<style lang="scss" scoped>
@import "./ChatHeader.scss";
</style>
