import { createSharedComposable, useScrollLock } from "@vueuse/core";

const bodyScrollLock = () => {
	const isLocked = useScrollLock(document.body);

	const lock = () => {
		if (!isLocked.value) {
			isLocked.value = true;
			const div = document.createElement("div");
			div.style.overflowY = "scroll";
			div.style.width = "50px";
			div.style.height = "50px";

			document.body.append(div);
			const scrollWidth = div.offsetWidth - div.clientWidth;
			div.remove();
			const hasVerScroll = document.body.scrollHeight > document.body.clientHeight;
			if (hasVerScroll) {
				window.document.body.style["padding-right"] = `${scrollWidth}px`;
			}
		}
	};

	const unlock = () => {
		if (isLocked.value) {
			isLocked.value = false;
			window.document.body.style["padding-right"] = "0";
		}
	};

	return { lock, unlock, isLocked };
};

export const useBodyScrollLock = createSharedComposable(bodyScrollLock);
