<script setup>
import { ref } from "vue";
import { Image, TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { getStoriesBuckerUrl } from "../../api";
import StoriesPreviewPending from "./StoriesPreviewPending.vue";

defineProps({
	story: { type: Object, default: () => {} },
	isPending: { type: Boolean, default: false },
	isViewed: { type: Boolean, default: false },
});

const imageRef = ref(null);

defineExpose({ imageRef });
</script>

<template>
	<div class="stories-preview" v-if="!isPending">
		<div
			class="stories-preview__img--wrapper"
			:class="{ 'stories-preview__img--wrapper_viewed': isViewed }"
			ref="imageRef"
		>
			<div class="stories-preview__img">
				<Image :src="getStoriesBuckerUrl(story.preview_url)" />
			</div>
		</div>
		<Typography :type="TYPOGRAPHY_TYPES.STORIES_TITLE" class="stories-preview__title">
			{{ story.storie_name }}
		</Typography>
	</div>
	<StoriesPreviewPending v-else />
</template>

<styles lang="scss" scoped>
@import "./StoriesPreview.scss";
</styles>
