export const isAutoShowModal = (fuse, localStorageKey, durationInDays) => {
	const lastOpenTime = localStorage.getItem(localStorageKey);

	if (
		(!lastOpenTime && fuse) ||
		(Date.now() - lastOpenTime > durationInDays * 24 * 60 * 60 * 1000 && fuse)
	) {
		localStorage.setItem(localStorageKey, Date.now().toString());

		return true;
	}

	return false;
};
