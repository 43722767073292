<script setup>
import { Typography, TYPOGRAPHY_TYPES } from "@shared/ui";
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();
</script>

<template>
	<div class="not-selected-chat">
		<Typography class="not-selected-chat__text" :type="TYPOGRAPHY_TYPES.TITLE_2">
			{{ t("chatNotSelectedPageText") }}
		</Typography>
	</div>
</template>

<style lang="scss" scoped>
@import "./ChatNotSelectedPage.scss";
</style>
