import { getApiUrl, HTTP_METHODS } from "@shared/const";
import { httpRequest } from "@shared/lib";

const ENPOINT = "currencies";

export const apiGetCurrency = () => {
	const url = getApiUrl(ENPOINT);
	const method = HTTP_METHODS.GET;

	return httpRequest({ url, method });
};
