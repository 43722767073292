<!-- eslint-disable no-unused-vars -->
<script setup>
import { ref, watch } from "vue";
import { SvgTemplate } from "@shared/ui";
import { useScreenSize, withPx } from "@shared/lib";
import { onKeyStroke, useMediaQuery } from "@vueuse/core";
import { trim } from "lodash";

const textArea = ref();

const emit = defineEmits(["update:modelValue", "send", "typing"]);

const props = defineProps({
	modelValue: { type: [String, null], default: "" },
	placeholder: { type: [String, null], default: "" },
});

const onClick = (e) => {
	e.stopPropagation();
	emit("send");
};

const textAreaHeight = ref(null);

const calcTextAreaHeight = () => {
	if (!props.modelValue) {
		textAreaHeight.value = null;

		return;
	}
	textArea.value.style.height = "auto";
	const { scrollHeight } = textArea.value;
	textAreaHeight.value = scrollHeight;
};

const onInput = (e) => {
	e.stopPropagation();
	emit("typing");
	emit("update:modelValue", textArea.value.value);
};

watch(
	() => props.modelValue,
	() => {
		calcTextAreaHeight();
	}
);

//  TODO Отвратительное решение, найти иной вариант
const { isDesktop } = useScreenSize();

onKeyStroke(
	"Enter",
	(e) => {
		if (!e.shiftKey && isDesktop.value) {
			e.preventDefault();
			emit("send");
		}
	},
	{ target: textArea }
);

const textAreaRef = ref(null);

defineExpose({ textAreaRef });
</script>

<template>
	<div class="chat__textarea-container" ref="textAreaRef">
		<slot></slot>
		<div class="chat__textarea">
			<textarea
				ref="textArea"
				class="chat__textarea-field"
				:placeholder="placeholder"
				:value="modelValue"
				rows="1"
				maxlength="1000"
				:style="{ height: textAreaHeight ? withPx(textAreaHeight) : null }"
				@input="onInput"
				spellcheck="false"
			/>
			<div class="chat__textarea-icon-wrapper">
				<SvgTemplate
					v-show="trim(modelValue)"
					@click="onClick"
					name="sendChatMsg"
					class="chat__textarea-icon"
				/>
			</div>
		</div>
	</div>
</template>
<style lang="scss" scoped>
@import "./ChatTextArea.scss";
</style>
