<script setup>
import { ref, watch, computed } from "vue";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { Typography, TYPOGRAPHY_TYPES, Spinner } from "@shared/ui";
import { REQUST_STATUSES } from "@shared/const";
import { useTranslation } from "i18next-vue";
import { ChatsList } from "../ChatsList";

const store = useStore();
const router = useRouter();
const route = useRoute();
const { t } = useTranslation();

const selectedChat = ref(null);

const chats = computed(() => {
	return store.getters["chat/chats"];
});

const chatsLoaded = computed(() => {
	return store.getters["chat/chatsStatus"] === REQUST_STATUSES.SUCCESS;
});

const anyChats = computed(() => {
	return chats.value.length > 0 && chatsLoaded.value;
});

watch(selectedChat, (value) => {
	if (value) router.push({ name: "Chat", params: { chat_id: value } });
});

const isChats = computed(() => {
	return route.name === "Chats";
});
</script>
<template>
	<template v-if="isChats">
		<div v-if="anyChats" class="chats-mobile">
			<ChatsList v-model="selectedChat" :chats="chats" />
		</div>
		<div class="no-chats" v-else-if="!chatsLoaded">
			<Spinner />
		</div>
		<div class="no-chats" v-else>
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
				{{ t("favoritesAnnouncementListEmptyTitle") }}
			</Typography>
			<Typography :type="TYPOGRAPHY_TYPES.TEXT">
				{{ t("chatsDesktopDescr_1") }}
				<Typography tag="span" :type="TYPOGRAPHY_TYPES.TEXT_CARD_NAME">
					{{ t("chatsDesktopDescr_2") }}
				</Typography>
				{{ t("chatsDesktopDescr_3") }}
				<Typography tag="span" :type="TYPOGRAPHY_TYPES.TEXT_CARD_NAME">
					{{ t("buttonTextCahtWrite") }}
				</Typography>
				{{ t("chatsDesktopDescr_4") }}
			</Typography>
		</div>
	</template>
	<template v-else>
		<RouterView />
	</template>
</template>
<style lang="scss" scoped>
@import "./ChatsMobile.scss";
</style>
