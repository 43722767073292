<!-- eslint-disable no-unused-vars -->
<script setup>
import { computed, watchEffect } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { LAYOUTS, LAYOUTS_TYPES, ScreenSafeArea } from "@widgets/Layout";
import { ForbiddenUser } from "@features/ForbiddenUser";
import { NetworkError, useErrors } from "@entities/errors";
import { Preloader } from "@shared/ui";
import { UpdateModal } from "@features/UpdateModal";
import { IS_NATIVE_APP } from "@shared/const";
import { useScreenSize } from "@shared/lib";

const store = useStore();
const route = useRoute();

const { isDesktop, isMobile, isTablet } = useScreenSize();

// TODO Сделать гибче
const layout = computed(() => {
	if (typeof route.meta.layout === "object") {
		if (isDesktop.value && route.meta.layout?.desktop) {
			return LAYOUTS[route.meta.layout.desktop];
		}
		if (isMobile.value && route.meta.layout?.mobile) {
			return LAYOUTS[route.meta.layout.mobile];
		}
		if (isTablet.value && route.meta.layout?.tablet) {
			return LAYOUTS[route.meta.layout.tablet];
		}

		return LAYOUTS[LAYOUTS_TYPES.CLEAN];
	}
	const currentLayout = LAYOUTS[route.meta.layout] || LAYOUTS[LAYOUTS_TYPES.CLEAN];
	return currentLayout;
});

// TODO ВЫНЕСТИ В КОНСТАНТЫ
const BG_COLORS = {
	WHITE: "white",
};

const bgColorsValues = Object.values(BG_COLORS);

const setBackground = (bgColor) => {
	if (bgColorsValues.includes(bgColor)) {
		document.body.classList.add(`${bgColor}-bg`);
	} else {
		document.body.removeAttribute("class");
	}
};

watchEffect(() => {
	if (typeof route.meta.bgColor === "object") {
		if (isDesktop.value && route.meta?.bgColor?.desktop) {
			setBackground(route.meta?.bgColor?.desktop);
			return;
		}
		if (isMobile.value && route.meta?.bgColor?.mobile) {
			setBackground(route.meta?.bgColor?.mobile);
			return;
		}
		if (isTablet.value && route.meta?.bgColor?.tablet) {
			setBackground(route.meta?.bgColor?.tablet);
			return;
		}
	}
	setBackground(route.meta?.bgColor);
});

const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});

const { isNetworkError } = useErrors();
</script>
<template>
	<ScreenSafeArea>
		<UpdateModal v-if="IS_NATIVE_APP" />
		<Preloader>
			<NetworkError v-if="isNetworkError" />
			<ForbiddenUser v-else-if="user.banned" />
			<component :is="layout" v-else>
				<slot />
			</component>
		</Preloader>
	</ScreenSafeArea>
</template>

<style lang="scss">
body.white-bg {
	background-color: $white;
}
</style>
