<script setup>
import { TYPOGRAPHY_TYPES, Typography } from "@shared/ui";
import { useTranslation } from "i18next-vue";

const { t } = useTranslation();
</script>

<template>
	<div class="empty-list-wrapper">
		<div class="empty-list-text-head">
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">{{ t("emptyTitle") }}</Typography>
		</div>
	</div>
</template>

<style lang="scss" scoped>
@import "./EmptyList.scss";
</style>
