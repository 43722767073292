import { getApiUrl, getApiUrlV2, HTTP_METHODS } from "@shared/const";
import { httpRequest } from "@shared/lib";

const ENDPOINT = "announcement";
const ONE_ANNOUNCEMENT = "announcement_card";

/** @param {GetAnnouncmentRequestParams} params */
export const apiGetAnnouncment = async (params, controller) => {
	const url = getApiUrl(ENDPOINT);

	return httpRequest({ method: HTTP_METHODS.POST, url }, { body: params, controller });
};

/**
 * @typedef {{
 * 	page: number;
 * 	on_page: number;
 * 	order_field?: "DATE";
 * 	order_direction?: import("@shared/const").Sort;
 * 	region_id?: number;
 * 	user_id?: number;
 * 	is_favorite?: boolean;
 * 	category_id?: number;
 * 	sub_category_id?: number;
 * }} GetAnnouncmentRequestParams
 */

export const apiGetCategories = (params) => {
	const url = getApiUrl("dimensions/categories");
	return httpRequest({ method: HTTP_METHODS.GET, url }, { query: params });
};

export const apiGetCities = (params) => {
	const url = getApiUrlV2("dimensions/cities");
	return httpRequest({ method: HTTP_METHODS.GET, url }, { query: params });
};

export const apiPostAnnouncement = (params) => {
	const url = getApiUrl(ONE_ANNOUNCEMENT);
	return httpRequest({ method: HTTP_METHODS.POST, url }, { body: params });
};

export const apiGetAnnouncement = (params) => {
	const url = getApiUrl(ONE_ANNOUNCEMENT);
	return httpRequest({ method: HTTP_METHODS.GET, url }, { query: params });
};

export const apiPostADPhoto = (params) => {
	const url = getApiUrl("files");
	return httpRequest(
		{ method: HTTP_METHODS.POST, url },
		{ query: params.query, formDataName: "files", formData: params.file }
	);
};

export const apiDeleteADPhoto = (params) => {
	const url = getApiUrl("files");
	return httpRequest({ method: HTTP_METHODS.DELETE, url }, { query: params });
};

export const apiGetADPhoto = (params) => {
	const url = getApiUrl("files");
	return httpRequest({ method: HTTP_METHODS.GET, url }, { query: params });
};

export const apiPostPreferAnnouncement = (params) => {
	const url = getApiUrl("announcement_prefer");
	return httpRequest({ method: HTTP_METHODS.POST, url }, { body: params });
};

export const apiFindUser = (params, controller) => {
	const url = getApiUrl("usersFind");
	return httpRequest({ method: HTTP_METHODS.GET, url }, { query: params, controller });
};

export const apiGetCurrencies = () => {
	const url = getApiUrl("dimensions/currencies");
	return httpRequest({ method: HTTP_METHODS.GET, url });
};

export const apiGetTags = () => {
	const url = getApiUrl("dimensions/tags");
	return httpRequest({ method: HTTP_METHODS.GET, url });
};
