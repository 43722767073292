import "@shared/styles/global/index.scss";
import VueTippy from "vue-tippy";
import "tippy.js/dist/tippy.css";
import "tippy.js/animations/shift-away.css";
import vClickOutside from "click-outside-vue3";
import ToastPlugin from "vue-toast-notification";
import "vue-toast-notification/dist/theme-default.css";
import { registerDirectives, useScreenSafeArea, initFirebase, initSentry } from "@shared/lib";
import { createApp } from "vue";
import I18NextVue from "i18next-vue";
import i18next from "i18next";
import { router } from "@pages";
import { IS_DEV_BUILD, IS_DEV_PWA, IS_NATIVE_APP, IS_PROD_WEB } from "@shared/const";
import qs from "qs";
import { App as CapacitorApp } from "@capacitor/app";
import { initI18n } from "./lib";
import App from "./App.vue";
import store from "./store";

const app = createApp(App);

const { init: initSafeScreenArea } = useScreenSafeArea();

const initApp = async () => {
	await initSentry(app, router);
	const lang = new URL(window.location).pathname.split("/")[1];
	await initI18n(lang);
	initSentry(app, router);
	if (IS_NATIVE_APP || IS_PROD_WEB || IS_DEV_PWA || IS_DEV_BUILD) {
		initFirebase();
	}
	initSafeScreenArea();
	app.use(I18NextVue, { i18next });
	app.use(router);
	app.use(VueTippy, { defaultProps: { animation: "shift-away", theme: "light", arrow: false } });
	app.use(store);
	app.use(vClickOutside);
	app.use(ToastPlugin, {
		position: "top-right",
	});
	registerDirectives(app);
	app.mount("#app");
};

initApp();

// TODO УБРАТЬ КУДА-НИБУДЬ
// DEEP-LINKS
CapacitorApp.addListener("appUrlOpen", (event) => {
	const url = new URL(event.url);

	if (url.pathname !== "/" || url.search) {
		const query = qs.parse(url.search.split("?").pop());

		router.push({
			path: url.pathname,
			query,
		});
	}
});
