export default {
	ru: {
		requiredHelpers: "Обязательно для заполнения",
		requiredContact: "Укажите хотя бы один контакт",
	},
	en: {
		requiredHelpers: "Required to be filled",
		requiredContact: "Specify at least one contact.",
	},
	ka: {
		requiredHelpers: "სავალდებულოა რომ შეავსოთ",
		requiredContact: "მიუთითეთ ერთი საკონტაქტო მაინც",
	},
	tr: {
		requiredHelpers: "Doldurulması gerekmektedir",
		requiredContact: "En az bir kişi belirtin",
	},
};
