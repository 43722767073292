export default {
	ru: {
		yesThanks: "Да, спасибо",
		anotherText1: "другой",
		anotherText2: "другая",
	},
	en: {
		yesThanks: "Yes, Thank you",
		anotherText1: "Other",
		anotherText2: "Another",
	},
	ka: {
		yesThanks: "კი, მადლობთ",
		anotherText1: "სხვა",
		anotherText2: "სხვა",
	},
	tr: {
		yesThanks: "Evet, teşekkür ederiz",
		anotherText1: "Diğer",
		anotherText2: "Başka",
	},
};
