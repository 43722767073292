import { computed, onActivated, onDeactivated, onUnmounted, ref, unref, watch } from "vue";
import { useStore } from "vuex";
import { REQUST_STATUSES } from "@shared/const";
import { nanoid } from "nanoid";
import { useRefHistory } from "@vueuse/core";
import { isEqual } from "lodash-es";

/**
 * @param {import("vue").MaybeRef<Record<string,any>} params
 * @returns
 */
export const useAnnouncementStore = (params = {}) => {
	const store = useStore();
	const id = nanoid(10);
	const currentParams = ref();

	const { history } = useRefHistory(currentParams, { capacity: 2 });

	const setNewParams = (params) => {
		currentParams.value = params;
	};

	const isDeactivated = ref(false);

	onDeactivated(() => {
		isDeactivated.value = true;
	});

	onActivated(() => {
		isDeactivated.value = false;
	});

	watch(history, () => {
		const prevParams = history.value[0]?.snapshot;
		const nextParams = history.value[1]?.snapshot;
		if (prevParams && nextParams && !isEqual(prevParams, nextParams)) {
			getAnnouncement();
		}
	});

	setNewParams(unref(params.value));

	const getAnnouncement = () => {
		return store.dispatch("announcement/getAnnouncementById", {
			id,
			params: unref(currentParams),
		});
	};

	const updateAnnouncements = () => {
		return store.dispatch("announcement/updateAnnouncementById", {
			id,
			params: unref(currentParams),
		});
	};

	const abortAnnouncements = () => {
		return store.dispatch("announcement/abortAnnouncementsById", {
			id,
		});
	};

	const clearAnnouncementById = () => {
		return store.commit("announcement/CLEAR_ANNOUNCEMENT_BY_ID", id);
	};

	onUnmounted(() => {
		abortAnnouncements();
		clearAnnouncementById();
	});

	const announcementsList = computed(() => {
		return store.getters["announcement/announcementsById"](id)?.items || [];
	});

	const isFullfield = computed(() => {
		return !!store.getters["announcement/announcementsById"](id)?.isFullfield;
	});

	const announcementsListStatus = computed(() => {
		return store.getters["announcement/announcementsStatusById"](id);
	});

	const isSuccess = computed(() => {
		return store.getters["announcement/announcementsStatusById"](id) === REQUST_STATUSES.SUCCESS;
	});

	const isPending = computed(() => {
		return store.getters["announcement/announcementsStatusById"](id) === REQUST_STATUSES.PENDING;
	});

	const isError = computed(() => {
		return store.getters["announcement/announcementsStatusById"](id) === REQUST_STATUSES.ERROR;
	});

	const isEmptyList = computed(() => {
		const isEmpty = announcementsList.value.length === 0;
		return isSuccess.value && isEmpty;
	});

	watch(
		[params, isDeactivated],
		() => {
			if (params && !isDeactivated.value) {
				setNewParams(unref(params));
			}
		},
		{ deep: true }
	);

	return {
		announcementsList,
		announcementsListStatus,
		isPending,
		isError,
		isEmptyList,
		isFullfield,
		getAnnouncement,
		updateAnnouncements,
		setNewParams,
	};
};
