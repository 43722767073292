export default {
	ru: {
		headerMain: "Главная",
		headerCatalog: "Каталог",
		headerPolicy: "Политика и Правила",
		headerFavorites: "Избранное",
		headerChats: "Чаты",
		headerEntrance: "Вход",
		headerSearchPlaceholder: "Поиск по объявлениям",
		headerSearchPlaceholder2: "Поиск",
	},
	en: {
		headerMain: "Home",
		headerCatalog: "Catalog",
		headerPolicy: "Policy and Rules",
		headerFavorites: "Favorites",
		headerChats: "Chats",
		headerEntrance: "Login",
		headerSearchPlaceholder: "Search by ads",
		headerSearchPlaceholder2: "Search",
	},
	ka: {
		headerMain: "მთავარი",
		headerCatalog: "კატალოგი",
		headerPolicy: "პოლიტიკა და წესები",
		headerFavorites: "რჩეული",
		headerChats: "ჩათი",
		headerEntrance: "შესვლა",
		headerSearchPlaceholder: "განცხადების ძიება",
		headerSearchPlaceholder2: "ძიება",
	},
	tr: {
		headerMain: "Ana sayfa",
		headerCatalog: "Katalog",
		headerPolicy: "Politika ve kurallar",
		headerFavorites: "Favorilerim",
		headerChats: "Sohbetler",
		headerEntrance: "Giriş",
		headerSearchPlaceholder: "İlan aratın",
		headerSearchPlaceholder2: "Arama",
	},
};
