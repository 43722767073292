<script setup>
import { ref, onUnmounted, computed, nextTick, watch } from "vue";
import { useHaptics, useScreenSize } from "@shared/lib";

import { nanoid } from "nanoid";
import ModalMobile from "./ModalMobile.vue";
import ModalDesktop from "./ModalDesktop.vue";
import { useModalScrollLock } from "./useModalScrollLock";

const props = defineProps({
	fullExpandMobile: { type: Boolean, default: false },
	disableClose: { type: Boolean, default: false },
	hideCloseBtn: { type: Boolean, default: false },
	showBackGround: { type: Boolean, default: false },
	isOpen: { type: Boolean, default: false },
	noPaddingContent: { type: Boolean, default: false },
	priority: { type: Number, default: 1 },
	hideTopBar: { type: Boolean, default: false },
});

const emit = defineEmits(["open", "close", "content-scroll"]);

const mobileModal = ref();
const id = nanoid(10);

const { impactMedium } = useHaptics();

const onClose = () => {
	if (!props.disableClose) {
		close();
	}
};

const onKeydown = (e) => {
	if (e.key === "Escape") {
		onClose();
	}
};

const showMobileModal = () => {
	nextTick(() => {
		mobileModal.value?.show();
	});
};

const hideMobileModal = async () => {
	await mobileModal.value?.hide();
};

const { addModal, removeModal } = useModalScrollLock();

const onLeave = () => {
	removeModal(id);
};

const close = async () => {
	if (isOpen.value === true) {
		impactMedium();
		await hideMobileModal();
		nextTick(() => {
			isOpen.value = false;
			removeModal(id);
			emit("close");
		});
	}
};

const open = () => {
	if (isOpen.value === false) {
		isOpen.value = true;
		nextTick(() => {
			impactMedium();
			showMobileModal();
			addModal(id);
			document.addEventListener("keydown", onKeydown);
			emit("open");
		});
	}
};

const isOpen = ref(false);

const contentRef = computed(() => {
	if (isDesktop.value) {
		return null;
	}

	return mobileModal.value?.contentRef;
});

defineExpose({ close, open, contentRef });

onUnmounted(() => {
	onLeave();
});

const { isDesktop } = useScreenSize();

watch(isDesktop, () => {
	if (!isDesktop.value) {
		if (isOpen.value) {
			showMobileModal();
		} else {
			hideMobileModal();
		}
	}
});

const onContentScroll = (e) => emit("content-scroll", e);
</script>

<template>
	<Teleport to="#app">
		<Transition name="fade-modal">
			<div
				v-if="isOpen"
				class="modal"
				:style="{ zIndex: priority + 10000 }"
				:class="{ modal_mobile: !isDesktop, modal_desktop: isDesktop }"
			>
				<ModalMobile
					v-if="!isDesktop"
					ref="mobileModal"
					:full-expand="fullExpandMobile"
					:disable-close="disableClose"
					:hide-close-btn="hideCloseBtn"
					:show-back-ground="showBackGround"
					:no-padding-content="noPaddingContent"
					:hide-top-bar="hideTopBar"
					@close="close"
					@content-scroll="onContentScroll"
				>
					<template #title>
						<slot name="mobile-title"></slot>
					</template>
					<slot />
				</ModalMobile>

				<ModalDesktop
					v-if="isDesktop"
					:disable-close="disableClose"
					:hide-close-btn="hideCloseBtn"
					:show-back-ground="showBackGround"
					:no-padding-content="noPaddingContent"
					@close="close"
				>
					<slot />
				</ModalDesktop>
			</div>
		</Transition>
	</Teleport>
</template>

<style lang="scss" scoped>
@import "./Modal.scss";
</style>
