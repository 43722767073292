<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import { GROUPS } from "@widgets/const";
import { Collapse, TYPOGRAPHY_TYPES, Typography, SvgTemplate } from "@shared/ui";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	group: {
		type: Number,
		required: true,
		default: null,
	},
	category: {
		type: Number,
		default: null,
	},
	subcategory: {
		type: Number,
		default: null,
	},
	prevSelecteCategory: {
		type: Number,
		default: null,
	},
	prevSelecteSubCategory: {
		type: Number,
		default: null,
	},
});

const store = useStore();
const { t } = useTranslation();

const emit = defineEmits(["update:category", "update:subcategory", "update:group", "select"]);

const colapses = ref({});
const selectedCategory = ref(null);
const selectedSubCategory = ref(null);

const categories = computed(() => {
	return store.getters["announcement/categories"];
});

const subCategories = (categoryId) => {
	return store.getters["announcement/subCategories"](categoryId);
};

const back = () => {
	emit("update:category", null);
	emit("update:subcategory", null);
	emit("update:group", null);
};

const selectCat = (catId) => {
	colapses.value[selectedCategory.value]?.closeCollapse();
	selectedCategory.value = catId;
	if (subCategories(catId).length === 0) finishSelect();
};

const selectSubCat = (subcatId) => {
	selectedSubCategory.value = subcatId;
	finishSelect();
};

const finishSelect = () => {
	setTimeout(() => {
		window.scrollTo(0, 0);
		emit("update:category", selectedCategory.value);
		emit("update:subcategory", selectedSubCategory.value);
		emit("select");
	}, 100);
};

const categoriesByGroup = computed(() => {
	return categories.value.filter((x) => x.section_id === props.group);
});

watch(
	() => categoriesByGroup,
	(val) => {
		if (val.value.length === 1) {
			selectCat(categoriesByGroup.value[0].id);
		}
	},
	{ immediate: true }
);
</script>
<template>
	<div class="categories">
		<div class="categories__back-title" @click.stop="back()">
			<SvgTemplate name="left-arrow" />
			<Typography :type="TYPOGRAPHY_TYPES.TITLE_2">
				{{ t(GROUPS[group].name) }}
			</Typography>
		</div>
		<Collapse
			:ref="(el) => (colapses[cat.id] = el)"
			class="categories__category"
			:opened="(selectedCategory || prevSelecteCategory) === cat.id"
			:is-show-chevron="subCategories(cat.id).length"
			v-for="cat in categoriesByGroup"
			:key="cat.id"
			@open="selectCat(cat.id)"
		>
			<template #title>
				<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">
					{{ cat.name }}
				</Typography>
			</template>
			<template #content>
				<div
					class="categories__subcategory"
					:class="{
						categories__subcategory_selected:
							(selectedSubCategory || prevSelecteSubCategory) === subcat.id,
					}"
					v-for="subcat in subCategories(cat.id)"
					:key="subcat.id"
					@click.stop="selectSubCat(subcat.id)"
				>
					<Typography :type="TYPOGRAPHY_TYPES.BUTTON_2">
						{{ subcat.name }}
					</Typography>
				</div>
			</template>
		</Collapse>
	</div>
</template>
<style lang="scss" scoped>
@import "./Category.scss";
</style>
