import { REQUST_STATUSES } from "@shared/const";

export default {
	status: {
		review: REQUST_STATUSES.OUTDATED,
		userReviews: REQUST_STATUSES.OUTDATED,
	},
	reviews: {
		avg_rate: 0,
		feedbackCount: 0,
		isFullfield: false,
		items: [],
		page: 1,
	},
	userReviews: {
		grade: 0,
		reviewsCount: 0,
		items: [],
		isNeedUpdate: false,
		page: 1,
	},
	feedbackCount: {},
	feedbackId: 0,
};
