export const TYPOGRAPHY_TYPES = {
	TEXT_BODY_LARGE: "text-body-large",
	TEXT_BODY_LARGE_REGULAR_MEDIUM: "text-body-large-regular-medium",
	CAPTION_2: "caption-2",
	CAPTION_5: "caption-5",
	TEXT_BODY_XLARGE: "text-body-xlarge",
	TEXT_BODY_XLARGE_REGULAR: "text-body-xlarge-regular",
	BTN: "btn",

	// TODO Redesign
	BUTTON_1: "button-1",
	BUTTON_2: "button-2",
	BUTTON_3: "button-3",
	INPUT: "input",
	INPUT_B: "input-b",
	INPUT_2: "input-2",
	TITLE_1: "title-1",
	TITLE_2: "title-2",
	TEXT_OLD: "text-old",
	LABEL_CATEGORY: "label-category",
	PRICE_TEXT: "price-text",
	PRICE_TEXT_2: "price-text-2",
	STORIES_LINK: "stories-link",
	STORIES_TITLE: "stories-title",
	CARD_NAME: "card-name",
	CARD_NAME_2: "card-name-2",
	USER_NAME: "user-name",
	PRICE_TEXT_3: "price-text-3",
	TEXT_BANNER: "text-banner",
	CONTACT_SUBTITLE: "contact-subtitle",
	SLOT_INPUT: "text-slot-input",
	DELETE_AD: "text-delete-ad",
	TEXT_LINK: "text-link",

	// SUPER new fonts name
	TEXT_LABEL_CATEGORY: "text-label-category",
	TEXT_CARD_NAME: "text-card-name",
	CARD_PRICE: "card-price",
	CARD_PRICE_2: "card-price-2",
	CARD_INFO_PRICE: "card-info-price",
	PRO_TEXT: "pro-text",
	TEXT: "text",
	TEXT_1: "text-1",
	CARD_TITLE: "card-title",
	TITLE_3: "title-3",
	CONTACT: "contact",
	ANNOUNCEMENT_CARD_PRICE: "announcement-card-price",
	BUTTON_4: "button-4",
	BOLD_TEXT: "bold-text",
	TAG: "tag",
};
