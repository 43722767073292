<script setup>
import { computed, ref, onMounted } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";
import { useEventBus } from "@vueuse/core";
import { TYPOGRAPHY_TYPES, Typography, ModalConfirm } from "@shared/ui";
import { isNil } from "lodash-es";
import { useTranslation } from "i18next-vue";

const props = defineProps({
	preventBack: {
		type: Boolean,
		default: false,
	},
});

const store = useStore();
const router = useRouter();
const { t } = useTranslation();

const closeCreateModal = useEventBus("closeCreateModal");

const modalRef = ref(null);

const user = computed(() => store.getters["user/getCurrentUser"]);

const userProfileOk = computed(() => {
	return (
		!isNil(user.value.first_name) &&
		user.value.first_name !== "" &&
		(!isNil(user.value.username) || !isNil(user.value.phone))
	);
});

onMounted(() => {
	if (!isNil(user.value?.user_id) && !userProfileOk.value) openModal();
});

const openModal = () => {
	modalRef.value.open();
};

const onConfirm = () => {
	modalRef.value.close();
	router.push({ name: "ProfileEdit" });
};

const onBack = () => {
	modalRef.value.close();
	if (props.preventBack) {
		closeCreateModal.emit();
		return;
	}
	router.back();
};
</script>

<template>
	<ModalConfirm
		ref="modalRef"
		@confirm="onConfirm"
		@back="onBack"
		back-text="buttonTextNotNow"
		confirm-text="buttonTextGoProfile"
		:hide-cross="true"
	>
		<div class="content">
			<Typography class="content__font" :type="TYPOGRAPHY_TYPES.TITLE_2">
				{{ t("profilePendingEditText") }}
			</Typography>
		</div>
	</ModalConfirm>
</template>

<style lang="scss" scoped>
@import "./ProfilePendingEdit.scss";
</style>
