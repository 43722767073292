<script setup>
import { computed, watch } from "vue";
import { useStore } from "vuex";
import { TYPOGRAPHY_TYPES, Typography, SvgTemplate } from "@shared/ui";
import { plural } from "@shared/lib";
import { useTranslation } from "i18next-vue";

const store = useStore();
const { t } = useTranslation();

const user = computed(() => {
	return store.getters["user/getCurrentUser"];
});

watch(
	() => user.value,
	async () => {
		await store.dispatch("reviews/getReviewsAnnouncement", { seller_id: user.value.user_id });
		await store.dispatch("user/getUser", user.value?.user_id);
	},
	{ immediate: true }
);

const announcementDescription = computed(() => {
	const count = store.getters["user/getUser"](user.value.user_id).count_ad;

	if (count === undefined) {
		return false;
	}
	return count === 0
		? t("myActivityMenuTextNotActiveAds")
		: `${t("myActivityMenuTextSutext2")} ${count} ${plural(
				[
					t("myActivityMenuTextActive1"),
					t("myActivityMenuTextActive2"),
					t("myActivityMenuTextActive3"),
				],
				count
		  )} ${plural(
				[t("myActivityMenuTextAds1"), t("myActivityMenuTextAds2"), t("myActivityMenuTextAds3")],
				count
		  )}`;
});

const reviewsCount = computed(() => {
	const count = store.getters["reviews/getReviews"].feedbackCount;
	if (count === undefined) {
		return false;
	}
	return count === 0
		? t("myActivityMenuTextSutext1")
		: `${t("myActivityMenuTextSutext2")} ${count} ${plural(
				[t("reviewsCountText1"), t("reviewsCountText2"), t("reviewsCountText3")],
				count
		  )}`;
});
</script>
<template>
	<div class="navigation-block__support">
		<RouterLink :to="{ name: 'myAnnouncements' }">
			<div class="navigation-block__title navigation-block__title_announcements">
				<SvgTemplate name="menucard" />
				<Typography class="content_block_white_text" :type="TYPOGRAPHY_TYPES.PRO_TEXT">
					{{ t("myActivityMenuText") }}
				</Typography>
				<SvgTemplate class="navigation-block__chevron" name="chevron" />
				<Typography class="content_block__text" :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
					{{ announcementDescription }}
				</Typography>
			</div>
		</RouterLink>

		<div class="navigation-block__line" />

		<RouterLink to="/my-reviews">
			<div class="navigation-block__title navigation-block__title_reviews">
				<SvgTemplate name="star" />
				<Typography :type="TYPOGRAPHY_TYPES.PRO_TEXT">{{ t("reviewsTitle") }}</Typography>
				<SvgTemplate class="navigation-block__chevron" name="chevron" />
				<Typography class="content_block__text" :type="TYPOGRAPHY_TYPES.TEXT_LABEL_CATEGORY">
					{{ reviewsCount }}
				</Typography>
			</div>
		</RouterLink>
	</div>
</template>

<style lang="scss" scoped>
@import "./MyActivityMenu.scss";
</style>
