<script setup>
import { Image, useSuspense } from "@shared/ui";

defineProps({
	avatarUrl: {
		type: String,
		default: "",
	},
});

const { isSuspense } = useSuspense(true);

</script>
<template>
	<div class="avatar">
		<div class="avatar__circle_block">
			<Image :src="avatarUrl" :alt="аватарка" :suspense="isSuspense" />
		</div>
	</div>
</template>
<style lang="scss" scoped>
@import "./Avatar.scss";
</style>
