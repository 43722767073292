<script setup>
import { IS_IOS } from "@shared/const";

const errorMessage = () => {
	if (IS_IOS) {
		return "Ой-ой, что-то не так с соединением, попробуйте позже";
	}

	return "Ой-ой, что-то не так с соединением, обновите страницу или попробуйте позже";
};
</script>

<template>
	<div class="network-error-fallback">
		{{ errorMessage() }}
	</div>
</template>

<style lang="scss">
@import "./NetworkError.scss";
</style>
