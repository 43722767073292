<script setup>
import { TYPOGRAPHY_TYPES, Typography, SvgTemplate } from "@shared/ui";
import { apiSaveStoryActivity } from "@widgets/Stories";

const props = defineProps({
	storiesActivity: {
		type: Object,
		default: () => {},
	},
	isStoryReady: {
		type: Boolean,
		default: false,
	},
	itemId: {
		type: Number,
		default: 0,
	},
});

const isActivityType = (activeType) => {
	if (activeType && activeType === "tg_button") {
		return true;
	}
	return false;
};

const registrClick = () => {
	apiSaveStoryActivity({ item_id: props.itemId, activity: "CLICK" });
};
</script>

<template>
	<div v-if="isStoryReady && storiesActivity" class="stories-links__wrapper">
		<a
			:href="storiesActivity?.activity_url"
			target="_blank"
			rel="noopener noreferrer"
			class="stories-links__btn"
			:class="{
				'stories-links__btn--round-button': isActivityType(storiesActivity?.type?.activity_type),
				'stories-links__btn--right-bottom':
					+storiesActivity?.position.position_right === 0 &&
					+storiesActivity?.position.position_top >= 100,
				'stories-links__btn--right-ceneter':
					+storiesActivity?.position.position_right === 0 &&
					+storiesActivity?.position.position_top === 50,
				'stories-links__btn--right-top':
					+storiesActivity?.position.position_right === 0 &&
					+storiesActivity?.position.position_top === 0,
				'stories-links__btn--center-top':
					+storiesActivity?.position.position_right === 50 &&
					+storiesActivity?.position.position_top === 0,
				'stories-links__btn--center-bottom':
					+storiesActivity?.position.position_right === 50 &&
					+storiesActivity?.position.position_top === 0,
				'stories-links__btn--сontent-right': storiesActivity.svg_position === 'right',
				'stories-links__btn--сontent-top': storiesActivity.svg_position === 'top',
				'stories-links__btn--сontent-bot': storiesActivity.svg_position === 'bot',
			}"
			:style="`
                top: ${storiesActivity?.position.position_top}%;
                right: ${storiesActivity?.position.position_right}%;
                background: ${storiesActivity?.background.color};
				height: ${storiesActivity?.type?.height ? storiesActivity?.type?.height + 'px' : 'initial'};
            `"
			@click="registrClick"
		>
			<SvgTemplate
				v-if="storiesActivity.activity_svg"
				class="stories-links__svg"
				:name="storiesActivity?.activity_svg"
				:style="`color: ${storiesActivity?.svg_color?.color}`"
			/>
			<Typography
				:type="TYPOGRAPHY_TYPES.STORIES_LINK"
				class="stories-links__text"
				:style="`background: ${storiesActivity?.color.color}`"
			>
				{{ storiesActivity?.activity_label }}
			</Typography>
		</a>
	</div>
</template>

<style lang="scss" scoped>
@import "./StoriesLinks.scss";
</style>
